













import {
  Evento, Direccion, EventType, fromIndexParadaToLabel,
} from '@/models/requests-and-responses/seguimiento';
import { propOfType, propOfTypeArrayOf } from '@/services/utils';
import Vue from 'vue';
import TimelineRow, { TimelineRowModel } from './TimelineRow.vue';

function fromEventoToRow(evento: Evento | VirtualEvent, paradas: Direccion[]): TimelineRowModel {
  const paradaIndex = evento.parada;
  const { type } = evento;
  return {
    rowType: type,
    paradaLabel: paradaIndex !== undefined
      ? fromIndexParadaToLabel(paradaIndex, paradas.length)
      : undefined,
    direccion: paradaIndex !== undefined && type !== 'circulando'
      ? paradas[paradaIndex]
      : undefined,
    futurePresentOrPast: 'timestamp' in evento
      ? 'past'
      : 'future',
    timestamp: 'timestamp' in evento
      ? new Date(evento.timestamp)
      : undefined,
  };
}

type VirtualEvent = {
  type: EventType
  parada?: number // Indice a parada
}

// Comentario (1): El evento 'finalizado' no lo vamos a usar.
// En el caso de que se haya arribado a destino, ahí es cuando mostramos 'completado'.

export default Vue.extend({
  components: {
    TimelineRow,
  },
  props: {
    eventos: propOfTypeArrayOf<Evento>(),
    paradas: propOfTypeArrayOf<Direccion>(),
    minutesLeft: propOfType<number | null>(null),
  },
  computed: {
    // Devuelve todos los eventos que se supone que deben existir.
    virtualEvents(): VirtualEvent[] {
      const virtualEvents = [] as VirtualEvent[];
      virtualEvents.push({ type: 'registrado' });
      virtualEvents.push({ type: 'confirmado' });
      this.paradas.forEach((_, index) => {
        virtualEvents.push({ type: 'circulando', parada: index });
        virtualEvents.push({ type: 'arribado', parada: index });
      });

      // Leer comentario (1) de arriba.
      // Comentamos la linea porque no lo usamos:
      // virtualEvents.push({ type: 'finalizado' });

      return virtualEvents;
    },
    rows(): TimelineRowModel[] {
      const rows: TimelineRowModel[] = [];

      // Primero creamos las rows que ya pasaron.
      this.virtualEvents.forEach((event) => {
        const realEvent = this.eventos
          .find((e) => e.type === event.type && e.parada === event.parada);
        if (realEvent && realEvent.type !== 'finalizado') {
          rows.push(fromEventoToRow(realEvent, this.paradas));
        }
      });

      // Creamos, quizás, la row del presente.
      const lastEvent = this.eventos[this.eventos.length - 1];
      if (!lastEvent) {
        // Nada. No la creamos.
      } else if (lastEvent.type === 'arribado' && lastEvent.parada === this.paradas.length - 1) {
        // Nada. No la creamos.
      } else if (lastEvent.type === 'circulando') {
        rows.push({
          futurePresentOrPast: 'present',
          rowType: 'espera llegar',
        });
      } else if (lastEvent.type === 'arribado') {
        rows.push({
          futurePresentOrPast: 'present',
          rowType: 'esperando',
          timestampForWaittingClock: new Date(lastEvent.timestamp),
        });
      } else {
        // Nada. No la creamos.
      }

      // En caso de que no haya una cancelación o un fallo o una anulación...
      const croppingEvents: EventType[] = ['anulado', 'cancelado', 'fallo'];
      if (!this.eventos.some((e) => croppingEvents.includes(e.type))) {
        // Añadimos las rows del futuro, que son todas aquellos eventos que no han pasado.
        this.virtualEvents.forEach((e) => {
          const happened = this.eventos.some((e2) => e2.type === e.type && e2.parada === e.parada);
          if (!happened) {
            rows.push(fromEventoToRow(e, this.paradas));
          }
        });
      }
      return rows;
    },
  },
});
