
















































































import Vue from 'vue';
import IconChevronD from '@/components/icons/IconChevronD.vue';
import { GeneralAccount } from '@/models/accounts/GeneralAccount';
import * as generalAccount from '@/models/accounts/GeneralAccount';
import * as accountId from '@/models/accounts/AccountId';
import { dispatch, mapStateTyped } from '@/services/store/utils';
import { equals } from '@/services/utils';
import { defaultProfilePictureUri, User } from '@/models/User';

export default Vue.extend({
  components: {
    IconChevronD,
  },
  data() {
    return {
      accountSelectorOpen: false,
    };
  },
  computed: {
    ...mapStateTyped(['user', 'accounts']),
    selectedAccount(): GeneralAccount | null {
      return this.$store.getters.selectedAccount;
    },
    selectableAccounts(): GeneralAccount[] {
      return [
        // TX-1857 Quitar la opción de cuenta particular
        // generalAccount.fromParticularAccount(this.accounts.particularAccount),
        ...this.accounts?.corporateAccounts.map(generalAccount.fromCorporateAccount) || [],
      ]
        .filter((account) => this.selectedAccount !== null
          && !equals(
            accountId.fromGeneralAccount(this.selectedAccount),
            accountId.fromGeneralAccount(account),
          ));
    },
    profilePictureUrl(): string {
      return this.user?.profilePictureUrl || defaultProfilePictureUri;
    },
  },
  methods: {
    userName(user: User | null): string {
      return user ? user.nombre : '-';
    },
    accountAlias(account: GeneralAccount | null): string {
      return account ? generalAccount.getAlias(account) : '-';
    },
    accountEmail(account: GeneralAccount | null): string | null {
      return account ? generalAccount.getEmail(account) : '-';
    },
    onAccountClick(account: GeneralAccount) {
      dispatch('selectAccount', account);
      this.accountSelectorOpen = false;
    },
  },
});
