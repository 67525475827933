






























import Vue from 'vue';
import { capitalizeText, formatNumber, propOfType } from '@/services/utils';
import { ServicioDisponible } from '@/models/taaxii.com/taaxiiServicioDisponible';
import { eventBus } from '@/main';
import ServiceIcon from './ServiceIcon.vue';

export default Vue.extend({
  components: {
    ServiceIcon,
  },
  props: {
    selected: Boolean,
    service: propOfType<ServicioDisponible>(),
    description: String,
    mostrarTiempoDeEspera: Boolean,
    dateRegreso: propOfType<Date | null>(null),
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    idTipoVehiculo(): number {
      return this.service.idTipoVehiculo;
    },
    name(): string {
      return capitalizeText(this.service.tipoVehiculo);
    },
    time(): string {
      const { esperaMin, esperaMax } = this.service;
      if (esperaMin) {
        return `en ${esperaMin} min`;
      }
      return `pasará el ${esperaMin} hs`;
    },
    money(): string {
      if (this.service.precotizacion < 0) return 'Sin cotización';
      return `$${formatNumber(this.service.precotizacion) + (this.dateRegreso
        ? ' (por tramo)' : '')}`;
    },
  },
  methods: {
    showServiceDetail() {
      eventBus.$emit('showServiceDetail', this.service);
    },
  },
});
