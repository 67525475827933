import { AddUseReq, PostDireccionesFavoritasReq } from '@/models/requests-and-responses/direcciones';
import { get, post, delet } from '../http';
import { Coords } from '../Coords';

export const favouriteAddress = {
  getAddress: () => get('/direcciones/v2/favoritas/'),
  addAddress: (data: PostDireccionesFavoritasReq) => post('/direcciones/v2/favoritas/', { ...data }),
  deleteAddress: (idDir: number) => delet('/direcciones/v2/favoritas/$id', { id: idDir }),
};

export const corporativeAddress = {
  getAddress: (idCliente: number) => get('/direcciones/v1/cliente/$idCliente/', { idCliente }),
  addAddress: (data: PostDireccionesFavoritasReq, idCliente: number) => post('/direcciones/v1/cliente/$idCliente/', { idCliente, ...data }),
  deleteAddress: (idDir: number, idCliente: number) => delet('/direcciones/v1/cliente/$idCliente/$id/', { idCliente, id: idDir }),
};

export const gralAddress = {
  getAddress: (
    query: string,
    cliente: number,
    external: boolean,
    center: { lat: number, lon: number },
    signal?: AbortSignal,
  ) => {
    const { lat, lon } = center;
    return get('/direcciones/v2/search/', {
      query,
      cliente,
      external,
      lat,
      lon,
    }, { signal });
  },
  getRoute: (data: Array<Coords>, signal?: AbortSignal) => {
    const ruta = data.map(({ lon, lat }) => `${lat},${lon}`).join(':');
    return get('/direcciones/rutas/v3/$ruta/', {
      ruta,
    }, { signal });
  },
  isTerminal: (street: string) => get('/direcciones/terminales/', {
    street,
  }),
  addUse: ({ id, type }: AddUseReq) => post('/direcciones/v1/usos/$id/$type/', { id, type }),
};
