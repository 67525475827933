import ProveedorData from './taaxiiProveedorData';
import GenericPerson from './taaxiiGenericPerson';
import { Importe } from './taaxiiImporte';
import VehiculoData from './taaxiiVehiculoData';
import { SolicitudParada, SolicitudPasaje } from '../ExtrasData';
import { GenericClient } from '../employees/Cliente';
import { CalificacionInfo } from '../requests-and-responses/sgv';

export default class ViajeExtras {
  idViaje: number

  idViajeTservice: number

  type: 'v'

  voucherNro: string

  fechaFacturacion: string

  tipoRegistro: string

  inicio: Date

  creado?: Date

  confirmado?: Date

  fin?: Date

  solicitante: GenericPerson

  cliente: GenericClient

  agencia?: ProveedorData

  vehiculo?: VehiculoData

  chofer?: GenericPerson

  cotizacion: number

  distancia: number

  duracion: number

  duracionCotizada: number

  importeTotal: number

  importeIva: number

  importePlus: number

  detalle?: Importe[]

  paradas: SolicitudParada[]

  pasajes: SolicitudPasaje[]

  calificaciones: CalificacionInfo[]

  constructor(data) {
    this.idViaje = data.idViaje;
    this.idViajeTservice = data.idViajeTservice;
    this.type = data.type;
    this.voucherNro = data.voucherNro;
    this.fechaFacturacion = data.fechaFacturacion;
    this.tipoRegistro = data.tipoRegistro;
    this.inicio = new Date(data.inicio);
    this.creado = data.creado ? new Date(data.creado) : undefined;
    this.confirmado = data.confirmado ? new Date(data.confirmado) : undefined;
    this.fin = data.fin ? new Date(data.fin) : undefined;
    this.solicitante = data.solicitante;
    this.cliente = data.cliente;
    this.agencia = data.agencia;
    this.vehiculo = data.vehiculo;
    this.chofer = data.chofer;
    this.cotizacion = data.cotizacion;
    this.distancia = data.distancia;
    this.duracion = data.duracion;
    this.duracionCotizada = data.duracionCotizada;
    this.importeTotal = data.importeTotal;
    this.importeIva = data.importeIva;
    this.importePlus = data.importePlus;
    this.detalle = data.detalle;
    this.paradas = data.paradas;
    this.pasajes = data.pasajes;
    this.calificaciones = data.calificaciones;
  }
}
