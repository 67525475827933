









import Vue from 'vue';
import { propOfType } from '@/services/utils';

import IconCheck20 from '@/components/icons/IconCheck20.vue';

export default Vue.extend({
  components: { IconCheck20 },
  props: {
    disabled: propOfType<boolean>(false),
    id: propOfType<number>(null),
    email: propOfType<string>(''),
    value: propOfType<boolean>(false),
    inputClass: propOfType<string | null>(null),
  },
  data() {
    return {
      model: this.value,
      focused: false,
    };
  },
  watch: {
    value() {
      this.model = this.value;
    },
  },
  methods: {
    onFocus() {
      this.focused = true;
      this.$emit('focus');
    },
    onBlur() {
      this.focused = false;
      this.$emit('blur');
    },
    addToInviteList() {
      if (this.disabled && !this.value) {
        this.$emit('maxInvites');
        return;
      }
      this.model = !this.model;
      this.$emit('input', this.model, this.id, this.email);
    },
  },
});
