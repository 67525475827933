import { UserAccounts } from '@/models/accounts/UserAccounts';
import { AccountId } from '@/models/accounts/AccountId';
import { User } from '@/models/User';
import { GeneralAccount, fromCorporateAccount, fromParticularAccount } from '@/models/accounts/GeneralAccount';
import * as userAccounts from '@/models/accounts/UserAccounts';
import { Permissions } from '@/models/accounts/shared';

export type State = {
  user: User | null,
  accounts: UserAccounts | null,
  selectedAccountId: AccountId | null,
  isMobile: boolean,
  navbarHeight: number,
}

export function initialState(): State {
  return {
    user: null,
    accounts: null,
    selectedAccountId: null,
    isMobile: false,
    navbarHeight: 0,
  };
}

export function getUser(s: State): User | null {
  return s ? s.user : null;
}

export function getSelectedAccount(s: State): GeneralAccount | null {
  if (s.accounts === null) {
    return null;
  }

  if (s.selectedAccountId === null) {
    const corporateAccount = s.accounts.corporateAccounts[0];

    if (corporateAccount !== undefined) {
      return fromCorporateAccount(corporateAccount);
    }

    if (s.accounts.particularAccount) {
      return fromParticularAccount(s.accounts.particularAccount);
    }

    return null;
  }

  return userAccounts.findById(s.selectedAccountId, s.accounts);
}

export function getIdCliente(s: State): number {
  const account = getSelectedAccount(s);
  return account?.tag === 'corporateAccount'
    ? account.account.idClientePadre : 0;
}

export function getPermissions(s: State): Permissions | null {
  const account = getSelectedAccount(s);
  return account?.tag === 'corporateAccount'
    ? account.account.permisos : null;
}

export function getIsMobile(s: State): boolean {
  return s.isMobile;
}

export function getNavbarHeight(s: State): number {
  return s.navbarHeight;
}
