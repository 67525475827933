import TomTom from '@tomtom-international/web-sdk-maps';

/** Este módulo contiene estado y funciones necesarias para cachear el mapa de TomTom y así
 * poder usar la misma instancia en todas las pantallas,
 * ahorrándonos el costo de inicialización ($$).
 *
 * Cada vez que se instancia un componente `Map`, se le asigna un Id único.
 * A ese Id lo usamos para ignorar todos los "mensajes" que no vengan de la última instancia creada.
 *
 * Las funciones de este archivo están pensadas para ser usadas solamente por `Map.vue`.
 */

export type NoCache = { tag: 'NoCache' };

export type Id = { tag: 'Id', value: number }

export type Cache = {
  tag: 'Cache',
  id: Id,
  element: HTMLElement,
  tomTomMap: TomTom.Map,
  tomTomMarkers: Array<TomTom.Marker | undefined>,
};

function nextId(mapCache: Cache | NoCache): Id {
  if (mapCache.tag === 'NoCache') {
    return { tag: 'Id', value: 0 };
  }
  return { tag: 'Id', value: mapCache.id.value + 1 };
}

/** Un `id` para asignarle a las instancias que no fueron inicializadas. */
export function invalidId(): Id {
  return { tag: 'Id', value: -1 };
}

/** Esta es una variable global.
 * No usé Vuex porque Typescript no me lo reconoce.
*/
let cache: Cache | NoCache = { tag: 'NoCache' };

export function create(args: { map: TomTom.Map, element: HTMLElement, id: Id }) {
  const { map, element, id } = args;

  cache = {
    tag: 'Cache',
    id,
    tomTomMap: map,
    element,
    tomTomMarkers: [],
  };
}

/** La instancia que llama a esta función se "adueña"
 * del mapa cacheado. Actualiza y devuelve el `Id` actual.
 *
 * Las llamadas a `getInstance` que usen un `Id` diferente van a devolver `null`.
*/
export function registerNewInstance(): Id {
  if (cache.tag === 'Cache') {
    const id = nextId(cache);
    cache = { ...cache, id };
    return id;
  }
  return { tag: 'Id', value: 0 };
}

/** Devuelve un `Cache` si existe (o sea si el mapa fue inicializado)
 * y además el `id` corresponde a la instancia de Map más reciente.
 *
 * De esta forma, al crear un nuevo Map, todas las llamadas de instancias
 * anteriores son ignoradas porque su `id` ya no es válido.
*/
export function getFromId(id: Id): Cache | null {
  if (cache.tag === 'Cache' && cache.id.value === id.value) {
    return cache;
  }
  return null;
}

export function updateMarkers(
  tomTomMarkers: Array<TomTom.Marker | undefined>,
  id: Id,
): Cache | null {
  const instance = getFromId(id);

  if (instance !== null) {
    cache = {
      ...instance,
      tomTomMarkers,
    };
    return cache;
  }

  return null;
}

/**
 * Una cosa habitual que se quiere hacer es agregar un layer.
 *
 * Esta función es parecida a `tomTomMap.addLayer` excepto que
 * protege contra algunas excepciones conocidas.
 *
 * Sin usar esta función, el código se vería algo así:
 * https://devforum.tomtom.com/t/create-addlayer-line-from-coordinates/783
 *
 * Pero en https://gitlab.com/taaxii-develop/miitaaxii/-/merge_requests/118 dejé un comentario
 * explicando por qué este enfoque puede fallar.
 *
 * El argumento `layerOptions` sirve para pasar las mismas opciones que pasaría a la función
 * `addLayer`, excepto que recibe el `id` y el `source` del Layer como parámetro. Se espera que
 * el objeto que devuelve esta función tenga la propiedad `id` y `source` con el valor de estos
 * parámetros. (Ver ejemplo en `Map.vue`)
 *
 * ```
 * const layerOptions = (id, source) => ({ id, source, type: 'line', paint: { ... } })
 * ```
 */
export function addLayer(
  id: Id,
  name: string,
  layerOptions: (layerId: string, layerSource: string, layerColor: string) => TomTom.AnyLayer,
  geoJson: any,
  routeColor: string,
): Cache | null {
  const instance = getFromId(id);

  if (instance !== null) {
    removeLayerAndSource(instance, name);
    createLayerAndSource(instance, name, layerOptions, geoJson, routeColor);
    return instance;
  }

  return null;
}

export function clearLayer(
  id: Id,
  name: string,
): void {
  const instance = getFromId(id);

  if (instance !== null) {
    removeLayerAndSource(instance, name);
  }
}

function removeLayerAndSource(instance: Cache, name: string): void {
  const { layerName, sourceName } = getLayerAndSourceName(name);

  if (instance.tomTomMap.getLayer(layerName)) {
    instance.tomTomMap.removeLayer(layerName);
  }
  if (instance.tomTomMap.getSource(sourceName)) {
    instance.tomTomMap.removeSource(sourceName);
  }
}

function createLayerAndSource(
  instance: Cache,
  name: string,
  layerOptions: (layerName: string, sourceName: string, layerColor: string) => TomTom.AnyLayer,
  geoJson: any,
  routeColor: string,
): void {
  const { layerName, sourceName } = getLayerAndSourceName(name);

  instance.tomTomMap.addSource(
    sourceName,
    {
      type: 'geojson',
      data: geoJson,
    },
  );

  instance.tomTomMap.addLayer(layerOptions(layerName, sourceName, routeColor));
}

function getLayerAndSourceName(name: string): { layerName: string, sourceName: string } {
  return {
    layerName: `${name}-layer`,
    sourceName: `${name}-source`,
  };
}
