
import Address from './indexAddress.vue';

export default Address.extend({
  computed: {
    isDestination(): boolean {
      return true;
    },
    error() {
      if (this.origenDestinoIguales) return 'Seleccione un destino válido';
      if (!this.userTriedToConfirm) return '';
      if (this.selectedAddress) return '';
      return 'Seleccione un destino';
    },
    headerText(): string {
      if (this.metadata.type === 'PARAMI') {
        return '¿A dónde te llevamos?';
      }
      if (this.metadata.type === 'CONPARADAS') {
        return '¿A dónde los llevamos?';
      }
      return '¿A dónde lo llevamos?';
    },
  },
});
