































import Vue from 'vue';
// eslint-disable-next-line
import { eventBus } from '@/main';
import msg from '@/services/userMsg';
import Pedido from '@/models/Pedido';
import PedidosDetail from './PedidosDetail.vue';
import PedidosListWFilters from './PedidosListWFilters.vue';

export default Vue.extend({
  name: 'misPedidos',
  components: {
    PedidosDetail,
    PedidosListWFilters,
  },
  props: {
    /** Route params */
    q: { type: String, default: '' },
    status: { type: String, default: '' },
    type: { type: String, default: '' },
    group: { type: String, default: '' },
    since: { type: String },
    until: { type: String },
    selected: { type: String, default: '-1' },
    modal: { type: String, default: null },
  },
  data() {
    return {
      sectionName: 'mis pedidos',
      pedidoSelected: undefined as Pedido | undefined,
      isSelected: false,
    };
  },
  mounted() {
    document.title = `${this.sectionData.title} - InPunto`;
  },
  beforeDestroy() {
    if (this.pedidoSelected) eventBus.$emit('pedidoSelected', false);
  },
  methods: {
    deselectPedido(): void {
      this.$router.replace({
        query: {
          ...this.$route.query,
          selected: undefined,
        },
      });
    },
    updatePedido(): void {
      if (this.pedidoSelected) {
        eventBus.$emit('updatePedido', this.pedidoSelected);
        this.$emit('update');
      }
    },
  },
  computed: {
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
    navbarHeight(): number {
      return this.$store.getters.navbarHeight;
    },
    sectionData(): { title: string, desc: string} {
      const title = msg.getTitle(this.sectionName);
      const desc = msg.getDescription(this.sectionName);
      return {
        title,
        desc,
      };
    },
  },
  watch: {
    pedidoSelected(pedido) {
      if (pedido === undefined) {
        this.isSelected = false;
      } else {
        this.isSelected = true;
      }
    },
  },
});
