





















import Vue from 'vue';
import msg from '@/services/userMsg';
import IndexUser from './usuario/indexUser.vue';
import IndexAddresses from './direcciones-favoritas/indexAddresses.vue';
import IndexAccounts from './cuentas/indexAccounts.vue';
import TabLink from './TabLink.vue';

export default Vue.extend({
  name: 'profile',
  components: {
    Usuario: IndexUser,
    DireccionesFavoritas: IndexAddresses,
    Cuentas: IndexAccounts,
    TabLink,
  },
  mounted() {
    document.title = `${this.sectionData.title} - InPunto`;
  },
  data(): { sectionName: string, currentTab: 0 | 1 | 2 } {
    return {
      sectionName: 'mi cuenta',
      currentTab: 0,
    };
  },
  computed: {
    sectionData(): { title: string, desc: string} {
      const title = msg.getTitle(this.sectionName);
      const desc = msg.getDescription(this.sectionName);
      return {
        title,
        desc,
      };
    },
  },
});
