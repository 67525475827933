








import Vue from 'vue';
import VerifyCode from './VerifyCode.vue';
import SingUpForm from './SingUpForm.vue';
import AccountCreated from './AccountCreated.vue';

export default Vue.extend({
  components: {
    VerifyCode,
    SingUpForm,
    AccountCreated,
  },
  data() {
    return {
      createComponent: 'VerifyCode',
      registrationCode: '',
      domain: '',
    };
  },
});
