
































import Vue from 'vue';
import msg from '@/services/userMsg';
import { propOfType } from '@/services/utils';
import { Novedad } from '@/models/Notificaciones';
import IconX20 from '@/components/icons/IconX20.vue';

export default Vue.extend({
  components: {
    IconX20,
  },
  props: {
    novedad: propOfType<Novedad>(),
  },
  computed: {
    fecha():string {
      return this.novedad.fecha;
    },
    hora():string {
      return this.novedad.hora;
    },
    estado():string {
      if (this.novedad.estado === 'Confirmado') return 'Confirmado por transportista.';
      if (this.novedad.estado === 'Anulado') return 'Anulado sin costo para el cliente.';
      if (this.novedad.estado === 'Finalizado') return 'Controla el costo y califica el servicio.';
      return 'Estado desconocido.';
    },
    subestado():string | null{
      return this.novedad.subestado;
    },
    idSolicitud():string {
      return this.novedad.idSolicitud;
    },
  },
  methods: {
    copy(str: string) {
      navigator.clipboard.writeText(str)
        .then(() => this.$toast.success(msg.getSuccess('string_copied')));
    },
    closeNotification(idSolicitud: string) {
      this.$store.commit('notifications/removeNovedad', this.novedad);
    },
  },
});
