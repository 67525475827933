





















































































import Vue from 'vue';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import { CorporateAccount } from '@/models/accounts/CorporateAccount';
import msg from '@/services/userMsg';
import { debounce, propOfType, capitalizeText } from '@/services/utils';
import { user } from '@/services/api/userApi';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import { dispatch } from '@/services/store/utils';
import { showPermission, Permissions } from '@/models/accounts/shared';
import IconEditSolid from '@/components/icons/IconEditSolid.vue';
import IconX24 from '@/components/icons/IconX24.vue';
import IconCheck24 from '@/components/icons/IconCheck24.vue';
import CheckboxList from './CheckboxList.vue';

type ValidPermission = {
  label: string,
  checked: boolean,
}

export default Vue.extend({
  components: {
    CheckboxList,
    IconEditSolid,
    IconX24,
    IconCheck24,
  },
  props: {
    corporateAccount: propOfType<CorporateAccount>(),
  },
  data(): {
    idCentroDeCostoPreferido: string,
    cancelToken: CancelTokenSource | null,
    debouncedChangeDefaultCeco: (...args) => void,
    editingPhone: Boolean,
    loading: Boolean,
    phone: string,
    phoneIsValid: boolean,
    } {
    return {
      idCentroDeCostoPreferido: '',
      cancelToken: null,
      debouncedChangeDefaultCeco: (...args) => {},
      editingPhone: false,
      loading: false,
      phone: this.corporateAccount.celular || '',
      phoneIsValid: true,
    };
  },
  mounted() {
    this.idCentroDeCostoPreferido = String(this.corporateAccount.idCentroDeCostoPreferido || '');
    this.debouncedChangeDefaultCeco = debounce(this.changeDefaultCeco, 500);
  },
  computed: {
    permisos(): ValidPermission[] {
      return Object.entries(this.corporateAccount.permisos)
        .reduce((result, [key, checked]) => {
          if (showPermission(key as keyof Permissions)) {
            const label = msg.getPermission(key);
            if (label) result.push({ label, checked });
          }
          return result;
        }, [] as ValidPermission[]);
    },
    formaDePago(): Array<{ checked: boolean, label: string }> {
      return [{
        checked: true,
        label: capitalizeText(this.corporateAccount.formaDePagoPorDefecto?.alias || ''),
      }];
    },
    cecosOptions(): Array<{ value: string, text: string }> {
      return this.corporateAccount.centrosDeCosto
        .map((ceco) => ({ value: String(ceco.id), text: ceco.denominacion || String(ceco.id) }));
    },
  },
  methods: {
    sanitizeValue(string: string | null | undefined): string {
      if (!string) {
        return '—';
      }
      return string.replaceAll('\n', ' ').trim();
    },
    getLabel(key: string): string {
      return msg.getLabel(key);
    },
    changeDefaultCeco(): void {
      const idCentroDeCostoPreferido = Number(this.idCentroDeCostoPreferido);

      if (this.idCentroDeCostoPreferido === '' || Number.isNaN(idCentroDeCostoPreferido)) {
        // No hay ninguna opción seleccionada
        return;
      }

      if (
        this.cancelToken === null
          && idCentroDeCostoPreferido === this.corporateAccount.idCentroDeCostoPreferido
      ) {
        return;
      }

      if (this.cancelToken !== null) {
        // Esto cancela la petición que está en curso.
        this.cancelToken.cancel();
      }

      this.cancelToken = axios.CancelToken.source();
      const payload = {
        idCuentaCorporativa: this.corporateAccount.idCuentaSgv,
        idCentroDeCosto: idCentroDeCostoPreferido,
      };

      user.changeDefaultCeco(
        payload,
        { cancelToken: this.cancelToken.token },
      )
        .then((ok) => {
          this.cancelToken = null;
          this.$toast.success(msg.getSuccess('default_ceco_changed'));
          dispatch('changedDefaultCeco', payload);
        })
        .catch((err: AxiosError) => {
          const code = errorCodeFromAxiosError(err);

          if (code === 'cuenta_corporativa_not_found' || code === 'ceco_not_found') {
            this.$toast.error('No pudimos cambiar el centro de costo por defecto');
          } else {
            this.$toast.error(msg.getError(code));
          }

          this.cancelToken = null;
          this.idCentroDeCostoPreferido = this.corporateAccount.idCentroDeCostoPreferido === null
            ? ''
            : String(idCentroDeCostoPreferido);
        });
    },
    changePhone() {
      if (!this.phoneIsValid) return;
      const idCuentaCorporativa = this.corporateAccount.idCuentaSgv;
      this.loading = true;
      user.changePhone({ idCuentaCorporativa, phone: this.phone })
        .then(() => {
          this.$toast.success(msg.getSuccess('phone_changed'));
          dispatch('changedPhone', { idCuentaCorporativa, celular: this.phone });
        })
        .catch((error) => {
          const code = errorCodeFromAxiosError(error);
          this.$toast.error(msg.getError(code));
        })
        .finally(() => {
          this.editingPhone = false;
          this.loading = false;
        });
    },
  },
  watch: {
    idCentroDeCostoPreferido() {
      this.debouncedChangeDefaultCeco();
    },
    editingPhone() {
      if (this.editingPhone) {
        this.phone = this.corporateAccount.celular || '';
      }
    },
  },
});
