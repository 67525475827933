export type Address = {
  id: number,
  alias?: string,
  info?: string,
  street: string,
  lat: number,
  lon: number,
  timesUsed?: number,
  source?: string,
  isCached?: boolean,
  type?: 'favorita' | 'corporativa',
}

export function addressFromUnknown(data: unknown): Address | null {
  if (typeof data === 'object' && data !== null) {
    const data2 = data as {
      id?: unknown,
      alias?: unknown,
      info?: unknown,
      street?: unknown,
      lat?: unknown,
      lon?: unknown,
      timesUsed?: unknown,
      isCached?: unknown,
      source?: unknown,
    };

    if (typeof data2.id === 'number'
      && (data2.alias === undefined || typeof data2.alias === 'string')
      && (data2.info === undefined || typeof data2.info === 'string')
      && typeof data2.street === 'string'
      && typeof data2.lat === 'number' && !Number.isNaN(data2.lat)
      && typeof data2.lon === 'number' && !Number.isNaN(data2.lon)
      && (data2.timesUsed === undefined
        || (typeof data2.timesUsed === 'number' && !Number.isNaN(data2.timesUsed)))
        && (data2.isCached === undefined || typeof data2.isCached === 'boolean')
      && (data2.source === undefined || typeof data2.source === 'string')
    ) {
      return {
        id: data2.id,
        alias: data2.alias,
        info: data2.info,
        street: data2.street,
        lat: data2.lat,
        lon: data2.lon,
        timesUsed: data2.timesUsed,
        isCached: data2.isCached,
        source: data2.source,
      };
    }
  }
  return null;
}

export function sortAddresses(addresses: Address[]): Address[] {
  addresses.sort((a, b) => {
    const aliasA = (a.alias || a.street).toLowerCase();
    const aliasB = (b.alias || a.street).toLowerCase();
    return aliasA.localeCompare(aliasB, 'es-AR');
  });
  return addresses;
}

/** Solución temporal para un error que será corregido en el backend */
export function humanReadableStreet(street: string) {
  return street
    .replace(/^Calle +/, '')
    .replace(/^Avenida +/, 'Av. ');
}

export function nameOf(address: Address): string {
  return address.alias ? address.alias : address.street.split(', ')[0];
}

export function localityOf(address: Address): string {
  if (address.alias) return address.street;
  const comma = address.street.indexOf(',') + 2;
  return address.street.slice(comma);
}
