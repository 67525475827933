







































































import Vue from 'vue';
import IconExclamationWhite from '@/components/icons/IconExclamationWhite.vue';
import IconStar from '@/components/icons/IconStar.vue';
import IconFlag from '@/components/icons/IconFlag.vue';
import IconClipboardCheck from '@/components/icons/IconClipboardCheck.vue';
import IconEmployees from '@/components/icons/IconEmployees.vue';
import IconBan from '@/components/icons/IconBan.vue';
import IconTicket from '@/components/icons/IconTicket.vue';
import IconMenuDots from '@/components/icons/IconMenuDots.vue';
import Pedido, { fromPedidoStringToUIName } from '@/models/Pedido';
import {
  propOfType, capitalizeText, toTwoDecimals, getFullName,
} from '@/services/utils';
import ViajeExtras from '@/models/taaxii.com/ViajeExtras';
import SolicitudExtras from '@/models/taaxii.com/SolicitudExtras';
import IconStopWithTrailVue from '@/components/icons/IconStopWithTrail.vue';
import { mapStateTyped } from '@/services/store/utils';
import { SolicitudParada, SolicitudPasaje } from '@/models/ExtrasData';
import indexDetails from './details/indexDetails.vue';

export default Vue.extend({
  components: {
    IconMenuDots,
    IconExclamationWhite,
    IconFlag,
    IconStar,
    IconClipboardCheck,
    IconBan,
    IconTicket,
    IconEmployees,
    IconStopWithTrailVue,
    indexDetails,
  },
  props: {
    pedido: propOfType<Pedido>(),
    isActive: Boolean,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    formatAddress(dir: string): string {
      return capitalizeText(dir.split(',', 2).join(','));
    },
  },
  computed: {
    ...mapStateTyped(['user']),
    time(): string {
      const date = this.pedido.datetime;
      const options = {
        hour: 'numeric', minute: 'numeric', hour12: false,
      } as const;
      return date.toLocaleString('es-AR', options);
    },
    date(): string {
      const date = this.pedido.datetime;
      const day = new Intl.DateTimeFormat('es-AR', { weekday: 'short' })
        .format(date)
        .substring(0, 3);
      const num = date.getDate();
      const month = date.getMonth() + 1;
      return `${day} ${num}/${month}`;
    },
    extras(): ViajeExtras | SolicitudExtras {
      return this.pedido.extras;
    },
    pasajes(): SolicitudPasaje[] {
      return this.extras.pasajes;
    },
    paradas(): SolicitudParada[] {
      return this.extras.paradas;
    },
    passengerName(): string {
      if (this.pedido.type !== 'trip') {
        return fromPedidoStringToUIName(this.pedido.type.toLowerCase());
      }
      if (!this.passenger || !this.passenger.pasajero) {
        return fromPedidoStringToUIName('package');
      }
      return this.passenger ? getFullName(this.passenger.pasajero) : '';
    },
    passenger(): SolicitudPasaje | null {
      if (this.pedido.type !== 'trip') {
        return null;
      }
      const passenger = this.pasajes.find(({ pasajero }) => (
        getFullName(pasajero).toLowerCase()
          === `${this.user?.apellido}, ${this.user?.nombre}`.toLowerCase()
      )) || this.pasajes[0];

      return passenger;
    },
    paxsNames(): string[] {
      if (this.pedido.type === 'trip' && this.pasajes.length) {
        return this.pasajes.reduce((total, { pasajero }) => {
          if (pasajero) {
            const paxName = getFullName(pasajero);
            if (paxName.toLowerCase() !== this.passengerName.toLowerCase()
                && !total.includes(paxName)) {
              total.push(capitalizeText(paxName));
            }
          }
          return total;
        }, [] as string[]);
      }
      return [];
    },
    packages(): string[] {
      if (this.pedido.type === 'trip' && this.pasajes.length) {
        const paxDespachante = getFullName(this.passenger?.despachante);
        const paxReceptor = getFullName(this.passenger?.receptor);
        return this.pasajes.reduce((total, { despachante, receptor }) => {
          if (despachante && receptor && getFullName(despachante) !== paxDespachante
              && getFullName(receptor) !== paxReceptor) {
            total.push(`Envío de ${getFullName(despachante)} a ${getFullName(receptor)}`);
          }
          return total;
        }, [] as string[]);
      }
      return [];
    },
    paxSubtext(): string {
      const paxs = this.paxsNames.length;
      const packages = this.packages.length;
      if (!paxs && !packages) return '';
      return paxs && packages
        ? `+ ${paxs} pasajero${paxs > 1 ? 's' : ''} y ${packages} envío${packages > 1 ? 's' : ''}`
        : `+ ${paxs || packages} ${paxs ? 'pasajero' : 'envío'}${paxs + packages > 1 ? 's' : ''}`;
    },
    status(): string {
      return capitalizeText(this.pedido.status);
    },
    alertMessage(): string {
      if (this.passenger?.anulando) {
        return 'Estamos procesando la solicitud de anulación de este pedido';
      }
      if (this.pedido.actions.find((action) => action.rel === 'calificar')) {
        return 'Tu calificación está pendiente y es importante para mejorar el servicio';
      }
      return '';
    },
    icon(): string {
      switch (this.pedido.status) {
        case 'registrado':
          return 'IconTicket';
        case 'confirmado':
          return 'IconClipboardCheck';
        case 'calificado':
          return 'IconStar';
        case 'anulado':
          return 'IconBan';
        default:
          return 'IconFlag';
      }
    },
    middleText(): string[] {
      if (this.pedido.service === 'InPunto') {
        let idOrigen = this.paradas[0].idParada;
        let idDestino = this.paradas[this.paradas.length - 1].idParada;
        if (this.passenger) {
          const idAsc = this.pasajes
            .find((pax) => getFullName(pax?.pasajero) === getFullName(this.passenger?.pasajero))
            ?.idPasaje || this.passenger.idPasaje;
          const idDesc = [...this.pasajes].reverse()
            .find((pax) => getFullName(pax?.pasajero) === getFullName(this.passenger?.pasajero))
            ?.idPasaje || this.passenger.idPasaje;
          idOrigen = this.paradas
            .find(({ idPasajesAsc }) => idPasajesAsc.some((id) => id === idAsc))?.idParada
            || idOrigen;
          idDestino = this.paradas
            .find(({ idPasajesDes }) => idPasajesDes.some((id) => id === idDesc))?.idParada
            || idDestino;
        }
        const origen = this.paradas
          .find((stop) => stop.idParada === idOrigen)?.dir.direccion || '-';
        const destino = this.paradas
          .find((stop) => stop.idParada === idDestino)?.dir.direccion || '-';
        const stops = this.paradas
          .filter(({ idParada }) => idParada !== idOrigen && idParada !== idDestino)
          .map(({ dir }) => this.formatAddress(dir.direccion)).join('<br />');
        return [
          this.formatAddress(origen).toUpperCase(),
          this.formatAddress(destino).toUpperCase(),
          stops,
        ];
      }
      return [];
    },
    isAnulado(): boolean {
      return this.pedido.status === 'anulado';
    },
    endText(): string {
      if (this.isAnulado) return '$0';
      const price = this.extras.type === 'v' && this.extras.importeTotal
        ? this.extras.importeTotal : this.extras.cotizacion;
      return price ? `$${toTwoDecimals(price)}` : '';
    },
  },
});
