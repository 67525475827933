import { DispatchOptions } from 'vuex';
import store, { rootModule } from '.';

type SecondArgument<T> = T extends (arg1: any, arg2: infer U, ...args: any[]) => any ? U : any;

type RootModule = typeof rootModule;
type RootModuleActions = RootModule['actions'];
type DispatchKeyOfModuleRoot = keyof RootModuleActions;

type PayloadOf<T extends DispatchKeyOfModuleRoot> = T extends keyof RootModuleActions
? SecondArgument<RootModuleActions[T]>
: undefined

// Dispatch con autocompletado y tipos.
export function dispatch<K extends DispatchKeyOfModuleRoot>(
  key: K,
  payload: PayloadOf<K>,
  options?: DispatchOptions | undefined,
) {
  store.dispatch(key, payload, options);
}

type StoreType = typeof store.state;
type KeyOfState = keyof StoreType;
type ComputedOf<K extends KeyOfState> = () => StoreType[K];
type ComputedsOf<SUBKEY extends KeyOfState> = {
  [K in SUBKEY]: ComputedOf<K>
};

export function mapStateTyped<T extends KeyOfState[]>(props: T) {
  type KeyOfT = T[number]
  const computeds = {};
  for (const k of props) {
    computeds[k] = () => store.state[k];
  }
  return computeds as ComputedsOf<KeyOfT>;
}

export default {
  dispatch,
  mapStateTyped,
};
