












import { propOfType } from '@/services/utils';
import Vue from 'vue';
import msg from '@/services/userMsg';
import { Group } from '@/models/requests-and-responses/grupos';
import { ErrorCode, errorCodeFromAxiosError } from '@/models/ErrorCode';
import { delet } from '@/services/http';

// Estado de la llamada a la API

type RequestStatus =
  | { status: 'notAsked' }
  | { status: 'loading' }
  | { status: 'error', error: ErrorCode }

function notAsked(): RequestStatus {
  return { status: 'notAsked' };
}

export default Vue.extend({
  props: {
    group: propOfType<Group>(),
    onClose: propOfType<Function>(() => () => {}),
    onSave: propOfType<Function>(() => () => {}),
  },
  data() {
    return {
      model: { ...this.group },
      save: notAsked(),
      showErrors: false,
      errorFound: false,
    };
  },
  methods: {
    onSaveButtonClick(): void {
      this.showErrors = true;
      if (this.errorFound) {
        return;
      }

      if (this.save.status === 'loading') {
        return;
      }

      this.save = { status: 'loading' };

      delet('$sgv/rest/cuentas/grupos/$id', { id: this.group.id })
        .then(() => {
          this.showErrors = false;
          this.$toast.success(msg.getSuccess('group_deleted'));
          this.onSave();
        })
        .catch((axiosError) => {
          const error = errorCodeFromAxiosError(axiosError);
          this.$toast.error(msg.getError(error));
          if (error) {
            this.save = { status: 'error', error };
          }
        });
    },
  },
});

