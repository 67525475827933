



















































import Vue from 'vue';
import IconSearch20 from '@/components/icons/IconSearch20.vue';
import IconX20 from '@/components/icons/IconX20.vue';
import { propOfType } from '@/services/utils';

/** Este componente tiene la prop `value` y emite el evento `input`.
 * Eso significa que puede usarse con `v-model`.
 * El resto de las props son callbacks para cuando se apretan los botones.
*/
export default Vue.extend({
  components: { IconSearch20, IconX20 },
  props: {
    value: { type: String, default: '' },
    id: propOfType<string>(String(new Date().getMilliseconds() + Math.random())),
    inputClass: propOfType<string | { [className: string]: boolean }>(''),
  },
  methods: {
    onInput(event: InputEvent) {
      this.$emit('input', (event?.target as any).value);
    },
    onClearButtonClick() {
      this.$emit('input', '');
    },
  },
});
