






































import Vue from 'vue';
import IconChevronL from '@/components/icons/IconChevronL.vue';
import IconChevronR from '@/components/icons/IconChevronR.vue';
import IconCalendar from '@/components/icons/IconCalendar.vue';
import store from '@/services/store';

// Me gustaría usar un componente de vue-tailwind, pero le falta ésta feature
// https://github.com/alfonsobries/vue-tailwind/issues/160

const today = new Date();
today.setHours(0, 0, 0, 0);

const year = today.getFullYear();
const month = today.getMonth();

type DateRange = {
  startDate: Date,
  endDate: Date,
};

type DateRangePicker = {
  ranges: Record<string, DateRange>,
  startDate: Date,
  endDate: Date,
  rangeText: string,
};

/**
 * Construimos una función lambda para resolver los problemas que ocurren entre días de
 * diferentes meses. Hay que crear un "curr" nuevo cada vez, ya que el "setDate" provoca
 * efectos indeseados. No se puede usar el mismo "curr" para crear los datos de los días
 * de la semana.
 */
const fromFirstDayOfWeekShift = (shift) => {
  const curr = new Date();
  return new Date(curr.setDate(curr.getDate() - curr.getDay() + shift));
};

const firstDay = fromFirstDayOfWeekShift(0);
const lastDay = fromFirstDayOfWeekShift(6);
const nextWeekFirstDay = fromFirstDayOfWeekShift(7);
const nextWeekLastDay = fromFirstDayOfWeekShift(7 + 6);

export default Vue.extend({
  components: {
    IconChevronL,
    IconChevronR,
    IconCalendar,
  },
  props: {
    isFirst: Boolean,
    isLast: Boolean,
    startingRange: { type: String, default: 'Semana actual' },
  },
  mounted() {
    const { since, until } = this.$route.query;
    // Si no hay since o until en el router, checkear rango e inicializarlo
    if ((!since || !until) && Object.keys(this.defaultRanges).includes(this.startingRange)) {
      const range = this.defaultRanges[this.startingRange];
      this.dateRange = {
        startDate: range[0],
        endDate: range[1],
      };
    } else {
      const startDate = new Date(`${since}T12:00`);
      const endDate = new Date(`${until}T12:00`);

      this.dateRange = {
        startDate,
        endDate,
      };
    }
    this.onUpdate(this.dateRange);
  },
  data() {
    return {
      dateRange: { startDate: new Date(firstDay), endDate: new Date(lastDay) },
      defaultRanges: {
        Hoy: [today, today],
        'Semana actual': [firstDay, lastDay],
        'Semana próxima': [nextWeekFirstDay, nextWeekLastDay],
        'Mes actual': [new Date(year, month, 1), new Date(year, month + 1, 0)],
        'Mes próximo': [new Date(year, month + 1, 1), new Date(year, month + 2, 0)],
        'Año actual': [new Date(year, 0, 1), new Date(year, 11, 31)],
      },
    };
  },
  methods: {
    onLoadMore(direction) {
      const start = this.dateRange.startDate;
      const end = this.dateRange.endDate;
      const diff = this.diffDaysBetween(start, end);

      if (this.isYearly(start, end)) {
        // Siguiente o anterior año
        // console.log('isYearly');
        this.dateRange = {
          startDate: new Date(start.getFullYear() + direction, 0, 1),
          endDate: new Date(end.getFullYear() + direction, 11, 31),
        };
      } else if (this.isMonthly(start, end)) {
        // Siguiente o anterior mes
        // console.log('isMonthly');
        this.dateRange = {
          startDate: new Date(year, start.getMonth() + direction, 1),
          endDate: new Date(year, end.getMonth() + 1 + direction, 0),
        };
      } else {
        // console.log('crude diff');
        // Por distancia entre las dos fechas
        this.dateRange = {
          startDate: new Date(start.setDate(start.getDate() + diff * direction)),
          endDate: new Date(end.setDate(end.getDate() + diff * direction)),
        };
      }

      this.onUpdate(this.dateRange);
    },
    onUpdate(picked) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          // Sólo la parte de la fecha en ISO
          since: picked.startDate.toISOString().split('T')[0],
          until: picked.endDate.toISOString().split('T')[0],
        },
      }).catch(() => {});
    },
    parseRange(range: DateRangePicker) {
      // console.log(Object.entries(range));
      const result = Object.entries(range.ranges)
        .find(([key, param]) => this.isSameDay(param[0], range.startDate)
                                && this.isSameDay(param[1], range.endDate));
      // console.log(result);
      if (result) {
        return result[0];
      }

      const start = this.dateRange.startDate;
      const end = this.dateRange.endDate;

      if (this.isYearly(start, end)) {
        return start.toLocaleString(
          'es-AR',
          { year: 'numeric' },
        );
      }
      if (this.isMonthly(start, end)) {
        return start.toLocaleString(
          'es-AR',
          { year: 'numeric', month: 'long' },
        );
      }

      if (this.isSameDay(start, end)) {
        return start.toLocaleString(
          'es-AR',
          { year: 'numeric', month: 'numeric', day: 'numeric' },
        );
      }

      return range.rangeText;
    },
    isSameDay(a: Date, b: Date) {
      a.setHours(0, 0, 0, 0);
      b.setHours(0, 0, 0, 0);
      return a.getTime() === b.getTime();
    },
    isYearly(start: Date, end: Date) {
      return start.getDate() === 1 && start.getMonth() === 0
            && this.isLastDayInMonth(end) && end.getMonth() === 11
            && start.getFullYear() === end.getFullYear();
    },
    isMonthly(start: Date, end: Date) {
      return start.getDate() === 1 && this.isLastDayInMonth(end)
        && start.getMonth() === end.getMonth()
        && start.getFullYear() === end.getFullYear();
    },
    isLastDayInMonth(date: Date) {
      // https://stackoverflow.com/a/6355083
      return new Date(date.getTime() + 86400000).getDate() === 1;
    },
    diffDaysBetween(start: Date, end: Date) {
      // https://stackoverflow.com/a/2627493
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const diffDays = Math.round(Math.abs((start.getTime() - end.getTime()) / oneDay)) + 1;
      // console.log(`diffDays: ${diffDays}`);
      return diffDays;
    },
  },
  computed: {
    locale() {
      return {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
        monthNames: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        firstDay: 0,
      };
    },
    isMobile() {
      return store.state.isMobile;
    },
  },
});
