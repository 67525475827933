
































import Vue from 'vue';
import { propOfType } from '@/services/utils';
import IconCheck24 from '@/components/icons/IconCheck24.vue';
import IconX24 from '@/components/icons/IconX24.vue';

export default Vue.extend({
  components: { IconCheck24, IconX24 },
  props: {
    title: { type: String },
    titleClass: { type: String, default: '' },
    checkboxItems: propOfType<Array<{ checked: boolean; label: string }>>(),
    itemClass: { type: String, default: '' },
  },
});
