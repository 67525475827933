











































































































































































































































import Vue from 'vue';
// eslint-disable-next-line import/no-cycle
import { eventBus } from '@/main';
import { Cliente } from '@/models/employees/Cliente';
import { ErrorCode, errorCodeFromAxiosError } from '@/models/ErrorCode';
import { GeneralAccount, getIdCliente } from '@/models/accounts/GeneralAccount';
import { getPermissions, getSelectedAccount } from '@/services/store/state';
import store from '@/services/store';
import axios, { AxiosError, CancelToken, CancelTokenSource } from 'axios';
import { Empleado, GrupoEmpleado } from '@/models/employees/Empleado';
import { debounce } from '@/services/utils';
import IconForward from '@/components/icons/IconForward.vue';
import IconPencilAlt20 from '@/components/icons/IconPencilAlt20.vue';
import IconCheck20 from '@/components/icons/IconCheck20.vue';
import IconX20 from '@/components/icons/IconX20.vue';
import IconClipboard24 from '@/components/icons/IconClipboard24.vue';
import IconList from '@/components/icons/IconList.vue';
import IconDownloadCloud from '@/components/icons/IconDownloadCloud.vue';
import { fetchClientes, fetchEmpleados } from '@/services/api/empleadosApi';
import ForwardFloatingButton from '@/modules/site/components/ForwardFloatingButton.vue';
import CreateFloatingButton from '@/modules/site/components/CreateFloatingButton.vue';
import IndexFilters, { Filter } from '@/modules/site/components/IndexFilters.vue';
import { Group } from '@/models/requests-and-responses/grupos';
import { user } from '@/services/api/userApi';
import msg from '@/services/userMsg';
import { dispatch } from '@/services/store/utils';
import { Permissions } from '@/models/accounts/shared';
import { EmpleadosResponseData, InviteUsersRequest, UserToInvite } from '@/models/requests-and-responses/empleados';
import { get, post } from '@/services/http';
import { ReportType } from '@/services/reports/pedidosToXLSX';
import Pagination from './Pagination.vue';
import EditUserModal from './edit/EditUserModal.vue';
import CreateUserModal from './create/CreateUserModal.vue';
import InviteCheckbox from './shared/checkbox/InviteCheckbox.vue';
import SendInviteModal from './SendInviteModal.vue';

// --- RequestStatus

type RequestStatus<A> =
  | { status: 'notAsked' }
  | { status: 'loading', cancelTokenSource?: CancelTokenSource }
  | { status: 'error', error: ErrorCode }
  | { status: 'loaded', data: A }

/** Cancela una petición pendiente antes de empezar a cargar una nueva. */
function reload<A>(apiCall: RequestStatus<A>): [RequestStatus<A>, CancelToken] {
  if (apiCall.status === 'loading' && apiCall.cancelTokenSource) {
    apiCall.cancelTokenSource.cancel();
  }

  const cancelTokenSource = axios.CancelToken.source();

  return [{ status: 'loading', cancelTokenSource }, cancelTokenSource.token];
}

/** Combina dos RequestStatus solamente si ambos están en estado 'loaded', usando
 * la función `f` para combinar los `data` de cada uno.
 *
 * Esta función también se conoce como `map2`.
*/
function mergeRequestStatuss<A, B, C>(
  apiCallA: RequestStatus<A>,
  apiCallB: RequestStatus<B>,
  f: (a: A, b: B) => C,
): RequestStatus<C> {
  if (apiCallA.status === 'loaded') {
    if (apiCallB.status === 'loaded') {
      return { status: 'loaded', data: f(apiCallA.data, apiCallB.data) };
    }

    return apiCallB;
  }

  return apiCallA;
}

// --- Model

// El data() del componente de Vue

const AMOUNT_OF_RESULTS_PER_PAGE = 10;

type Model = {
  sectionName: string,
  cliente: RequestStatus<Cliente>,
  empleados: RequestStatus<Array<Empleado>>,

  /** Empieza en 0. El número de página que se ve en pantalla es currentPage + 1. */
  currentPage: number,

  debouncedFetchEmpleados: (...args) => void,

  status:
    | { tag: 'viewingEmployees' }
    | { tag: 'editingEmployee', id: number }
    | { tag: 'creatingEmployee' },

  /** Guardo el último valor de empleados.data.numeroDeResultados y de
   * empleados.data.empleados.length para evitar cambios en el layout
   * mientras cargo nuevos resultados.
   */
  numeroDeResultados: number,
  amountOfResultsInThisPage: number,
  actionsClass: string,
  reportOptions: ReportType[],
  reportLoading: boolean,
  isDownloadOpen: boolean,
  filters: Filter[],
  groups: Group[],
  inviteMode: boolean,
  inviteList: number[],
  usersToInvite: UserToInvite[],
  showConfirmModal: boolean,
  viewUserOrders: boolean,
}

export default Vue.extend({
  name: 'Employees',
  components: {
    IconPencilAlt20,
    Pagination,
    IconForward,
    IconClipboard24,
    IconCheck20,
    IconX20,
    IconList,
    IconDownloadCloud,
    EditUserModal,
    CreateUserModal,
    CreateFloatingButton,
    ForwardFloatingButton,
    IndexFilters,
    InviteCheckbox,
    SendInviteModal,
  },
  props: {
    /** Route params */
    query: { type: String, default: '' },
    estado: { type: String, default: '' },
    group: { type: String, default: '' },
  },
  mounted() {
    document.title = `${this.sectionData.title} - InPunto`;

    this.debouncedFetchEmpleados = debounce(this.fetchEmpleados, 500);
    this.fetchEmpleados();
    this.fetchCliente();
  },
  beforeDestroy() {
    if (this.viewUserOrders) {
      this.viewUserOrders = false;
      eventBus.$emit('viewUserOrders');
    }
  },
  watch: {
    selectedAccount() {
      this.fetchCliente();
      this.fetchEmpleados();
    },
    $route(to, from) {
      if (to.query.q !== from.query.q
        || to.query.estado !== from.query.estado
        || to.query.group !== from.query.group
      ) {
        this.currentPage = 0;
        this.debouncedFetchEmpleados();
      }
    },
  },
  data(): Model {
    return {
      sectionName: 'usuarios',
      cliente: { status: 'notAsked' },
      empleados: { status: 'notAsked' },
      currentPage: 0,
      debouncedFetchEmpleados: () => {},
      status: { tag: 'viewingEmployees' },
      numeroDeResultados: AMOUNT_OF_RESULTS_PER_PAGE,
      amountOfResultsInThisPage: AMOUNT_OF_RESULTS_PER_PAGE,
      actionsClass: 'text-gray-700 hover:text-accent-500 focus:outline-none focus:text-accent-700',
      reportOptions: ['nominaUsuarios'] as ReportType[],
      reportLoading: false,
      isDownloadOpen: false,
      filters: [
        {
          param: 'estado',
          title: 'Estado',
          options: ['activo', 'inactivo'],
          selected: [],
        },
        {
          param: 'group',
          title: 'Grupos',
          options: [],
          selected: [],
        },
      ] as Filter[],
      groups: [] as Group[],
      inviteMode: false,
      inviteList: [],
      usersToInvite: [],
      showConfirmModal: false,
      viewUserOrders: false,
    };
  },
  computed: {
    sectionData(): { title: string, desc: string} {
      return {
        title: msg.getTitle(this.sectionName),
        desc: msg.getDescription(this.isOnlyGroupAdmin ? 'usuariosGroupAdmin' : this.sectionName),
      };
    },
    selectedAccount(): GeneralAccount | null {
      return getSelectedAccount(store.state);
    },
    permissions(): Permissions | null {
      return getPermissions(store.state);
    },
    isOnlyGroupAdmin(): boolean {
      return Boolean(!this.permissions?.manageEmpleados && this.permissions?.manageGroups);
    },
    canSeeOnlyGroupOrders(): boolean {
      return Boolean(!this.permissions?.showClientPedidos && this.permissions?.manageGroups);
    },
    clienteAndEmpleados(
    ): RequestStatus<{ cliente: Cliente, empleados: Array<Empleado> }> {
      return mergeRequestStatuss(
        this.cliente,
        this.empleados,
        (cliente, empleados) => ({ cliente, empleados }),
      );
    },
    employeeList(): Array<Empleado> {
      if (this.clienteAndEmpleados.status === 'loaded') {
        return this.clienteAndEmpleados.data.empleados;
      }
      return [];
    },
    employeesWithMail(): Array<Empleado> {
      return this.employeeList.filter((employee) => employee.email);
    },
    amountOfPages(): number {
      return Math.ceil(
        this.numeroDeResultados
          / AMOUNT_OF_RESULTS_PER_PAGE,
      );
    },
    editingEmployee(): Empleado | null {
      if (this.status.tag === 'editingEmployee' && this.empleados.status === 'loaded') {
        const editingEmployeeId = this.status.id;

        return this.empleados.data.find((x) => x.id === editingEmployeeId) || null;
      }
      return null;
    },
    idClientePadre(): number {
      if (this.selectedAccount === null) {
        return -1;
      }

      return getIdCliente(this.selectedAccount);
    },
    estadoFromFiltros(): 'activo' | 'inactivo' | undefined {
      const estado = this.filters.find(({ param }) => param === 'estado')?.selected;
      if (estado?.length === 1) return estado[0] === 'activo' ? 'activo' : 'inactivo';
      return undefined;
    },
    clientAlias(): string {
      return this.cliente.status === 'loaded'
        ? this.cliente.data.cliente.alias : '-';
    },
    amountOfResultsPerPage(): number {
      return AMOUNT_OF_RESULTS_PER_PAGE;
    },
    areAllInvited(): boolean {
      return this.employeesWithMail.every(
        (employee) => this.inviteList.includes(employee.idPersona),
      ) && this.clienteAndEmpleados.status === 'loaded' && this.employeeList.length > 0;
    },
    invitesCountLabel(): string {
      if (this.inviteCount === 0) return 'No hay usuarios seleccionados';
      return `Se han seleccionado ${this.inviteCount} usuario${this.onlyOneInvite ? '' : 's'}`;
    },
    inviteCount(): number {
      return this.inviteList.length;
    },
    inviteSelected(): boolean {
      return this.inviteList.length > 0;
    },
    modalTitle(): string {
      return `Reenviar ${this.onlyOneInvite ? 'invitación' : 'invitaciones'}`;
    },
    modalBody(): string {
      return `Se envían por email ${this.onlyOneInvite ? '' : `a ${this.inviteCount} usuarios`} los datos para acceder a la plataforma de InPunto.`;
    },
    onlyOneInvite(): boolean {
      return this.inviteList.length === 1;
    },
    inviteLimit(): number {
      return 50;
    },
    limitReached(): boolean {
      return this.inviteCount >= this.inviteLimit;
    },
    cannotAddAll(): boolean {
      return (this.inviteCount + this.employeesWithMail.length) > this.inviteLimit;
    },
  },
  methods: {
    // Ayudante para la vista
    arrayFrom(length: number): Array<number> {
      return Array.from({ length }).map((_, i) => i);
    },
    fetchEmpleados() {
      if (this.selectedAccount === null) {
        return;
      }

      const [empleados, cancelToken] = reload(this.empleados);

      this.empleados = empleados;

      this.getEmpleados(AMOUNT_OF_RESULTS_PER_PAGE, this.currentPage, cancelToken)
        .then((empleadosData) => {
          this.empleados = { status: 'loaded', data: empleadosData.empleados };
          this.numeroDeResultados = empleadosData.numeroDeResultados;
          this.amountOfResultsInThisPage = empleadosData.empleados.length;
        })
        .catch((err: AxiosError) => {
          this.empleados = { status: 'error', error: errorCodeFromAxiosError(err) };
        });
    },
    getEmpleados(resultsPerPage: number, currentPage: number, cancelToken: CancelToken | undefined):
      Promise<EmpleadosResponseData> {
      return fetchEmpleados(
        {
          idClientePadre: this.idClientePadre,
          length: resultsPerPage,
          offset: resultsPerPage * currentPage,
          query: this.query,
          estado: this.estadoFromFiltros,
          group: this.group,
        },
        { cancelToken },
      );
    },
    fetchCliente() {
      if (this.selectedAccount === null) {
        return;
      }

      this.cliente = { status: 'loading' };

      fetchClientes(getIdCliente(this.selectedAccount))
        .then((cliente) => {
          this.cliente = { status: 'loaded', data: cliente };
        })
        .catch((err: AxiosError) => {
          this.cliente = { status: 'error', error: errorCodeFromAxiosError(err) };
        });
    },
    fetchUserAccounts() {
      user.getAccounts()
        .then(({ data }) => {
          dispatch('updateAccounts', data);
        })
        .catch((error) => {
          this.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
          dispatch('logout', null);
          this.$router.push('/login');
        });
    },
    currentPageChanged(currentPage: number) {
      this.currentPage = currentPage;
      this.fetchEmpleados();
    },
    onEditEmployeeClick(employeeId: number): void {
      this.status = { tag: 'editingEmployee', id: employeeId };
    },
    onModalClose(): void {
      this.status = { tag: 'viewingEmployees' };
    },
    onModalSave(employeeId: number | null): void {
      this.status = { tag: 'viewingEmployees' };
      this.fetchEmpleados();
      if (employeeId && employeeId === store.state.selectedAccountId?.id) {
        this.fetchUserAccounts();
      }
    },
    canSeeOrders(grupo: GrupoEmpleado | undefined): boolean {
      if (this.permissions?.showReports) {
        if (this.permissions?.showClientPedidos) { return true; }
        if (this.permissions?.showGroupPedidos) {
          const account = this.selectedAccount?.tag === 'corporateAccount'
            ? this.selectedAccount.account : null;
          if (account?.gruposAuditados.some((group) => group.id === grupo?.id)) { return true; }
        }
      }
      return false;
    },
    onNuevoEmpleadoClick(): void {
      this.status = { tag: 'creatingEmployee' };
    },
    onRenviarInvitacionesClick(): void {
      this.inviteMode = true;
    },
    onViewOrdersClick({ nombre, apellido, grupo }: Empleado): void {
      if (!this.canSeeOrders(grupo)) { return; }
      const query = {
        q: `"${apellido}, ${nombre}"`,
        group: this.canSeeOnlyGroupOrders ? grupo?.id.toString() || '-1' : null,
      };
      this.viewUserOrders = true;
      this.$router.push({ name: 'informes', query });
    },
    downloadReport(reportType: ReportType): void {
      const corporateAccount = this.selectedAccount?.tag === 'corporateAccount'
        ? this.selectedAccount.account : null;

      if (!corporateAccount || this.reportLoading) {
        return;
      }

      this.reportLoading = true;
      const idCliente = corporateAccount.idClientePadre;

      const reportTS = localStorage.getItem(`${corporateAccount.idCuentaSgv}-${reportType}-ts`) as string | null;

      // Revisa que exista el timestamp y que la diferencia entre el timestamp
      // y el tiempo actual sea menor a 10 minutos
      if (reportTS && new Date().getTime() - new Date(reportTS).getTime() < 600000) {
        this.$toast.info(msg.getInfo('report_already_requested'));
        this.reportLoading = false;
        return;
      }

      get('/cuentas/reportes/nomina', { idCliente })
        .then(() => {
          localStorage.setItem(`${corporateAccount.idCuentaSgv}-report-ts`, new Date().toISOString());
          this.$toast.success(msg.getSuccess('report_requested'));

          this.$logEvent(
            'descarga_reporte',
            { tipo_reporte: msg.getReports(reportType) },
          );
        })
        .catch((error: AxiosError) => {
          const errorCode = errorCodeFromAxiosError(error);
          if (errorCode === 'internal_error') {
            this.$toast.error(msg.getError('error_on_report'));
          } else {
            this.$toast.error(msg.getError(errorCode));
          }
        })
        .finally(() => {
          this.reportLoading = false;
        });
    },
    getReport(name: string): string {
      return msg.getReports(name) || '-';
    },
    getEmail(id: number): string {
      return this.employeesWithMail.find((employee) => employee.idPersona === id)?.email || '';
    },
    singleInvite(empleado: Empleado): void {
      if (!empleado.email) {
        this.employeeHasNoMailToast();
        return;
      }
      this.inviteList.push(empleado.idPersona);
      this.usersToInvite.push({ idPersona: empleado.idPersona, email: empleado.email });
      this.showConfirmModal = true;
    },
    singleCancel(): void {
      this.inviteList = [];
      this.usersToInvite = [];
      this.showConfirmModal = false;
    },
    groupInvite(): void {
      if (this.inviteSelected) this.showConfirmModal = true;
      else this.$toast.error('No hay usuarios seleccionados');
    },
    addUserToInviteList(value:boolean, id: number): void {
      if (!this.employeesWithMail.some((employee) => employee.idPersona === id)) {
        this.employeeHasNoMailToast();
        return;
      }
      if (value) {
        this.inviteList.push(id);
        this.usersToInvite.push({ idPersona: id, email: this.getEmail(id) });
      } else {
        this.inviteList = this.inviteList.filter((i) => i !== id);
        this.usersToInvite = this.usersToInvite.filter((invite) => invite.idPersona !== id);
      }
    },
    addAllToInviteList(value: boolean): void {
      if (value) {
        this.employeesWithMail.forEach((employee) => {
          if (!this.inviteList.includes(employee.idPersona)) {
            this.inviteList.push(employee.idPersona);
            this.usersToInvite.push(
              {
                idPersona: employee.idPersona, email: this.getEmail(employee.idPersona),
              },
            );
          }
        });
      } else {
        this.inviteList = this.inviteList.filter(
          (id) => !this.employeeList.some((employee) => employee.idPersona === id),
        );
        this.usersToInvite = this.usersToInvite.filter(
          (invite) => this.inviteList.includes(invite.idPersona),
        );
      }
    },
    maxInvitesToast(): void {
      this.$toast.error(`Alcanzaste el límite máximo de ${this.inviteLimit} usuarios para reenviar invitaciones en simultáneo.`);
    },
    employeeHasNoMailToast(): void {
      this.$toast.error('Ups!. El usuario no tiene un email registrado y no podemos reenviar la invitación.');
    },
    sendInvites(): void {
      const request = { usersToInvite: this.usersToInvite } as InviteUsersRequest;
      post('/usuarios/invite', request)
        .then(() => {
          this.$toast.success('El reenvío de invitaciones fue procesado con éxito.');
        })
        .catch((error: AxiosError) => {
          this.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
        })
        .finally(() => {
          this.inviteList = [];
          this.usersToInvite = [];
          this.showConfirmModal = false;
          this.inviteMode = false;
        });
    },
  },
});
