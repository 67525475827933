<template>
  <div class="flex flex-col gap-4">
    <p class="text-gray-700">
      La nueva contraseña debe tener al menos ocho caracteres,
      una mayúscula, una minúscula y números.
    </p>
    <BaseInput
      v-model="data.oldPassword"
      :type="'password'"
      inputId="user-pass-contraseña-actual"
      :autocomplete="'new-password'"
    >
      Contraseña actual
    </BaseInput>
    <BaseInput
      v-model="data.newPassword"
      :type="'password'"
      inputId="user-pass-nueva-contraseña"
      :autocomplete="'new-password'"
    >
      Nueva contraseña
    </BaseInput>
    <BaseInput
      v-model="repPassword"
      :type="'password'"
      inputId="user-pass-repetir-contraseña"
      :autocomplete="'new-password'"
    >
      Repetir contraseña
    </BaseInput>
    <span class="text-red-500 text-sm italic">{{ errorMsg }}</span>
    <BaseButton
      class="btn-gray-primary self-end"
      label="Cambiar contraseña"
      :showSpinner="showSpinner"
      @click.native="onChangePass()"
    />
  </div>
</template>

<script>
import msg from '@/services/userMsg';
import { user } from '@/services/api/userApi';
import { validators } from '@/services/utils';
import { errorCodeFromAxiosError, isAuthorizationError } from '@/models/ErrorCode';
import { dispatch } from '@/services/store/utils';

export default {
  data() {
    return {
      repPassword: '',
      data: {
        oldPassword: '',
        newPassword: '',
      },
      errorMsg: '',
      showSpinner: false,
    };
  },
  methods: {
    onChangePass() {
      this.errorMsg = '';
      if (!this.isFilled()) {
        this.errorMsg = msg.getError('emptyFields');
      } else if (!validators.isValidPass(this.data.newPassword)) {
        this.errorMsg = msg.getError('password_invalid');
      } else if (!this.isSamePassword()) {
        this.errorMsg = msg.getError('password_not_equal');
      } else if (this.isRepeatedPassword()) {
        this.errorMsg = msg.getError('password_unchanged');
      } else {
        this.showSpinner = true;
        this.handleChangePass();
      }
    },
    handleChangePass() {
      user.changePassword(this.data).then(
        (response) => {
          this.cleanFields();
          this.showSpinner = false;
          this.$toast.success(msg.getSuccess('password_changed'));
        },
        (error) => {
          const code = errorCodeFromAxiosError(error);
          if (isAuthorizationError(code)) {
            this.$toast.error(msg.getError(code));
            dispatch('logout', null);
            this.$router.push('/login');
          } else {
            this.showSpinner = false;
            this.errorMsg = msg.getError(code);
          }
        },
      );
    },
    isFilled() {
      return this.repPassword !== ''
        && this.data.oldPassword !== ''
        && this.data.newPassword !== '';
    },
    isSamePassword() {
      return this.data.newPassword === this.repPassword;
    },
    isRepeatedPassword() {
      return this.data.newPassword === this.oldPassword;
    },
    cleanFields() {
      this.repPassword = '';
      this.data.oldPassword = '';
      this.data.newPassword = '';
    },
  },
};
</script>
