<template>
  <div class="mt-6 space-y-6">
    <section class="flex flex-col space-y-6">
      <h1 class="section-title">Tus datos personales</h1>
      <UserData />
    </section>
    <section class="flex flex-col space-y-6">
      <h1 class="section-title">Cambiar contraseña</h1>
      <UserPass />
    </section>
  </div>
</template>

<script>
import UserData from './UserData.vue';
import UserPass from './UserPass.vue';

export default {
  components: {
    UserData,
    UserPass,
  },
};
</script>
