















import { propOfType } from '@/services/utils';
import Vue from 'vue';
import { Address } from '@/models/Address';

export default Vue.extend({
  props: {
    onConfirm: propOfType<Function>(),
    selected: propOfType<Address>(),
    loading: propOfType<Boolean>(false),
    onlyCorporative: propOfType<Boolean>(false),
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onDelete() {
      this.onConfirm(this.selected.id);
    },
  },
});

