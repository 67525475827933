





































import Vue from 'vue';
import { propOfType } from '@/services/utils';
import IconUser from '@/components/icons/IconUser.vue';
import { PersonWithCC } from '@/models/Person';
import { apply } from '../../indexForm.vue';
import { Metadata } from '../../../CreateWeb.vue';
import SectionLayout from '../SectionLayout.vue';
import PersonSearchBar, { Pax } from './PersonSearchBar.vue';

export default Vue.extend({
  components: {
    SectionLayout,
    Icon: IconUser,
    PersonSearchBar,
  },
  data() {
    return {
      nameAndLastName: '' as string,
      phone: '' as string,
      personIsSetted: false,
      person: null as PersonWithCC | null,
      phoneIsValid: false,
    };
  },
  props: {
    isExpanded: Boolean,
    metadata: propOfType<Metadata>(),
    userTriedToConfirm: Boolean,
  },
  methods: {
    onPersonChange(suggestion: Pax) {
      this.nameAndLastName = suggestion.nameAndLastName;
      this.phone = suggestion.phone;
      this.phoneIsValid = suggestion.phoneIsValid;
      this.person = suggestion?.person || null;
      this.personIsSetted = Boolean(this.person);
      apply(this, (data) => {
        if ('person' in data) {
          data.person = this.person;
        }
      });
    },

    // Los watchers no son sobreescritos al extender el componente, por eso
    // delego la implementación a estos métodos y luego sobreescribo los métodos
    // en los "herederos".

    nameAndLastNameWatcher(val: string) { // Override me
    },
    phoneWatcher(val: string) { // Override me
      apply(this, (data) => {
        if ('phone' in data) {
          data.phone = val;
        }
      });
    },
  },
  watch: {
    nameAndLastName(val: string) {
      this.nameAndLastNameWatcher(val);
    },
    phone(val: string) {
      this.phoneWatcher(val);
    },
  },
  computed: {
    headerText() {
      return '¿Quién viaja?';
    },
    error() {
      if (!this.userTriedToConfirm) return '';
      if (!this.personIsSetted) return 'Debe seleccionar a una persona';
      if (this.phone === '') {
        return 'Complete el teléfono';
      }
      if (!this.phoneIsValid) {
        return 'El teléfono tiene un formato inválido.';
      }
      return '';
    },
    autocomplete() { // Override this if you want.
      return false;
    },
    showSuggestions() { // Override this if you want.
      return true;
    },
    initialData(): Pax | null {
      if (this.nameAndLastName && this.phone) {
        return {
          nameAndLastName: this.nameAndLastName,
          phone: this.phone,
          phoneIsValid: this.phoneIsValid,
          person: this.person,
        };
      }
      return null;
    },
  },
});
