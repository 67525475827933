var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"appear":"","name":"modal"}},[_c('div',{staticClass:"select-text cursor-auto",class:{'modal-mask' : !_vm.isMobile,
              'h-full w-full fixed inset-0 flex items-center justify-center z-50' : _vm.isMobile}},[_c('div',{class:{'modal-container w-modal' : !_vm.isMobile,
                  'w-full' : _vm.isMobile}},[_c('div',{staticClass:"relative flex",class:{'flex-row' : !_vm.isMobile,
                  'flex-col' : _vm.isMobile},style:({'height': _vm.isMobile ? '100vh' : '80vh',
                  'max-height': _vm.isMobile ? '100vh' : '80vh'})},[(!_vm.isMobile)?_c('div',{staticClass:"button-right absolute right-2 top-2 z-10"},[_c('BaseButton',{staticClass:"btn-icon bg-white hover:bg-gray-100",nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_c('IconClose24')],1)],1):_vm._e(),_c('div',{staticClass:"overflow-hidden",class:{ 'w-full h-3/5 fixed top-0 bg-white' : _vm.isMobile,
                    'w-1/2 rounded-l-xl' : !_vm.isMobile}},[_c('div',{staticClass:"absolute m-2 poppins font-medium text-sm sm:text-base\n            px-2 w-auto rounded-md border\n            border-gray-300 bg-white z-50 shadow-md"},[_vm._v(" "+_vm._s(_vm.recorridoLabel)+" ")]),_c('Map',{attrs:{"markers":_vm.filteredMarkers,"zoom":11,"trackerRoute":_vm.trackerRoute,"route":_vm.route,"selectedStops":_vm.selectedStops,"padding":_vm.mapPadding}})],1),_c('div',{staticClass:"bg-white px-5 py-3 overflow-hidden shadow-bottomsheet",class:{
              'rounded-r-xl w-1/2':!_vm.isMobile,
              'fixed flex flex-grow flex-col w-full rounded-t-xl bottom-0 z-50':_vm.isMobile,
              'h-3/5':_vm.isMobile && !_vm.isExpanded,
              'h-full':_vm.isMobile && _vm.isExpanded,
            },style:({transition: 'height 0.3s ease-in-out'})},[_c('div',{staticClass:"relative flex flex-col"},[(_vm.isMobile)?_c('div',{staticClass:"flex items-center justify-center mt-2"},[_c('button',{staticClass:"animate-bounce w-10 absolute flex items-center justify-center z-50",on:{"click":_vm.changeBottomSheetSize}},[_c('IconChevronDownSection',{staticClass:"transition-transform duration-100 transform text-gray-900 h-5 w-5",class:{'rotate-90': _vm.isExpanded,
                        '-rotate-90': !_vm.isExpanded}})],1)]):_vm._e(),_c('div',{staticClass:"flex flex-row poppins",class:{'mt-1':_vm.isMobile}},[_c('span',{staticClass:"text-2xl font-bold mr-1.5"},[_vm._v("Pedido")]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Copiar'),expression:"'Copiar'"}],staticClass:"text-lg text-gray-600 mt-1.5\n                  hover:underline cursor-pointer flex flex-row",on:{"click":function($event){_vm.copy(_vm.id.toString())}}},[_vm._v(" #"+_vm._s(_vm.id)+" ")]),(!_vm.isMobile)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Ver Seguimiento'),expression:"'Ver Seguimiento'"}],staticClass:"mt-1.5",attrs:{"target":"_blank","href":_vm.hrefSeguimiento}},[_c('IconExternalLink20',{staticClass:"text-gray-500 mt-0.5 ml-0.5"})],1):_vm._e(),(_vm.filteredSections.length > 1 && !_vm.isMobile)?_c('div',{staticClass:"flex flex-row gap-1 ml-auto mr-7 mt-1"},_vm._l((_vm.filteredSections),function(section,i){return _c('div',{key:i,staticClass:"flex flex-row group cursor-pointer text-gray-500 hover:text-black",on:{"click":function($event){return _vm.scrollTo(section.component)}}},[_c(section.icon,{tag:"component",staticClass:"h-5 w-5 overflow-hidden"})],1)}),0):_vm._e(),(_vm.isMobile)?_c('BaseButton',{staticClass:"bg-white absolute top-0 -right-1 mt-4",nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_c('IconClose24')],1):_vm._e()],1),_c('div',[_c('span',[_vm._v(_vm._s(_vm.cliente)+" | "+_vm._s(_vm.servicio)+" | "+_vm._s(_vm.estado))])]),_c('div',{staticClass:"bg-gray-300 h-px mt-2 w-full"})]),_c('div',{staticClass:"flex flex-col h-full -mr-5",class:{'flex-none':!_vm.isMobile,
                    'flex-grow':_vm.isMobile},style:({ paddingBottom: _vm.isMobile ? (_vm.navbarHeight + "px") : '0px'})},[_c('div',{ref:"sections",staticClass:"overflow-auto scrollbar flex-grow mb-14 pb-4 pt-2 pr-5",class:_vm.scrollbarClass},_vm._l((_vm.filteredSections),function(section,i){return _c('div',{key:i},[_c(section.component,{ref:section.component,refInFor:true,tag:"component",attrs:{"driver":_vm.driver,"costs":_vm.costs,"isCanceladoFDT":_vm.isCanceladoFDT,"isAnulado":_vm.isAnulado,"isCanceladoFallo":_vm.isCanceladoFallo,"ratings":_vm.infoCalificacion,"route":_vm.infoRecorrido,"showSwitch":_vm.showRecorridoReal},on:{"selected":function($event){_vm.selectedStops = $event},"switchRecorrido":function($event){_vm.isRecorridoReal = $event}}})],1)}),0)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }