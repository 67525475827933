
import { VueConstructor } from 'vue';
import { ServicioDisponible } from '@/models/taaxii.com/taaxiiServicioDisponible';
import { post } from '@/services/http';
import msg from '@/services/userMsg';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import { getIdPersona } from '@/models/accounts/GeneralAccount';
import {
  SolicitudesSimpleReq,
  SolicitudSuccessfullyBundle,
} from '@/models/requests-and-responses/sgv';
import { PersonWithCC } from '@/models/Person';
import Shared, {
  AddressOfForm,
  faltanDatosDeArribo,
  necesitaDatosDeArribo,
  Section,
} from '../indexForm.vue';

import ServiceSection from '../sections/service/indexService.vue';
import ExtraSection from '../sections/extra/indexExtra.vue';
import WhenSection from '../sections/when/indexWhen.vue';
import PersonSection from '../sections/person/indexPerson.vue';
import OriginSection from '../sections/address/Origin.vue';
import DestinationSection from '../sections/address/Destination.vue';
import CecoOfPersonSection from '../sections/ceco/CecoOfPerson.vue';

export function data() {
  return {
    type: 'PARAOTRO' as const, // Utilizado para conocer el tipo de la data.
    addresses: [] as AddressOfForm[],
    service: null as ServicioDisponible | null,
    date: null as Date | null, // null = Lo antes posible.
    dateRegreso: null as Date | null,
    finalDataResponse: null as SolicitudSuccessfullyBundle | null,
    finalDataResponseRegreso: null as SolicitudSuccessfullyBundle | null,
    days: [] as number[],
    daysRegreso: [] as number[],
    typeOfTrip: 0 as number,
    paxError: false as boolean,
    dateError: false as boolean,
    datosDeArribo: '' as string,
    userTriedToConfirm: false,
    idCeCo: null as number | null,
    observacion: '',
    person: null as PersonWithCC | null,
    phone: '',
  };
}

export default Shared.extend({
  components: {
    PersonSection,
    OriginSection,
    DestinationSection,
    WhenSection,
    ExtraSection,
    CecoOfPersonSection,
    ServiceSection,
  },
  data,
  computed: {
    buttonText() {
      const d = this.date;
      if (!d) {
        return 'Pedir';
      }
      // TO-DO: Poner reservar para mañana a las blabla
      return 'Reservar';
    },
    price(): string | null {
      const { service } = this;
      if (!service) return null;
      if (this.typeOfTrip === 3 && this.days.length === 0) return null;
      return service.precotizacion > 0
        ? `$${service.precotizacion + (this.dateRegreso ? ' (por tramo)' : '')}`
        : 'Sin cotización';
    },
    sections(): Section[] {
      return [
        { component: PersonSection, steps: ['person1', 'person2'] },
        { component: OriginSection, steps: ['origin1', 'origin2'] },
        { component: DestinationSection, steps: ['destination'] },
        { component: WhenSection, steps: ['when1', 'when2'] },
        { component: ExtraSection, steps: ['extra'] },
        { component: CecoOfPersonSection, steps: ['cecoOfPerson'] },
        { component: ServiceSection, steps: ['service1', 'service2'] },
      ];
    },
    conRetorno(): boolean {
      if (this.dateRegreso && !this.days.length) return true;
      if (this.dateRegreso && this.daysRegreso.length) return true;
      return false;
    },
  },
  watch: {
    addresses(val) {
      this.setMapAddresses(val);
    },
  },
  methods: {
    async onConfirm() {
      this.expandedSection = -1;
      this.userTriedToConfirm = true;

      if (this.checkIfLAP() || this.reservating) {
        return;
      }

      const [origin, destination] = this.addresses;

      if (!origin || !destination) {
        return;
      }

      if (!this.service) {
        return;
      }

      // La selectedAccount es del solicitante, no es la persona que viaja.
      const { selectedAccount, person } = this;

      if (!person) {
        // Se mostraría un error en la sección correspondiente.
        return;
      }

      if (!this.idCeCo) {
        // Se supone que no debería llegar nunca a este punto
        // ya que la sección del centro de costo se selecciona
        // automaticamente. El idCeco nunca debería ser nulo.
        this.error = 'Seleccione el centro de costo.';
        return;
      }

      if (!selectedAccount) {
        // Nunca debería llegar a este punto.
        this.error = 'Error fatal.';
        return;
      }

      if (selectedAccount.tag === 'particularAccount') {
        this.error = 'Su cuenta debe ser corporativa.';
        return;
      }

      const idCeCoSolicitante = selectedAccount.account.idCentroDeCostoPreferido;

      if (!idCeCoSolicitante) {
        this.error = 'El centro de costo por defecto del solicitante no está definido.';
        return;
      }

      if (faltanDatosDeArribo(this, this.conRetorno)
          || this.observacion.length > 250) {
        return;
      }

      if (this.dateError || this.paxError) {
        return;
      }

      const pasajero = {
        idPasajero: person.personId,
        idCeCo: this.idCeCo,
        telefono: this.phone,
      };

      let req: SolicitudesSimpleReq = {
        idTipoVehiculo: this.service.idTipoVehiculo,
        idPersonaSolicitante: getIdPersona(selectedAccount),
        idCeCoSolicitante,
        origen: {
          direccion: origin.street,
          lat: origin.lat,
          lon: origin.lon,
        },
        destino: {
          direccion: destination.street,
          lat: destination.lat,
          lon: destination.lon,
        },
        fechaHoraSalida: this.date ? this.date : undefined,
        observacion: this.observacion,
        pasajero,
      };

      if (necesitaDatosDeArribo(this.addresses, this.conRetorno)) {
        req = {
          ...req,
          pasIdEmpresaAerea: 143,
          pasVueloTipo: '1',
          pasVueloNumero: 'ABC123',
        };
      }

      this.reservating = true;
      this.error = '';

      if (this.days.length) {
        const [fechaDesde, horaIda] = this.dateToDateStrings(this.date as Date);
        const [fechaHasta, horaRegreso] = this.dateToDateStrings(this.dateRegreso as Date);
        const reqPlanificada: SolicitudesSimpleReq = {
          ...req,
          planificacion: {
            fechaDesde,
            fechaHasta,
            horaIda,
            horaRegreso,
            frecuenciaIda: this.daysToString(this.days),
            frecuenciaRegreso: this.daysToString(this.daysRegreso),
          },
        };
        this.finalDataResponse = await this.makeRequest(reqPlanificada);
      } else {
        this.finalDataResponse = await this.makeRequest(req);
        if (this.dateRegreso != null && !this.error) {
          req.fechaHoraSalida = this.dateRegreso;
          [req.origen, req.destino] = [req.destino, req.origen];
          this.finalDataResponseRegreso = await this.makeRequest(req);
        }
      }

      if (this.error) {
        return;
      }
      this.exit(this.$data);
    },

    makeRequest(req : SolicitudesSimpleReq) : Promise<SolicitudSuccessfullyBundle | null> {
      return post('$sgv/rest/solicitudes/simples', req)
        .then((res) => {
          const response = res.data;
          return response;
        })
        .catch((error) => {
          this.error = msg.getError(errorCodeFromAxiosError(error));
          return null;
        })
        .finally(() => {
          this.reservating = false;
        });
    },
    propsOf(section: VueConstructor) {
      switch (section) {
        case OriginSection:
          return { addressIndex: 0 };
        case DestinationSection:
          return { addressIndex: 1 };
        case ExtraSection:
          return {
            needDatosDeArribo: necesitaDatosDeArribo(this.addresses, this.conRetorno),
            obs: this.observacion,
          };
        case ServiceSection:
          return {
            addresses: this.addresses,
            date: this.date,
            idCliente: this.idCliente,
            isLoAntesPosible: this.date === null,
            service: this.service,
          };
        case CecoOfPersonSection:
          return {
            person: this.person,
          };
        default:
          return {};
      }
    },
    daysToString(days: number[]) {
      let daysString = '';
      const daysRef = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
      daysRef.forEach((day, index) => {
        daysString += days.includes(index) ? day : '-';
      });
      return daysString;
    },
    dateToDateStrings(d: Date): [string, string] {
      const ds = d.toISOString().split('T')[0];
      const ts = d.toLocaleString('es-AR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
      return [ds, ts];
    },
  },
});
