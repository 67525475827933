


























































import { Direccion, EventType } from '@/models/requests-and-responses/seguimiento';
import { propOfType } from '@/services/utils';
import Vue from 'vue';

export type TimelineRowModelType = 'past' | 'present' | 'future';

export type TimelineRowModel = {
  timestamp?: Date,
  rowType: EventType | 'esperando' | 'espera llegar',
  direccion?: Direccion,
  paradaLabel?: string,
  futurePresentOrPast: TimelineRowModelType,
  timestampForWaittingClock?: Date,
};

export default Vue.extend({
  props: {
    model: propOfType<TimelineRowModel>(),
    isFirst: Boolean,
    isLast: Boolean,
    minutesLeft: propOfType<number | null>(null),
  },
  data() {
    return {
      timer: undefined as number | undefined,
      clockString: '',
    };
  },
  created() {
    this.timer = window.setInterval(this.updateClockString, 1000);
  },
  destroyed() {
    window.clearInterval(this.timer);
  },
  methods: {
    updateClockString() {
      const { timestampForWaittingClock } = this.model;
      if (!timestampForWaittingClock) return;
      const diff = (new Date()).getTime() - timestampForWaittingClock.getTime();
      const diffInSeconds = Math.floor(diff / 1000.0);
      const seconds = diffInSeconds % 60;
      const minutes = Math.floor(diffInSeconds / 60) % 60;
      const hours = Math.floor(diffInSeconds / (60 * 60));
      const secondsTwoDigits = (`0${seconds}`).slice(-2);
      const minutesTwoDigits = (`0${minutes}`).slice(-2);
      if (hours > 0) {
        this.clockString = `${hours}:${minutesTwoDigits}:${secondsTwoDigits}`;
      } else {
        this.clockString = `${minutes}:${secondsTwoDigits}`;
      }
    },
  },
  computed: {
    primaryText(): string {
      switch (this.model.rowType) {
        case 'registrado': return 'Registrado';
        case 'confirmado': return 'Confirmado';
        case 'circulando': return `Circulando a ${this.model.paradaLabel}`;
        case 'arribado':
          if (this.isLast) return 'Completado';
          return `Arribado en ${this.model.paradaLabel}`;
        case 'espera llegar': return `Espera llegar en ${this.minutesToString} min`;
        case 'esperando': return `Esperando hace ${this.clockString} min`;
        case 'fallo': return 'Fallo';
        case 'cancelado': return 'Cancelado';
        case 'anulado': return 'Anulado';
        default: return 'Evento indefinido';
      }
    },
    secondaryText(): string {
      const { direccion } = this.model;
      if (!direccion) return '';
      return direccion.alias || direccion.direccion;
    },
    hour(): string {
      const timestamp = this.model?.timestamp;
      if (!timestamp) return '';
      return timestamp.toLocaleString('es-AR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    },
    minutesToString(): string {
      if (this.minutesLeft === null) return '...';
      return this.minutesLeft === 0 ? '1' : this.minutesLeft.toString();
    },
  },
});
