











































import Vue from 'vue';
import { auth } from '@/services/api/authApi';
import { identity, validators } from '@/services/utils';
import msg from '@/services/userMsg';
import { ErrorCode, errorCodeFromAxiosError, isAuthorizationError } from '@/models/ErrorCode';
import ButtonRecoverPass from '../components/ButtonRecoverPass.vue';
import ButtonCreateAccount from '../components/ButtonCreateAccount.vue';
import ButtonRecoverPassMobile from '../components/ButtonRecoverPassMobile.vue';

type RequestStatus =
  | { status: 'notAsked' }
  | { status: 'loading' }
  | { status: 'error', code: ErrorCode | 'emptyFields' }

export default Vue.extend({
  components: {
    ButtonRecoverPass,
    ButtonCreateAccount,
    ButtonRecoverPassMobile,
  },
  data() {
    return {
      username: '',
      password: '',
      loginError: null as string | null,
      requestStatus: identity<RequestStatus>({ status: 'notAsked' }),
    };
  },
  mounted() {
    document.title = 'Iniciar sesión - InPunto';
    this.loginError = sessionStorage.getItem('errorMessage');
  },
  computed: {
    errorMsg(): string | null {
      if (this.loginError) { return msg.getError(this.loginError); }
      if (this.requestStatus.status === 'error'
      && this.requestStatus.code === 'bad_credentials'
      && this.isMobile) { return msg.getError('bad_credentials_mobile'); }
      return this.requestStatus.status === 'error'
        ? msg.getError(this.requestStatus.code)
        : null;
    },
    showSpinner(): boolean {
      return this.requestStatus.status === 'loading';
    },
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    onSubmit(event) {
      /** Evitar el comportamiento por defecto (redireccionar la página). */
      event.preventDefault();
      this.loginError = null;
      sessionStorage.removeItem('errorMessage');
      /** Validar y hacer el login */
      if (!validators.isValidString(this.username) || !validators.isValidString(this.password)) {
        this.requestStatus = { status: 'error', code: 'emptyFields' };
      } else {
        this.doLogin();
      }
    },
    doLogin() {
      this.requestStatus = { status: 'loading' };

      auth.login({ username: this.username, password: this.password })
        .then(
          (response) => {
            localStorage.setItem('user', response.data.token);
            this.$router.push({ name: 'home' });
          },
        )
        .catch((error) => {
          const code = errorCodeFromAxiosError(error);

          this.requestStatus = {
            status: 'error',
            code: isAuthorizationError(code) ? 'bad_credentials' : code,
          };
        });
    },
    getLabel(key) {
      return msg.getLabel(key);
    },
  },
});
