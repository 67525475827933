




















import IconServiceBasico from '@/components/icons/IconServiceBasico.vue';
import IconServiceCorporativo from '@/components/icons/IconServiceCorporativo.vue';
import IconServicePool from '@/components/icons/IconServicePool.vue';
import IconServicePremium from '@/components/icons/IconServicePremium.vue';
import Vue from 'vue';

export default Vue.extend({
  props: {
    iconId: Number,
  },
  components: {
    IconServicePool,
    IconServiceBasico,
    IconServiceCorporativo,
    IconServicePremium,
  },
  data() {
    return {
    };
  },
  computed: {
    isServicioPool(): boolean {
      return this.iconId === 23 || this.iconId === 26;
    },
    isServicioBasico(): boolean {
      return this.iconId === 2;
    },
    isServicioCorporativo(): boolean {
      return this.iconId === 3;
    },
    isServicioPremium(): boolean {
      return this.iconId === 8;
    },
  },
  methods: {
  },
});
