





























































































































import Vue from 'vue';
import { humanReadableStreet } from '@/models/Address';
import msg from '@/services/userMsg';
import { Coords, toCoords } from '@/services/Coords';
import { corporativeAddress, favouriteAddress } from '@/services/api/addressApi';
import {
  capitalizeText, debounce, getUserLocation, validators,
} from '@/services/utils';
import IconCheck from '@/components/icons/IconCheck.vue';
import IconRefresh from '@/components/icons/IconRefresh.vue';
import axios, { AxiosError } from 'axios';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import { eventBus } from '@/main';
import IconLocation from '@/components/icons/IconLocation2.vue';
import IconCheck20 from '@/components/icons/IconCheck20.vue';
import IconX20 from '@/components/icons/IconX20.vue';
import IconHelp from '@/components/icons/IconHelp.vue';
import IconExclamationRounded from '@/components/icons/IconExclamationRounded.vue';
import { performAddressSearch, getRealCoords } from '../../../../services/addressService';
import {
  fromSearchResults,
  getSelectedAddress,
  SearchResult,
  withSelectedSearchResult,
  notAsked as newSearchAddressRequest,
} from './create/SearchAddressRequest';
import {
  errorCodeFromAliasAndInfo,
  notAsked as newCreateAddressRequest,
} from './create/CreateAddressRequest';

export default Vue.extend({
  components: {
    IconCheck,
    IconRefresh,
    IconLocation,
    IconCheck20,
    IconX20,
    IconHelp,
    IconExclamationRounded,
  },
  props: {
    onClose: { type: Function },
    onlyCorporative: { type: Boolean, default: false },
  },
  data() {
    return {
      aliasInputValue: '',
      infoInputValue: '',
      addressInputValue: '',
      alertMessage: '',
      addressOnMap: false,
      selectedAddress: null as Coords | null,
      abortController: null as null | AbortController,
      searchAddressRequest: newSearchAddressRequest(),
      createAddressRequest: newCreateAddressRequest(),
      debouncedFetchSearchResults: (...args) => {},
    };
  },
  created() {
    // Evitar llamar demasiadas veces a la API.
    this.debouncedFetchSearchResults = debounce(this.fetchSearchResults, 500);

    eventBus.$on('setCenter', (coords) => {
      this.selectedAddress = coords as Coords;
    });
  },
  beforeDestroy() {
    eventBus.$emit('addressOnMap', false);
  },
  computed: {
    idCliente(): number {
      return this.$store.getters.idCliente;
    },
    searchResultsOrEmptyArray(): Array<SearchResult> {
      if (this.searchAddressRequest.status === 'withSearchResults'
        && this.addressInputValue.length > 3) {
        return this.searchAddressRequest.results;
      }
      return [];
    },
    errorMessage(): string | null {
      // Los errores de createAddressRequest ya se muestran en el $toast.
      if (this.searchAddressRequest.status === 'error') {
        return msg.getError(this.searchAddressRequest.errorCode);
      }
      return null;
    },
    helpMessage(): { message: string, isError: boolean } | null {
      if (this.alertMessage) {
        return {
          message: msg.getError(this.alertMessage),
          isError: true,
        };
      }
      if (this.searchResultsOrEmptyArray.length && !this.addressOnMap) {
        if (validators.isValidAddress(this.addressInputValue.trim())) {
          return {
            message: msg.getInfo('no_useful_addresses'),
            isError: false,
          };
        }
        return {
          message: msg.getInfo('complete_address_field'),
          isError: false,
        };
      }
      return null;
    },
    buttonInfo() {
      if (!this.addressOnMap) {
        const isValidAddress = validators.isValidAddress(this.addressInputValue.trim());
        return {
          icon: 'IconLocation',
          iconClass: isValidAddress
            ? 'bg-gray-200 text-gray-700 hover:bg-white hover:text-black'
            : 'bg-gray-300 text-gray-400',
          onClick: (): void => {
            if (isValidAddress) {
              this.searchForCity(this.addressInputValue, false);
            }
          },
          tooltip: isValidAddress
            ? 'Seleccionar en mapa'
            : 'Debe completar el formato: Calle/Ruta N°, Localidad, Provincia, País',
        };
      }
      return {
        icon: 'IconX20',
        iconClass: 'bg-gray-100 text-gray-700 hover:bg-white hover:text-red-600',
        onClick: (): void => {
          this.addressOnMap = false;
          this.addressInputValue = '';
          this.aliasInputValue = '';
          this.infoInputValue = '';
          this.alertMessage = '';
          this.selectedAddress = null;
          eventBus.$emit('selectedAddress', null);
          eventBus.$emit('addressOnMap', false);
        },
        tooltip: 'Cancelar seleccion',
      };
    },
  },
  methods: {
    // Functions
    isSelectedAddress(address: SearchResult): boolean {
      if (this.searchAddressRequest.status === 'withSelectedSearchResult') {
        return this.searchAddressRequest.selectedResult.id === address.id;
      }
      return false;
    },
    getTooltip(code): string {
      return msg.getTooltip(code);
    },
    addressToString({ street }: SearchResult): string {
      return capitalizeText(`${humanReadableStreet(street)}`);
    },
    aliasToString({ alias }: SearchResult): string {
      return capitalizeText(alias || '');
    },
    // Methods
    onAliasInput(newInputValue) {
      this.aliasInputValue = newInputValue;
    },
    onInfoInput(newInputValue) {
      this.infoInputValue = newInputValue;
    },
    onAddressInput(newInputValue) {
      this.addressInputValue = newInputValue;
      eventBus.$emit('selectedAddress', null);
      this.alertMessage = '';
      if (this.addressInputValue.length > 3 && !this.addressOnMap) {
        this.debouncedFetchSearchResults();
      }
    },
    async onSearchResultSelect(addressId) {
      this.searchAddressRequest = withSelectedSearchResult(
        this.searchAddressRequest,
        addressId,
      );

      const selectedAddress = getSelectedAddress(this.searchAddressRequest);

      if (selectedAddress !== null) {
        /* En algunos casos las dir vuelven con coordenadas 0. En caso de que el source de las
          mismas sea google, se las puede recuperar con el endpoint /geocoding/v1/ */
        if ((!selectedAddress.lat || !selectedAddress.lon)
          && selectedAddress.source.startsWith('google')) {
          await getRealCoords(selectedAddress.source)
            .then(({
              id,
              street,
              lat,
              lon,
            }) => {
              selectedAddress.id = id;
              selectedAddress.street = street;
              selectedAddress.lat = lat;
              selectedAddress.lon = lon;
            })
            .catch((error) => {
              this.searchForCity(this.addressToString(selectedAddress));
            });
        }
        if (selectedAddress.lat && selectedAddress.lon) {
          const addressCoords = toCoords(selectedAddress);
          this.selectedAddress = addressCoords;
          eventBus.$emit('selectedAddress', addressCoords);
        }
        this.addressInputValue = this.addressToString(selectedAddress);
        this.aliasInputValue = this.aliasToString(selectedAddress);
        this.infoInputValue = selectedAddress.info || '';
      }
    },
    fetchSearchResults() {
      if (this.searchAddressRequest.status === 'loading' && this.abortController) {
        this.abortController.abort();
      }
      this.searchAddressRequest = { status: 'loading' };

      this.abortController = new AbortController();

      performAddressSearch(
        this.addressInputValue,
        this.idCliente,
        getUserLocation(),
        this.abortController.signal,
      )
        .then((response) => {
          this.searchAddressRequest = fromSearchResults(response);
          this.abortController = null;
        })
        .catch((error: AxiosError) => {
          if (!axios.isCancel(error)) {
            this.searchAddressRequest = {
              status: 'error',
              errorCode: errorCodeFromAxiosError(error),
            };
          }
        });
    },
    clickedCreateButton() {
      this.addressInputValue = this.addressInputValue.trim();
      this.aliasInputValue = this.aliasInputValue.trim();
      this.infoInputValue = this.infoInputValue.trim();
      if (this.alertMessage && this.alertMessage !== 'address_not_found') {
        this.$toast.error(msg.getError(this.alertMessage));
        return;
      }
      if (this.createAddressRequest.status !== 'loading') {
        this.createAddressRequest = { status: 'loading' };

        if (this.addressOnMap) {
          /* Lo que hace el getCenter es emitir una señal al componente mapa.
          El mismo toma las coordenadas del centro del mapa y sobrescribe la
          variable selectedAddress mediante la señal setCenter. */
          eventBus.$emit('getCenter');
        }

        // Validar datos

        const validationError = errorCodeFromAliasAndInfo(this);

        if (!validators.isValidAddress(this.addressInputValue.trim())) {
          this.createAddressRequest = {
            status: 'error',
            errorCode: 'address_invalid',
          };
        } else if (validationError !== null) {
          this.createAddressRequest = {
            status: 'error',
            errorCode: validationError,
          };
        } else if (this.selectedAddress === null) {
          this.createAddressRequest = {
            status: 'error',
            errorCode: 'missing_fields',
          };
        } else {
          const { lat, lon } = this.selectedAddress;
          if (!lat || !lon) {
            this.$toast.error(msg.getError('missing_coords'));
            return;
          }

          const endpointToUse = this.onlyCorporative ? corporativeAddress : favouriteAddress;

          endpointToUse
            .addAddress({
              alias: this.aliasInputValue,
              info: this.infoInputValue,
              street: this.formatAddress(this.addressInputValue),
              lat,
              lon,
            }, this.idCliente)
            .then(
              (response) => {
                this.$toast.success(msg
                  .getSuccess(`${this.onlyCorporative ? 'corporative' : 'favourite'}_address_added`));
                this.onClose();
              },
              (error: AxiosError) => {
                let errorCode = errorCodeFromAxiosError(error);
                if (errorCode === 'alias_used') errorCode = 'address_alias_used';
                this.createAddressRequest = { status: 'error', errorCode };
              },
            );
        }
      }
    },
    searchForCity(address: string, showAlert = true) {
      const query = address.slice(address.indexOf(',') + 1).trim();
      const city = address.split(',')[1].toLocaleLowerCase();

      performAddressSearch(query, this.idCliente, getUserLocation())
        .then(async (res) => {
          if (!res.length && showAlert) {
            this.alertMessage = 'location_not_found';
          } else {
            const dir = res.filter((adds) => adds.street.split(',')[1].toLocaleLowerCase() === city)[0]
              || res[0];
            if (!dir.lat || !dir.lon) {
              await getRealCoords(dir.source)
                .then(({ lat, lon }) => {
                  dir.lat = lat;
                  dir.lon = lon;
                });
            }
            if (dir.lat && dir.lon) {
              eventBus.$emit('selectedAddress', toCoords(dir));
            }
            if (showAlert) this.alertMessage = 'address_not_found';
            this.addressOnMap = true;
            this.selectedAddress = null;
            eventBus.$emit('addressOnMap', true);
          }
        }).catch((error) => { this.$toast.error(msg.getError(error)); });
    },
    formatAddress(originalAdds: string): string {
      let formattedAdds = originalAdds.replaceAll(/[^\p{L}\p{M}\p{N},.'°&]/gu, ' ');
      formattedAdds = formattedAdds.split(',')
        .map((string) => string.trim()).join(', ');
      return capitalizeText(formattedAdds);
    },
  },
  watch: {
    // Mostrar $toast cuando haya error de crear dirección
    createAddressRequest() {
      if (this.createAddressRequest.status === 'error') {
        this.$toast.error(msg.getError(this.createAddressRequest.errorCode));
      }
    },
    searchResultsOrEmptyArray() {
      if (!(this.searchAddressRequest.status === 'emptyResponse')) return;
      if (validators.isValidAddress(this.addressInputValue.trim())) {
        this.searchForCity(this.addressInputValue);
      } else {
        this.alertMessage = 'address_invalid';
      }
    },
  },
});
