<template>
  <div :class="customClass">
    <div v-for="(value, index) in showData"
      :key="index" v-show="value">
      <label class="detail-label" for="inline-input">
        {{ index | label }}
      </label>
      <p class="detail-value" >{{ value | capitalize }}</p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    showData: { type: Object, default: () => null },
    customClass: { type: String, default: 'grid grid-cols-2 gap-4' },
  },
};
</script>
