




















import { Address } from '@/models/Address';
import { propOfType } from '@/services/utils';
import Vue from 'vue';
import AddressesCreate from './AddressesCreate.vue';
import AddressesList from './AddressesList.vue';

// --- ACTIVE VIEW

export type ActiveView =
  | 'AddressesList'
  | 'AddressesCreate'

function toggleView(x: ActiveView): ActiveView {
  if (x === 'AddressesList') {
    return 'AddressesCreate';
  }
  return 'AddressesList';
}

export default Vue.extend({
  components: {
    AddressesCreate,
    AddressesList,
  },
  data() {
    return {
      activeView: 'AddressesList' as ActiveView,
    };
  },
  props: {
    onCardDimensionsChange: { type: Function, default: () => {} },
    updateAddresses: { type: Function, default: () => {} },
    value: { type: Boolean },
    onlyCorporative: { type: Boolean, default: false },
    isFetching: { type: Boolean, default: false },
    addresses: propOfType<Address[]>(() => []),
  },
  watch: {
    value() {
      this.toggleActiveView();
    },
  },
  methods: {
    toggleActiveView(): void {
      this.activeView = toggleView(this.activeView);
      // Cada vez que cambiamos de vista, calculamos la height de la `card` porque la necesitamos
      // en un componente padre.
      this.cardDimensionsChanged();
    },
    cardDimensionsChanged(): void {
      // El requestAnimationFrame espera un frame de animación.
      // Queremos esperar a que Vue haga los cambios en el DOM y que se rendericen
      // antes de preguntar por el nuevo height.
      requestAnimationFrame(() => {
        // Nota: es importante que sea arrow function `() => {}` en vez
        // de function expression `function() {}` porque si no quedaría `this === window`
        // y se rompe todo.
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions

        if (this.onCardDimensionsChange) {
          const height = (this.$refs.addressCard as any).offsetHeight;
          this.onCardDimensionsChange(height);
        }
      });
    },
  },
});
