





























































































import Vue from 'vue';
// eslint-disable-next-line
import { eventBus } from '@/main';
import msg from '@/services/userMsg';
import { copyStringToClipboard } from '@/services/utils';
import { GeneralAccount, getIdCliente } from '@/models/accounts/GeneralAccount';
import store from '@/services/store';
import Pedido from '@/models/Pedido';
import { getPermissions } from '@/services/store/state';
import { Permissions } from '@/models/accounts/shared';
import { INSERVER_BASE_URL } from '@/services/http';
import TabLink from '@/modules/site/pages/dashboard/mi-cuenta/TabLink.vue';
import LoadingFullscreen from '@/modules/LoadingFullscreen.vue';
import PedidosListWFilters from '../mis-pedidos/PedidosListWFilters.vue';
import PedidosDetail from '../mis-pedidos/PedidosDetail.vue';
import YearReportSelector from './YearReportSelector.vue';
import MonthReportSelector from './MonthReportSelector.vue';

export default Vue.extend({
  name: 'pedidos',
  components: {
    PedidosDetail,
    TabLink,
    PedidosListWFilters,
    LoadingFullscreen,
    YearReportSelector,
    MonthReportSelector,
  },
  props: {
    /** Route params */
    q: { type: String, default: '' },
    status: { type: String, default: '' },
    type: { type: String, default: '' },
    group: { type: String, default: '' },
    since: { type: String },
    until: { type: String },
    selected: { type: String, default: '-1' },
    modal: { type: String, default: null },
  },
  data() {
    return {
      sectionName: 'informes',
      pedidoSelected: undefined as Pedido | undefined,
      showCecos: false,
      chartLoading: true,
      currentTab: 0 as 0 | 1 | 2,
      selectedYear: new Date().getFullYear(),
      selectedMonth: new Date().getMonth(),
      viewUserOrders: false,
    };
  },
  created() {
    eventBus.$on('viewUserOrders', () => { this.viewUserOrders = true; });
  },
  mounted() {
    document.title = `${this.sectionData.title} - InPunto`;
    if (this.viewUserOrders && this.permisos?.showClientPedidos) {
      this.currentTab = 1;
    } else {
      this.currentTab = this.permisos?.showClientPedidos ? 2 : 1;
    }
  },
  beforeDestroy() {
    if (this.pedidoSelected) eventBus.$emit('pedidoSelected', false);
  },
  watch: {
    currentTab(newTab: number) {
      this.chartLoading = true;
      if (this.pedidoSelected) this.deselectPedido();
    },
    selectedClient() {
      this.currentTab = this.permisos?.showClientPedidos ? this.currentTab : 1;
    },
  },
  methods: {
    deselectPedido(): void {
      this.$router.replace({
        query: {
          ...this.$route.query,
          selected: undefined,
        },
      });
    },
    onClose() {
      this.showCecos = false;
    },
    copy(str: string) {
      copyStringToClipboard(str)
        .then(() => this.$toast.success(msg.getSuccess('string_copied')));
    },
    handleYearSelected(year: number) {
      this.selectedYear = year;
    },
    handleMonthSelected(month: number) {
      this.selectedMonth = month;
    },
  },
  computed: {
    sectionData(): { title: string, desc: string} {
      const title = msg.getTitle(this.sectionName);
      const desc = msg.getDescription(this.sectionName);
      return {
        title,
        desc,
      };
    },
    selectedAccount(): GeneralAccount { return store.getters.selectedAccount; },
    selectedClient(): number { return getIdCliente(this.selectedAccount); },
    permisos(): Permissions | null {
      return getPermissions(store.state);
    },
    isAuditor(): boolean {
      return Boolean(this.permisos?.showCeCoPedidos || this.permisos?.showGroupPedidos);
    },
    auditedCecosOrGroups(): Array<string> {
      const corporateAccount = this.selectedAccount?.tag === 'corporateAccount'
        ? this.selectedAccount.account : null;

      if (corporateAccount) {
        if (this.permisos?.showCeCoPedidos) {
          return corporateAccount.cecosAuditados.map((ceco) => ceco.alias || '-')
            .sort((a, b) => a.localeCompare(b));
        }
        if (this.permisos?.showGroupPedidos) {
          return corporateAccount.gruposAuditados.map((group) => group.alias)
            .sort((a, b) => a.localeCompare(b));
        }
      }
      return [];
    },
    subtitleText(): Array<string> {
      if (this.permisos?.showClientPedidos) {
        return ['Consulta los pedidos de tu empresa.'];
      }
      if (this.permisos?.showCeCoPedidos) {
        return [
          'Consulta los pedidos de tus',
          'centros de costo',
        ];
      }
      if (this.permisos?.showGroupPedidos) {
        return [
          'Consulta los pedidos de tus',
          'grupos',
        ];
      }
      return [];
    },
    anualStreamlitURL(): string {
      return `${INSERVER_BASE_URL}streamlit/resumen_anual?id_cliente=${this.selectedClient}&year=${this.selectedYear}&embed=true`;
    },
    mensualStreamlitURL(): string {
      // eslint-disable-next-line max-len
      return `${INSERVER_BASE_URL}streamlit/resumen_mensual?id_cliente=${this.selectedClient}&year=${this.selectedYear}&month=${this.selectedMonth}&embed=true`;
    },
  },
});
