import { equals } from '@/services/utils';
import { AccountId } from './AccountId';
import * as accountId from './AccountId';
import { CorporateAccount } from './CorporateAccount';
import { ParticularAccount } from './ParticularAccount';
import { GeneralAccount } from './GeneralAccount';
import * as generalAccount from './GeneralAccount';

/** Las cuentas del usuario, incluyendo una cuenta particular
 * y cuentas corporativas.
*/
export type UserAccounts = {
  particularAccount: ParticularAccount | null,
  corporateAccounts: Array<CorporateAccount>,
}

/** Modifica la cuenta corporativa que tiene el ID dado
 * usando la función `f`.
 *
 * En este caso no uso el `AccountId` porque el AccountId puede ser
 * un ID de una cuenta particular, lo cual no nos interesa.
*/
export function updateCorporateAccount(
  accounts: UserAccounts,
  corporateAccountId: number,
  f: (corpAccount: CorporateAccount) => CorporateAccount,
): UserAccounts {
  return {
    particularAccount: accounts.particularAccount,
    corporateAccounts: accounts.corporateAccounts
      .map((corpAccount) => {
        if (corpAccount.idCuentaSgv === corporateAccountId) {
          return f(corpAccount);
        }
        return corpAccount;
      }),
  };
}

export function findById(id: AccountId, accounts: UserAccounts): GeneralAccount | null {
  if (accounts.particularAccount && equals(id, accountId
    .fromParticularAccount(accounts.particularAccount))) {
    return generalAccount.fromParticularAccount(accounts.particularAccount);
  }

  for (const account of accounts.corporateAccounts) {
    if (equals(id, accountId.fromCorporateAccount(account))) {
      return generalAccount.fromCorporateAccount(account);
    }
  }

  return null;
}
