/**
 * Crear un elemento DOM, que es la API nativa para representar nodos HTML.
 *
 * Vue nos ayuda a no tener que hacer esto manualmente, porque usamos los VNode de Vue
 * en vez de la API nativa de los navegadores.
 *
 * Sin embargo, para usar la API de TomTom necesitamos usar elementos DOM.
 *
 * Usar esta función tiene el desafío de conocer algunos detalles de la API del DOM.
 * Por ejemplo, [la diferencia entre atributos y propiedades](https://es.javascript.info/dom-attributes-and-properties).
 *
 * Para simplificar, esta API solo permite usar *atributos*.
 * (No funciona con `onclick`, `value`, `checked`, etc).
 *
 * **Ejemplo**
 *
 * La siguiente llamada devuelve `<div class="marker">Hola</div>`:
 *
 * ```typescript
 * htmlElement('div', { 'class': 'marker' }, [htmlText('Hola')])
 * ```
 *
 * Ver también `htmlText`.
*/
export function htmlElement(
  tag: string,
  attributes: { [attribute: string]: string },
  children: Array<HTMLElement | Text>,
): HTMLElement {
  const el = document.createElement(tag);

  Object.entries(attributes).forEach((entry) => {
    const [attribute, value] = entry;
    el.setAttribute(attribute, value);
  });

  children.forEach((child) => el.appendChild(child));
  return el;
}

/** Devuelve un elemento DOM que representa un nodo de texto plano en HTML. */
export function htmlText(content: string): Text {
  const textNode = document.createTextNode(content);
  return textNode;
}
