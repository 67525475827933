



































import Vue from 'vue';
import { get } from '@/services/http';
import ButtonLogin from '../components/ButtonLogin.vue';
import ButtonRecoverPass from '../components/ButtonRecoverPass.vue';

export default Vue.extend({
  components: {
    ButtonLogin,
    ButtonRecoverPass,
  },
  data() {
    return {
      code: '',
      errorMsg: '',
      domain: '',
      showSpinner: false,
    };
  },
  computed: {
  },
  mounted() {
    document.title = 'Registrarse - InPunto';
  },
  methods: {
    verifyCode() {
      if (this.code.length > 0) {
        this.showSpinner = true;
        get('/usuarios/registro/codigos/$registrationCode', { registrationCode: this.code.toUpperCase() })
          .then(({ data }) => {
            this.domain = data.domain;
            this.$emit('input', this.domain);
            this.$emit('updateCode', this.code.toUpperCase());
            this.$emit('changeView', 'SingUpForm');
          })
          .catch((error) => { this.errorMsg = 'El código de registro no coincide con ninguna empresa'; })
          .finally(() => { this.showSpinner = false; });
      } else {
        this.errorMsg = 'Debe ingresar un código';
      }
    },
  },
});
