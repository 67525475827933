


































































































































































































































































































import {
  pair, propOfType, toKeyValuePair, validators,
} from '@/services/utils';
import Vue from 'vue';
import msg from '@/services/userMsg';
import { Cliente } from '@/models/employees/Cliente';
import { Permissions } from '@/models/accounts/shared';
import { Group } from '@/models/requests-and-responses/grupos';
import { getPermissions } from '@/services/store/state';
import store from '@/services/store';
import LabeledCheckbox from './LabeledCheckbox.vue';
import SelectorPorLista from './SelectorPorLista.vue';

// --- Model

/** El modelo de datos del formulario, se puede usar con `v-model`.
*/
export type Model = {
  nombre: string,
  apellido: string,
  dni: string,
  legajo: string,
  celular: string,
  email: string,
  activo: boolean,
  permisos: Permissions,
  centrosDeCostoHabilitados: Array<number>,
  idCentrosDeCostoAuditados: Array<number>,
  idGruposAuditados: Array<number>,
  centroDeCostoPreferido: number,
  idFormaDePagoPorDefecto: number | null,
  // null = Opción 'Sin grupo' por defecto. undefined = No se selecciona ninguna opción por defecto
  idGrupo: number | null | undefined
}

// --- ValidationError

/** El estado de validación. Se usa en el componente padre.
 * Guarda los campos en los que hubo errores.
*/
export type ValidationError =
  | { tag: 'noError' }
  | { tag: 'errors', fields: Array<string> }

export function noError(): ValidationError {
  return { tag: 'noError' };
}

function validationError(validation: ValidationErrorMessages): ValidationError {
  if (!Object.values(validation).some((x) => x)) {
    return { tag: 'noError' };
  }

  return {
    tag: 'errors',
    fields: Object.entries(validation)
      .filter(([_, errorMessage]) => errorMessage !== null)
      .map(([field, _]) => getFieldName(field as keyof ValidationErrorMessages)),
  };
}

export function validationErrorToString(validation: ValidationError): string | null {
  if (validation.tag === 'noError') {
    return null;
  }

  return `Hay errores en los siguientes campos: ${validation.fields.join(', ')}`;
}

/** Mensajes de validación. Se usan en el template. */
type ValidationErrorMessages = {
  nombre: string | null,
  apellido: string | null,
  dni: string | null,
  legajo: string | null,
  celular: string | null,
  email: string | null,
  centrosDeCostoHabilitados: string | null,
  centroDeCostoPreferido: string | null,
  roles: string | null,
  cecosAuditados: string | null,
  gruposAuditados: string | null,
  permisos: string | null,
  formaDePagoPorDefecto: string | null,
}

function validationErrorMessages(
  model: Model,
  phoneIsValid: boolean,
): ValidationErrorMessages {
  return {
    nombre: validarNombre(model.nombre.trim()),
    apellido: validarApellido(model.apellido.trim()),
    dni: validarDni(model.dni.trim()),
    legajo: validarLegajo(model.legajo.trim()),
    celular: !phoneIsValid
      ? 'El celular no es válido'
      : null,
    email: !validators.isValidEmail(model.email)
      ? 'El email no es válido'
      : null,
    centrosDeCostoHabilitados: !model.centrosDeCostoHabilitados.length
      ? 'Debes seleccionar al menos un centro de costo'
      : null,
    centroDeCostoPreferido: model.centrosDeCostoHabilitados.length
      && !model.centrosDeCostoHabilitados.includes(model.centroDeCostoPreferido)
      ? 'Debes elegir un centro de costo preferido'
      : null,
    permisos: Object.values(model.permisos).some((x) => x)
      ? null : 'Debes seleccionar al menos un permiso',
    roles: model.permisos.showReports && !availableRoles.some((role) => model.permisos[role])
      ? 'Debes seleccionar al menos un rol' : null,
    cecosAuditados: model.permisos.showCeCoPedidos && !model.idCentrosDeCostoAuditados.length
      ? 'Debes seleccionar al menos un centro de costo' : null,
    gruposAuditados: model.permisos.showGroupPedidos && !model.idGruposAuditados.length
      ? 'Debes seleccionar al menos un grupo' : null,
    formaDePagoPorDefecto: model.idFormaDePagoPorDefecto
      ? null : 'Debes seleccionar una forma de pago predeterminada',
  };
}

function validarNombre(nombre: string): string | null {
  if (nombre.length === 0) {
    return 'El nombre no puede ser vacío';
  }
  if (nombre.length > 100) {
    return 'El nombre es demasiado largo';
  }
  if (!validators.isValidName(nombre)) {
    return msg.getError('name_invalid');
  }
  return null;
}

function validarApellido(apellido: string): string | null {
  if (apellido.length === 0) {
    return 'El apellido no puede ser vacío';
  }

  if (apellido.length > 75) {
    return 'El apellido es demasiado largo';
  }
  if (!validators.isValidName(apellido)) {
    return msg.getError('surname_invalid');
  }
  return null;
}

function validarDni(dni: string): string | null {
  if (dni.length === 0) {
    return 'El DNI no puede ser vacío';
  }
  if (dni.length > 10) {
    return 'El DNI es demasiado largo';
  }
  if (!validators.isValidDNI(dni)) {
    return msg.getError('dni_invalid');
  }
  return null;
}

function validarLegajo(legajo: string): string | null {
  if (legajo.length === 0) {
    return 'El legajo no puede ser vacío';
  }
  if (legajo.length > 60) {
    return 'El legajo es demasiado largo';
  }
  return null;
}

// eslint-disable-next-line consistent-return
function getFieldName(key: keyof ValidationErrorMessages): string {
  // eslint-disable-next-line default-case
  switch (key) {
    case 'nombre':
    case 'apellido':
    case 'legajo':
    case 'celular':
    case 'email':
    case 'permisos':
    case 'roles':
      return key;
    case 'cecosAuditados':
      return 'centros de costo auditados';
    case 'gruposAuditados':
      return 'grupos auditados';
    case 'centrosDeCostoHabilitados':
      return 'centros de costo habilitados';
    case 'centroDeCostoPreferido':
      return 'centro de costo preferido';
    case 'dni':
      return 'DNI';
    case 'formaDePagoPorDefecto':
      return 'forma de pago predeterminada';
  }
}

// Permisos que son usados como roles en Informes
const availableRoles = [
  'showClientPedidos',
  'showCeCoPedidos',
  'showGroupPedidos',
] as (keyof Permissions)[];

export default Vue.extend({
  props: {
    value: propOfType<Model>(),
    cliente: propOfType<Cliente>(),
    groups: propOfType<Group[]>(),
    disableGroupSelector: propOfType<boolean>(false),
    showValidationError: propOfType<boolean>(false),
    // El tipo de esta prop es `(error: ValidationError) => void`, pero se rompe todo si lo pongo.
    onValidationErrorChange: propOfType<Function>(() => {}),
  },
  components: { LabeledCheckbox, SelectorPorLista },
  data() {
    return {
      phoneIsValid: false,
    };
  },
  computed: {
    permissions(): Permissions | null {
      return getPermissions(store.state);
    },
    canManageEmpleados(): boolean {
      return this.permissions?.manageEmpleados || false;
    },
    cecoOrGroupOptions(): Array<{ id: number, alias: string }> {
      if (this.value.permisos.showCeCoPedidos) {
        return [
          ...this.cliente.cecos,
          ...this.cliente.cecosInactivos,
        ].sort((a, b) => a.alias.localeCompare(b.alias));
      }
      if (this.value.permisos.showGroupPedidos) {
        return this.groups.map((group) => ({
          id: group.id,
          alias: group.nombre,
        })).sort((a, b) => a.alias.localeCompare(b.alias));
      }
      return [];
    },
    selectedCecosOrGroups(): Array<number> {
      if (this.value.permisos.showCeCoPedidos) {
        return [
          ...this.cliente.cecos,
          ...this.cliente.cecosInactivos,
        ].reduce((result, ceco) => {
          if (this.value.idCentrosDeCostoAuditados.includes(ceco.id)) {
            result.push(ceco.id);
          }
          return result;
        }, [] as number[]);
      }
      if (this.value.permisos.showGroupPedidos) {
        return this.groups.reduce((result, group) => {
          if (this.value.idGruposAuditados.includes(group.id)) {
            result.push(group.id);
          }
          return result;
        }, [] as number[]);
      }
      return [];
    },
    validationErrorMessages(): ValidationErrorMessages {
      return validationErrorMessages(this.value, this.phoneIsValid);
    },
    shownValidationErrorMessages(): ValidationErrorMessages {
      if (!this.showValidationError) {
        return {
          nombre: null,
          apellido: null,
          email: null,
          dni: null,
          legajo: null,
          celular: null,
          centrosDeCostoHabilitados: null,
          centroDeCostoPreferido: null,
          permisos: null,
          roles: null,
          cecosAuditados: null,
          gruposAuditados: null,
          formaDePagoPorDefecto: null,
        };
      }

      return this.validationErrorMessages;
    },
    centroDeCostoPreferidoOptions(): Array<{ value: number, text: string }> {
      // Convierto los cecos privados para hacer consultas en O(1)
      // Recordemos que `this.cliente.cecosPrivados: Array<{ id: number, nombre: string }>`
      const cecosPrivados = toKeyValuePair(
        this.cliente.cecos,
        ({ id, alias }) => pair(String(id), alias),
      );

      return this.value.centrosDeCostoHabilitados.map((id) => ({
        value: id,
        text: cecosPrivados[id] || 'Desconocido',
      }));
    },
    formaDePagoPorDefecto(): Array<{ value: number, text: string }> {
      return this.cliente.formaDePagoHabilitadas.map((fp) => ({
        text: fp.alias,
        value: fp.id,
      }));
    },
    groupOptions(): string[] {
      return [
        'Sin grupo',
        ...this.groups.map((group) => group.nombre),
      ];
    },
    disableAdminToggle(): boolean {
      return !this.value.idGrupo || this.value.idGrupo < 0;
    },
    reportRoles(): Array<{ value: string, text: string }> {
      return availableRoles.map((role) => ({
        value: role,
        text: msg.getPermission(role),
      }));
    },
    selectedRole(): (keyof Permissions) | null {
      return availableRoles.find((role) => this.value.permisos[role]) || null;
    },
  },
  mounted() {
    const isFPEnabled = this.cliente.formaDePagoHabilitadas
      .some((fp) => fp.id === this.value.idFormaDePagoPorDefecto);
    if (!isFPEnabled) {
      this.input({
        ...this.value,
        idFormaDePagoPorDefecto: null,
      });
    }
    this.onValidationErrorChange(validationError(this.validationErrorMessages));
  },
  watch: {
    value() {
      this.onValidationErrorChange(validationError(this.validationErrorMessages));
    },
  },
  methods: {
    label(key: string): string {
      return msg.getPermission(key);
    },
    input(model: Model): void {
      this.$emit('input', model);
    },
    nameToGroupId(groupName: string): number {
      return this.groups.find(({ nombre }) => nombre === groupName)?.id || -1;
    },
    idToGroupName(idGrupo: number | null | undefined): string | null {
      if (idGrupo === undefined) return null;
      return this.groups.find(({ id }) => id === idGrupo)?.nombre || 'Sin grupo';
    },
    onNombreInput(nombre: string): void {
      this.input({ ...this.value, nombre });
    },
    onApellidoInput(apellido: string): void {
      this.input({ ...this.value, apellido });
    },
    onEmailInput(email: string): void {
      this.input({ ...this.value, email });
    },
    onDniInput(dni: string): void {
      this.input({ ...this.value, dni });
    },
    onLegajoInput(legajo: string): void {
      this.input({ ...this.value, legajo });
    },
    onCelularInput(celular: string): void {
      this.input({ ...this.value, celular });
    },
    onCentrosDeCostoHabilitadosInput(centrosDeCostoHabilitados: Array<number>) {
      this.input({ ...this.value, centrosDeCostoHabilitados });
    },
    onPermisosInput(permiso: keyof Model['permisos'], checked: boolean): void {
      const permisos = {
        ...this.value.permisos,
        [permiso]: checked,
      };
      if (permiso === 'travel' && !checked) permisos.registerGoRequest = false;
      if (permiso === 'registerGoRequest' && checked) permisos.travel = true;
      if (permiso === 'showReports' && !checked) {
        permisos.showClientPedidos = false;
        permisos.showCeCoPedidos = false;
        permisos.showGroupPedidos = false;
      }
      if (permiso === 'showClientPedidos' && checked) {
        permisos.showCeCoPedidos = false;
        permisos.showGroupPedidos = false;
      }
      if (permiso === 'showCeCoPedidos' && checked) {
        permisos.showClientPedidos = false;
        permisos.showGroupPedidos = false;
      }
      if (permiso === 'showGroupPedidos' && checked) {
        permisos.showClientPedidos = false;
        permisos.showCeCoPedidos = false;
      }

      this.input({
        ...this.value,
        permisos,
      });
    },
    onRoleInput(cecosOrGroups: Array<number>) {
      const { permisos } = this.value;
      if (permisos.showReports && !permisos.showClientPedidos) {
        if (permisos.showCeCoPedidos) {
          this.input({
            ...this.value,
            idCentrosDeCostoAuditados: cecosOrGroups,
          });
        } else if (permisos.showGroupPedidos) {
          this.input({
            ...this.value,
            idGruposAuditados: cecosOrGroups,
          });
        }
      }
    },
    onCentroDeCostoPreferidoInput(value: string): void {
      this.input({
        ...this.value,
        centroDeCostoPreferido: Number(value),
      });
    },
    onFormaDePagoPorDefectoInput(value: number): void {
      this.input({
        ...this.value,
        idFormaDePagoPorDefecto: value,
      });
    },
    onGroupInput(value: string): void {
      this.input({
        ...this.value,
        idGrupo: this.nameToGroupId(value),
      });
    },
  },
});

