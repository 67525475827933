import type { Person } from '../Person';
import type GenericPerson from '../taaxii.com/taaxiiGenericPerson';
import type { GenericCeCo } from '../accounts/shared';

export type Direccion = {
  alias?: string
  direccion: string
  lat: number
  lon: number
}

export type Pasaje = {
  idSolicitud: number
  pasajero?: Person
  despachante?: Person
  receptor?: Person
  tipo: 'PASAJERO' | 'BULTO' | 'MENSAJERIA'
  paradas: [number, number] // Indices de paradas
}

export type EventType = never
  | 'registrado'
  | 'confirmado'
  | 'arribado'
  | 'circulando'
  | 'fallo'
  | 'cancelado'
  | 'anulado'
  | 'finalizado'
  | 'calificado'

export type Evento = {
  timestamp: StringISO
  type: EventType
  parada?: number // Indice a parada
}

export type SeguimientoData = {
  status: string
  substatus: string
  viaje: {
    distanciaCotizada: number
    inicio: StringISO
    estado: unknown // Estado del viaje?
    vehiculo: {
      marca: string
      modelo: string
      patente: string
      tipoVehiculo: {
        alias: string
        id: number
      }
    } | null,
    chofer: Person | null
    cliente: {
      alias: string
      id: number
    }
  },
  pasajes: { [idPasaje: string]: Pasaje }
  paradas: Direccion[]
}

export type TrackerPosition = {
  lat: number
  lon: number
  timestamp: StringISO
}

export type CarActualPosition = {
  lat: number
  lon: number
  timestamp: StringISO
  angle: number
}

export type Recorrido = {
  isReal: boolean
  paradas: Direccion[]
  recorrido: TrackerPosition[]
}

export type PassengerModel = {
  person?: GenericPerson | Person | null,
  emisor?: GenericPerson | Person | null,
  receptor?: GenericPerson | Person | null,
  paradas?: string[] | null,
  from: string
  to: string
  ceco: GenericCeCo | null,
  obs: string | null,
}

export function fromIndexParadaToLabel(index: number, paradasAmount: number) {
  if (index === 0) return 'origen';
  if (index === paradasAmount - 1) return 'destino';
  return 'parada';
}

/**
 * MOCKEANDO:
 */

// const PersonEXAMPLE: Person = {
//   firstName: 'Agustín',
//   lastName: 'Orlando',
//   picUrl: 'https://resume.agusavior.info/agus.png',
//   phone: '+5493512442259',
//   email: 'agusavior@gmail.com',
// };

// const idPasajeEXAMPLE1 = '1000';
// const idPasajeEXAMPLE2 = '2000';
// const direccionEXAMPLE1 = {
//   lat: -31.3702181,
//   lon: -64.146723,
//   direccion: 'Julio de Vedia 2592, Córdoba, Córdoba, Argentina',
//   alias: 'Casa de Agus',
// };
// const direccionEXAMPLE2 = {
//   lat: -31.3761972,
//   lon: -64.1668262,
//   direccion: 'Luis Vernet 2592, Córdoba, Córdoba, Argentina',
// };
// const direccionEXAMPLE3 = {
//   lat: -31.3990615,
//   lon: -64.1601785,
//   direccion: 'Julio Roca 1592, Córdoba, Córdoba, Argentina',
//   alias: 'Aeropuerto Córdoba',
// };
// // Mockeamos el dato para luego reemplazarlo por el backend
// export const SeguimientoDataEXAMPLE: SeguimientoData = {
//   status: 'finalizado',
//   substatus: 'Completado',
//   viaje: {
//     inicio: '2021-08-28T00:58:48.799Z',
//     estado: 'Registrado',
//     chofer: PersonEXAMPLE,
//     vehiculo: {
//       marca: 'FIAT',
//       modelo: 'SIENA',
//       patente: 'AB 587 OB',
//     },
//   },
//   pasajes: {
//     [idPasajeEXAMPLE1]: {
//       pasajero: PersonEXAMPLE,
//       tipo: 'PASAJERO',
//       paradas: [0, 1],
//     },
//     [idPasajeEXAMPLE2]: {
//       pasajero: PersonEXAMPLE,
//       tipo: 'PASAJERO',
//       paradas: [1, 2],
//     },
//   },
//   paradas: [direccionEXAMPLE1, direccionEXAMPLE2, direccionEXAMPLE3],
// };

// export const eventosEXAMPLE: Evento[] = [
//   {
//     type: 'registrado',
//     timestamp: '2021-08-28T00:58:48.799Z',
//   },
//   {
//     type: 'confirmado',
//     timestamp: '2021-08-28T00:58:48.799Z',
//   },
//   {
//     type: 'circulando',
//     timestamp: '2021-08-28T00:58:48.799Z',
//     parada: 0,
//   },
//   {
//     type: 'arribado',
//     timestamp: '2021-09-08T15:46:46.498Z',
//     parada: 0,
//   },
//   {
//     type: 'circulando',
//     timestamp: '2021-08-28T00:58:48.799Z',
//     parada: 1,
//   },
//   {
//     type: 'arribado',
//     timestamp: '2021-08-28T00:58:48.799Z',
//     parada: 1,
//   },
//    {
//     type: 'fallo',
//     timestamp: '2021-08-28T00:58:48.799Z',
//   },
//   {
//     type: 'circulando',
//     timestamp: '2021-08-28T00:58:48.799Z',
//     parada: 2,
//   },
//   {
//     type: 'arribado',
//     timestamp: '2021-08-28T00:58:48.799Z',
//     parada: 2,
//   },
//   {
//     type: 'finalizado',
//     timestamp: '2021-08-28T00:58:48.799Z',
//   }, */
// ];
