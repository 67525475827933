






























































































































































import Vue from 'vue';
import Pedido from '@/models/Pedido';
import IconStar from '@/components/icons/IconStar.vue';
import IconClose24 from '@/components/icons/IconClose24.vue';
import Map, { padding } from '@/components/map/Map.vue';
import { Coords } from '@/services/Coords';
import {
  Marker, originMarker, destinationMarker, stopMarker, withTooltip,
  filterMarkers,
} from '@/components/map/Marker';
import { propOfType, toTwoDecimals } from '@/services/utils';
import IconChevronDownSection from '@/components/icons/IconChevronDownSection.vue';
import { api, get, getHeaders } from '@/services/http';
import { mapStateTyped } from '@/services/store/utils';
import taaxiiService from '@/services/taaxii.com/taaxiiService';
import { CostosInfo } from '@/models/requests-and-responses/sgv';
import { Direccion, TrackerPosition } from '@/models/requests-and-responses/seguimiento';
import msg from '@/services/userMsg';
import InfoCostos from './InfoCostos.vue';

export default Vue.extend({
  props: {
    pedido: propOfType<Pedido>(),
    href: String,
  },
  components: {
    InfoCostos,
    IconClose24,
    Map,
    IconStar,
    IconChevronDownSection,
  },
  data() {
    return {
      infoCostos: null as CostosInfo | null,
      isRecorridoReal: false,
      paradas: [] as Direccion[],
      trackerPositions: [] as TrackerPosition[],
      isViajeLoaded: false,
      isMapaLoaded: false,
      isTimelineLoaded: false,
      stars: 0,
      solicitaRevisar: false,
      loading: false,
      message: '',
      starHint: 0,
      expanded: true,
    };
  },
  beforeMount() {
    this.loadCosts();
    this.loadMap();
  },
  methods: {
    loadCosts() {
      taaxiiService.getCostos(this.pedido.resources)
        .then((result) => { this.infoCostos = result.costos; })
        .catch(() => { this.infoCostos = null; });
    },
    loadMap() {
      get('/pedidos/seguimiento/$pedidoPublicId/recorrido',
        { pedidoPublicId: this.pedido.idPublic, showReal: true.toString() })
        .then((e) => e.data)
        .then(({ isReal, paradas, recorrido }) => {
          this.isRecorridoReal = isReal
            && recorrido.length > paradas.length;
          this.paradas = paradas;
          this.trackerPositions = this.isRecorridoReal ? recorrido : [];
        }).catch((err) => {
          this.$toast.error(msg.getError(err.code));
        });
    },
    onConfirm() {
      this.loading = true;
      api.post(this.href, {
        username: localStorage.getItem('username'),
        enDesacuerdo: this.solicitaRevisar,
        calificacion: this.stars,
        observacion: this.message,
      }, {
        headers: getHeaders(),
      }).catch((err) => {
        this.$toast.error(msg.getError(err.code));
      }).finally(() => {
        this.$logEvent('calificar', {
          accion_calificar: 'enviar_calificacion',
        });

        this.loading = false;
        this.$emit('update');
        this.$emit('close');
      });
    },
    starToRatingString(stars: number) { // TODO: Usar
      switch (stars) {
        case 1: return 'Malo';
        case 2: return 'Regular';
        case 3: return 'Esperado';
        case 4: return 'Muy bueno';
        case 5: return 'Excelente';
        default: return '';
      }
    },
    toTwoDecimals(number: number): string {
      return toTwoDecimals(number);
    },
  },
  computed: {
    ...mapStateTyped(['user']),
    ratingExplanation() { // TODO: Usar
      switch (this.stars) {
        case 1: return 'Nada del servicio esperado funcionó.';
        case 2: return 'Algo del servicio esperado no funcionó.';
        case 3: return 'Estuvo a la hora solicitada.\nConducción prudente y sin sobresaltos.\nImpecable el coche y el chofer vestía formal.';
        case 4: return 'Me ayudaron con el equipaje y me abrieron la puerta de ingreso al coche.';
        case 5: return 'El interior del coche tenía una temperatura agradable, las ventanillas levantadas y la música apagada.\nMe ofrecieron una botella de agua fresca, golosinas y una revista de interés general en recorridos de media y larga distancia.';
        default: return '';
      }
    },
    mapMarkers() {
      const ret: Array<{ coords: Coords, marker: Marker }> = [];
      if (this.paradas.length) {
        const paradas = this.isRecorridoReal
          ? this.paradas : this.pedido.extras.paradas.map((p) => p.dir);

        paradas.forEach((p, i, ps) => {
          const { lat, lon } = p;
          let tooltip = i === 0 ? 'origen' : `parada ${i}`;
          let marker = i === 0 ? originMarker() : stopMarker();
          if (i === ps.length - 1) {
            tooltip = 'destino';
            marker = destinationMarker();
          }
          ret.push({
            coords: { lat, lon },
            marker: withTooltip(
              tooltip,
              marker,
            ),
          });
        });
      }
      return ret;
    },
    filteredMarkers(): Array<{ coords: Coords, marker: Marker } | undefined> {
      return filterMarkers(this.mapMarkers, false);
    },
    mapRoute(): Array<Coords> {
      if (!this.isRecorridoReal) {
        return this.pedido.extras.paradas
          .map(({ dir }) => ({ lat: dir.lat, lon: dir.lon }));
      }
      return [];
    },
    mapTrackerRoute(): Array<Coords> {
      if (this.isRecorridoReal) return this.trackerPositions;
      return [];
    },
    costs(): CostosInfo | null {
      if (this.infoCostos) {
        return {
          ...this.infoCostos,
          coefProrrateo: this.pedido.extras.pasajes[0].coefProrrateo || 100,
        };
      }
      return null;
    },
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
    navbarHeight(): number {
      return this.$store.getters.navbarHeight;
    },
    mapPadding(): { left: number, top: number, bottom: number, right: number } {
      return this.isMobile ? {
        left: 40, right: 40, top: 50, bottom: 220,
      } : padding(100);
    },
    recorridoLabel(): string {
      return this.isRecorridoReal
        ? msg.getHelpMessage('recorridoTransportista')
        : msg.getHelpMessage('recorridoCotizado');
    },
  },
});
