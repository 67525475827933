import TomTom from '@tomtom-international/web-sdk-maps';
import { htmlElement, htmlText } from '@/services/html';
import { Coords, toLngLat, CoordswithTag } from '@/services/Coords';
import { pair } from '@/services/utils';
import markerIcon from '@/assets/markerIcon.svg';
import markerNewAddress from '@/assets/markerNewAddress.svg';
import markerDestination from '@/assets/markerDestination.svg';
import markerStop from '@/assets/markerStop.svg';
import markerOrigin from '@/assets/markerOrigin.svg';
import markerDestinationLight from '@/assets/markerDestinationLight.svg';
import markerStopLight from '@/assets/markerStopLight.svg';
import markerOriginLight from '@/assets/markerOriginLight.svg';
import carMarkerSrc from '@/assets/carMarker.svg';

/**
 * Se refiere al elemento HTML que se usa como Marker en el mapa.
 *
 * ```typescript
 * defaultMarker();
 * withTooltip('Mi casa', defaultMarker());
 * customMarker(() => htmlElement('div', {}, []));
 * ```
 *
 * El componente Map recibe Markers en una de sus props.
 */
export type Marker = {
  tag: 'Marker',
  toHtml: () => HTMLElement,
  onCreate?: (tomTomMarker: TomTom.Marker) => void
};

export function customMarker(config: {
  toHtml: () => HTMLElement,
  onCreate?: (tomTomMarker: TomTom.Marker) => void,
}): Marker {
  return {
    tag: 'Marker',
    ...config,
  };
}

export function putOnTomTomMap(
  tomTomMap: TomTom.Map,
  coords: Coords,
  marker: Marker,
): TomTom.Marker {
  const element = marker.toHtml();

  const tomTomMarker = new TomTom.Marker({
    element,
    anchor: 'bottom',
  })
    .setLngLat(toLngLat(coords))
    .addTo(tomTomMap);

  if (marker.onCreate) {
    marker.onCreate(tomTomMarker);
  }

  return tomTomMarker;
}

/* Devuelve el marcador común. */
export function defaultMarker() {
  return customMarker({
    toHtml: () => htmlElement(
      'img',
      {
        width: '20px',
        height: '28px',
        src: markerIcon,
        class: 'hover:z-100',
      },
      [],
    ),
  });
}

/* Devuelve el marcador para una direccion nueva. */
export function newAddMarker() {
  return customMarker({
    toHtml: () => htmlElement(
      'img',
      {
        width: '20px',
        height: '28px',
        src: markerNewAddress,
        class: 'hover:z-100',
      },
      [],
    ),
  });
}

/** Devuelve el marcador del origen. */
export function originMarker(isSelected = true) {
  return customMarker({
    toHtml: () => htmlElement(
      'img',
      {
        width: '24px',
        height: '24px',
        src: isSelected ? markerOrigin : markerOriginLight,
        class: 'hover:z-100',
        style: 'top: 10px;',
      },
      [],
    ),
  });
}

/** Devuelve el marcador de una parada. */
export function stopMarker(isSelected = true) {
  return customMarker({
    toHtml: () => htmlElement(
      'img',
      {
        width: '24px',
        height: '24px',
        src: isSelected ? markerStop : markerStopLight,
        class: 'hover:z-100',
        style: 'top: 10px;',
      },
      [],
    ),
  });
}

/** Devuelve el marcador del destino. */
export function destinationMarker(isSelected = true) {
  return customMarker({
    toHtml: () => htmlElement(
      'img',
      {
        width: '24px',
        height: '24px',
        src: isSelected ? markerDestination : markerDestinationLight,
        class: 'hover:z-100',
        style: 'top: 10px;',
      },
      [],
    ),
  });
}

/** Devuelve el marcador del autito */
export function carMarker(last: Coords, actual: Coords) {
  return customMarker({
    toHtml: () => htmlElement(
      'img',
      {
        width: '56px',
        height: '56px',
        src: carMarkerSrc,
        class: 'hover:z-100',
      },
      [],
    ),
  });
}

/** Devuelve un marcador que tiene un "tooltip" siempre visible, implementado
 * usando Popups de TomTom.
 */
export function withTooltip(
  tooltip: string,
  marker: Marker,
  tooltipClass = '',
  forceRefresh = false,
): Marker {
  return customMarker({
    toHtml: marker.toHtml,
    onCreate(tomTomMarker: TomTom.Marker) {
      if (marker.onCreate && !forceRefresh) {
        marker.onCreate(tomTomMarker);
      }

      const rect = tomTomMarker.getElement().getBoundingClientRect();

      const margin = 6;

      const radius = Math.sqrt(rect.width * rect.width + rect.height * rect.height) / 2;
      const linearOffset = radius + margin;

      const popupOffsets = {
        top: pair(0, 0),
        'top-left': pair(0, 0),
        'top-right': pair(0, 0),
        bottom: pair(0, -rect.height - margin),
        'bottom-left': pair(linearOffset, -(rect.height - radius + linearOffset)),
        'bottom-right': pair(-linearOffset, -(rect.height - radius + linearOffset)),
        left: pair(radius, -(rect.height - radius)),
        right: pair(-radius, -(rect.height - radius)),
      };

      const popup = new TomTom.Popup({
        offset: popupOffsets,
        className: `tooltip-map ${tooltipClass}`.trim(),
        closeButton: false,
        closeOnClick: false,
        closeOnMove: false,
      })
        .setLngLat(tomTomMarker.getLngLat())
        .setText(tooltip);

      tomTomMarker
        .setPopup(popup)
        .togglePopup();

      /* Agregar esto hace que el tooltip arriba del marcador se vea solo al hacer hover:
      if (this.popupOnHover) {
        const markerDiv = marker.getElement();
        markerDiv.addEventListener('mouseenter', () => popup.addTo(this.map));
        markerDiv.addEventListener('mouseleave', () => popup.remove());
      } else {
        marker.togglePopup();
      }
      */

      /** Clickear en el popup hace que se cierre. Para evitar esto,
       * fuerzo a que el popup quede abierto siempre.
       */
      popup.on('close', () => {
        try {
          tomTomMarker.togglePopup();
        } catch (e: unknown) {
          /** Al parecer, hacer `marker.remove()` llama a este evento,
           * por lo que se intenta hacer `togglePopup` DESPUÉS de que el
           * marcador haya sido borrado, lo cual tira una excepción.
           *
           * Ignoro silenciosamente el error a falta de una API de TomTom que me permita
           * evitar esto.
           */
        }
      });
    },
  });
}

/** Devuelve un Marker que tiene un tooltip que se ve solamente al hacer hover.
 * Implementado usando CSS.
 */
export function withHiddenTooltip(
  tooltip: string,
  marker: Marker,
): Marker {
  return customMarker({
    toHtml() {
      return htmlElement(
        'div',
        { class: 'flex flex-col items-center hover:z-100 group' },
        [
          htmlElement(
            'div',
            {
              class: 'map-tooltip m-2 opacity-0 group-hover:opacity-100',
            },
            [htmlText(tooltip)],
          ),
          marker.toHtml(),
        ],
      );
    },
    ...(marker.onCreate ? { onCreate: marker.onCreate } : {}),
  });
}

export function coordsToTag(coords: Coords, index:number): string {
  const coordwithtag = coords as CoordswithTag;
  if (coordwithtag.tag === 'origen' || coordwithtag.tag === 'destino') return coordwithtag.tag;
  return `parada ${index}`;
}

export function indexToTag(index: number, maxIndex: number): string {
  if (index === 0) return 'origen';
  if (index === maxIndex) return 'destino';
  return `parada ${index}`;
}

export function filterMarkers(
  markers: Array<{ coords: Coords, marker: Marker } | undefined>, isTracking: boolean,
): Array<{ coords: Coords, marker: Marker } | undefined> {
  markers.forEach((marker, index) => {
    const markerfinded = markers.find((m) => m?.coords.lat === marker?.coords.lat
                                          && m?.coords.lon === marker?.coords.lon
                                          && m !== marker);
    if (markerfinded && marker) {
      const indexfinded = markers.indexOf(markerfinded);
      let markername1;
      let markername2;
      if (isTracking) {
        markername1 = coordsToTag(marker.coords, index);
        markername2 = coordsToTag(markerfinded.coords, indexfinded);
      } else {
        markername1 = indexToTag(index, markers.length - 1);
        markername2 = indexToTag(indexfinded, markers.length - 1);
      }
      marker.marker = withTooltip(`${markername2} | ${markername1}`, marker.marker);
      markerfinded.marker = withTooltip('', markerfinded.marker, 'hidden', true);
    }
  });
  return markers;
}
