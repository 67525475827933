









































































































import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import IconFilter from '@/components/icons/IconFilter.vue';
import IconChevronR20 from '@/components/icons/IconChevronR20.vue';
import IconChevronUpDown from '@/components/icons/IconChevronUpDown.vue';
import { fromPedidoStringToUIName } from '@/models/Pedido';
import store from '@/services/store';
import { Group } from '@/models/requests-and-responses/grupos';
import { get } from '@/services/http';
import { propOfType } from '@/services/utils';
import { CorporateAccount } from '@/models/accounts/CorporateAccount';

export type Filter = {
  param: string,
  title: string,
  showAlone?: boolean,
  isOpen?: boolean,
  options: (string | number)[],
  selected: (string | number)[],
}

export default Vue.extend({
  components: {
    IconFilter,
    IconChevronR20,
    IconChevronUpDown,
  },
  props: {
    filters: propOfType<Filter[]>(() => []),
    showGroups: propOfType<boolean>(false),
    limitGroups: propOfType<boolean>(false),
    showSinGrupo: propOfType<boolean>(true),
  },
  data() {
    return {
      searchQuery: '' as string | (string | null)[],
      typeToOpen: null as number | null,
      groups: [] as Group[],
      openFilters: false,
    };
  },
  beforeMount() {
    if (this.$route.query.q) this.searchQuery = this.$route.query.q;

    this.filters.forEach(({ param }) => {
      if (this.$route.query[param]) {
        this.$route.query[param].toString().split(',').forEach((type) => {
          const field = this.filters.find((f) => f.param === param);
          if (field) {
            this.onSelectOption(field, type);
          }
        });
      }
    });

    this.fetchGroups();
  },
  methods: {
    onSearchQueryInput(input): void {
      this.searchQuery = input;
      this.emitUpdate();
    },
    clearSearchQuery(): void {
      this.searchQuery = '';
      this.emitUpdate();
    },
    onSelectOption(field, option): void {
      this.openFilters = false;
      if (field.isOpen !== undefined) {
        field.isOpen = false;
      }
      const index = field.selected.findIndex((e) => e === option);
      if (index < 0) {
        field.selected.push(option);
        this.emitUpdate();
      } else {
        this.onRemoveOption(field, option);
      }
    },
    onRemoveOption(field, value): void {
      this.$set(field, 'selected', field.selected.filter((i) => i !== value));
      this.emitUpdate();
    },
    emitUpdate(): void {
      const query = {
        ...this.$route.query,
        q: this.searchQuery,
      };
      this.filters.forEach(({ param, selected }) => { query[param] = selected.join(','); });
      this.$router.replace({
        query,
      }).catch(() => {});
    },
    fetchGroups(): void {
      if (!this.showGroups) return;

      get('$sgv/rest/cuentas/grupos', undefined,
        {
          params: {
            nombre: '',
            offset: 0,
            limit: 9999,
            idCliente: this.idCliente,
          },
        })
        .then((res) => {
          this.groups = res.data.content.map(({
            id,
            nombre,
            descripcion,
            idClienteFk,
          }) => (
            {
              id,
              nombre,
              descripcion,
              idCliente: idClienteFk,
            }
          ));

          if (this.limitGroups) {
            this.groups = this.groups.filter(({ id }) => id === this.selectedAccount?.grupo.id);
          }

          this.groups.sort((a, b) => (
            a.nombre.toLocaleLowerCase().localeCompare(b.nombre.toLocaleLowerCase())
          ));

          this.$emit('groupUpdate', this.groups);

          const groupsIndex = this.filters.findIndex(({ param }) => param === 'group');
          if (groupsIndex > -1) {
            this.filters[groupsIndex].options = [
              ...this.defaultGroupOptions,
              ...this.groups.map((group) => group.id.toString()),
            ];
          }
        });
    },
    getDivBy5(num: number): number {
      return Math.floor(num / 5) * 5;
    },
    getOptionName(option: string | number): string {
      const opt2Number = Number(option);
      if (!Number.isNaN(opt2Number)) {
        if (opt2Number === -1) return 'Sin grupo';
        return this.groups.find(({ id }) => opt2Number === id)?.nombre || '-';
      }
      return fromPedidoStringToUIName(option);
    },
  },
  computed: {
    selected(): Array<{ field: Filter, value: string | number }> {
      return [
        ...this.filters.flatMap((field) => field.selected.map((value) => ({ field, value }))),
      ];
    },
    availableFilters(): Array<Filter> {
      const filters = this.filters.filter(({ showAlone }) => !showAlone);
      if (this.groupAmount < 1 || !this.showGroups) {
        return filters.filter(({ param }) => param !== 'group');
      }

      return filters;
    },
    individualFilters(): Array<Filter> {
      return this.filters.filter(({ showAlone }) => showAlone);
    },
    groupAmount(): number {
      const grupos = this.filters.find((filter) => filter.title === 'Grupos');
      return grupos?.options.length || 0;
    },
    idCliente(): number {
      return store.getters.idCliente;
    },
    selectedAccount(): CorporateAccount | null {
      return store.getters.selectedAccount?.tag === 'corporateAccount'
        ? store.getters.selectedAccount.account : null;
    },
    defaultGroupOptions(): number[] {
      if (this.showSinGrupo) return [-1];
      return [];
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
});
