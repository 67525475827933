
















































import Vue from 'vue';
import { mapGetters } from 'vuex';
import msg from '@/services/userMsg';
import store from '@/services/store/index';
import { UserAccounts } from '@/models/accounts/UserAccounts';
import CorporateAccountComponent from './CorporateAccount.vue';

export default Vue.extend({
  components: {
    CorporateAccountComponent,
    // CheckboxList,
  },
  computed: {
    accounts(): UserAccounts | null { return store.state.accounts; },
    ...mapGetters(['selectedAccount']),
    formaDePago(): { checked: boolean, label: string } {
      if (this.accounts?.particularAccount) {
        const fp = this.accounts.particularAccount.formaDePagoPorDefecto;
        return {
          checked: Boolean(fp),
          label: fp?.alias || 'Sin forma de pago por defecto',
        };
      }
      return {
        checked: false,
        label: 'Sin forma de pago por defecto',
      };
    },
  },
  methods: {
    getLabel(key: string): string {
      return msg.getLabel(key);
    },
  },
});
