<template>
  <div>
    <div class="text-gray-500 text-sm font-normal mb-1">
      {{ label }}
    </div>
    <div class="text-gray-900 font-normal text-base tracking-wide" >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: '' },
  },
};
</script>
