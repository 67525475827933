



































import { propOfType } from '@/services/utils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: propOfType<string>('Modal'),
    body: propOfType<string>(''),
    onCancel: propOfType<Function>(() => {}),
    onConfirm: propOfType<Function>(() => {}),
    showSpinnerInConfirmButton: propOfType<boolean>(false),
    showConfirmButton: propOfType<boolean>(true),
    showCancelButton: propOfType<boolean>(true),
    confirmLabel: propOfType<string>('Enviar'),
  },
});
