



















































































import Vue from 'vue';
import IconSupport from '@/components/icons/IconSupport.vue';
import IconCallSupport from '@/components/icons/IconCallSupport.vue';
import IconWhatsapp from '@/components/icons/IconWhatsapp.vue';
import IconX20 from '@/components/icons/IconX20.vue';
import { post } from '@/services/http';
import { eventBus } from '@/main';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import { AxiosError } from 'axios';
import msg from '@/services/userMsg';

export default Vue.extend({
  components: {
    IconSupport,
    IconCallSupport,
    IconWhatsapp,
    IconX20,
  },
  computed: {
    buttonClass() {
      if (this.isLoading) {
        return `font-semibold p-2 rounded-lg text-center text-white
          shadow-inner bg-gray-600`;
      }
      return `font-semibold p-2 rounded-lg text-center
        text-white bg-gray-900 hover:bg-gray-700`;
    },
  },
  data() {
    return {
      isOpen: false,
      callSelected: false,
      afterCall: false,
      isLoading: false,
      pedidoSelected: false,
      idPersona: this.$store.getters.selectedAccount.account.idPersona,
      user: this.$store.state.user,
      account: this.$store.getters.selectedAccount.account.nombreCliente,
    };
  },
  created() {
    eventBus.$on('pedidoSelected', (isPedidoSelected) => {
      this.pedidoSelected = isPedidoSelected;
    });
  },
  methods: {
    onConfirm() {
      this.isLoading = true;
      post('$sgv/rest/alerta/sos/$idPersona', { idPersona: this.idPersona })
        .then(() => {
          this.afterCall = true;
        })
        .catch((error: AxiosError) => {
          this.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onWppSupport() {
      const phone = +5491127350000;
      const message = `Hola, soy ${this.capitalize(this.user.apellido)}, ${this.capitalize(this.user.nombre)} de la`
        + ` cuenta ${this.capitalize(this.account)} y necesito soporte`;
      window.open(`https://web.whatsapp.com/send?phone=${phone}&text=${message}`);
      this.isOpen = false;
    },
    capitalize(name: string): string {
      return name.split(' ')
        .map((word: string) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
        .join(' ');
    },
  },
});
