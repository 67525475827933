import { UserAccounts } from '@/models/accounts/UserAccounts';
import * as userAccounts from '@/models/accounts/UserAccounts';
import { AccountId } from '@/models/accounts/AccountId';
import { User } from '@/models/User';
import { MutationTree } from 'vuex';
import { State } from './state';
// eslint-disable-next-line import/no-cycle
import userStorage from '../userStorage';

const mutations: MutationTree<State> = {
  SET_USER(state: State, user: User) {
    state.user = user;
  },
  SET_ACCOUNTS(state: State, accounts: UserAccounts) {
    state.accounts = accounts;
  },
  SET_SELECTED_ACCOUNT_ID(state: State, selectedAccountId: AccountId) {
    if (state.accounts) {
      const selectedAccount = userAccounts.findById(selectedAccountId, state.accounts);
      if (selectedAccount?.tag === 'corporateAccount') {
        localStorage.setItem('cliente', selectedAccount.account.nombreCliente);
      }
    }

    state.selectedAccountId = selectedAccountId;
    userStorage.write(state.user?.id || 0, { selectedAccountId: state.selectedAccountId });
  },
  SET_USER_PROFILE_PICTURE_URL(state: State, profilePictureUrl: string | null) {
    const u = state.user;
    if (u) state.user = { ...u, profilePictureUrl };
  },
  SET_USER_EMAIL(state: State, email: string) {
    const u = state.user;
    if (u) state.user = { ...u, email };
  },
  SET_IS_MOBILE(state: State, isMobile: boolean) {
    state.isMobile = isMobile;
  },
  SET_NAVBAR_HEIGHT(state: State, navbarHeight: number) {
    state.navbarHeight = navbarHeight;
  },
};

export default mutations;
