export const HOME = 'home';
export const CREAR = 'create';
export const PEDIDOS = 'pedidos';
export const INFORMES = 'informes';
export const DIRECCIONES = 'direcciones';
export const PERFIL = 'profile';
export const USUARIOS = 'usuarios';
export const GRUPOS = 'grupos';
export const SOPORTE = 'helpcenter';
export const LOGIN = 'login';
export const RECOVER_PASSWORD = 'recoverPassword';
export const CREATE_ACCOUNT = 'createAccount';
export const CONFIRM_ACCOUNT = 'confirmAccount';
export const TRACKING = 'seguimiento';
export const SOPORTEMOBILE = 'soporteMobile';
