



























import { formatNumber } from '@/services/utils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    loading: Boolean,
    price: String,
    text: String,
  },
  computed: {
    formatedPrice(): string {
      if (!this.price) return '';
      const number = this.extractNumber(this.price);
      const formatedPrice = formatNumber(number);
      return this.price.replace(number!!.toString(), formatedPrice);
    },
  },
  methods: {
    extractNumber(value: string): number | null {
      const match = value.match(/[\d,]+(\.\d{1,2})?/);
      return match ? parseFloat(match[0]) : null;
    },
  },
});
