





































import Vue from 'vue';
import audiBlackSrc from '@/assets/audi-black.jpg';
import MaxWidthLayout from './MaxWidthLayout.vue';
import Header from './Header.vue';
import Footer from './Footer.vue';

export default Vue.extend({
  components: { MaxWidthLayout, Header, Footer },
  data() { return { audiBlackSrc }; },
});
