























































































import Vue from 'vue';
import msg from '@/services/userMsg';
import { corporativeAddress, favouriteAddress } from '@/services/api/addressApi';
import IconTrash24 from '@/components/icons/IconTrash24.vue';
import IconSearch20 from '@/components/icons/IconSearch20.vue';
import IconHeartPlus from '@/components/icons/IconHeartPlus.vue';
import IconHeartOutline from '@/components/icons/IconHeartOutline.vue';
import IconOffice from '@/components/icons/IconOffice.vue';
import IconOfficePlus from '@/components/icons/IconOfficePlus.vue';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import { AxiosError } from 'axios';
import { eventBus } from '@/main';
import { Coords } from '@/services/Coords';
import { Address } from '@/models/Address';
import { propOfType } from '@/services/utils';
import AddressesDetail from './AddressesDetail.vue';
import ChipFilter from './ChipFilter.vue';
import DeleteModal from './DeleteModal.vue';

type DeleteAddressRequest =
  | { status: 'notAsked' }
  | { status: 'loading', id: number }

export default Vue.extend({
  computed: {
    idCliente(): number {
      return this.$store.getters.idCliente;
    },
    filteredAddresses(): Address[] {
      return [
        ...this.filterAddress(this.addresses),
      ];
    },
    emptyListText(): string {
      if (!this.isFetching) {
        if (this.onlyCorporative && !this.addresses.length) {
          return 'Empezá a crear las direcciones de tu empresa!';
        }
        if (!this.addresses.length) {
          return 'Empezá a crear direcciones favoritas!';
        }
        if (!this.filteredAddresses.length) {
          if (!this.selected.length) {
            return 'Debe seleccionar algún filtro';
          }
          if (!this.query.length && this.selected.length === 1) {
            if (this.selected[0] === 'fav') {
              return 'Aún no tienes direcciones favoritas registradas';
            }
            return 'Tu empresa aún no tiene direcciones registradas';
          }
          return 'Sin resultados';
        }
      }
      return '';
    },
  },
  beforeMount() {
    if (!this.isFetching) {
      this.updateAddresses();
    }
  },
  props: {
    onClose: { type: Function },
    updateAddresses: { type: Function },
    onlyCorporative: { type: Boolean, default: false },
    isFetching: { type: Boolean, default: false },
    addresses: propOfType<Address[]>(() => []),
  },
  components: {
    AddressesDetail,
    IconTrash24,
    IconSearch20,
    IconHeartPlus,
    IconHeartOutline,
    IconOffice,
    IconOfficePlus,
    ChipFilter,
    DeleteModal,
  },
  data() {
    return {
      // Modal de detalles
      showDetails: false,
      showDelete: false,
      index: 0,
      query: '',
      filters: ['fav', 'cliente'],
      selected: ['fav', 'cliente'],
      deleteAddressRequest: { status: 'notAsked' } as DeleteAddressRequest,
    };
  },
  methods: {
    onDetail(index) {
      this.index = index;
      this.showDetails = true;
    },
    openDeleteModal(index: number) {
      this.index = index;
      this.showDelete = true;
    },
    getSelectedAddress() {
      return this.filteredAddresses[this.index];
    },
    filterAddress(addresses: Address[]): Address[] {
      return addresses.filter(({ street, alias, source }) => (
        (street.toLocaleLowerCase().includes(this.query.toLocaleLowerCase())
        || alias?.toLocaleLowerCase().includes(this.query.toLocaleLowerCase()))
        && (source && this.selected.includes(source))
      ));
    },
    onDelete(addressId: number) {
      if (this.deleteAddressRequest.status !== 'loading') {
        this.deleteAddressRequest = { status: 'loading', id: addressId };
        const self = this;

        const endpointToUse = this.onlyCorporative ? corporativeAddress : favouriteAddress;

        endpointToUse.deleteAddress(addressId, this.idCliente)
          .then(() => {
            self.$toast.success(msg
              .getSuccess(`${this.onlyCorporative ? 'corporative' : 'favourite'}_address_deleted`));
            self.deleteAddressRequest = { status: 'notAsked' };
            eventBus.$emit('selectedAddress', null);
            this.updateAddresses();
          },
          (error: AxiosError) => {
            self.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
            self.deleteAddressRequest = { status: 'notAsked' };
          })
          .finally(() => {
            this.showDelete = false;
          });
      }
    },
    getTooltip(code) {
      return msg.getTooltip(code);
    },
    onCreateAddressClick() {
      this.onClose();
    },
    centerAddress({ lat, lon }: Address) {
      eventBus.$emit('selectedAddress', { lat, lon } as Coords);
    },
  },
});
