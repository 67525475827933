














import Vue from 'vue';
import IconPlus24 from '@/components/icons/IconPlus24.vue';
import { propOfType } from '@/services/utils';

export default Vue.extend({
  components: { IconPlus24 },
  props: {
    tooltip: propOfType<string | null>(null),
    onClick: propOfType<() => void>(() => {}),
    offset: propOfType<string>('right-7 bottom-7'),
    buttonClass: propOfType<string>('bg-accent-500 text-white'),
  },
});
