










import Vue from 'vue';
import msg from '@/services/userMsg';
import indexAddresses from '../mi-cuenta/direcciones-favoritas/indexAddresses.vue';

export default Vue.extend({
  components: {
    indexAddresses,
  },
  mounted() {
    document.title = `${this.sectionData.title} - InPunto`;
  },
  data() {
    return {
      sectionName: 'direcciones',
    };
  },
  computed: {
    sectionData(): { title: string, desc: string} {
      const title = msg.getTitle(this.sectionName);
      const desc = msg.getDescription(this.sectionName);
      return {
        title,
        desc,
      };
    },
  },
});
