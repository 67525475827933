




































import Vue from 'vue';
import { ServicioDisponible } from '@/models/taaxii.com/taaxiiServicioDisponible';
import { formatNumber, propOfType } from '@/services/utils';
import IconExclamation from '@/components/icons/IconExclamation.vue';
import BackAndTitle from '../../BackAndTitle.vue';
import ServiceIcon from './ServiceIcon.vue';

export default Vue.extend({
  components: {
    BackAndTitle,
    ServiceIcon,
    IconExclamation,
  },
  props: {
    service: propOfType<ServicioDisponible>(),
  },
  data() {
    return {
    };
  },
  computed: {
    iconID(): number {
      return this.service.idTipoVehiculo;
    },
    denominacion(): string {
      return this.service.tipoVehiculo;
    },
    descripcion(): string {
      return this.service.descripcionTipoVehiculo || '';
    },
    precio(): string {
      return formatNumber(this.service.precotizacion);
    },
    tiempoDeEspera(): string {
      return this.service.esperaMin ? `en ${this.service.esperaMin} min` : '';
    },
    textoPrecotizado(): string {
      return this.service.textoPrecotizado || '';
    },
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
  },
  methods: {
    onExit() {
      this.$emit('exit');
    },
  },
});
