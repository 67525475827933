





























import Vue from 'vue';
import { propOfType, capitalizeText } from '@/services/utils';
import { CalificacionInfo } from '@/models/requests-and-responses/sgv';
import IconStar from '@/components/icons/IconStar.vue';
import IconStarFull from '@/components/icons/IconStarFull.vue';
import IconExclamation from '@/components/icons/IconExclamation.vue';
import IconChevronD from '@/components/icons/IconChevronD.vue';

export default Vue.extend({
  props: {
    ratings: propOfType<CalificacionInfo[]>(),
  },
  components: {
    IconStar,
    IconStarFull,
    IconExclamation,
    IconChevronD,
  },
  data() {
    return {
      show: true,
      showReviews: false,
    };
  },
  computed: {
    calificacion(): number {
      if (this.ratings.length === 1) return this.ratings[0].calificacion;
      return Math.floor(this.ratings.reduce((total, rating) => (
        total + rating.calificacion), 0) / this.ratings.length);
    },
    hasReviews(): boolean {
      return this.ratings.some((rating) => rating.obs !== '');
    },
  },
  methods: {
    capitalizeText(text: string): string {
      return capitalizeText(text);
    },
  },
});
