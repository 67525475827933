import { GeneralAccount } from '@/models/accounts/GeneralAccount';
import * as generalAccount from '@/models/accounts/GeneralAccount';
import { UserAccounts } from '@/models/accounts/UserAccounts';
import * as userAccounts from '@/models/accounts/UserAccounts';
import * as accountId from '@/models/accounts/AccountId';
import * as corporateAccount from '@/models/accounts/CorporateAccount';
import { User } from '@/models/User';
// eslint-disable-next-line import/no-cycle
import userStorage from '../userStorage';

const actions = {
  gotLoginData(
    { commit, dispatch },
    payload: { user: User, accounts: UserAccounts },
  ) {
    localStorage.setItem('username', payload.user.username);

    commit('SET_USER', payload.user);
    dispatch('updateAccounts', payload.accounts);
  },
  updateAccounts({ state, commit, dispatch }, accounts: UserAccounts) {
    commit('SET_ACCOUNTS', accounts);

    const savedSelectedAccountId = userStorage.read(state.user.id).selectedAccountId;

    if (
      savedSelectedAccountId !== undefined
        && userAccounts.findById(savedSelectedAccountId, accounts) !== null
    ) {
      commit('SET_SELECTED_ACCOUNT_ID', savedSelectedAccountId);
    } else if (accounts.corporateAccounts.length > 0) {
      dispatch(
        'selectAccount',
        generalAccount.fromCorporateAccount(accounts.corporateAccounts[0]),
      );
    } else if (accounts.particularAccount) {
      dispatch(
        'selectAccount',
        generalAccount.fromParticularAccount(accounts.particularAccount),
      );
    }
  },
  selectAccount({ commit }, account: GeneralAccount) {
    commit('SET_SELECTED_ACCOUNT_ID', accountId.fromGeneralAccount(account));
  },
  updateProfilePicture({ commit }, newProfilePictureUrl: string) {
    commit('SET_USER_PROFILE_PICTURE_URL', newProfilePictureUrl);
  },
  logout({ commit }) {
    commit('SET_USER', null);
    commit('SET_ACCOUNTS', null);
    commit('SET_SELECTED_ACCOUNT_ID', null);
    localStorage.removeItem('user');
    localStorage.removeItem('username');
    localStorage.removeItem('cliente');
  },
  changedDefaultCeco(
    { state, commit },
    payload: { idCuentaCorporativa: number, idCentroDeCosto: number },
  ) {
    commit(
      'SET_ACCOUNTS',
      userAccounts.updateCorporateAccount(
        state.accounts,
        payload.idCuentaCorporativa,
        (corpAccount) => corporateAccount
          .setIdCentroDeCostoPreferido(corpAccount, payload.idCentroDeCosto),
      ),
    );
  },
  changedPhone(
    { state, commit },
    payload: { idCuentaCorporativa: number, celular: string },
  ) {
    commit(
      'SET_ACCOUNTS',
      userAccounts.updateCorporateAccount(
        state.accounts,
        payload.idCuentaCorporativa,
        (corpAccount) => corporateAccount
          .setNewPhone(corpAccount, payload.celular),
      ),
    );
  },
  changedEmail({ state, commit }, email: string) {
    commit('SET_USER_EMAIL', email);
  },
  updateMobileState({ commit }, isMobile: boolean) {
    commit('SET_IS_MOBILE', isMobile);
  },
  updateNavbarHeight({ commit }, navbarHeight: number) {
    commit('SET_NAVBAR_HEIGHT', navbarHeight);
  },
};

export default actions;
