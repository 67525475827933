<template>
  <div>
    <span class="text-base text-white font-regular mr-1.5">Ya tengo una cuenta</span>
    <BaseButton
      :class="'btn-accent-secondary font-medium px-0.5 bg-dark-500'"
      :buttonClass="'h-6 uppercase'"
      :label="'Iniciar sesión'"
      @click.native="onNavigate('login')"
    />
  </div>
</template>

<script>
export default {
  methods: {
    onNavigate(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>
