
import { propOfType } from '@/services/utils';
import { CentroDeCosto, FormaDePago } from '@/models/accounts/shared';
import { PersonWithCC } from '@/models/Person';
import Ceco from './indexCeco.vue';

export default Ceco.extend({
  props: {
    person: propOfType<PersonWithCC | null>(null),
    hasSender: propOfType<Boolean>(false),
  },
  computed: {
    formasDePagoHabilitadas(): FormaDePago | null {
      const { person } = this;
      if (this.unknownSender) {
        return this.fpDefecto;
      }
      if (!person) return null;
      return person.formaDePagoPorDefecto;
    },
    centrosDeCosto(): CentroDeCosto[] {
      const { person } = this;
      if (this.unknownSender) {
        const { selectedAccount } = this;
        if (selectedAccount?.tag === 'corporateAccount') {
          return selectedAccount.account.centrosDeCosto;
        }
        return [];
      }
      if (!person) return [];
      return person.centrosDeCostoHabilitados.map((cc) => ({
        id: cc.id,
        denominacion: cc.denominacion,
        habilitadoViajesExternos: true,
        publico: true,
      }));
    },
    unknownSender(): boolean {
      return (this.hasSender && !this.person);
    },
    idCecoPreferido(): number | null {
      if (this.person) return this.person.cecoPreferido;

      const { selectedAccount } = this;
      if (!selectedAccount) return null;
      if (selectedAccount.tag === 'particularAccount') return null;
      return selectedAccount.account.idCentroDeCostoPreferido;
    },
  },
  watch: {
    person(val) {
      this.paymentSelected = null;
      if (this.person || this.hasSender) {
        this.selectSome();
      }
    },
  },
});
