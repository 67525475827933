
import Address from './indexAddress.vue';

export default Address.extend({
  computed: {
    error() {
      if (!this.userTriedToConfirm) return '';
      if (this.selectedAddress) return '';
      return 'Seleccione una parada';
    },
    headerText(): string {
      if (this.metadata.type === 'PARAMI') {
        return '¿A dónde te llevamos?';
      }
      return '¿Dónde paramos?';
    },
  },
});
