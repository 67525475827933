import { AxiosError } from 'axios';
import msg from '@/services/userTexts/toastMsg.json';

const errorCodeKeys = msg.error;

export type ErrorCode = keyof typeof errorCodeKeys;

function errorCodeFromString(string: string): ErrorCode {
  if (Object.keys(errorCodeKeys).includes(string)) {
    return string as ErrorCode;
  }
  return 'unknown';
}

export function errorCodeFromAxiosError(error: AxiosError<any> | string): ErrorCode {
  if (typeof error === 'string') {
    return errorCodeFromString(error);
  }

  if (error.response !== undefined) {
    if (typeof error.response.data === 'object'
          && error.response.data !== null // necesario porque typeof null === 'object'
          && typeof error.response.data.code === 'string'
    ) {
      return errorCodeFromString(error.response.data.code);
    }
    return 'unknown';
  }
  return 'network_error';
}

export function isAuthorizationError(error: ErrorCode): boolean {
  return error === 'token_invalid' || error === 'user_invalid' || error === 'bad_credentials';
}
