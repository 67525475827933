import SolicitudExtras from '@/models/taaxii.com/SolicitudExtras';
import ViajeExtras from '@/models/taaxii.com/ViajeExtras';
import Pedido, { fromPedidoStringToUIName } from '@/models/Pedido';
import {
  capitalizeText, downloadFile, formatDate, getCurrentTimestamp, getFullName,
} from '@/services/utils';
import msg from '@/services/userMsg';
import * as text from '@/services/userTexts/labels.json';
import { Empleado } from '@/models/employees/Empleado';
import { CorporateAccount } from '@/models/accounts/CorporateAccount';
import Excel from 'exceljs';

/**
 * Esta función es necesaria por un requerimiento del ticket TX-1418,
 * en donde se pide que los formatos de fecha y hora dentro del CSV tengan
 * un formato que se parece al de ISO.
 */
function dateToISOLikeButLocal(date: Date): string {
  const offsetMs = date.getTimezoneOffset() * 60 * 1000;
  const msLocal = date.getTime() - offsetMs;
  const dateLocal = new Date(msLocal);
  const ds = dateLocal.toLocaleDateString('es-AR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  const ts = dateLocal.toLocaleTimeString('es-AR', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return `${ds.replaceAll('/', '-')}T${ts}`;
}

function formatDateString(date: string) {
  const str = date.split('-');
  return `${str[2]}-${str[1]}-${str[0]}`;
}

// Función para convertir los números a una string usando el formato de argentina
function numToLocaleString(number: number | undefined): string {
  return number !== undefined ? number.toLocaleString('es-AR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }) : 's/i';
}

function formatCanal(canal: string): string {
  switch (canal.toLocaleLowerCase()) {
    case 'inweb':
      return 'InWeb';
    case 'inmob':
      return 'InMob';
    default:
      return canal;
  }
}

function formatRegistro(canal: string): string {
  if (canal && canal.toLocaleLowerCase().includes('externo')) {
    return 'Externo';
  }
  return 'InPunto';
}

const reportKeys = text.reports;

export type ReportType = keyof typeof reportKeys;

function fromPedidoToCsvRow(pedido: Pedido, reportType: ReportType,
  account?: CorporateAccount): (string | number)[][] {
  const pasajesArray = [] as (string | number)[][];
  const extras = pedido.extras as ViajeExtras | SolicitudExtras;
  const pasajes = reportType === 'pedidosView' ? [pedido.extras.pasajes[0]] : pedido.extras.pasajes;
  const cecosAuditados = account?.cecosAuditados.map((ceco) => ceco.id);

  pasajes.forEach((pax) => {
    if (checkCeco(reportType, account) && !cecosAuditados?.includes(pax.ceco.id)) {
      return;
    }

    // Id Solicitud y Viaje
    const idSolicitud = pax.idSolicitud?.toString() || 's/i';
    const idViaje = extras.type === 'v' ? extras.idViaje?.toString() : 's/i';

    // Costos
    let importeKms = null as null | number;
    let kms = extras.distancia;
    let importeEspera = null as null | number;
    let espera = null as null | number;
    let otrosGastos = null as null | number;
    let total = null as null | number;
    let importeIVA = null as null | number;
    let importePlus = null as null | number;
    let importePasajero = null as null | number;
    const coefProrrateo = pax.coefProrrateo || null;
    if (extras.type === 'v') {
      if (extras.detalle) {
        extras.detalle.forEach((d) => {
          if (d.concepto === 'espera') {
            espera = d.cantidad || null;
            importeEspera = d.importe;
          } else if (d.concepto === 'kilometros') {
            kms = d.cantidad || 0;
            importeKms = d.importe;
          } else if (d.concepto === 'otros') {
            otrosGastos = d.importe;
          }
        });
      }
      if (extras.importeTotal) {
        total = extras.importeTotal;
        if (pax.coefProrrateo) {
          importePasajero = extras.importeTotal * (pax.coefProrrateo / 100);
        }
      }
      if (typeof extras.importeIva === 'number') {
        importeIVA = extras.importeIva;
      }
      if (typeof extras.importeIva === 'number') {
        importePlus = extras.importePlus;
      }
    }

    // Pasajero, emisor, receptor, solicitante, ceco, legajo, obs
    let pasajero = 's/i';
    let emisor = 's/i';
    let receptor = 's/i';
    let solicitante = 's/i';
    const legajoSolicitante = pax.legajoSolicitante || 's/i';
    const ceco = pax.ceco ? pax.ceco.alias || numToLocaleString(pax.ceco.id) : 's/i';
    let legajo = 's/i';
    const obs = pax.obs || 's/i';
    if (pax.pasajero) {
      pasajero = pax.pasajero.name
        || `${pax.pasajero.lastName}, ${pax.pasajero.firstName}`;
      legajo = numToLocaleString(pax.pasajero.legajo) || 's/i';
    }
    if (pax.despachante) {
      emisor = pax.despachante.name
        || `${pax.despachante.lastName}, ${pax.despachante.firstName}`;
    }
    if (pax.receptor) {
      receptor = pax.receptor.name
        || `${pax.receptor.lastName}, ${pax.receptor.firstName}`;
    }
    if (extras.solicitante) {
      solicitante = extras.solicitante.name
        || `${extras.solicitante.lastName}, ${extras.solicitante.firstName}`;
    } else if (pax.nombreSolicitante) {
      solicitante = capitalizeText(pax.nombreSolicitante);
    }

    // Agencia, vehiculo y chofer
    let agencia = 's/i';
    let vehiculo = 's/i';
    let chofer = 's/i';
    if (extras.type === 'v') {
      if (extras.agencia) {
        agencia = extras.agencia.denominacion;
      }
      if (extras.vehiculo) {
        vehiculo = `${extras.vehiculo.marca} ${extras.vehiculo.modelo} (${extras.vehiculo.patente})`;
      }
      if (extras.chofer) {
        chofer = `${extras.chofer.lastName}, ${extras.chofer.firstName}`;
      }
    }
    // Fecha salida.
    let fechaSalida = dateToISOLikeButLocal(extras.inicio);
    if (!Number.isNaN(extras.inicio.getTime())) {
      fechaSalida = dateToISOLikeButLocal(extras.inicio);
    }

    // Origen y Destino
    let origen = 's/i';
    let destino = 's/i';
    if (reportType === 'pedidosView') {
      origen = extras.paradas[0].dir.direccion;
      destino = extras.paradas[extras.paradas.length - 1].dir.direccion;
    } else {
      const idOrigen = pax.paradas.length > 2
        ? pax.paradas[(pax.paradas.length / 2) - 1] : pax.paradas[0];
      const idDestino = pax.paradas.length > 2
        ? pax.paradas[pax.paradas.length - 1] : pax.paradas[1];

      origen = extras.paradas
        .find((parada) => parada.idParada === idOrigen)?.dir.direccion || 's/i';
      destino = extras.paradas
        .find((parada) => parada.idParada === idDestino)?.dir.direccion || 's/i';
    }

    // Calificacion
    let calificacion = null as null | number;
    if (extras.type === 'v') {
      if ((reportType === 'pedidosView' || reportType === 'envios') && extras.calificaciones?.length) {
        calificacion = extras.calificaciones
          .reduce((result, rating) => result + rating.calificacion, 0)
          / extras.calificaciones.length;
      } else if (reportType === 'paxView') {
        const calificacionInt = extras.calificaciones?.find((rating) => (
          getFullName(pax.pasajero).toLocaleLowerCase() === rating.nombre.toLocaleLowerCase()
        ))?.calificacion || pax.calificacion;

        calificacion = calificacionInt || null;
      }
    }

    // Otros
    let nroVoucher = 's/i';
    const tipoRegistro = formatRegistro(pax.canal) || 's/i';
    const tipoPasajero = pax.pasajero?.username ? 'Registrado' : 'Externo';
    let fechaFacturación = 's/i';
    let nroLiquidacion = 's/i';
    if (extras.type === 'v') {
      nroVoucher = extras.voucherNro || 's/i';
      fechaFacturación = extras.fechaFacturacion
        ? formatDateString(extras.fechaFacturacion) : 's/i';

      if (reportType === 'pedidosView') {
        const liquidaciones = extras.pasajes.reduce((result, p) => {
          if (p.nroLiquidacion && !result.includes(p.nroLiquidacion)) {
            result.push(p.nroLiquidacion);
          }
          return result;
        }, [] as number[]);

        if (liquidaciones.length) {
          nroLiquidacion = liquidaciones.length > 1
            ? liquidaciones.join(' | ') : `${liquidaciones[0]}`;
        }
      } else {
        nroLiquidacion = pax.nroLiquidacion?.toString() || 's/i';
      }
    }
    let tipoRecorrido = 's/i';
    if (kms !== undefined) {
      // eslint-disable-next-line no-nested-ternary
      tipoRecorrido = kms < 60 ? 'CD' : (kms >= 60 && kms <= 200 ? 'MD' : 'LD');
    }
    const cantidadPax = new Set(extras.pasajes
      .map((p) => p.pasajero?.name || p.despachante?.name)).size;
    const compartido = cantidadPax > 1 ? 'SI' : 'NO';

    switch (reportType) {
      case 'pedidos-front':
      case 'pedidosView':
        pasajesArray.push([
          idSolicitud,
          idViaje,
          pedido.status,
          extras.type === 's' ? pax.servicio : extras.vehiculo?.tipoVehiculo.alias || 's/i',
          fromPedidoStringToUIName(pedido.type),
          fechaSalida.split('T')[0],
          fechaSalida.split('T')[1],
          agencia,
          chofer,
          vehiculo,
          origen,
          destino,
          obs,
          solicitante,
          emisor,
          receptor,
          nroVoucher,
          total,
          importeKms,
          kms,
          importeEspera,
          espera,
          otrosGastos,
          importePlus,
          importeIVA,
          fechaFacturación,
          nroLiquidacion,
          calificacion,
          formatCanal(pax.canal),
          tipoRegistro,
          tipoRecorrido,
          cantidadPax,
          compartido,
        ]);
        break;
      case 'pedidosPax':
      case 'paxView':
        pasajesArray.push([
          idSolicitud,
          idViaje,
          pedido.status,
          extras.type === 's' ? pax.servicio : extras.vehiculo?.tipoVehiculo.alias || 's/i',
          fromPedidoStringToUIName(pedido.type),
          fechaSalida.split('T')[0],
          fechaSalida.split('T')[1],
          agencia,
          chofer,
          vehiculo,
          origen,
          destino,
          nroVoucher,
          solicitante,
          legajoSolicitante,
          tipoPasajero,
          pasajero,
          ceco,
          legajo,
          emisor,
          receptor,
          obs,
          importePasajero,
          coefProrrateo,
          total,
          importeKms,
          kms,
          importeEspera,
          espera,
          otrosGastos,
          importePlus,
          importeIVA,
          fechaFacturación,
          `${nroLiquidacion}`,
          calificacion,
          formatCanal(pax.canal),
          tipoRegistro,
        ]);
        break;
      default:
        break;
    }
  });
  return pasajesArray;
}

function titleRow(reportType: ReportType): string[] {
  switch (reportType) {
    case 'pedidos-front':
    case 'pedidosView':
      return [
        'NRO PEDIDO',
        'NRO VIAJE',
        'ESTADO',
        'TIPO SERVICIO',
        'TIPO PEDIDO',
        'FECHA',
        'HORA',
        'AGENCIA',
        'CHOFER',
        'VEHICULO',
        'ORIGEN',
        'DESTINO',
        'OBSERVACIÓN VIAJE/ENVÍO',
        'SOLICITANTE',
        'EMISOR ENVÍO',
        'RECEPTOR ENVÍO',
        'NRO VOUCHER',
        'IMPORTE DEL VIAJE [$]',
        'IMPORTE KMS [$]',
        'KMS [Cantidad]',
        'IMPORTE ESPERA [$]',
        'ESPERA [Minutos]',
        'OTROS GASTOS [$]',
        'IMPORTE PLUS [$]',
        'IMPORTE IVA [$]',
        'FECHA FACTURACIÓN',
        'NRO LIQUIDACIÓN',
        'CALIFICACIÓN',
        'MEDIO DE INGRESO',
        'TIPO REGISTRO',
        'TIPO RECORRIDO',
        'CANTIDAD PAX',
        'COMPARTIDO',
      ];
    case 'pedidosPax':
    case 'paxView':
    case 'envios':
      return [
        'NRO PEDIDO',
        'NRO VIAJE',
        'ESTADO',
        'TIPO SERVICIO',
        'TIPO PEDIDO',
        'FECHA',
        'HORA',
        'AGENCIA',
        'CHOFER',
        'VEHICULO',
        'ORIGEN',
        'DESTINO',
        'NRO VOUCHER',
        'SOLICITANTE',
        'LEGAJO SOLICITANTE',
        'TIPO PASAJERO',
        'PASAJERO',
        'CECO PASAJERO',
        'LEGAJO PASAJERO',
        'EMISOR ENVÍO',
        'RECEPTOR ENVÍO',
        'OBSERVACIÓN VIAJE/ENVÍO',
        'IMPORTE PASAJERO [$]',
        'COEF. PRORRATEO [%]',
        'IMPORTE TOTAL [$]',
        'IMPORTE KMS [$]',
        'KMS [Cantidad]',
        'IMPORTE ESPERA [$]',
        'ESPERA [Minutos]',
        'OTROS GASTOS [$]',
        'IMPORTE PLUS [$]',
        'IMPORTE IVA [$]',
        'FECHA FACTURACIÓN',
        'NRO LIQUIDACIÓN',
        'CALIFICACIÓN',
        'MEDIO DE INGRESO',
        'TIPO REGISTRO',
      ];
    default:
      return [];
  }
}

function cecosAuditadosRow(reportType: ReportType, account?: CorporateAccount): string[] {
  if (checkCeco(reportType, account) && account?.cecosAuditados.length) {
    const cecos = account?.cecosAuditados.map((ceco) => ceco.alias).join(' | ');
    return ['CECOs:', cecos];
  }
  return [];
}

function checkCeco(reportType: ReportType, account?: CorporateAccount): boolean {
  const permisos = account?.permisos;

  return ((reportType === 'pedidosView' || reportType === 'paxView') && !permisos?.showClientPedidos
    && !permisos?.manageGroups && permisos?.showReports) as boolean;
}

function exportPedidosToSheets(
  array: Pedido[] | Empleado[],
  account: CorporateAccount,
  reportType: ReportType,
  since?: string,
  until?: string,
) {
  const row0 = [msg.getReports(reportType)];
  const row1 = ['Cuenta:', account.nombreCliente];
  const row2 = since && until
    ? [['Fecha desde:', formatDateString(since)], ['Fecha hasta:', formatDateString(until)]]
    : [['F y H de Generación:', formatDate(new Date())], []];
  const row3 = cecosAuditadosRow(reportType, account);
  const row4 = [];
  const row5 = titleRow(reportType);

  let rows = [] as (string | number)[][];

  const pedidos = array as Pedido[];
  rows = pedidos.reduce((result, pedido) => {
    result.push(...fromPedidoToCsvRow(pedido, reportType, account));
    return result;
  }, [] as (string | number)[][]);

  return [row0, row1, ...row2, row3, row4, row5, ...rows];
}

function exportPedidosToXLSX(
  array: Pedido[],
  account: CorporateAccount,
  reportType: ReportType,
  since?: string,
  until?: string,
) {
  if (reportType !== 'pedidos-front') return;
  const workbook = new Excel.Workbook();
  workbook.creator = 'InPunto';
  workbook.lastModifiedBy = 'InPunto';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  const worksheets = ['pedidosView', 'paxView'] as ReportType[];

  worksheets.forEach((view) => {
    const worksheet = workbook.addWorksheet(msg.getReports(view));

    worksheet.properties.defaultRowHeight = 20;
    worksheet.properties.defaultColWidth = 25;

    worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 7 }];

    const rows = exportPedidosToSheets(array, account, view, since, until);
    const titleRows = titleRow(view);
    const titleRowIndex = rows.findIndex((row) => row[0] === titleRows[0]);

    const colsWithNumbersIndex = titleRows.reduce((result, title, index) => {
      if (/(.*\[.*\].*)/.test(title)) {
        result.push(index + 1);
      }
      return result;
    }, [] as number[]);

    colsWithNumbersIndex.forEach((colIndex) => {
      const wsCol = worksheet.getColumn(colIndex);
      wsCol.alignment = { vertical: 'middle', horizontal: 'right' };
      wsCol.numFmt = '#,##0.00';
      wsCol.width = 25;
    });

    rows.forEach((row, index) => {
      const wsRow = worksheet.getRow(index + 1);
      wsRow.values = row;
      const isTitleRow = index === titleRowIndex;

      const defaultFont = {
        name: 'Arial',
        family: 2,
        size: 10,
      };

      wsRow.font = defaultFont;

      if (index === 0) {
        wsRow.font = {
          ...defaultFont,
          size: 12,
        };
      } else if (index >= titleRowIndex) {
        if (isTitleRow) {
          wsRow.font = {
            ...defaultFont,
            bold: true,
          };
          wsRow.alignment = { vertical: 'middle', horizontal: 'center' };
        }

        wsRow.eachCell({ includeEmpty: true }, (cell) => {
          if (isTitleRow) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'C0C0C0' },
            };
          }

          const borderStyle = { style: 'thin' } as Excel.Border;
          cell.border = {
            top: borderStyle,
            left: borderStyle,
            bottom: borderStyle,
            right: borderStyle,
          };
        });
      }
    });
  });

  const filename = `InPunto - ${msg.getReports(reportType)} - ${getCurrentTimestamp()}.xlsx`;
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    downloadFile(blob, filename, reportType);
  });
}

export default exportPedidosToXLSX;
