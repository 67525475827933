
import { VueConstructor } from 'vue';
import {
  ServicioDisponible,
} from '@/models/taaxii.com/taaxiiServicioDisponible';
import { post } from '@/services/http';
import userMsg from '@/services/userMsg';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import { getIdPersona } from '@/models/accounts/GeneralAccount';
import {
  ParadaSolCom,
  PaxSolCom,
  SolicitudesCompletaReq,
  SolicitudSuccessfullyBundle,
} from '@/models/requests-and-responses/sgv';
import { PaxOfForm } from '@/models/Person';
import { propOfType } from '@/services/utils';
import Shared, {
  necesitaDatosDeArribo,
  AddressOfForm,
  faltanDatosDeArribo,
  Section,
} from '../indexForm.vue';

import ServiceSection from '../sections/service/indexService.vue';
import ExtraSection from '../sections/extra/indexExtra.vue';
import WhenSection from '../sections/when/indexWhen.vue';
import StopsSection from '../sections/address/AddressWithStops.vue';
import CecoSection from '../sections/ceco/indexCeco.vue';
import PassengersSection from '../sections/passengers/indexPassengers.vue';
import TripPreview from '../TripPreview.vue';

export function data() {
  return {
    type: 'CONPARADAS' as const, // Utilizado para conocer el tipo de la data.
    addresses: [...Array(2)] as AddressOfForm[], // Se rellena con Origen, Parada 1 y Destino
    passengers: [] as PaxOfForm[],
    service: null as ServicioDisponible | null,
    date: null as Date | null, // null = Lo antes posible.
    dateRegreso: null as Date | null, // null = Sin viaje de regreso.
    dateOption: 0 as number,
    finalDataResponse: null as SolicitudSuccessfullyBundle | null,
    finalDataResponseRegreso: null as SolicitudSuccessfullyBundle | null,
    days: [] as number[],
    daysRegreso: [] as number[],
    typeOfTrip: 0 as number,
    paxError: false as boolean,
    dateError: false as boolean,
    datosDeArribo: '' as string,
    userTriedToConfirm: false,
    idCeCo: null as number | null,
    observacion: '',
    showTripPreview: false,
  };
}

export default Shared.extend({
  components: {
    StopsSection,
    PassengersSection,
    WhenSection,
    ExtraSection,
    CecoSection,
    ServiceSection,
    TripPreview,
  },
  props: {
    timeEstimation: propOfType<number>(),
  },
  data,
  computed: {
    buttonText() {
      if (!this.showTripPreview) return 'Continuar';
      const d = this.date;
      if (!d) {
        return 'Pedir';
      }
      // TO-DO: Poner reservar para mañana a las blabla
      return 'Reservar';
    },
    buttonTitle() {
      if (this.showTripPreview) {
        return 'Revisá los datos ingresados antes de pedir';
      }
      return '';
    },
    price(): string | null {
      const { service } = this;
      if (!service || !this.showTripPreview) return null;
      return service.precotizacion > 0
        ? `$${service.precotizacion}` : 'Sin cotización';
    },
    sections(): Section[] {
      if (this.showTripPreview) {
        return [{ component: TripPreview, steps: ['tripPreview'] }];
      }
      return [
        { component: StopsSection, steps: ['stops1', 'stops2'] },
        { component: PassengersSection, steps: ['passengers1', 'passengers2'] },
        { component: WhenSection, steps: ['when3'] },
        { component: ExtraSection, steps: ['extra'] },
        { component: CecoSection, steps: ['ceco'] },
        { component: ServiceSection, steps: ['service1', 'service2'] },
      ];
    },
  },
  watch: {
    addresses(val) {
      this.setMapAddresses(val);
    },
    showTripPreview() {
      this.error = '';
    },
  },
  methods: {
    async onConfirm() {
      this.expandedSection = -1;
      this.userTriedToConfirm = true;

      if (this.checkIfLAP() || this.reservating) {
        if (this.showTripPreview) {
          this.error = `La antelación para reservar debe ser mayor a ${this.antelacionLAP} `
            + 'min sobre la fecha y hora actual';
        }
        return;
      }

      if (!this.addresses.length || !this.passengers.length) {
        return;
      }

      if (!this.service) {
        return;
      }

      if (!this.idCeCo || !this.selectedAccount) {
        // Nunca debería llegar a este punto.
        this.error = 'Error fatal.';
        return;
      }

      if (faltanDatosDeArribo(this, false)
          || this.observacion.length > 250) {
        return;
      }

      if (this.dateError || this.paxError) {
        return;
      }

      if (!this.showTripPreview) {
        this.showTripPreview = true;
        return;
      }

      const paradas = this.addresses.map((adds) => ({
        dir: {
          direccion: adds?.street,
          lat: adds?.lat,
          lon: adds?.lon,
        },
        espera: adds?.espera,
        ascensos: this.mapPaxToIndex(adds?.ascensos || []),
        descensos: this.mapPaxToIndex(adds?.descensos || []),
      })) as ParadaSolCom[];

      const paxEnTerminal = this.addresses.slice(0, -1).reduce((result, adds): number[] => {
        if (adds?.isTerminal && adds?.ascensos) {
          return result.concat(adds?.ascensos.map((pax) => pax.id));
        }
        return result;
      }, [] as number[]);

      const pasajes = this.passengers.map(({
        id, nombre, tel, idPersona, idCeco,
      }) => {
        let pax = {
          nombre,
          tel,
          idPersona,
          idCeco,
        } as PaxSolCom;

        if (paxEnTerminal.includes(id)) {
          pax = {
            ...pax,
            idEmpresaAerea: 143,
            vueloTipo: '1',
            vueloNumero: 'ABC123',
          };
        }

        return pax;
      }) as PaxSolCom[];

      const req: SolicitudesCompletaReq = {
        idTipoVehiculo: this.service.idTipoVehiculo,
        idPersonaSol: getIdPersona(this.selectedAccount),
        idCecoSol: this.idCeCo,
        fechaHoraSalida: this.date ? this.date : undefined,
        obs: this.observacion,
        paradas,
        pasajes,
      };

      this.reservating = true;
      this.error = '';

      this.finalDataResponse = await this.makeRequest(req);

      if (this.error) {
        return;
      }
      this.exit(this.$data);
    },
    onExit() {
      if (this.showTripPreview) {
        this.showTripPreview = false;
      } else {
        this.exit(null);
      }
    },
    makeRequest(req : SolicitudesCompletaReq) : Promise<SolicitudSuccessfullyBundle | null> {
      return post('$sgv/rest/solicitudes/completas', req)
        .then((res) => {
          const response = res.data;
          return response;
        })
        .catch((error) => {
          this.error = userMsg.getError(errorCodeFromAxiosError(error));
          return null;
        })
        .finally(() => {
          this.reservating = false;
        });
    },

    propsOf(section: VueConstructor) {
      switch (section) {
        case StopsSection:
          return { addresses: this.addresses };
        case PassengersSection:
          return {
            addresses: this.addresses,
            passengers: this.passengers,
          };
        case WhenSection:
          return {
            timeEstimation: this.timeEstimation,
            addresses: this.addresses,
            initialOption: this.dateOption,
            initialDate: this.date,
          };
        case ExtraSection:
          return {
            needDatosDeArribo: necesitaDatosDeArribo(this.addresses, false),
            showEquipaje: true,
            obs: this.observacion,
          };
        case CecoSection:
          return { idCeco: this.idCeCo };
        case ServiceSection:
          return {
            addresses: this.addresses,
            date: this.date,
            idCliente: this.idCliente,
            isLoAntesPosible: this.date === null,
            service: this.service,
          };
        case TripPreview:
          return {
            distanceKm: this.distanceKm,
            addresses: this.addresses,
            date: this.date,
            service: this.service,
          };
        default:
          return {};
      }
    },
    mapPaxToIndex(passengers: PaxOfForm[]): number[] {
      return passengers.map((pax) => this.passengers.findIndex((pax2) => pax2.id === pax.id));
    },
  },
});
