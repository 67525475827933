
import IconHand from '@/components/icons/IconHandHorizontalPackage.vue';
import store from '@/services/store';
import { mapStateTyped } from '@/services/store/utils';
import { GeneralAccount } from '@/models/accounts/GeneralAccount';
import { apply } from '../../indexForm.vue';
import Receiver from './Receiver.vue';
import { Pax } from './PersonSearchBar.vue';

export default Receiver.extend({
  components: {
    Icon: IconHand,
  },
  mounted() {
    const { selectedAccount } = this;
    this.nameAndLastName = this.user?.nombre && this.user?.apellido
      ? `${this.user?.apellido}, ${this.user?.nombre}` : `${this.user?.nombre || this.user?.apellido}`;
    if (selectedAccount) {
      if (selectedAccount.tag === 'particularAccount') {
        this.phone = selectedAccount.account.phone;
      } else {
        const { celular } = selectedAccount.account;
        if (celular) this.phone = celular;
      }
    }
  },
  methods: {
    onPersonChange(suggestion: Pax) {
      this.nameAndLastName = suggestion.nameAndLastName;
      this.phone = suggestion.phone;
      this.phoneIsValid = suggestion.phoneIsValid;
      apply(this, (data) => {
        if ('dispatcher' in data) {
          this.personIsSetted = Boolean(suggestion);
          data.dispatcher = suggestion?.person || null;
        }
      });
    },
    nameAndLastNameWatcher(val) {
      apply(this, (data) => {
        if ('dispatcherNameAndLastName' in data) {
          data.dispatcherNameAndLastName = val;
        }
      });
    },
    phoneWatcher(val) {
      apply(this, (data) => {
        if ('dispatcherPhone' in data) {
          data.dispatcherPhone = val;
        }
      });
    },
  },
  computed: {
    ...mapStateTyped(['user']),
    selectedAccount(): GeneralAccount | null {
      return store.getters.selectedAccount;
    },
    headerText() {
      return '¿Quién envía?';
    },
  },
});
