import { render, staticRenderFns } from "./NoPhone.vue?vue&type=template&id=d12ccdbc&"
import script from "./NoPhone.vue?vue&type=script&lang=ts&"
export * from "./NoPhone.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports