<template>
  <div
    :class="{ 'opacity-50 cursor-not-allowed': disabled }"
    class="flex items-center gap-2"
    @mouseover="upHere = true"
    @mouseleave="upHere = false"
  >
    <div
      class="rounded-full border-2 mr-1"
      :class="{
        'border-4': checked || upHere || hover,
        'border-accent-300': checked,
        'bg-accent-50': checked,
      }"
      style="width: 1em; height: 1em"
    />
    <slot />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      upHere: false,
    };
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
