import axios from 'axios';
import Pasaje from '@/models/taaxii.com/taaxiiPasaje';
import Tracker from '@/models/taaxii.com/taaxiiTracker';
import Transportista from '@/models/taaxii.com/taaxiiTransportista';
import Costos from '@/models/taaxii.com/taaxiiCostos';
import TimelineEvent from '@/models/taaxii.com/taaxiiTimelineEvent';

function setResourceData(rel, response) {
  if (response.value) {
    if (rel === 'pasaje') {
      return new Pasaje(response.value.data.data);
    }
    if (rel === 'tracker') {
      return new Tracker(response.value.data.data);
    }
    if (rel === 'chofer') {
      return new Transportista(response.value.data.data);
    }
    if (rel === 'costos') {
      return new Costos(response.value.data.data);
    }
    if (rel === 'timeline') {
      return response.value.data.data.map((evento) => new TimelineEvent(evento));
    }
  }
  return undefined;
}

/*
 * Devuelve un objeto con los recursos necesitados y sus datos
 * {
 *   rel1: body1,
 *   rel2: body2,
 * }
*/
async function getResources(resources, needed) {
  const fetch = needed
    .map((need) => resources.find((e) => e.rel === need))
    .filter((e) => e); // Quitar undefined

  const calls = fetch.map((res) => axios.get(res.href));
  const response = await Promise.allSettled(calls);
  return fetch.reduce((acc, value, index) => ({
    ...acc,
    [value.rel]: setResourceData(value.rel, response[index]),
  }), {});
}

export default {
  getCostos: async (res) => getResources(res, ['costos']),
  getViajeDetail: async (res) => getResources(res, ['pasaje', 'chofer', 'costos']),
  getTimeline: async (res) => getResources(res, ['timeline']),
  getTracker: async (res) => getResources(res, ['tracker']),
};
