









































































import Vue from 'vue';
import IconInfo from '@/components/icons/IconInfo.vue';
import { propOfType, toTwoDecimals, formatStringNumber } from '@/services/utils';
import { CostosInfo } from '@/models/requests-and-responses/sgv';
import IconChevronD from '@/components/icons/IconChevronD.vue';
import type { Importe } from '@/models/taaxii.com/taaxiiImporte';

export default Vue.extend({
  props: {
    costs: propOfType<CostosInfo | null>(null),
    isAnulado: propOfType<boolean>(false),
    isCanceladoFDT: propOfType<boolean>(false),
    isCanceladoFallo: propOfType<boolean>(false),
  },
  components: {
    IconInfo,
    IconChevronD,
  },
  data() {
    return {
      showCosts: false,
    };
  },
  computed: {
    costoPasajero(): string {
      if (this.costs.coefProrrateo) {
        return toTwoDecimals(this.costs.importeTotal * (this.costs.coefProrrateo / 100));
      }
      return '-';
    },
    costDetails(): Importe[] {
      return this.ordenarCostos([...this.costs.detalle]);
    },
  },
  methods: {
    getCantidad(item): string {
      return item.cantidad ? `${item.cantidad} ${item.unidad}` : '';
    },
    toTwoDecimals(number: number): string {
      return formatStringNumber(number.toFixed(2));
    },
    formatStringNumber(number: string): string {
      return formatStringNumber(number);
    },
    compararConcepto(a: Importe, b: Importe) {
      const orden: { [concepto: string]: number } = {
        kilometros: 1,
        espera: 2,
        otros: 3,
      };
      return orden[a.concepto] - orden[b.concepto];
    },
    ordenarCostos(detalles: Importe[]): Importe[] {
      return detalles.sort(this.compararConcepto);
    },
  },
});
