

















































import { propOfType } from '@/services/utils';
import Vue from 'vue';

export default Vue.extend({
  name: 'BaseToggle',
  props: {
    value: propOfType<boolean>(false),
    disabled: propOfType<boolean>(false),
    // Es escencial pasarle un `id` único al componente para que funcione correctamente.
    id: propOfType<string>(String(new Date().getMilliseconds() + Math.random())),
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    onChange(event) {
      this.$emit('input', event.target.checked);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    checkIfDisabled() {
      if (this.disabled) {
        this.$emit('input', false);
      }
    },
  },
  mounted() {
    this.checkIfDisabled();
  },
  watch: {
    disabled() {
      this.checkIfDisabled();
    },
  },
});
