




















import Vue from 'vue';
import { Address } from '@/models/Address';
import IconLocationOutline from '@/components/icons/IconLocationOutline.vue';
import { propOfType } from '@/services/utils';
import IconVerifiedOutline from '@/components/icons/IconVerifiedOutline.vue';
import IconHeartOutline from '@/components/icons/IconHeartOutline.vue';
import IconOffice from '@/components/icons/IconOffice.vue';
import IconInpunto from '@/components/icons/IconInpunto.vue';

export type SuggestionAddress = {
  primary: string
  secondary: string
  address: Address;
}

export function newSuggestionAddress(
  primary: string,
  secondary: string,
  address: Address,
): SuggestionAddress {
  return {
    primary,
    secondary,
    address,
  };
}

export default Vue.extend({
  components: {
    IconLocationOutline,
    IconHeartOutline,
    IconVerifiedOutline,
    IconOffice,
    IconInpunto,
  },
  props: {
    address: propOfType<SuggestionAddress>(),
  },
  computed: {
    icon(): string[] {
      const { source, isCached } = this.address.address;
      if (source?.startsWith('fav')) {
        return ['IconHeartOutline', 'Dirección favorita del usuario'];
      }
      if (source?.startsWith('cliente')) {
        return ['IconOffice', 'Dirección corporativa del cliente'];
      }
      if (source?.startsWith('general')) {
        return ['IconVerifiedOutline', 'Dirección validada por InPunto'];
      }
      if (source?.startsWith('taaxii') || isCached) {
        return ['IconInpunto', 'Dirección sugerida por InPunto'];
      }
      return ['IconLocationOutline', 'Dirección sugerida por Google Maps'];
    },
  },
});
