import { GeocodingAddress } from '@/models/requests-and-responses/direcciones';
import { gralAddress } from '@/services/api/addressApi';
import { get } from '@/services/http';
import { SearchResult } from '../pages/dashboard/mi-cuenta/direcciones-favoritas/create/SearchAddressRequest';

export async function checkIfAddressIsTerminal(street: string): Promise<boolean> {
  return gralAddress.isTerminal(street)
    .then(((res) => res.data))
    .catch(() => false);
}

export async function performAddressSearch(
  query: string,
  idCliente: number,
  center: { lat: number, lon: number },
  signal?: AbortSignal,
): Promise<SearchResult[]> {
  const useES = true;
  const res = await gralAddress
    .getAddress(query, idCliente, useES, center, signal);
  return res.data as any[];
}

export async function getRealCoords(
  source: string,
): Promise<GeocodingAddress> {
  const res = await get('/direcciones/geocoding/v1/$source/', { source });
  return res.data;
}
