






























import { propOfTypeArrayOf } from '@/services/utils';
import Vue from 'vue';
import { PassengerModel } from '@/models/requests-and-responses/seguimiento';
import IconChevronD from '@/components/icons/IconChevronD.vue';
import DetailsPassengerItem from './DetailsPassengerItem.vue';

export default Vue.extend({
  components: {
    DetailsPassengerItem,
    IconChevronD,
  },
  data() {
    return {
      passengersExpanded: false,
      cantPasajeros: this.passengers.length,
    };
  },
  props: {
    passengers: propOfTypeArrayOf<PassengerModel>(),
  },
  computed: {
    hayMasPasajeros():boolean {
      return this.passengers.length > 3;
    },
    showPasajeros():string {
      if (this.cantPasajeros - 3 === 1) {
        return '+1 pasajero';
      } return `+${this.cantPasajeros - 3} pasajeros`;
    },
    filteredPaxs(): PassengerModel[] {
      return this.hayMasPasajeros && !this.passengersExpanded
        ? this.passengers.slice(0, 3)
        : this.passengers;
    },
  },
  methods: {
    togglePassengers(state: boolean): void {
      if (state) {
        this.$logEvent('seguimiento', { accion_seguimiento: 'expandir_paxs' });
      }

      this.passengersExpanded = state;
    },
  },
});
