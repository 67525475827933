














































import Vue from 'vue';
import IconSupport from '@/components/icons/IconSupport.vue';
import IconX20 from '@/components/icons/IconX20.vue';
import IconPhone from '@/components/icons/IconPhone.vue';
import IconWhatsapp from '@/components/icons/IconWhatsapp.vue';
import IconMail from '@/components/icons/IconMail.vue';

export default Vue.extend({
  components: {
    IconSupport,
    IconX20,
    IconWhatsapp,
    IconPhone,
    IconMail,
  },
  props: {
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
  },
  computed: {
    phone() {
      return '0800 888 8229';
    },
    whatsapp() {
      return '11 2735 0000';
    },
    email() {
      return 'reservas@inpunto.la';
    },
    hrefWhatsApp(): string {
      return 'https://wa.me/5491127350000';
    },
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
    hrefMail(): string {
      return 'mailto:reservas@inpunto.la';
    },
    hrefPhone(): string {
      return 'tel:08008888229';
    },
  },
});
