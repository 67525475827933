// Search address request

export type SearchResult = {
  alias: string | null,
  id: number,
  street: string,
  info: string | null,
  lat: number,
  lon: number,
  source: string,
}

export type SearchAddressRequest =
  | { status: 'notAsked' }
  | { status: 'loading' }
  | { status: 'emptyResponse' }
  | { status: 'withSearchResults', results: Array<SearchResult> }
  | { status: 'withSelectedSearchResult', selectedResult: SearchResult }
  | { status: 'error', errorCode: string }

export function notAsked(): SearchAddressRequest {
  return { status: 'notAsked' };
}

export function castResultsFromApiResponse(response: SearchResult[]): Array<SearchResult> | null {
  if (response instanceof Array) {
    // Confiamos en el backend porque hacer la conversión segura
    // me dio varios problemas
    return response.map((elem) => elem as SearchResult);
  }
  return null;
}

export function withSelectedSearchResult(
  searchAddressRequest: SearchAddressRequest,
  addressId: number,
): SearchAddressRequest {
  if (searchAddressRequest.status === 'withSearchResults') {
    const selectedResult = searchAddressRequest
      .results
      .find((result) => result.id === addressId);

    if (selectedResult !== undefined) {
      return {
        status: 'withSelectedSearchResult',
        selectedResult,
      };
    }
  }
  return searchAddressRequest;
}

export function fromSearchResults(response: SearchResult[]): SearchAddressRequest {
  const results = castResultsFromApiResponse(response);

  if (results === null) {
    return { status: 'error', errorCode: 'internal_error' };
  }

  if (results.length === 0) {
    return { status: 'emptyResponse' };
  }

  return {
    status: 'withSearchResults',
    results,
  };
}

export function getSelectedAddress(search: SearchAddressRequest): SearchResult | null {
  if (search.status === 'withSelectedSearchResult') {
    return search.selectedResult;
  }
  return null;
}
