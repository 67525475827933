





































































































import store from '@/services/store';
import userMsg from '@/services/userMsg';
import stepCodes from '@/services/userTexts/labels.json';
import { GeneralAccount, getIdCliente } from '@/models/accounts/GeneralAccount';
import Vue, { VueConstructor } from 'vue';
import { debounce, propOfType } from '@/services/utils';
import { Address } from '@/models/Address';
import { PaxOfForm } from '@/models/Person';
import { eventBus } from '@/main';
import IconHelp from '@/components/icons/IconHelp.vue';
import IconX24 from '@/components/icons/IconX24.vue';
import IconElipsisMenu from '@/components/icons/IconElipsisMenu.vue';
import { data as dataParaMi } from './forms/ParaMi.vue';
import { data as dataConParadas } from './forms/ConParadas.vue';
import { data as dataMensajeria } from './forms/Mensajeria.vue';
import { data as dataPaqueteria } from './forms/Paqueteria.vue';
import { data as dataParaOtro } from './forms/ParaOtro.vue';
import { data as dataParaInvitado } from './forms/ParaInvitado.vue';
import { Metadata } from '../CreateWeb.vue';
import BigButton from './BigButton.vue';
import BackAndTitle from './BackAndTitle.vue';

// --- Address

export type AddressOfForm = Address & {
  ascensos?: PaxOfForm[]
  descensos?: PaxOfForm[]
  espera?: string
  isTerminal: boolean
  tag: string
} | undefined

export function necesitaDatosDeArribo(addresses: AddressOfForm[], conRetorno: boolean): boolean {
  /** Se necesitan datos de arribo cuando la dirección
   * de origen es terminal o aeropuerto.
  */
  const isTerminal = addresses.findIndex((a) => {
    if (a && a.isTerminal) {
      if (a.ascensos && !a.ascensos.length) return false;
      return true;
    }
    return false;
  });
  if (conRetorno) return isTerminal !== -1;
  return isTerminal > -1 && isTerminal < addresses.length - 1;
}

// Agregar el "data" aquí en caso de que se añada un nuevo formulario.
export type DataOfSomeForm = never
  | ReturnType<typeof dataParaMi>
  | ReturnType<typeof dataConParadas>
  | ReturnType<typeof dataParaOtro>
  | ReturnType<typeof dataParaInvitado>
  | ReturnType<typeof dataMensajeria>
  | ReturnType<typeof dataPaqueteria>;

const stepsCodeKeys = stepCodes.helpMessages;
export type StepCode = keyof typeof stepsCodeKeys;

export type Section = {
  component: VueConstructor,
  steps: StepCode[],
}

export type Step = {
  target: string,
  content: string,
  params: {
    placement: string,
  },
}

export function faltanDatosDeArribo(data: DataOfSomeForm & { datosDeArribo: string },
  conRetorno: boolean): boolean {
  if (necesitaDatosDeArribo(data.addresses, conRetorno)) {
    return data.datosDeArribo?.trim() === '';
  }
  return false;
}

export default Vue.extend({
  components: {
    BigButton,
    BackAndTitle,
    IconHelp,
    IconX24,
    IconElipsisMenu,
  },
  props: {
    setMapAddresses: Function,
    exit: Function,
    distanceKm: Number,
    metadata: propOfType<Metadata>(),
    timeEstimation: propOfType<number>(),
    antelacionLAP: propOfType<number>(30),
  },
  data() {
    return {
      expandedSection: -1,
      reservating: false,
      applying: false,
      datosDeArribo: '' as string,
      userTriedToConfirm: false,
      debouncedStopApplying: (...args) => { },
      checkIfLAP: (): boolean => false,
      error: '',
      openTour: false,
      keepOpen: false,
      tourCallbacks: {
        onPreviousStep: (currentStep: number) => {},
        onNextStep: (currentStep: number) => {},
        onStop: () => {},
        onSkip: () => {},
        onFinish: () => {},
      },
      // Este id permite hacer scrolleable el dropdown menu de IndexWhen.vue
      scrollableDashboardId: 'scrollable-dashboard',
    };
  },
  created() {
    eventBus.$on('checkIfLAP', (fun) => { this.checkIfLAP = fun; });
  },
  mounted() {
    // Creamos la función para poder hacer el applying...
    this.debouncedStopApplying = debounce(() => {
      this.applying = false;
    }, 1000);
    this.tourCallbacks.onPreviousStep = this.onPreviousStep;
    this.tourCallbacks.onNextStep = this.onNextStep;
    this.tourCallbacks.onSkip = this.onTourClose;
    this.tourCallbacks.onStop = this.onTourClose;
    this.tourCallbacks.onFinish = this.onTourClose;
  },
  computed: {
    selectedAccount(): GeneralAccount | null { return store.getters.selectedAccount; },
    idCliente(): number | null {
      return this.selectedAccount !== null
        ? getIdCliente(this.selectedAccount)
        : null;
    },
    buttonText(): string {
      return ''; // OVERRIDE THIS.
    },
    buttonTitle(): string {
      return ''; // OVERRIDE THIS.
    },
    price(): string | null {
      return null; // OVERRIDE THIS.
    },
    sections(): Section[] {
      return []; // OVERRIDE THIS.
    },
    steps(): Step[] {
      const finalSteps = [] as Step[];

      this.sections.reduce((result, { steps }) => {
        steps.forEach((step) => {
          const content = userMsg.getHelpMessage(step);
          if (step && content) {
            result.push({
              target: `.${step}`,
              content,
              params: { placement: 'right' },
            });
          }
        });
        return result;
      }, finalSteps);

      const extraSteps = ['reserveButton1', 'reserveButton2'];

      extraSteps.forEach((step) => {
        finalSteps.push({
          target: `.${step}`,
          content: userMsg.getHelpMessage(step),
          params: { placement: 'right' },
        });
      });

      return finalSteps;
    },
    menuSteps(): { open: number, close: number } {
      const step3 = this.steps.findIndex((step) => step.target === '.when3');
      if (step3 && step3 !== -1) {
        return {
          open: step3,
          close: step3,
        };
      }
      return {
        open: this.steps.findIndex((step) => step.target === '.when1'),
        close: this.steps.findIndex((step) => step.target === '.when2'),
      };
    },
    isMobile(): boolean {
      return store.getters.isMobile;
    },
  },
  methods: {
    onToggleSectionByIndex(sectionIndex) {
      if (this.expandedSection === sectionIndex) {
        this.expandedSection = -1;
      } else {
        this.expandedSection = sectionIndex;
      }
    },
    onOpenSectionByIndex(sectionIndex) {
      this.expandedSection = sectionIndex;
    },
    onCloseSectionByIndex(sectionIndex) {
      if (this.expandedSection === sectionIndex) {
        this.expandedSection = -1;
      }
    },
    onConfirm() {
      // IMPLEMENT THIS.
    },
    onExit() {
      this.exit(null);
    },
    onApply(event: Function) {
      this.userTriedToConfirm = false;
      this.applying = true;
      this.debouncedStopApplying();
      event(this.$data);
    },
    propsOf(section: VueConstructor) {
      // IMPLEMENT THIS.
      return { };
    },
    startTest() {
      this.openTour = !this.openTour;
    },
    onPreviousStep(currentStep: number): void {
      this.keepOpen = (currentStep - 1 >= this.menuSteps.open
        && currentStep - 1 <= this.menuSteps.close);
    },
    onNextStep(currentStep: number): void {
      this.keepOpen = (currentStep + 1 >= this.menuSteps.open
        && currentStep + 1 <= this.menuSteps.close);
    },
    onTourClose() {
      this.openTour = false;
      this.keepOpen = false;
    },
  },
  watch: {
    openTour() {
      if (this.openTour) {
        // eslint-disable-next-line
        this['$tours']['myTour'].start();
      } else {
        // eslint-disable-next-line
        this['$tours']['myTour'].finish();
        this.onTourClose();
      }
    },
  },
});

// Eslint se queja cuando intento importar CombinedVueInstance
// Así que por el momento voy a comentar la siguiente linea:
// type SectionVueComponent = CombinedVueInstance<Vue, any, any, any, any>
// Y, en su lugar, voy a usar:
type SectionVueComponent = any;

// Función utilizada por los componentes hijos para
// cambiar el data de este componente.
export function apply(sectionComponent: SectionVueComponent, fun?: (d: DataOfSomeForm) => void) {
  sectionComponent.$emit('apply', fun);
}
