
import IconHand from '@/components/icons/IconHandHorizontalArrow.vue';
import { apply } from '../../indexForm.vue';
import Guest from './Guest.vue';

export default Guest.extend({
  components: {
    Icon: IconHand,
  },
  methods: {
    nameAndLastNameWatcher(val) {
      apply(this, (data) => {
        if ('receiverNameAndLastName' in data) {
          data.receiverNameAndLastName = val;
        }
      });
    },
    phoneWatcher(val) {
      apply(this, (data) => {
        if ('receiverPhone' in data) {
          data.receiverPhone = val;
        }
      });
    },
  },
  computed: {
    headerText() {
      return '¿Quién recibe?';
    },
    autocomplete() {
      return false;
    },
    showSuggestions() {
      return true;
    },
  },
});
