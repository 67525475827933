import { AxiosError } from 'axios';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import {
  InwebEndpointOfMethod, URLOfEndpoint,
  api, get, getHeaders,
} from '../http';
import Pedido from '../../models/Pedido';
import { GET } from '../http/types';

const pedidosUrl = 'pedidos/';

export class Paginated {
  content: any;

  count: any;

  first: any;

  last: any;

  constructor(content: any, count: any, first: any, last: any) {
    this.content = content;
    this.count = count;

    // Cronologicamente:
    // first indica si no hay pedidos más viejos,
    // y last indica que ya es el último pedido.
    this.first = first;
    this.last = last;
  }
}

export const pedidos = {
  async get(dir: string, idCliente: number, from: Date, offset = 0, limit = 10,
    signal?: AbortSignal) {
    try {
      const response = await api.get<any>(pedidosUrl, {
        headers: getHeaders(),
        params: {
          direction: dir,
          idCliente,
          from: from.toISOString(),
          offset,
          limit,
        },
        signal,
      });
      const { data } = response.data;
      return new Paginated(
        data.content.map((e: any) => new Pedido(e)),
        data.count,

        /*
        *          past                       future
        * <--------------------| from |------------------->
        * data.last   data.first       data.fist   data.last
        *  (first)      (last)          (first)     (last)
        *
        * paginated.first = pasado (<---)
        * paginated.last = futuro (--->)
        */
        dir === 'past' ? data.last : data.first,
        dir === 'past' ? data.first : data.last,
      );
    } catch (error) {
      throw errorCodeFromAxiosError(error as AxiosError);
    }
  },
  async search(
    url: URLOfEndpoint<InwebEndpointOfMethod<GET>>, idClient: number,
    query: string, status: string, type: string, group: string,
    since: string, until: string, offset?: number, limit?: number, signal?: AbortSignal,
  ): Promise<Pedido[]> {
    let arrayPedidos = [] as Pedido[];
    await get(url, undefined, {
      headers: getHeaders(),
      params: {
        idClient,
        q: query,
        status,
        type,
        group,
        since,
        until,
        offset,
        limit,
        provider: 'InPunto',
      },
      signal,
    })
      .then(({ data }) => {
        arrayPedidos = data.map((e: any) => new Pedido(e));
      })
      .catch((error) => {
        throw error;
      });
    return arrayPedidos;
  },
  async getPedidoById(
    tipoExtras: 'v' | 's',
    id: string,
    onlyVisibles?: boolean,
    onlyRelated?: boolean,
    signal?: AbortSignal,
  ): Promise<Pedido[]> {
    let arrayPedidos = [] as Pedido[];
    await get('/pedidos/InPunto/$tipoExtras/$id', { tipoExtras, id }, {
      headers: getHeaders(),
      params: { onlyVisibles, onlyRelated },
      signal,
    })
      .then(({ data }) => {
        arrayPedidos = data.map((e: any) => new Pedido(e));
      })
      .catch((error) => {
        throw error;
      });
    return arrayPedidos;
  },
  initLoad: (from: Date, idCliente: number) => Promise.all([
    pedidos.get('past', idCliente, from),
    pedidos.get('future', idCliente, from),
  ])
    .then((response) => {
      const resPast = response[0];
      const resFuture = response[1];

      return new Paginated(
        resFuture.content.concat(resPast.content),
        resPast.count + resFuture.count,
        resPast.first,
        resFuture.last,
      );
    })
    .catch((error: AxiosError) => {
      throw errorCodeFromAxiosError(error);
    }),
};

export default { pedidos, Paginated };
