























import Vue from 'vue';
import { propOfType } from '@/services/utils';
import Origin from './Origin.vue';
import Destination from './Destination.vue';
import Stop from './Stop.vue';
import { Metadata } from '../../../CreateWeb.vue';
import { AddressOfForm, apply } from '../../indexForm.vue';
import { deletePax } from '../passengers/indexPassengers.vue';

export default Vue.extend({
  components: {
    Origin,
    Destination,
    Stop,
  },
  props: {
    addresses: propOfType<AddressOfForm[]>(),
    isExpanded: propOfType<Boolean>(),
    userTriedToConfirm: propOfType<Boolean>(false),
    metadata: propOfType<Metadata>(),
  },
  data() {
    return {
      expandedStop: -1,
    };
  },
  computed: {
  },
  watch: {
    isExpanded() {
      if (!this.isExpanded) {
        this.expandedStop = -1;
      }
    },
  },
  methods: {
    getComponent(index: number): string {
      if (index === 0) return 'Origin';
      if (index === this.addresses.length - 1) return 'Destination';
      return 'Stop';
    },
    onOpen(index: number) {
      if (this.expandedStop !== index) {
        this.expandedStop = index;
      }
      if (!this.isExpanded) this.$emit('open');
    },
    onToggle(index: number) {
      if (this.expandedStop === index) {
        this.onClose(index);
      } else {
        this.onOpen(index);
      }
    },
    onClose(index: number) {
      if (this.expandedStop === index) {
        this.expandedStop = -1;
      }
      if (this.isExpanded) this.$emit('close');
    },
    onApply(event: Function) {
      this.$emit('apply', event);
    },
    addStop(index: number) {
      if (index > 3) return;
      apply(this, (data) => {
        const adds = [...data.addresses];
        adds.splice(index, 0, undefined);
        data.addresses = adds;
      });
    },
    removeStop(index: number) {
      apply(this, (data) => {
        const adds = [...data.addresses];
        const addToRemove = adds[index];
        if ('passengers' in data) {
          if (addToRemove?.ascensos) {
            addToRemove.ascensos
              .forEach((pax) => deletePax(pax.id, data.addresses, data.passengers));
          }
          if (addToRemove?.descensos) {
            addToRemove.descensos
              .forEach((pax) => deletePax(pax.id, data.addresses, data.passengers));
          }
        }
        adds.splice(index, 1);
        data.addresses = adds;
      });
    },
  },
});
