















































































import { propOfType } from '@/services/utils';
import Vue from 'vue';
import { PaxOfForm } from '@/models/Person';
import store from '@/services/store';
import { GeneralAccount } from '@/models/accounts/GeneralAccount';
import { mapStateTyped } from '@/services/store/utils';
import { AddressOfForm } from '../../indexForm.vue';
import { deletePax } from './indexPassengers.vue';
import PersonSearchBar, { Pax } from '../person/PersonSearchBar.vue';
import RouteTimeline from '../../RouteTimeline.vue';

function getEmptyPerson(): Pax {
  return {
    nameAndLastName: '',
    phone: '',
    phoneIsValid: false,
    person: null,
  } as Pax;
}

export default Vue.extend({
  props: {
    addresses: propOfType<AddressOfForm[]>(),
    passengers: propOfType<PaxOfForm[]>(),
    onClose: propOfType<Function>(() => () => {}),
    onSave: propOfType<Function>(() => () => {}),
  },
  components: {
    PersonSearchBar,
    RouteTimeline,
  },
  data() {
    return {
      selectedPerson: getEmptyPerson(),
      origen: null as number | null,
      destino: null as number | null,
      currentId: 0,
      triedToAddPax: false,
      triedToConfirm: false,
      wrongStopOrder: false,
    };
  },
  mounted() {
    const indexArray = this.passengers.map((pax) => pax.id);
    if (indexArray.length) {
      this.currentId = Math.max(...indexArray) + 1;
    }
  },
  watch: {
    origen() {
      this.wrongStopOrder = false;
    },
    destino() {
      this.wrongStopOrder = false;
    },
    isPersonComplete() {
      this.triedToConfirm = false;
    },
  },
  methods: {
    updatePerson(newPerson: Pax) {
      this.selectedPerson = newPerson;
    },
    findPaxById(passengers: PaxOfForm[], id: number): number {
      return passengers.findIndex((pax) => pax.id === id);
    },
    addPax() {
      this.triedToAddPax = true;
      this.wrongStopOrder = false;

      if (this.errorOnPax || this.origen === null || this.destino === null) {
        return;
      }

      const paxName = this.selectedPerson.nameAndLastName;

      if (this.passengers.some((pax) => pax.nombre === paxName)) {
        const ascensos = this.addresses.slice(this.origen, this.destino)
          .filter((adds) => adds?.ascensos?.some((pax) => pax.nombre === paxName));

        const descensos = this.addresses.slice(this.origen + 1, this.destino + 1)
          .filter((adds) => adds?.descensos?.some((pax) => pax.nombre === paxName));

        if (ascensos.length || descensos.length) {
          this.wrongStopOrder = true;
          return;
        }
      }

      const origen = this.addresses[this.origen]?.ascensos;
      const destino = this.addresses[this.destino]?.descensos;

      if (origen && destino) {
        origen.push(this.paxToPaxOfForm);
        destino.push(this.paxToPaxOfForm);

        this.passengers.push(this.paxToPaxOfForm);
        this.currentId += 1;
        this.selectedPerson = getEmptyPerson();
        this.origen = null;
        this.destino = null;
        this.triedToAddPax = false;
      }
    },
    deletePassenger(id: number) {
      deletePax(id, this.addresses, this.passengers);
    },
    clearPerson() {
      this.selectedPerson = getEmptyPerson();
      this.origen = null;
      this.destino = null;
    },
    copiarSol() {
      const { selectedAccount } = this;
      this.selectedPerson.nameAndLastName = `${this.user?.apellido}, ${this.user?.nombre}`;
      if (selectedAccount) {
        if (selectedAccount.tag === 'particularAccount') {
          this.selectedPerson.phone = selectedAccount.account.phone;
        }
        if (selectedAccount.tag === 'corporateAccount') {
          const { account } = selectedAccount;
          if (account) {
            this.selectedPerson.phone = account.celular || '';
            this.selectedPerson.person = {
              firstname: this.user?.nombre || '-',
              lastname: this.user?.apellido || '-',
              phone: account.celular || '',
              corporateAccountId: account.idCuentaSgv,
              personId: account.idPersona,
              cecoPreferido: account.idCentroDeCostoPreferido || 0,
              centrosDeCostoHabilitados: account.centrosDeCosto,
              formaDePagoPorDefecto: account.formaDePagoPorDefecto,
            };
          }
        }
      }
    },
    onConfirm() {
      this.triedToConfirm = true;
      if (this.errorOnConfirm) return;
      this.onSave();
    },
  },
  computed: {
    ...mapStateTyped(['user']),
    selectedAccount(): GeneralAccount | null {
      return store.getters.selectedAccount;
    },
    filteredAddresses(): { value: number, text: string }[] {
      return this.addresses.filter((address) => address)
        .map((address, index) => ({ value: index, text: address?.street || '-' }));
    },
    origenOptions(): { value: number, text: string }[] {
      const index = this.destino === null ? -1 : this.destino;
      return this.filteredAddresses.slice(0, index);
    },
    destinoOptions(): { value: number, text: string }[] {
      const index = this.origen === null ? 1 : this.origen + 1;
      return this.filteredAddresses.slice(index);
    },
    paxToPaxOfForm(): PaxOfForm {
      const pax = this.selectedPerson;
      return {
        id: this.currentId,
        nombre: pax.nameAndLastName,
        tel: pax.phone,
        idPersona: pax.person ? pax.person.personId : undefined,
        idCeco: pax.person ? pax.person.cecoPreferido : undefined,
      } as PaxOfForm;
    },
    // Con esta computed fuerzo que los datos dentro de PersonSearchBar se actualicen siempre
    initialData(): Pax {
      const {
        nameAndLastName,
        phone,
        phoneIsValid,
        person,
      } = this.selectedPerson;
      return {
        nameAndLastName,
        phone,
        phoneIsValid,
        person,
      };
    },
    errorOnPax(): string {
      const errors = [] as string[];
      if (this.wrongStopOrder) {
        return 'El pasajero debe descender antes de volver a ascender';
      }
      if (this.passengers.length > 50) {
        return 'No puede haber mas de 50 pasajes';
      }
      if (!this.selectedPerson.nameAndLastName.length) {
        errors.push('nombre');
      }
      if (!this.selectedPerson.phone.length) {
        errors.push('teléfono');
      }
      if (this.origen === null) {
        errors.push('origen');
      }
      if (this.destino === null) {
        errors.push('destino');
      }

      return errors.length ? `Faltan los siguientes datos: ${errors.join(', ')}` : '';
    },
    errorOnConfirm(): string {
      if (!this.triedToConfirm) return '';
      if (this.isPersonComplete) return 'No terminó de agregar un pasajero';
      if (this.addresses.some((adds) => !adds?.ascensos?.length && !adds?.descensos?.length)) {
        return 'No puede haber paradas sin ascensos ni descensos';
      }
      return '';
    },
    isPersonComplete(): boolean {
      return Boolean(this.selectedPerson.nameAndLastName && this.selectedPerson.phone
        && this.origen !== null && this.destino !== null);
    },
  },
});

