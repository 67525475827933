

























import Vue from 'vue';
import IconX24 from '@/components/icons/IconX24.vue';
import msg from '@/services/userMsg';
import Pedido from '@/models/Pedido';
import store from '@/services/store';
import TextWithLabel from './TextWithLabel.vue';
import Taaxii from './taaxii.com/indexTaaxii.vue';

export default Vue.extend({
  components: {
    TextWithLabel,
    IconX24,
    Taaxii,
  },
  props: {
    pedido: { type: Pedido, default: null },
    onClose: Function,
    hideActions: { type: Boolean, default: false },
  },
  methods: {
    getLabel(key) {
      return msg.getLabel(key);
    },
  },
  computed: {
    serviceDetail() {
      if (this.pedido === null) {
        return null;
      }
      switch (this.pedido.service) {
        case 'InPunto':
          return Taaxii;
        default:
          return null;
      }
    },
    isMobile() {
      return store.getters.isMobile;
    },
  },
});
