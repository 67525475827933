var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"font-medium mb-4 title text-grey-900 text-lg mt-2"},[_vm._v(" Nueva dirección "+_vm._s(_vm.onlyCorporative ? 'corporativa' : 'favorita')+" ")]),_c('div',[_c('div',{staticClass:"flex flex-row mb-1 gap-1 items-end"},[_c('BaseInput',{staticClass:"w-full",attrs:{"value":_vm.addressInputValue,"placeholder":"Ingrese Calle N°, Localidad, Provincia y País","inputId":"addresses-create-direccion","autocomplete":false},on:{"input":_vm.onAddressInput}},[_vm._v(" Dirección ")]),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.buttonInfo.tooltip),expression:"buttonInfo.tooltip"}],staticClass:"border border-l-0 rounded border-gray-300",class:_vm.buttonInfo.iconClass,on:{"click":_vm.buttonInfo.onClick}},[_c(_vm.buttonInfo.icon,{tag:"component",staticClass:"m-2 h-5 w-5"})],1)],1),(!_vm.addressOnMap)?_c('div',{staticClass:"mb-1"},[_c('div',{staticClass:"bg-white rounded border mb-2 transition-all shadow max-h-32",class:{
          'h-fit overflow-y-auto': _vm.searchResultsOrEmptyArray.length > 0,
          'h-12': _vm.searchAddressRequest.status === 'loading',
          'hidden': _vm.searchResultsOrEmptyArray.length === 0 &&
            _vm.searchAddressRequest.status !== 'loading',
        }},[(_vm.searchAddressRequest.status === 'loading')?_c('div',{staticClass:"p-4 h-full"},[_c('div',{staticClass:"text-center w-full flex flex-row justify-center items-center"},[_c('BaseButtonSpinner',{staticClass:"self-center mb-2"})],1)]):_vm._e(),_vm._l((_vm.searchResultsOrEmptyArray),function(address,i){return _c('button',{key:i,staticClass:"py-3 w-full flex flex-row text-sm items-center hover:text-black hover:bg-gray-200\n            focus:outline-none focus:bg-gray-200 pl-2",class:{
            'text-gray-500': !_vm.isSelectedAddress(address),
            'text-gray-600 bg-gray-100': _vm.isSelectedAddress(address),
          },on:{"click":function($event){return _vm.onSearchResultSelect(address.id)}}},[_c('div',{staticClass:"text-left pr-2 flex-grow"},[_vm._v(" "+_vm._s(_vm.addressToString(address) + (address.alias ? (" - " + (_vm.aliasToString(address))) : ''))+" ")]),(_vm.isSelectedAddress(address))?_c('BaseIcon',{staticClass:"w-5 text-gray-700",attrs:{"viewBox":"0 0 20 20","ariaLabel":"Seleccionada"}},[_c('IconCheck')],1):_vm._e()],1)})],2)]):_vm._e(),(_vm.helpMessage)?_c('div',{staticClass:"flex flex-row items-center gap-2 ml-1",class:_vm.helpMessage.isError ? 'text-accent-warning' : 'text-gray-600'},[_c(_vm.helpMessage.isError ? 'IconExclamationRounded' : 'IconHelp',{tag:"component",staticClass:"w-7 h-7 flex-shrink-0"}),_c('div',{staticClass:"text-xs font-semibold"},[_vm._v(" "+_vm._s(_vm.helpMessage.message)+" ")])],1):_vm._e()]),_c('BaseInput',{staticClass:"mb-4",attrs:{"value":_vm.aliasInputValue,"placeholder":("Ejemplo: " + (_vm.onlyCorporative ? 'Oficina' : 'Mi casa')),"inputId":"addresses-create-alias"},on:{"input":_vm.onAliasInput}},[_vm._v(" Alias ")]),_c('BaseInput',{staticClass:"mb-4",attrs:{"value":_vm.infoInputValue,"placeholder":"3 H, entre calles ...","inputId":"addresses-create-info-extra"},on:{"input":_vm.onInfoInput}},[_vm._v(" Info extra (opcional) ")]),(_vm.errorMessage)?_c('div',{staticClass:"mt-2 text-left text-red-500 text-sm italic"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('div',{staticClass:"text-right py-3"},[_c('BaseButton',{staticClass:"btn-gray-primary mr-2",attrs:{"label":"Cerrar"},nativeOn:{"click":function($event){return _vm.onClose()}}}),_c('BaseButton',{staticClass:"btn-accent-primary",attrs:{"label":"Crear"},nativeOn:{"click":function($event){return _vm.clickedCreateButton()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }