





























































import Vue from 'vue';
import { Direccion } from '@/models/requests-and-responses/seguimiento';
import { propOfTypeArrayOf, capitalizeText } from '@/services/utils';
import IconChevronD from '@/components/icons/IconChevronD.vue';

export default Vue.extend({
  components: {
    IconChevronD,
  },
  props: {
    paradas: propOfTypeArrayOf<Direccion>(),
  },
  data() {
    return {
      origen: this.paradas[0].direccion,
      destino: this.paradas[this.paradas.length - 1].direccion,
      cantParadas: this.paradas.length - 2,
      paradasList: this.paradas.slice(1, this.paradas.length - 1),
      recorridoExpanded: false,
    };
  },
  methods: {
    formatDirection(direccion: string): string {
      return capitalizeText(direccion).split(',', 3).join(',');
    },
    toggleRecorrido(state: boolean) {
      this.$logEvent('seguimiento', { accion_seguimiento: 'expandir_recorrido' });

      this.recorridoExpanded = state;
    },
  },
  computed: {
    hayParadas(): boolean {
      return this.paradas.length > 2;
    },
    showParadas(): string {
      if (this.cantParadas === 1) {
        return '+1 parada';
      } return `+${this.cantParadas} paradas`;
    },
  },
});
