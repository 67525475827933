

























import Vue from 'vue';
import msg from '@/services/userMsg';
import IconEdit from '@/components/icons/IconEdit.vue';
import IconList from '@/components/icons/IconList.vue';
import IconAccount from '@/components/icons/IconAccount.vue';
import IconEmployees from '@/components/icons/IconEmployees.vue';
import IconEmployeeGroup from '@/components/icons/IconEmployeeGroup.vue';
import IconHelp from '@/components/icons/IconHelp.vue';
import IconLogout from '@/components/icons/IconLogout.vue';
import IconAddresses from '@/components/icons/IconAddresses.vue';
import IconChart from '@/components/icons/IconChart.vue';
import { GeneralAccount } from '@/models/accounts/GeneralAccount';
import { Permissions } from '@/models/accounts/shared';
import { dispatch } from '@/services/store/utils';
import store from '@/services/store';
import { getPermissions } from '@/services/store/state';
import { CorporateAccount } from '@/models/accounts/CorporateAccount';

export default Vue.extend({
  props: {
    refreshDashboard: Function,
  },
  components: {
    IconEdit,
    IconList,
    IconAccount,
    IconEmployees,
    IconEmployeeGroup,
    IconHelp,
    IconLogout,
    IconAddresses,
    IconChart,
  },
  computed: {
    indexActive(): number {
      const indexOfCurrentRoute = this.itemsNavbar
        .findIndex(({ route }) => this.$route.name === route);

      return indexOfCurrentRoute === -1 ? 0 : indexOfCurrentRoute;
    },
    selectedAccount(): GeneralAccount | null {
      return store.getters.selectedAccount;
    },
    permisos(): Permissions | null {
      return getPermissions(store.state);
    },
    itemsNavbar(): Array<{ title: string, route: string, icon: string }> {
      const selectedAccount = this.selectedAccount?.tag === 'corporateAccount'
        ? this.selectedAccount.account : null as CorporateAccount | null;

      const nuevoPedido = (this.permisos?.travel && this.permisos?.registerGoRequest)
        || this.permisos?.registerBoxRequest || this.permisos?.registerBusRequest
        || this.permisos?.registerExpressRequest
        ? [{ title: 'nuevo pedido', route: 'create', icon: 'IconEdit' }] : [];

      const informes = this.permisos?.showReports && (this.permisos?.showClientPedidos
        || this.permisos?.showCeCoPedidos || this.permisos?.showGroupPedidos)
        ? [{ title: this.getInformesTitle, route: 'informes', icon: 'IconChart' }] : [];

      const direcciones = this.permisos?.manageEmpleados
        ? [{ title: 'direcciones', route: 'direcciones', icon: 'IconAddresses' }] : [];

      const empleados = this.permisos?.manageEmpleados
        || (this.permisos?.manageGroups && selectedAccount?.grupo)
        ? [{ title: 'usuarios', route: 'usuarios', icon: 'IconEmployees' }] : [];

      const grupos = this.permisos?.manageEmpleados
        ? [{ title: 'grupos', route: 'grupos', icon: 'IconEmployeeGroup' }] : [];

      return [
        ...nuevoPedido,
        {
          title: 'mis pedidos',
          route: 'pedidos',
          icon: 'IconList',
        },
        {
          title: 'mi cuenta',
          route: 'profile',
          icon: 'IconAccount',
        },
        ...informes,
        ...direcciones,
        ...empleados,
        ...grupos,
        /* TX-1938 Ocultar menú Ayuda
        {
          title: 'Ayuda',
          route: 'helpcenter',
          icon: 'IconHelp',
        }, */
        {
          title: 'Cerrar sesión',
          route: 'login',
          icon: 'IconLogout',
        },
      ];
    },
    getInformesTitle(): string {
      if (this.permisos?.showCeCoPedidos) {
        return 'informes cecos';
      }
      if (this.permisos?.showGroupPedidos) {
        return 'informes grupos';
      }
      return 'informes';
    },
  },
  methods: {
    onNavbarClick(value) {
      const { route } = value;
      if (route === 'login') {
        dispatch('logout', null);
      }
      if (this.$route.name !== route) {
        this.$router.push({ name: route }).catch((error) => {});
      } else {
        this.refreshDashboard();
      }
    },
    getTitle(code: string): string {
      return msg.getTitle(code.toLocaleLowerCase()) || code;
    },
    getDesc(code: string): string {
      return msg.getDescription(code.toLocaleLowerCase());
    },
  },
});
