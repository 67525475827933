class Tracker {
  total: number;

  tracker: Array<{ timestamp: number, lat: number, lon: number }>;

  constructor(data) {
    this.total = data.total;
    this.tracker = data.tracker;
  }

  static entryToString(entry: { timestamp: number, lat: number, lon: number }): string {
    return new Date(entry.timestamp).toLocaleString(
      'es-AR',
      { hour: 'numeric', minute: 'numeric' },
    );
  }
}
export default Tracker;
