
























































import Vue from 'vue';
import { PaxOfForm } from '@/models/Person';
import { propOfType, capitalizeText } from '@/services/utils';
import { AddressOfForm } from './indexForm.vue';

export default Vue.extend({
  props: {
    addresses: propOfType<AddressOfForm[]>(() => []),
    passengers: propOfType<PaxOfForm[]>(() => []),
    date: propOfType<Date | null>(null),
    smallText: propOfType<Boolean>(false),
    showXButton: propOfType<Boolean>(true),
    showWaitTimes: propOfType<Boolean>(false),
  },
  computed: {
    filteredAddresses(): AddressOfForm[] {
      return this.addresses.filter((address) => address);
    },
    numberOfPax(): number {
      return this.passengers.length;
    },
    formatedDate(): string {
      if (!this.date) return '';
      const day = `${this.date.toLocaleDateString('es-AR', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      })}`;
      const hour = `${this.date.toLocaleTimeString('es-AR', {
        hour: '2-digit',
        minute: '2-digit',
      })}`;
      return `${day} a las ${hour} hs`;
    },
  },
  methods: {
    capitalizeText(string: string): string {
      return capitalizeText(string);
    },
    formatStreet(street: string): string {
      if (!this.showWaitTimes) return street;
      return street.split(/,\s*/, 2).join(', ');
    },
    waitTime(waitTime: string): string {
      const wait = waitTime.match(/PT(\d*)H(\d*)M/);
      if (wait) {
        const hour = Number(wait[1]);
        const min = Number(wait[2]);
        if (hour || min) {
          return `Espera de ${hour ? `${hour}h` : ''}
            ${hour && min ? ' y ' : ''}${min ? `${min}m` : ''}`;
        }
      }
      return '';
    },
  },
});
