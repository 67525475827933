import GenericPerson from './taaxiiGenericPerson';
import ProveedorData from './taaxiiProveedorData';
import VehiculoData from './taaxiiVehiculoData';

class Transportista {
  agencia?: ProveedorData

  vehiculo?: VehiculoData

  chofer?: GenericPerson

  choferFoto?: string

  constructor(data) {
    this.agencia = data.agencia;
    this.chofer = data.chofer;
    this.vehiculo = data.vehiculo;
    this.choferFoto = data.choferFoto;
  }
}
export default Transportista;
