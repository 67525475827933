

































import { propOfType } from '@/services/utils';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import Vue from 'vue';

/** https://louismazel.github.io/maz-ui/documentation/maz-phone-number-input
 */

export default Vue.extend({
  name: 'BasePhoneNumberInput',
  props: {
    value: propOfType<string>(''),
    inputClass: propOfType<string>(''),
    phoneClass: propOfType<string>(''),
    phoneStyle: propOfType<string>(''),
    color: propOfType<string>('#f73163'),
    errorColor: propOfType<string>('red'),
    validColor: propOfType<string>('#898F9A'),
    required: propOfType<boolean>(false),
    label: { type: String, default: '' },
    placeholder: { type: String, default: 'Número de Teléfono' },
    validationError: { type: String, default: '' },
    dark: { type: Boolean, default: false },
    onValidChangeCallback: Function,
  },
  data() {
    return {
      model: this.value,
      currentExtraInputClasses: '',
      defaultCountry: 'AR' as CountryCode | undefined,
      defaultPhone: '',
      updateComponent: 0,
    };
  },
  watch: {
    value() {
      if (this.value !== this.model) {
        this.onValueChange(this.value);
      }
    },
  },
  created() {
    this.onValueChange(this.value);
  },
  mounted() {
    this.applyInputClassName();
  },
  updated() {
    this.applyInputClassName();
  },
  computed: {
    phonePlaceholder(): string {
      return this.$store.getters.isMobile ? 'Teléfono' : this.placeholder;
    },
  },
  methods: {
    onInputChange(event) {
      const phone = event?.e164 || '';
      this.model = phone;
      this.$emit('input', phone);
      if (this.onValidChangeCallback) this.onValidChangeCallback(event.isValid);
    },
    onValueChange(newValue: string) {
      const formattedPhone = parsePhoneNumber(newValue, this.defaultCountry);
      if (formattedPhone) {
        this.defaultCountry = formattedPhone.country;
        this.defaultPhone = formattedPhone.nationalNumber.toString();
      } else {
        this.defaultCountry = undefined;
        this.defaultPhone = newValue;
      }
      this.model = newValue;
      this.updateComponent += 1;
    },
    applyInputClassName() {
    // Este es un hack horrible para modificar el color de fondo de los <input> a pesar
    // de que la librería no lo permite nativamente.
      if (this.currentExtraInputClasses === this.inputClass) {
        return;
      }

      try {
        const container = (this.$refs.vuePhoneNumberInput as Vue | null)?.$el;

        if (container) {
          for (const input of container.querySelectorAll('input')) {
            const inputClassName = input.className;

            input.className = inputClassName.endsWith(` ${this.currentExtraInputClasses}`)
              ? inputClassName.replace(` ${this.currentExtraInputClasses}`, ` ${this.inputClass}`)
              : `${inputClassName} ${this.inputClass}`;
          }

          this.currentExtraInputClasses = this.inputClass;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
  },
});
