



































import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import { propOfType } from '@/services/utils';

/** Un botón que abre y cierra un menú "dropdown". */
export default Vue.extend({
  props: {
    /** Está pensado para usar con `v-model`, de esa forma el componente
     * padre pude decidir cuándo abrir o cerrar el menú. */
    value: propOfType<boolean>(false),
    buttonClass: propOfType<string | null>(null),
    menuClass: propOfType<string | null>(null),
    /** Para hacerlo más amigable a los lectores de pantalla. */
    menuId: propOfType<string | null>(null),
    scrollableContainerId: propOfType<string | null>(null),
    /* Booleano para casos donde no se quiera manejar el menú mediante v-model. */
    selfControl: propOfType<boolean>(false),
    centerText: propOfType<boolean>(true),
    keepOpen: propOfType<boolean>(false),
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      isOpen: false,
      scrollOffset: 0,
    };
  },
  methods: {
    onClose() {
      if (this.keepOpen) return;
      if (this.selfControl) {
        this.isOpen = false;
      } else {
        this.$emit('input', false);
      }
    },
    onButtonClick() {
      if (this.selfControl) {
        this.isOpen = !this.isOpen;
      } else {
        this.$emit('input', !this.value);
      }
    },
    keydownEventListener(event) {
      if (event.key === 'Escape') {
        this.onClose();
      }
    },
    scrollListener(event) {
      this.scrollOffset = event.currentTarget.scrollTop;
    },
  },
  computed: {
    checkIfOpen(): boolean {
      if (this.selfControl) {
        return this.isOpen;
      }
      return this.value;
    },
    menuOffset(): string {
      return `-${this.scrollOffset}px`;
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keydownEventListener);

    const scrollable = document.getElementById(this.scrollableContainerId);

    if (scrollable) {
      scrollable.onscroll = this.scrollListener;
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keydownEventListener);
  },
});
