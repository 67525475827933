







































































































import Vue from 'vue';
// eslint-disable-next-line
import { eventBus } from '@/main';
import { pedidos } from '@/services/api/pedidosApi';
import msg from '@/services/userMsg';
import {
  debounce,
  copyStringToClipboard,
  getCurrentTimestamp,
  downloadFile,
} from '@/services/utils';
import { GeneralAccount, getIdCliente } from '@/models/accounts/GeneralAccount';
import store from '@/services/store';
import IconRefresh from '@/components/icons/IconRefresh.vue';
import IconDownloadCloud from '@/components/icons/IconDownloadCloud.vue';
import NotificationRefreshBall from '@/modules/site/components/NotificationRefreshBall.vue';
import Pedido from '@/models/Pedido';
import exportPedidosToXLSX, {
  ReportType,
} from '@/services/reports/pedidosToXLSX';
import IndexFilters, {
  Filter,
} from '@/modules/site/components/IndexFilters.vue';
import { getPermissions } from '@/services/store/state';
import { Permissions } from '@/models/accounts/shared';
import axios, { AxiosError } from 'axios';
import {
  get,
  InwebEndpointOfMethod,
  post,
  URLOfEndpoint,
} from '@/services/http';
import { GET } from '@/services/http/types';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import PedidosList from './PedidosList.vue';
import PedidosNavigation from './PedidosNavigation.vue';
import PedidosNavigationMobile from './PedidosNavigationMobile.vue';
import ItemSkeleton from './ItemSkeleton.vue';

export default Vue.extend({
  name: 'pedidos',
  components: {
    IndexFilters,
    PedidosList,
    PedidosNavigation,
    IconRefresh,
    IconDownloadCloud,
    ItemSkeleton,
    NotificationRefreshBall,
    PedidosNavigationMobile,
  },
  props: {
    /** Route params */
    sectionType: { type: String, default: 'mis pedidos' },
    q: { type: String, default: '' },
    status: { type: String, default: '' },
    type: { type: String, default: '' },
    group: { type: String, default: '' },
    since: { type: String },
    until: { type: String },
    selected: { type: String, default: '-1' },
    modal: { type: String, default: null },
  },
  data() {
    return {
      debounceQuery: (...args) => {},
      pedidoList: [] as Pedido[],
      resultsPerFetch: 50,
      allPedidosFetched: false,
      loading: false,
      isDownloadOpen: false,
      showCecos: false,
      abortController: null as null | AbortController,
      reportesAbortController: null as null | AbortController,
      reportLoading: false,
      filters: [
        {
          param: 'status',
          title: 'Estado',
          showAlone: true,
          isOpen: false,
          options: [
            'registrado',
            'confirmado',
            'finalizado',
            'calificado',
            'anulado',
          ],
          selected: [],
        },
        {
          param: 'type',
          title: 'Tipo',
          options: ['trip', 'package'],
          selected: [],
        },
        {
          param: 'group',
          title: 'Grupos',
          options: [],
          selected: [],
        },
      ] as Filter[],
    };
  },
  created() {
    eventBus.$on('updatePedido', (pedido: Pedido) => {
      this.updatePedido(pedido);
    });
    eventBus.$on('updateConfirmado', (idViaje: number, idSolicitud: number) => {
      const modalAbierto = this.modal !== null;
      if (modalAbierto) {
        eventBus.$emit('updateAtClose', idViaje, idSolicitud);
      } else {
        this.updateConfirmado(idViaje, idSolicitud);
      }
    });
  },
  mounted() {
    this.debounceQuery = debounce(this.load, 500);
    this.load(true);
    // Rutina de notificaciones.
    const listContainer = this.$refs.pedidosContainer as HTMLElement;
    if (listContainer) {
      listContainer.onscroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = listContainer;
        if (scrollTop + clientHeight >= scrollHeight - 100) {
          this.load();
        }
      };
    }
  },
  beforeDestroy() {
    if (this.pedidoSelected) {
      this.deselectPedido();
      eventBus.$emit('pedidoSelected', false);
    }
  },
  watch: {
    selectedClient() {
      this.load(true);
    },
    $route(to, from) {
      if (
        to.query.q !== from.query.q
        || to.query.status !== from.query.status
        || to.query.type !== from.query.type
        || to.query.group !== from.query.group
        || to.query.since !== from.query.since
        || to.query.until !== from.query.until
      ) {
        this.debounceQuery(true);
      }
    },
    pedidoSelected() {
      this.$emit('pedidoSelected', this.pedidoSelected);
      eventBus.$emit('pedidoSelected', Boolean(this.pedidoSelected));
    },
    isDownloadOpen() {
      if (this.reportLoading) {
        this.isDownloadOpen = false;
      }
    },
  },
  methods: {
    fetchPedidos(
      offset?: number,
      limit?: number,
      signal?: AbortSignal,
    ): Promise<Pedido[]> {
      return pedidos.search(
        this.requestURL,
        this.selectedClient,
        this.q,
        this.status,
        this.type,
        this.group,
        this.since,
        this.until,
        offset,
        limit,
        signal,
      );
    },
    load(resetPedidosList = false): void {
      if (this.since === null || this.until === null) {
        // No vamos a hacer requests sin limitar por tiempo
        return;
      }
      if (
        (this.loading && !resetPedidosList)
        || (this.allPedidosFetched && !resetPedidosList)
      ) {
        // Ya hay un request en curso o ya se cargaron todos los pedidos
        return;
      }

      if (this.loading && resetPedidosList && this.abortController) {
        this.abortController.abort();
      }

      if (resetPedidosList) {
        this.pedidoList = [];
        this.allPedidosFetched = false;
      }

      this.loading = true;
      this.abortController = new AbortController();
      let requestWasCancelled = false;

      this.fetchPedidos(
        this.pedidoList.length,
        this.resultsPerFetch,
        this.abortController?.signal,
      )
        .then((response) => {
          this.pedidoList.push(...response);
          this.sortPedidosByDatetime();

          if (!response.length || response.length < this.resultsPerFetch) {
            this.allPedidosFetched = true;
          }
        })
        .catch((errorCode) => {
          if (!axios.isCancel(errorCode)) {
            this.$toast.error(msg.getError(errorCode));
          } else {
            requestWasCancelled = true;
          }
        })
        .finally(() => {
          if (!requestWasCancelled) {
            this.abortController = null;
            this.loading = false;
          }
        });
      this.$store.commit('notifications/setRefreshNotification', false);
      this.$store.commit('notifications/setRefreshTooltip', 'Actualizar');
      this.$store.commit('notifications/clearNotifications');
    },
    downloadReport(reportType: ReportType): void {
      // Por el momento esta funcionalidad solo se puede usar con pedidos de taaxii.
      const corporateAccount = this.selectedAccount?.tag === 'corporateAccount'
        ? this.selectedAccount.account
        : null;
      if (!corporateAccount) return;

      if (this.reportLoading && this.reportesAbortController) {
        this.reportesAbortController.abort();
      }

      this.isDownloadOpen = false;
      this.reportLoading = true;
      this.reportesAbortController = new AbortController();

      if (reportType === 'tarifas') {
        get(
          '/cuentas/reportes/tarifa',
          { idCliente: this.selectedClient },
          { signal: this.reportesAbortController?.signal },
        )
          .then(({ data }) => {
            const file = new Blob([Buffer.from(data, 'base64')], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64;' });
            const filename = `InPunto - ${msg.getReports(reportType)} - ${getCurrentTimestamp()}.xlsx`;
            downloadFile(file, filename, reportType);
          })
          .catch((err) => {
            this.$toast.error(msg.getError(err));
          })
          .finally(() => {
            this.reportLoading = false;
          });
      } else if (reportType === 'pedidos') {
        const idCliente = corporateAccount.idClientePadre;
        const reportTS = localStorage.getItem(`${corporateAccount.idCuentaSgv}-${reportType}-ts`) as string | null;

        // Revisa que exista el timestamp y que la diferencia entre el timestamp
        // y el tiempo actual sea menor a 10 minutos
        if (reportTS && new Date().getTime() - new Date(reportTS).getTime() < 600000) {
          this.$toast.info(msg.getInfo('report_already_requested'));
          this.reportLoading = false;
          return;
        }

        post('/pedidos/v1/reporte/pedidos', undefined, {
          params: {
            idClient: idCliente,
            q: this.q,
            status: this.status,
            type: this.type,
            group: this.group,
            since: this.since,
            until: this.until,
            provider: 'InPunto',
            areMyOrders: this.sectionType === 'mis pedidos',
          },
        })
          .then(() => {
            localStorage.setItem(`${corporateAccount.idCuentaSgv}-${reportType}-ts`, new Date().toISOString());
            this.$toast.success(msg.getSuccess('report_requested'));

            this.$logEvent(
              'descarga_reporte',
              { tipo_reporte: msg.getReports(reportType) },
            );
          })
          .catch((error: AxiosError) => {
            const errorCode = errorCodeFromAxiosError(error);
            if (errorCode === 'internal_error') {
              this.$toast.error(msg.getError('error_on_report'));
            } else {
              this.$toast.error(msg.getError(errorCode));
            }
          })
          .finally(() => {
            this.reportLoading = false;
          });
      } else {
        // TODO: Remover cuando no hagan mas falta reportes por Frontend
        this.fetchPedidos(
          undefined,
          undefined,
          this.reportesAbortController?.signal,
        )
          .then((data) => {
            exportPedidosToXLSX(
              data,
              corporateAccount,
              reportType,
              this.since,
              this.until,
            );
          })
          .catch((err) => {
            this.$toast.error(msg.getError(err));
          })
          .finally(() => {
            this.reportLoading = false;
          });
      }
    },
    updateConfirmado(idViaje: number, idSolicitud: number): void {
      const foundPedido = this.pedidoList.find((pedido) => pedido.extras.type === 's'
      && pedido.extras.idSolicitud.toString() === idSolicitud.toString());
      if (foundPedido) {
        this.updatePedido(foundPedido, idViaje);
      }
    },
    updatePedido(pedido: Pedido, viaje: number | null = null): void {
      // si el argumento de viaje es null, se asume que un pedido registrado va a pasar a confirmado
      const { extras } = pedido;
      let idViaje = null as number | null;
      let idsSolicitud = [] as number[];

      if (extras.type === 'v') {
        idViaje = extras.idViaje;
        idsSolicitud = extras.pasajes.map((p) => p.idSolicitud);
      } else {
        idsSolicitud.push(extras.idSolicitud);
        if (viaje) idViaje = viaje;
      }

      const promesas = [] as Promise<Pedido[]>[];
      if (idViaje) {
        promesas.push(pedidos.getPedidoById('v', idViaje.toString(), true, false));
      }
      promesas.push(...idsSolicitud.map((idSolicitud) => pedidos
        .getPedidoById('s', idSolicitud.toString(), true, false)));

      Promise.allSettled(promesas)
        .then((response) => {
          const data = response.flatMap((res) => (res.status === 'fulfilled' ? res.value : []));

          const listaFiltrada = this.pedidoList.filter((p) => {
            if (p.extras.type === 'v') {
              return p.extras.idViaje !== idViaje;
            }
            return !idsSolicitud.includes(p.extras.idSolicitud);
          });

          this.pedidoList = [...listaFiltrada, ...data];
          this.sortPedidosByDatetime();

          if (viaje) {
            // en el caso de que se va a updatear un pedido registrado a confirmado
            // hay que dejar seleccionado ese pedido
            const pedidoUpdated = this.pedidoList.find((p) => p.extras.type === 'v'
              && p.extras.idViaje.toString() === viaje.toString());
            this.selectPedido(pedidoUpdated?.id || 0);
          }
        })
        .catch((errorCode) => {
          this.$toast.error(msg.getError(errorCode));
        });
    },
    selectPedido(id: number): void {
      const idAsString = String(id);

      this.$router
        .replace({
          query: {
            ...this.$route.query,
            selected: this.$route.query.selected !== idAsString ? idAsString : undefined,
          },
        })
        .catch(() => {});
    },
    deselectPedido(): void {
      this.$router.replace({
        query: {
          ...this.$route.query,
          selected: undefined,
        },
      });
    },
    sortPedidosByDatetime(): void {
      this.pedidoList.sort(
        (a, b) => b.datetime.getTime() - a.datetime.getTime(),
      );
    },
    getReport(name: string): string {
      return msg.getReports(name) || '-';
    },
    onClose() {
      this.showCecos = false;
    },
    copy(str: string) {
      copyStringToClipboard(str).then(() => this.$toast.success(msg.getSuccess('string_copied')));
    },
  },
  computed: {
    selectedAccount(): GeneralAccount {
      return store.getters.selectedAccount;
    },
    selectedClient(): number {
      return getIdCliente(this.selectedAccount);
    },
    permisos(): Permissions | null {
      return getPermissions(store.state);
    },
    canSeeAll(): boolean {
      return Boolean(this.permisos?.showClientPedidos || this.permisos?.manageGroups);
    },
    limitGroups(): boolean {
      return Boolean(!this.permisos?.showClientPedidos && this.permisos?.manageGroups);
    },
    selectedId(): number | null {
      return this.selected ? Number(this.selected) : null;
    },
    pedidoSelected(): Pedido | undefined {
      return this.pedidoList.find((e) => e.id === this.selectedId);
    },
    supportMargin(): string {
      return 'mr-56';
    },
    isAuditor(): boolean {
      return Boolean(this.permisos?.showCeCoPedidos || this.permisos?.showGroupPedidos);
    },
    auditedCecosOrGroups(): Array<string> {
      const corporateAccount = this.selectedAccount?.tag === 'corporateAccount'
        ? this.selectedAccount.account
        : null;

      if (corporateAccount) {
        if (this.permisos?.showCeCoPedidos) {
          return corporateAccount.cecosAuditados
            .map((ceco) => ceco.alias || '-')
            .sort((a, b) => a.localeCompare(b));
        }
        if (this.permisos?.showGroupPedidos) {
          return corporateAccount.gruposAuditados
            .map((group) => group.alias)
            .sort((a, b) => a.localeCompare(b));
        }
      }
      return [];
    },
    subtitleText(): Array<string> {
      if (this.permisos?.showClientPedidos) {
        return ['Consulta los pedidos de tu empresa.'];
      }
      if (this.permisos?.showCeCoPedidos) {
        return ['Consulta los pedidos de tus', 'centros de costo'];
      }
      if (this.permisos?.showGroupPedidos) {
        return ['Consulta los pedidos de tus', 'grupos'];
      }
      return [];
    },
    requestURL(): URLOfEndpoint<InwebEndpointOfMethod<GET>> {
      switch (this.sectionType) {
        case 'informes':
          return '/pedidos/v2/informes';
        case 'mis pedidos':
        default:
          return '/pedidos/usuario';
      }
    },
    reportOptions(): ReportType[] {
      const reportTypes = ['pedidos'] as ReportType[];
      if (/.*(staging|localhost).*/g.test(window.location.host)) {
        reportTypes.push('pedidos-front');
      }
      if (this.permisos?.showClientPedidos && this.sectionType === 'informes') {
        reportTypes.push('tarifas');
      }
      return reportTypes;
    },
    haveNotifications(): boolean {
      return this.$store.state.notifications.refreshNotification;
    },
    refreshTooltipContent(): string {
      return this.$store.state.notifications.refreshTooltip;
    },
    isMobile(): boolean {
      return store.getters.isMobile;
    },
  },
});
