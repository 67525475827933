// --- CORPORATE ACCOUNT

import { GenericGroup, Group } from '../requests-and-responses/grupos';
import {
  CentroDeCosto, FormaDePago, GenericCeCo, Permissions,
} from './shared';

/** Una cuenta corporativa, de un empleado de una empresa.
 *
 * Se usa el "idCuentaSgv" como ID de la cuenta.
*/
export type CorporateAccount = {
  idCuentaSgv: number,
  email: string | null,
  celular: string | null,
  legajo: string | null,
  soporteTelefonico: string | null,
  permisos: Permissions,
  idClientePadre: number,
  nombreCliente: string,
  idCentroDeCostoPreferido: number | null,
  centrosDeCosto: Array<CentroDeCosto>,
  cecosAuditados: Array<GenericCeCo>,
  gruposAuditados: Array<GenericGroup>,
  idPersona: number,
  formaDePagoPorDefecto: FormaDePago | null,
  grupo: {
    id: number,
    alias: string,
  }
}

/** Cambia el ID del centro de costo preferido.
 *
 * Es importante notar que no modifica a la cuenta que recibe,
 * sino que crea una nueva con el ID cambiado.
 */
export function setIdCentroDeCostoPreferido(
  corpAccount: CorporateAccount,
  idCentroDeCostoPreferido: number,
): CorporateAccount {
  return { ...corpAccount, idCentroDeCostoPreferido };
}

/** Cambia el número de teléfono de la cuenta corporativa.
 *
 * Es importante notar que no modifica a la cuenta que recibe,
 * sino que crea una nueva con el teléfono cambiado.
 */
export function setNewPhone(
  corpAccount: CorporateAccount,
  celular: string,
): CorporateAccount {
  return { ...corpAccount, celular };
}
