

































import Vue from 'vue';

import { auth } from '@/services/api/authApi';
import { equals, identity, validators } from '@/services/utils';
import msg from '@/services/userMsg';
import { ErrorCode, errorCodeFromAxiosError } from '@/models/ErrorCode';
import { LOGIN } from '@/services/routeName';
import ButtonLogin from '../components/ButtonLogin.vue';
import ButtonLoginMobile from '../components/ButtonLoginMobile.vue';
import ButtonCreateAccount from '../components/ButtonCreateAccount.vue';

type RequestStatus =
  | { status: 'notAsked' }
  | { status: 'loading' }
  | { status: 'error', code: ErrorCode }

function getErrorMsg(code: ErrorCode): string {
  /** Errores del endpoint de Recuperar contraseña
   * https://api-backend-taaxii.stoplight.io/docs/api-backend/reference/users.v1.json/paths/~1usuarios~1recuperar-contrase%C3%B1a/patch
  */
  switch (code) {
    case 'not_found':
      return 'No encontramos ningún usuario asociado a ese e-mail';
    case 'email_invalid':
    default:
      return msg.getError(code);
  }
}

export default Vue.extend({
  components: {
    ButtonLogin,
    ButtonCreateAccount,
    ButtonLoginMobile,
  },
  data() {
    return {
      requestStatus: identity<RequestStatus>({ status: 'notAsked' }),
      email: '',
    };
  },
  computed: {
    showSpinner(): boolean {
      return this.requestStatus.status === 'loading';
    },
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
  },
  mounted() {
    document.title = 'Recuperar contraseña - InPunto';
  },
  methods: {

    onReset() {
      if (!validators.isValidEmail(this.email)) {
        this.requestStatus = { status: 'error', code: 'email_invalid' };
      } else {
        this.handleReset();
      }
    },
    handleReset() {
      auth.recoverPassword({ email: this.email }).then(
        (response) => {
          this.$toast.success(`Se envió un mail a ${this.email} con los nuevos datos de acceso.`);
          this.$router.push({ name: LOGIN });
        },
        (error) => {
          this.requestStatus = { status: 'error', code: errorCodeFromAxiosError(error) };
        },
      );
    },
  },
  watch: {
    requestStatus(requestStatus: RequestStatus, oldRequestStatus) {
      if (requestStatus.status === 'error' && !equals(requestStatus, oldRequestStatus)) {
        this.$toast.error(getErrorMsg(requestStatus.code));
      }
    },
  },
});
