import defaultProfileSrc from '@/assets/defaultProfile.png';

export type User = {
  id: number,
  nombre: string,
  apellido: string,
  tipoDoc: string,
  docNum: string,
  profilePictureUrl: string | null,
  username: string,
  email: string,
};

export const defaultProfilePictureUri = defaultProfileSrc;

export function userFromUnknown(data: unknown): User | null {
  if (typeof data === 'object' && data !== null) {
    const data2 = data as {
      id?: unknown,
      nombre?: unknown,
      apellido?: unknown,
      tipoDoc?: unknown,
      docNum?: unknown,
      profilePictureUrl?: unknown,
      username?: unknown,
      email?: unknown,
    };

    if (typeof data2.id === 'number'
      && typeof data2.nombre === 'string'
      && typeof data2.apellido === 'string'
      && typeof data2.tipoDoc === 'string'
      && typeof data2.docNum === 'string'
      && (data2.profilePictureUrl === null || typeof data2.profilePictureUrl === 'string')
      && typeof data2.username === 'string'
      && typeof data2.email === 'string'
    ) {
      return {
        id: data2.id,
        nombre: data2.nombre,
        apellido: data2.apellido,
        tipoDoc: data2.tipoDoc,
        docNum: data2.docNum,
        profilePictureUrl: data2.profilePictureUrl,
        username: data2.username,
        email: data2.email,
      };
    }
  }
  return null;
}
