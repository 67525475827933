<template>
<div class="flex flex-col h-full max-h-full justify-between">
  <AccountSelector />
  <SidebarOptions :refreshDashboard="refreshDashboard"/>
  <div class="self-center">
    <div class="w-20 h-24 mb-4">
      <img aira-hidden="true" class="w-20 h-24" src="/logo-gris.min.svg">
    </div>
  </div>
</div>
</template>

<script>
import AccountSelector from './AccountSelector.vue';
import SidebarOptions from './SidebarOptions.vue';

export default {
  props: {
    refreshDashboard: Function,
  },
  components: {
    AccountSelector,
    SidebarOptions,
  },
};
</script>
