



























































import Vue from 'vue';
import IconChat from '@/components/icons/IconChat.vue';
import { propOfType } from '@/services/utils';
import msg from '@/services/userMsg';
import SectionLayout from '../SectionLayout.vue';
import { apply } from '../../indexForm.vue';
import { Metadata } from '../../../CreateWeb.vue';

const NO_LUGGAGE = 'ninguno';
const SMALL_LUGGAGE = 'pequeño';
const MEDIUM_LUGGAGE = 'mediano';
const BIG_LUGGAGE = 'grande';
const MAX_LEN_HEADER = 50;

export default Vue.extend({
  components: {
    SectionLayout,
    IconChat,
  },
  props: {
    isExpanded: Boolean,
    extras: Object,
    showEquipaje: Boolean,
    needDatosDeArribo: Boolean,
    obs: propOfType<string>(''),
    isEnvio: propOfType<Boolean>(false),
    userTriedToConfirm: Boolean,
    metadata: propOfType<Metadata>(),
  },
  mounted() {
    if (this.obs) {
      this.message = this.obs;
    }
  },
  data() {
    return {
      selectedLuggageOption: NO_LUGGAGE,
      message: '',
      luggagesOptions: [
        NO_LUGGAGE,
        SMALL_LUGGAGE,
        MEDIUM_LUGGAGE,
        BIG_LUGGAGE,
      ],
      datosDeArribo: '',
    };
  },
  watch: {
    isExpanded(val) {
      if (val) {
        this.requestFocusOnDatosDeArribo();
      }
    },
    datosDeArribo(val) {
      apply(this, (data) => {
        if ('datosDeArribo' in data) {
          data.datosDeArribo = val;
        }
      });
    },
    message(val) {
      apply(this, (data) => {
        data.observacion = val;
        if (this.needDatosDeArribo && 'datosDeArribo' in data) {
          this.datosDeArribo = val;
          data.datosDeArribo = val;
        }
      });
    },
  },
  computed: {
    headerText(): string {
      if (this.isExpanded) {
        return '';
      }
      const strings = [] as string[];
      if (this.selectedLuggageOption !== NO_LUGGAGE) {
        strings.push(`Equipaje ${this.selectedLuggageOption}`);
      }
      if (this.message !== '') {
        strings.push(this.message);
      }
      let r = strings.join(', ');
      if (r.length > MAX_LEN_HEADER) {
        r = r.substr(0, MAX_LEN_HEADER);
        r += '...';
      }
      return strings.join(', ');
    },
    placeholderText(): string {
      if (this.isEnvio) {
        return 'Detalle el envío a realizar (ej: 5 cajas obsequio)';
      }
      return 'Ingresa datos valiosos para el transportista (ej: detalle de equipajes, datos de arribo en aeropuertos...)';
    },
    error() {
      if (!this.userTriedToConfirm) return '';
      if (this.isEnvio && !this.message) return 'Complete detalles del envío';
      if (this.needDatosDeArribo && this.datosDeArribo === '') return 'Complete datos de arribo';
      if (this.message.length > 250) return msg.getError('desc_invalid');
      return '';
    },
  },
  methods: {
    requestFocusOnDatosDeArribo() {
      const el = this.$refs.datosDeArribo as HTMLInputElement;
      Vue.nextTick(() => {
        el.focus();
      });
    },
  },
});

