






import { propOfType } from '@/services/utils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    class_: propOfType<string>('h-5 w-5'),
  },
});
