









































































import Vue from 'vue';
import {
  Evento,
  Direccion,
  EventType,
  fromIndexParadaToLabel,
  SeguimientoData,
} from '@/models/requests-and-responses/seguimiento';
import {
  propOfType,
  propOfTypeArrayOf,
  formatTime,
  capitalizeText,
  isToday,
  getDayAndMonth,
} from '@/services/utils';
import { get } from '@/services/http';
import TimelineSegment, {
  TimelineSegmentModel,
} from '@/modules/tracking/components/TimelineSegment.vue';
import Pedido from '@/models/Pedido';
import ViajeExtras from '@/models/taaxii.com/ViajeExtras';
import SolicitudExtras from '@/models/taaxii.com/SolicitudExtras';
import SwitchRecorrido from './SwitchRecorrido.vue';

// eslint-disable-next-line max-len
function fromEventoToSegment(
  evento: Evento | VirtualEvent,
  paradas: Direccion[],
): TimelineSegmentModel {
  const paradaIndex = evento.parada;
  const { type } = evento;
  return {
    rowType: type,
    paradaLabel:
      paradaIndex !== undefined
        ? fromIndexParadaToLabel(paradaIndex, paradas.length)
        : undefined,
    direccion: paradaIndex !== undefined ? paradas[paradaIndex] : undefined,
    futurePresentOrPast: 'timestamp' in evento ? 'past' : 'future',
    timestamp: 'timestamp' in evento ? new Date(evento.timestamp) : undefined,
  };
}

type VirtualEvent = {
  type: EventType;
  parada?: number; // Indice a parada
};

export default Vue.extend({
  components: {
    TimelineSegment,
    SwitchRecorrido,
  },
  props: {
    pedido: propOfType<Pedido>(),
    paradas: propOfTypeArrayOf<Direccion>(),
    showSwitch: propOfType<boolean>(false),
    isAnulado: propOfType<boolean>(false),
    isCalificado: propOfType<boolean>(false),
    isCanceladoFDT: propOfType<boolean>(false),
    isCanceladoFallo: propOfType<boolean>(false),
    calificationTimestamp: propOfType<string | null>(null),
    anuladoTimestamp: propOfType<Date>(null),
    canceladoTimestamp: propOfType<Date>(null),
  },
  data() {
    return {
      segmentHovered: null as number | null,
      eventos: [] as Evento[],
      seguimientoData: null as null | SeguimientoData,
      eventosReady: false,
      recorridoReal: false,
      tempDisable: false,
    };
  },
  mounted() {
    this.fetchEventos();
  },
  methods: {
    switchRecorrido(value: boolean) {
      this.$emit('switchRecorrido', value);
    },
    formatDirection(direccion: string): string {
      return capitalizeText(direccion)
        .split(',', 3)
        .join(',');
    },
    selectSegment(index: null | number) {
      if (index !== null && this.checkIfSegmentIsPast(index)) {
        this.segmentHovered = index;

        this.$logEvent('seguimiento', {
          accion_seguimiento: 'hover_linea_de_tiempo',
        });
      } else {
        this.segmentHovered = null;
      }
    },
    checkIfSegmentIsPast(index: number): boolean {
      return this.segments[index].futurePresentOrPast === 'past';
    },
    fetchEventos(): void {
      if (this.isAnulado || !this.isIntegrated) {
        this.eventosReady = true;
        return;
      }
      get('/pedidos/seguimiento/$pedidoPublicId/eventos', {
        pedidoPublicId: this.pedido.idPublic,
      })
        .then(({ data }) => {
          this.eventos = data;
          this.eventosReady = true;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          this.eventos = [];
          this.eventosReady = true;
        });
    },
  },
  computed: {
    extras(): ViajeExtras | SolicitudExtras {
      return this.pedido.extras;
    },
    isIntegrated(): boolean {
      if (this.extras.type === 's') {
        return true;
      }

      return Boolean(this.extras.agencia?.integrado);
    },
    fetchFinished(): boolean {
      return this.eventosReady;
    },
    // Devuelve todos los eventos que se supone que deben existir.
    virtualEvents(): VirtualEvent[] {
      const virtualEvents = [] as VirtualEvent[];
      virtualEvents.push({ type: 'registrado' });
      virtualEvents.push({ type: 'confirmado' });

      if (this.isIntegrated) {
        this.paradas.forEach((_, index) => {
          virtualEvents.push({ type: 'circulando', parada: index });
          virtualEvents.push({ type: 'arribado', parada: index });
        });
      } else {
        virtualEvents.push({ type: 'finalizado' });
        virtualEvents.push({ type: 'calificado' });
      }

      return virtualEvents;
    },
    segments(): TimelineSegmentModel[] {
      if (!this.isIntegrated) {
        return this.notIntegratedSegments;
      }

      const segments: TimelineSegmentModel[] = [];

      // Primero creamos las rows que ya pasaron.
      this.virtualEvents.forEach((event) => {
        const realEvent = this.eventos.find(
          (e) => e.type === event.type && e.parada === event.parada,
        );
        if (realEvent && realEvent.type !== 'finalizado') {
          segments.push(fromEventoToSegment(realEvent, this.paradas));
        }
      });

      // Añadimos las rows del futuro, que son todas aquellos eventos que no han pasado.
      this.virtualEvents.forEach((e) => {
        const happened = this.eventos.some(
          (e2) => e2.type === e.type && e2.parada === e.parada,
        );
        if (!happened) {
          segments.push(fromEventoToSegment(e, this.paradas));
        }
      });

      const lastIndex = segments
        .slice()
        .reverse()
        .findIndex((segment) => segment.timestamp !== undefined);
      if (lastIndex !== -1) {
        const lastNonNullTimestampIndex = segments.length - 1 - lastIndex;
        if (this.isCalificado || this.isCanceladoFDT || this.isCanceladoFallo) {
          segments[lastNonNullTimestampIndex].futurePresentOrPast = 'past';
        } else segments[lastNonNullTimestampIndex].futurePresentOrPast = 'present';
      }
      return segments;
    },
    notIntegratedSegments(): TimelineSegmentModel[] {
      if (this.extras.type === 's') {
        return [];
      }

      const allEvents = this.virtualEvents.map((e) => ({
        rowType: e.type,
        futurePresentOrPast: 'future',
      })) as TimelineSegmentModel[];

      const segments: TimelineSegmentModel[] = [];
      if (this.extras.creado) {
        segments.push({
          rowType: 'registrado',
          futurePresentOrPast: 'past',
          timestamp: new Date(this.extras.creado),
        });
      }
      if (this.extras.confirmado) {
        segments.push({
          rowType: 'confirmado',
          futurePresentOrPast: 'past',
          timestamp: new Date(this.extras.confirmado),
        });
      }
      if (this.extras.fin) {
        segments.push({
          rowType: 'finalizado',
          futurePresentOrPast: 'past',
          timestamp: new Date(this.extras.fin),
        });
      }
      if (this.isCalificado) {
        segments.push({
          rowType: 'calificado',
          futurePresentOrPast: 'past',
          timestamp: new Date(this.calificationTimestamp),
        });
      }

      segments[segments.length - 1].futurePresentOrPast = 'present';

      return [
        ...segments,
        ...allEvents.slice(segments.length - 1, allEvents.length - 1),
      ];
    },
    hoveredSegmentData(): string {
      if (this.segmentHovered !== null) {
        const segment = this.segments[this.segmentHovered];
        const timestamp = segment.timestamp
          ? new Date(segment.timestamp)
          : new Date();
        const fecha = isToday(timestamp)
          ? ''
          : getDayAndMonth(timestamp).concat(' ');
        const direccion = segment.direccion
          ? segment.direccion.alias || segment.direccion.direccion
          : '';
        const stringDireccion = direccion
          ? `a ${capitalizeText(this.formatDirection(direccion))}`
          : '';
        if (
          this.segmentHovered === this.segments.length - 1
          && this.isCalificado
        ) {
          return `${fecha}${formatTime(timestamp).slice(0, -2)} · Finalizado`;
        }
        return segment.timestamp
          ? `${fecha}${formatTime(timestamp).slice(0, -2)} · ${capitalizeText(
            segment.rowType,
          )} ${stringDireccion}`
          : '';
      }
      return '';
    },
    notIntegratedPresentSegment(): TimelineSegmentModel | undefined {
      return this.notIntegratedSegments
        .find((segment) => segment.futurePresentOrPast === 'present');
    },
    presentSegmentTimestamp(): string {
      let timestamp;
      const lastEvent = this.eventos[this.eventos.length - 1];
      if (this.eventos.length !== 0) timestamp = new Date(lastEvent.timestamp);

      if (!this.isIntegrated) {
        timestamp = this.notIntegratedPresentSegment?.timestamp;
      } else if (this.isCanceladoFDT || this.isCanceladoFallo) {
        timestamp = lastEvent.type === 'cancelado' || lastEvent.type === 'fallo'
          ? new Date(lastEvent.timestamp)
          : this.canceladoTimestamp;
      } else if (this.isCalificado) {
        timestamp = new Date(this.calificationTimestamp);
      } else if (this.isAnulado) {
        timestamp = new Date(this.anuladoTimestamp);
      }

      const fecha = isToday(timestamp)
        ? ''
        : getDayAndMonth(timestamp).concat(' ');
      return timestamp !== null
        ? `${fecha}${formatTime(timestamp).slice(0, -2)}`
        : '-';
    },
    presentSegmentStatus(): string {
      if (!this.isIntegrated) return capitalizeText(this.notIntegratedPresentSegment?.rowType || '-');
      if (this.isCanceladoFDT) return 'Finalizado';
      if (this.isCanceladoFallo) return 'Finalizado';
      if (this.isCalificado) return 'Calificado';
      if (this.isAnulado) return 'Anulado';
      const lastEvent = this.eventos[this.eventos.length - 1];
      const paradaIndex = lastEvent.parada as number;
      const label = fromIndexParadaToLabel(paradaIndex, this.paradas.length);
      switch (lastEvent?.type) {
        case 'circulando':
          return `En camino a ${label}`;
        case 'arribado':
          return paradaIndex === this.paradas.length - 1
            ? 'Finalizado'
            : `Arribado en ${label}`;
        default:
          return capitalizeText(lastEvent.type);
      }
    },
    presentSegmentDirection(): string {
      if (
        this.isCalificado
        || this.isAnulado
        || this.isEnded
        || !this.isIntegrated
      ) return '';
      const lastEvent = this.eventos[this.eventos.length - 1];
      const paradaIndex = lastEvent.parada as number;
      const direccion = this.paradas[paradaIndex];
      if (direccion === undefined) return '';
      return capitalizeText(
        this.formatDirection(direccion.alias || direccion.direccion),
      );
    },
    motivoDeCancelacion(): string {
      if (this.isCanceladoFDT) return 'por cancelación fuera de término';
      if (this.isCanceladoFallo) return 'por fallo';
      return '';
    },
    isEnded(): boolean {
      return this.presentSegmentStatus === 'Finalizado';
    },
    isWaiting(): boolean {
      const lastEvent = this.eventos[this.eventos.length - 1];
      return (
        lastEvent?.type === 'arribado'
        && this.presentSegmentStatus !== 'Finalizado'
      );
    },
    isEnCamino(): boolean {
      const lastEvent = this.eventos[this.eventos.length - 1];
      return lastEvent?.type === 'circulando';
    },
    hayElipsis(): boolean {
      const charCount = this.presentSegmentTimestamp.length
        + this.presentSegmentStatus.length
        + this.motivoDeCancelacion.length
        + this.presentSegmentDirection.length;
      return charCount > 53;
    },
  },
});
