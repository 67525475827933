
import { apply } from '../../indexForm.vue';
import Person from './indexPerson.vue';

export default Person.extend({
  methods: {
    nameAndLastNameWatcher(val) { // overrides Person's
      apply(this, (data) => {
        if ('nameAndLastName' in data) {
          data.nameAndLastName = val;
        }
      });
    },
  },
  computed: {
    error() {
      if (!this.userTriedToConfirm) return '';
      if (!this.nameAndLastName || !this.phone) {
        return 'Complete el nombre y el teléfono';
      }
      if (!this.phoneIsValid) {
        return 'El teléfono tiene un formato inválido.';
      }
      return '';
    },
    autocomplete() {
      return true;
    },
    showSuggestions() {
      return false;
    },
  },
});
