






































import IconCube from '@/components/icons/IconCube.vue';
import Vue from 'vue';
import { apply } from '../../indexForm.vue';
import PackageItem, { dimensionesOf, packageSizes } from './PackageItem.vue';
import SectionLayout from '../SectionLayout.vue';

export default Vue.extend({
  components: {
    SectionLayout,
    PackageItem,
    IconCube,
  },
  data() {
    return {
      packageSizes,
      selectedPackageSize: null as null | typeof packageSizes[number],
    };
  },
  props: {
    isExpanded: Boolean,
    userTriedToConfirm: Boolean,
  },
  watch: {
    selectedPackageSize(val) {
      this.updateForm();
    },
  },
  mounted() {
    this.updateForm();
  },
  computed: {
    error() {
      if (!this.userTriedToConfirm) return '';
      if (!this.selectedPackageSize) return 'Seleccione un tamaño de paquete';
      return '';
    },
  },
  methods: {
    updateForm() {
      const { selectedPackageSize } = this;
      if (selectedPackageSize) {
        apply(this, (data) => {
          if (data.type === 'PAQUETERIA') {
            [data.alto, data.largo, data.ancho, data.peso] = dimensionesOf(selectedPackageSize);
          }
        });
      }
    },
  },
});
