<template>
  <div class="flex flex-col space-y-0.5 w-full flex-grow">
    <span class="text-base text-white font-regular text-center">Olvidé mi contraseña</span>
    <BaseButton
      :class="'btn-accent-secondary font-medium bg-dark-500'"
      :buttonClass="'uppercase border text-white'"
      :label="'Recuperar'"
      @click.native="onNavigate('recoverPassword')"
    />
  </div>
</template>

<script>
export default {
  methods: {
    onNavigate(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>
