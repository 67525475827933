import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import actions from './actions';
import { initialState } from './state';
import mutations from './mutations';
import getters from './getters';
import notifications from './notifications';

Vue.use(Vuex);

export const rootModule = {
  modules: { notifications },
  state: initialState(),
  mutations,
  actions,
  getters,
};

const store = new Vuex.Store(rootModule);

export default store;
