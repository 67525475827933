import { capitalizeFirstLetter } from '@/services/utils';
import SolicitudExtras from './taaxii.com/SolicitudExtras';
import ViajeExtras from './taaxii.com/ViajeExtras';

export function fromPedidoStringToUIName(type: string | number) {
  switch (type) {
    case 'trip': return 'Viaje';
    case 'package':
    case 'mensajeria':
    case 'paquete':
    case 'bulto':
    case 'mensajeria,paquete,bulto':
      return 'Envío';
    default: return capitalizeFirstLetter(type);
  }
}

export type HeaderData = {
  pedidoId: number;
  cliente: string;
  servicio: string;
  fechaHoraSalida: Date;
}

export type PedidoLink = {
  rel: string;
  href: string;
}

type PedidoStatus = 'registrado' | 'confirmado' | 'calificado' | 'anulado' | 'finalizado';

class Pedido {
  id: number;

  idPublic: string;

  status: PedidoStatus;

  substatus: string;

  service: string;

  datetime: Date;

  created: Date;

  modified: Date;

  type: 'trip' | 'package'; // 'PASAJERO' | 'MENSAJERÍA' | 'PAQUETE';

  resources: PedidoLink[];

  actions: PedidoLink[];

  extras: ViajeExtras | SolicitudExtras;

  constructor(data) {
    this.id = data.id;
    this.idPublic = data.idPublic;
    this.status = data.status;
    this.substatus = data.substatus;
    this.service = data.service;
    this.type = data.type.toLocaleLowerCase();
    this.datetime = new Date(data.datetime);
    this.created = new Date(data.created);
    this.modified = new Date(data.modified);
    this.resources = data.resources;
    this.actions = data.actions;

    switch (data.service) {
      /* case 'taaxii.com':
        this.extras = new TaaxiiExtras(data.extras);
        break; */
      case 'InPunto':
        if (data.extras.type === 'v') {
          this.extras = new ViajeExtras(data.extras);
        } else {
          this.extras = new SolicitudExtras(data.extras);
        }
        break;
      default:
        this.extras = data.extras;
        break;
    }
  }
}

export default Pedido;
