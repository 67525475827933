











































































import Vue from 'vue';
import msg from '@/services/userMsg';
import {
  Evento, fromIndexParadaToLabel, SeguimientoData, CarActualPosition, TrackerPosition,
  Direccion, PassengerModel,
} from '@/models/requests-and-responses/seguimiento';
import {
  propOfType, propOfTypeArrayOf, getPassengersList, capitalizeText,
  toTwoDecimals, formatStringNumber, copyStringToClipboard,
} from '@/services/utils';
import IconAlert from '@/components/icons/IconAlert.vue';
import DriverDesktop from './components/DriverDesktop.vue';
import Map, { AddressForMap } from './Map.vue';
import shareTrackingButton from './components/shareTrackingButton.vue';
import horizontalTimeline from './components/horizontalTimeline.vue';
import Recorrido from './components/Recorrido.vue';
import PassengersList from './components/PassengersList.vue';
import TrackingSupportButton from './components/TrackingSupportButton.vue';
import ErrorModal from './components/ErrorModal.vue';

function fromStatusToLabel(status: string) {
  switch (status) {
    case 'iniciado': return 'en curso';
    default: return status;
  }
}

const url = window.location.href;

export default Vue.extend({
  components: {
    Map,
    shareTrackingButton,
    horizontalTimeline,
    Recorrido,
    DriverDesktop,
    PassengersList,
    TrackingSupportButton,
    IconAlert,
    ErrorModal,
  },
  props: {
    seguimientoData: propOfType<SeguimientoData>(),
    eventos: propOfTypeArrayOf<Evento>(),
    paradas: propOfTypeArrayOf<Direccion>(),
    trackerPositions: propOfTypeArrayOf<TrackerPosition>(),
    carActualPosition: propOfType<CarActualPosition>(null),
    minutesLeft: propOfType<number | null>(null),
    isRecorridoReal: propOfType<boolean>(false),
    error: propOfType<string | null>(null),
  },
  data() {
    return {
      errorModalOpen: !!this.error,
    };
  },
  methods: {
    closeModal() {
      this.errorModalOpen = false;
    },
    copy(str: string) {
      copyStringToClipboard(str)
        .then(() => this.$toast.success(msg.getSuccess('string_copied')));
    },
  },
  computed: {
    seguimientoType(): string {
      const pasajes = Object.values(this.seguimientoData.pasajes);
      if (pasajes.some((pasaje) => pasaje.tipo === 'PASAJERO')) {
        return 'Viaje';
      } return 'Envío';
    },
    statusLabel(): string {
      return fromStatusToLabel(this.seguimientoData.status);
    },
    viajeDateTime(): string {
      const date = new Date(this.seguimientoData.viaje.inicio);

      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      const monthNames = [
        'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic',
      ];
      const monthName = monthNames[date.getMonth()];

      return `${day} ${monthName} ${hours}:${minutes} hs`;
    },
    passengers(): PassengerModel[] {
      return getPassengersList(this.seguimientoData);
    },
    addressesOfMap(): AddressForMap[] {
      return this.paradas.map((direccion, index) => ({
        lat: direccion.lat,
        lon: direccion.lon,
        tag: fromIndexParadaToLabel(index, this.seguimientoData.paradas.length),
      }));
    },
    distanciaCotizada(): string {
      return formatStringNumber(toTwoDecimals(this.seguimientoData.viaje.distanciaCotizada));
    },
    recorridoLabel(): string {
      return `Recorrido cotizado • ${this.distanciaCotizada} km`;
    },
    solicitud(): number {
      return Object.values(this.seguimientoData.pasajes)[0].idSolicitud;
    },
    cliente(): string {
      return capitalizeText(this.seguimientoData.viaje.cliente.alias);
    },
    servicio(): string {
      return capitalizeText(this.seguimientoData.viaje.vehiculo?.tipoVehiculo.alias as string);
    },
  },
});
