



















import { propOfType } from '@/services/utils';
import Vue from 'vue';
import CheckboxInput from './CheckboxInput.vue';

export default Vue.extend({
  components: { CheckboxInput },
  props: {
    id: propOfType<string>(String(new Date().getMilliseconds() + Math.random())),
    value: propOfType<boolean>(false),
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
  },
});
