











































import Vue from 'vue';
import IconChevronL from '@/components/icons/IconChevronL.vue';
import IconChevronR from '@/components/icons/IconChevronR.vue';
import IconCalendar from '@/components/icons/IconCalendar.vue';
import BaseDropdownMenu from '@/components/BaseDropdownMenu.vue';

// Obtener la fecha de hoy
const today = new Date();
today.setHours(0, 0, 0, 0);
// Obtener el primer y último día de la semana actual
const firstDay = new Date(today);
firstDay.setDate(today.getDate() - today.getDay()); // Restar los días ya pasados en la semana
const lastDay = new Date(today);
lastDay.setDate(today.getDate() - today.getDay() + 6); // Sumar los días restantes en la semana

// Obtener el primer y último día de la próxima semana
const nextWeekFirstDay = new Date(firstDay);
nextWeekFirstDay.setDate(firstDay.getDate() + 7);
const nextWeekLastDay = new Date(lastDay);
nextWeekLastDay.setDate(lastDay.getDate() + 7);

// Obtener el primer y último día del mes actual
const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

// Obtener el primer y último día del próximo mes
const firstDayOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
const lastDayOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);

// Obtener el primer y último día del año actual
const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
const lastDayOfYear = new Date(today.getFullYear(), 11, 31);

export default Vue.extend({
  components: {
    IconChevronL,
    IconChevronR,
    IconCalendar,
    BaseDropdownMenu,
  },
  props: {
    isFirst: Boolean,
    isLast: Boolean,
    startingRange: { type: String, default: 'Semana actual' },
  },
  mounted() {
    const { since, until } = this.$route.query;
    // Si no hay since o until en el router, verificar el rango e inicializarlo
    if ((!since || !until) && Object.keys(this.defaultRanges).includes(this.startingRange)) {
      const range = this.defaultRanges[this.startingRange];
      this.dateRange = {
        startDate: range[0],
        endDate: range[1],
      };
    } else {
      const startDate = new Date(`${since}T12:00`);
      const endDate = new Date(`${until}T12:00`);

      this.dateRange = {
        startDate,
        endDate,
      };
    }

    this.onUpdate(this.dateRange);
  },
  data() {
    return {
      isOpen: false,
      dateRange: { startDate: new Date(firstDay), endDate: new Date(lastDay) },
      defaultRanges: {
        Hoy: [today, today],
        'Semana actual': [firstDay, lastDay],
        'Semana próxima': [nextWeekFirstDay, nextWeekLastDay],
        'Mes actual': [firstDayOfMonth, lastDayOfMonth],
        'Mes próximo': [firstDayOfNextMonth, lastDayOfNextMonth],
        'Año actual': [firstDayOfYear, lastDayOfYear],
      },
    };
  },
  methods: {
    isDefaultRange(range) {
      return Object.values(this.defaultRanges).some((defaultRange) => {
        const [start, end] = defaultRange;
        return this.isSameDay(start, range.startDate) && this.isSameDay(end, range.endDate);
      });
    },
    setRange(range) {
      [this.dateRange.startDate, this.dateRange.endDate] = range;
      this.onUpdate(this.dateRange);
      this.isOpen = false;
    },
    onLoadMore(direction) {
      const start = this.dateRange.startDate;
      const end = this.dateRange.endDate;

      if (this.isWeekly(start, end)) {
        // Siguiente o anterior semana
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const daysToAdjust = 7 * direction;
        this.dateRange = {
          startDate: new Date(start.getTime() + daysToAdjust * oneDay),
          endDate: new Date(end.getTime() + daysToAdjust * oneDay),
        };
      } else if (this.isSameDay(start, end)) {
        // Siguiente o anterior día
        this.dateRange = {
          startDate: new Date(start.getFullYear(), start.getMonth(), start.getDate() + direction),
          endDate: new Date(end.getFullYear(), end.getMonth(), end.getDate() + direction),
        };
      } else if (this.isMonthly(start, end)) {
        // Siguiente o anterior mes
        this.dateRange = {
          startDate: new Date(start.getFullYear(), start.getMonth() + direction, 1),
          endDate: new Date(end.getFullYear(), end.getMonth() + 1 + direction, 0),
        };
      } else if (this.isYearly(start, end)) {
        // Siguiente o anterior año
        this.dateRange = {
          startDate: new Date(start.getFullYear() + direction, 0, 1),
          endDate: new Date(end.getFullYear() + direction, 11, 31),
        };
      }
      this.onUpdate(this.dateRange);
    },
    parseRange(range) {
      const start = range.startDate;
      const end = range.endDate;

      if (this.isDefaultRange(range)) {
        return Object.keys(this.defaultRanges).find((key) => {
          const [defaultStart, defaultEnd] = this.defaultRanges[key];
          return this.isSameDay(defaultStart, start) && this.isSameDay(defaultEnd, end);
        });
      }

      if (this.isYearly(start, end)) {
        return start.toLocaleString(
          'es-AR',
          { year: 'numeric' },
        );
      }
      if (this.isMonthly(start, end)) {
        return start.toLocaleString(
          'es-AR',
          { year: 'numeric', month: 'long' },
        );
      }

      if (this.isSameDay(start, end)) {
        return start.toLocaleString(
          'es-AR',
          { year: 'numeric', month: 'numeric', day: 'numeric' },
        );
      }

      return `${start.toLocaleString(
        'es-AR',
        { year: 'numeric', month: 'numeric', day: 'numeric' },
      )} - ${end.toLocaleString(
        'es-AR',
        { year: 'numeric', month: 'numeric', day: 'numeric' },
      )}`;
    },
    isSameDay(start: Date, end: Date) {
      return start.getDate() === end.getDate()
            && start.getMonth() === end.getMonth()
            && start.getFullYear() === end.getFullYear();
    },
    isYearly(start: Date, end: Date) {
      return start.getDate() === 1 && start.getMonth() === 0
            && this.isLastDayInMonth(end) && end.getMonth() === 11
            && start.getFullYear() === end.getFullYear();
    },
    isMonthly(start: Date, end: Date) {
      return start.getDate() === 1 && this.isLastDayInMonth(end)
        && start.getMonth() === end.getMonth()
        && start.getFullYear() === end.getFullYear();
    },
    isWeekly(start: Date, end: Date) {
      return this.diffDaysBetween(start, end) === 7;
    },
    isLastDayInMonth(date: Date) {
      // https://stackoverflow.com/a/6355083
      return new Date(date.getTime() + 86400000).getDate() === 1;
    },
    diffDaysBetween(start: Date, end: Date) {
      // https://stackoverflow.com/a/2627493
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const diffDays = Math.round(Math.abs((start.getTime() - end.getTime()) / oneDay)) + 1;
      return diffDays;
    },
    onUpdate(picked) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          // Sólo la parte de la fecha en ISO
          since: picked.startDate.toISOString().split('T')[0],
          until: picked.endDate.toISOString().split('T')[0],
        },
      }).catch(() => {});
    },
  },
  computed: {
    locale() {
      return {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
        monthNames: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        firstDay: 0,
      };
    },
  },
});
