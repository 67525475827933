
import { VueConstructor } from 'vue';
import { ServicioDisponible } from '@/models/taaxii.com/taaxiiServicioDisponible';
import { post } from '@/services/http';
import msg from '@/services/userMsg';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import { getIdPersona } from '@/models/accounts/GeneralAccount';
import {
  SolicitudesMensajeReq,
  SolicitudSuccessfullyBundle,
} from '@/models/requests-and-responses/sgv';
import { validators } from '@/services/utils';
import { PersonWithCC } from '@/models/Person';
import Shared, {
  AddressOfForm,
  faltanDatosDeArribo,
  necesitaDatosDeArribo,
  Section,
} from '../indexForm.vue';

import ServiceSection from '../sections/service/indexService.vue';
import ExtraSection from '../sections/extra/indexExtra.vue';
import WhenSection from '../sections/when/indexWhen.vue';
import OriginSection from '../sections/address/Origin.vue';
import DestinationSection from '../sections/address/Destination.vue';
import ReceiverSection from '../sections/person/Receiver.vue';
import SenderSection from '../sections/person/Sender.vue';
import CecoSection from '../sections/ceco/CecoOfPerson.vue';

export function data() {
  return {
    type: 'MENSAJERIA' as const, // Utilizado para conocer el tipo de la data.
    addresses: [] as AddressOfForm[],
    service: null as ServicioDisponible | null,
    date: null as Date | null, // null = Lo antes posible.
    dateRegreso: null as Date | null,
    finalDataResponse: null as SolicitudSuccessfullyBundle | null,
    finalDataResponseRegreso: null as SolicitudSuccessfullyBundle | null,
    days: [] as number[],
    daysRegreso: [] as number[],
    typeOfTrip: 0 as number,
    paxError: false as boolean,
    dateError: false as boolean,
    userTriedToConfirm: false,
    receiverNameAndLastName: '',
    receiverPhone: '',
    dispatcher: null as PersonWithCC | null,
    dispatcherNameAndLastName: '',
    dispatcherPhone: '',
    idCeCo: null as number | null,
    observacion: '',
  };
}

export default Shared.extend({
  components: {
    SenderSection,
    ReceiverSection,
    OriginSection,
    DestinationSection,
    WhenSection,
    ExtraSection,
    CecoSection,
    ServiceSection,
  },
  data,
  computed: {
    buttonText() {
      const d = this.date;
      if (!d) {
        return 'Pedir';
      }
      // TO-DO: Poner reservar para mañana a las blabla
      return 'Reservar';
    },
    price(): string | null {
      const { service } = this;
      if (!service) return null;
      return service.precotizacion > 0
        ? `$${service.precotizacion}`
        : 'Sin cotización';
    },
    sections(): Section[] {
      return [
        { component: SenderSection, steps: ['sender1', 'sender2'] },
        { component: ReceiverSection, steps: ['receiver'] },
        { component: OriginSection, steps: ['origin1', 'origin2'] },
        { component: DestinationSection, steps: ['destination'] },
        { component: WhenSection, steps: ['when3'] },
        { component: ExtraSection, steps: ['extraEnvios'] },
        { component: CecoSection, steps: ['ceco'] },
        { component: ServiceSection, steps: ['service1', 'service2'] },
      ];
    },
  },
  watch: {
    addresses(val) {
      this.setMapAddresses(val);
    },
  },
  methods: {
    onConfirm() {
      this.expandedSection = -1;
      this.userTriedToConfirm = true;

      if (this.checkIfLAP() || this.reservating) {
        return;
      }

      const [origin, destination] = this.addresses;

      if (!origin || !destination) {
        return;
      }

      if (!this.service) {
        return;
      }

      if (this.dispatcherNameAndLastName === '' || this.receiverNameAndLastName === '') {
        return;
      }

      if (!validators.isValidPhone(this.receiverPhone)
      || !validators.isValidPhone(this.dispatcherPhone)) {
        return;
      }

      if (!this.idCeCo || !this.selectedAccount) {
        // Nunca debería llegar a este punto.
        this.error = 'Error fatal.';
        return;
      }

      if (faltanDatosDeArribo(this, false)
          || this.observacion.length > 250) {
        return;
      }

      if (!this.observacion) {
        return;
      }

      if (this.dateError || this.paxError) {
        return;
      }

      const req: SolicitudesMensajeReq = {
        idTipoVehiculo: this.service.idTipoVehiculo,
        idPersonaSolicitante: getIdPersona(this.selectedAccount),
        idCeCoSolicitante: this.idCeCo,
        origen: {
          direccion: origin.street,
          lat: origin.lat,
          lon: origin.lon,
        },
        destino: {
          direccion: destination.street,
          lat: destination.lat,
          lon: destination.lon,
        },
        fechaHoraSalida: this.date ? this.date : undefined,
        receptorNombre: this.receiverNameAndLastName,
        receptorCelular: this.receiverPhone,
        despachanteNombre: this.dispatcherNameAndLastName,
        despachanteCelular: this.dispatcherPhone,
        observacion: this.observacion,
      };

      this.reservating = true;
      this.error = '';
      post('$sgv/rest/solicitudes/mensajes', req)
        .then((res) => {
          this.finalDataResponse = res.data;
          this.exit(this.$data);
        })
        .catch((error) => {
          this.error = msg.getError(errorCodeFromAxiosError(error));
        })
        .finally(() => {
          this.reservating = false;
        });
    },
    propsOf(section: VueConstructor) {
      switch (section) {
        case OriginSection:
          return { addressIndex: 0 };
        case DestinationSection:
          return { addressIndex: 1 };
        case ExtraSection:
          return {
            needDatosDeArribo: necesitaDatosDeArribo(this.addresses, false),
            isEnvio: true,
            obs: this.observacion,
          };
        case ServiceSection:
          return {
            addresses: this.addresses,
            date: this.date,
            idCliente: this.idCliente,
            isLoAntesPosible: this.date === null,
            service: this.service,
          };
        case CecoSection:
          return {
            idCeco: this.idCeCo,
            person: this.dispatcher,
            hasSender: true,
          };
        default:
          return {};
      }
    },
  },
});
