
















import Vue from 'vue';
import logoBlanco from '@/assets/logo-blanco.svg';
import NoPhone from '@/components/no-phone/NoPhone.vue';

export default Vue.extend({
  components: {
    NoPhone,
  },
  data() {
    return {
      inLogoSvgPath: logoBlanco,
    };
  },
  computed: {
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
  },
  name: 'Auth',
});
