<template>
  <button class="bg-white w-54 rounded flex flex-col
  items-left px-4 py-2 border border-gray-200"
  @mouseover="hover = true"
  @mouseleave="hover = false"
  >
    <p class="">
      <BaseCheckBox :checked="selected" :hover="hover">
        <span>{{name}}</span>
      </BaseCheckBox>
    <p class="subtitle">{{description}}</p>
  </button>
</template>

<script>
export default {
  components: {
  },
  props: {
    selected: Boolean,
    name: String,
    description: String,
  },
  data() {
    return {
      hover: false,
    };
  },
  methods: {
  },
};
</script>
