<template>
  <!-- Chip que se puede cerrar -->
  <div class="inline-flex items-center rounded-full bg-frame-normal p-1">
    <span class="text-black select-none"
      :class="smallChip ? 'text-xs px-0.5' : 'text-sm px-2'">
      {{ label }}
    </span>
    <IconCancel v-if="showXButton"
      @click.native="$emit('onClose')"
      width="24px"
      height="24px"
      class="cursor-pointer text-frame-dark-100"
      />
  </div>
</template>

<script>
import Vue from 'vue';
import IconCancel from '@/components/icons/IconCancel.vue';

export default Vue.extend({
  components: {
    IconCancel,
  },
  props: {
    label: { type: String, default: '' },
    showXButton: { type: Boolean, default: true },
    smallChip: { type: Boolean, default: false },
  },
});
</script>
