

















import Vue from 'vue';
import BottomsheetAndMap from '@/modules/site/components/BottomsheetAndMap.vue';
import CreateWeb, { AddressOfMap } from './CreateWeb.vue';

export default Vue.extend({
  name: 'CreateMobile',
  components: {
    BottomsheetAndMap,
    CreateWeb,
  },
  data() {
    return {
      timeChangedCallback: Function,
      distanceChangedCallback: Function,
      addressesOfMap: [] as AddressOfMap[],
      isFormSelected: false,
    };
  },
});
