
















































































import Vue from 'vue';
import IconChevronR20 from '@/components/icons/IconChevronR20.vue';
import IconChevronL20 from '@/components/icons/IconChevronL20.vue';
import { propOfType } from '@/services/utils';

const MAX_PAGE_BUTTONS = 10;

export default Vue.extend({
  components: { IconChevronR20, IconChevronL20 },
  props: {
    currentPage: propOfType<number>(0),
    amountOfPages: propOfType<number>(0),
    onCurrentPageChange: propOfType<Function>(),
  },
  computed: {
    minPageNumber(): number {
      return Math.max(0, this.currentPage - MAX_PAGE_BUTTONS / 2);
    },
    maxPageNumber(): number {
      return Math.min(this.amountOfPages, this.minPageNumber + MAX_PAGE_BUTTONS);
    },
    pageNumbers(): Array<number> {
      return Array.from({ length: this.maxPageNumber - this.minPageNumber })
        .map((_, i) => this.minPageNumber + i);
    },
  },
  methods: {
    clickedAnterior() {
      this.onCurrentPageChange(Math.max(0, this.currentPage - 1));
    },
    clickedSiguiente() {
      this.onCurrentPageChange(Math.min(this.amountOfPages - 1, this.currentPage + 1));
    },
    clickedEllipsis() {
      // eslint-disable-next-line no-alert
      const newPageNumber = Number(prompt('Ir a la página:')) - 1;

      if (!Number.isNaN(newPageNumber)) {
        this.clickedPageNumber(newPageNumber);
      } else {
        this.$toast.error('El número de página ingresado no es correcto');
      }
    },
    clickedPageNumber(newPageNumber: number) {
      this.onCurrentPageChange(Math.max(0, Math.min(newPageNumber, this.amountOfPages - 1)));
    },
  },
});
