import * as msg from './userTexts/toastMsg.json';
import * as text from './userTexts/labels.json';

export default {
  getError: (code) => msg.error[code] || msg.error.unknown,
  getSuccess: (code) => msg.success[code] || msg.success.general,
  getInfo: (code) => msg.info[code] || msg.info.general,
  getLabel: (code) => text.input[code],
  getFilter: (code) => text.filters[code],
  getHelpMessage: (code) => text.helpMessages[code] || '',
  getTitle: (code) => text.sections.titles[code],
  getDescription: (code) => text.sections.descriptions[code],
  getPermission: (code) => text.permissions[code],
  getTooltip: (code) => text.tooltip[code],
  getReports: (code) => text.reports[code],
  getModalContent: (code) => text.modalContent[code],
};
