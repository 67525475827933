

























import Vue from 'vue';
import { propOfType } from '@/services/utils';

export default Vue.extend({
  props: {
    value: propOfType<string>(''),
    disabled: propOfType<boolean>(false),
    type: propOfType<string>('text'),
    placeholder: propOfType<string>(''),
    inputId: propOfType<string>(String(new Date().getMilliseconds() + Math.random())),
    validationError: propOfType<string | null>(null),
    domain: { type: String, default: '' },
    dark: { type: Boolean, default: false },
  },
  methods: {
    onInput(value: string) {
      this.$emit('input', value);
    },
  },
});
