









































import { Person } from '@/models/Person';
import { defaultProfilePictureUri } from '@/models/User';
import {
  fromPhoneToWhatsappValidPhone, propOfType, capitalizeText, copyStringToClipboard,
} from '@/services/utils';
import msg from '@/services/userMsg';
import Vue from 'vue';
import IconWhatsapp from '@/components/icons/IconWhatsapp.vue';
import IconPhone from '@/components/icons/IconPhone.vue';
import IconLoadingCircle from '@/components/icons/IconLoadingCircle.vue';
import IconDriverAvatar from '@/components/icons/IconDriverAvatar.vue';

export type Vehiculo = {
  marca: string,
  modelo: string,
  patente: string,
  tipoVehiculo: string,
}

export default Vue.extend({
  components: {
    IconWhatsapp,
    IconPhone,
    IconLoadingCircle,
    IconDriverAvatar,
  },
  props: {
    person: propOfType<Person>(null),
    vehiculo: propOfType<Vehiculo>(),
    hasDriver: propOfType<boolean>(),
  },
  methods: {
    capitalizeText,
    copy(str: string) {
      this.$logEvent('seguimiento', { accion_seguimiento: 'contactar_chofer_tel' });

      copyStringToClipboard(str).then(() => this.$toast.success(msg.getSuccess('phone_copied')));
    },
  },
  computed: {
    parsedPatente(): string {
      if (this.vehiculo.patente.length === 7) {
        return `${this.vehiculo.patente.slice(0, 2)} ${this.vehiculo.patente.slice(2, 5)} ${this.vehiculo.patente.slice(5, 7)}`;
      }
      return `${this.vehiculo.patente.slice(0, 3)} ${this.vehiculo.patente.slice(3, 6)}`;
    },
    profilePictureUrl(): string {
      if (!this.hasDriver) return defaultProfilePictureUri;
      return this.person?.picUrl || defaultProfilePictureUri;
    },
    name(): string {
      return capitalizeText(`${this.person?.lastName}, ${this.person?.firstName}`);
    },
    marcaModelo(): string {
      return `${this.vehiculo.marca} ${this.vehiculo.modelo}`;
    },
    phone(): string {
      return this.person?.phone || '';
    },
    hrefPhone(): string {
      return `tel:${this.phone}`;
    },
    hrefWhatsApp(): string {
      if (!this.phone) return '';
      const validPhone = fromPhoneToWhatsappValidPhone(this.phone);
      return `https://wa.me/${validPhone}`;
    },
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
    hasPicture(): boolean {
      return this.person?.picUrl !== undefined;
    },
  },
});
