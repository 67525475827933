















import Map from '@/components/map/Map.vue';
import {
  withTooltip, Marker, originMarker, destinationMarker, stopMarker, carMarker, filterMarkers,
} from '@/components/map/Marker';
import { CarActualPosition, TrackerPosition } from '@/models/requests-and-responses/seguimiento';
import { Coords } from '@/services/Coords';
import { propOfType, propOfTypeArrayOf } from '@/services/utils';
import Vue from 'vue';

export interface AddressForMap {
  lat: number
  lon: number
  tag: string
}

export default Vue.extend({
  components: {
    Map,
  },
  props: {
    addresses: propOfType<AddressForMap[]>(),
    trackerPositions: propOfTypeArrayOf<TrackerPosition>(),
    carActualPosition: propOfType<CarActualPosition>(null),
    showRoute: Boolean,
    showTrackerRoute: Boolean,
    padding: {
      type: Object,
      default: () => ({
        left: 50, top: 100, bottom: 100, right: 50,
      }),
    },
    isRecorridoReal: propOfType<boolean>(false),
    showNavigationControls: propOfType<boolean>(true),
  },
  data() {
    return {
      mapZoom: 11,
      fitMarkersOnBounds: true,
      fitRouteOnBounds: true,
    };
  },
  watch: {
    carActualPosition(val, oldVal) {
      if (val !== null && oldVal === null) {
        this.fitMarkersOnBounds = true;
        this.fitRouteOnBounds = true;
      }
    },
  },
  computed: {
    mapMarkers(): Array<{ coords: Coords, marker: Marker }> {
      const lastIndex = this.addresses.length - 1;
      const mapMarkers = this.addresses.map((address, index) => {
        let marker;
        // Si el marcador es de parada se agrega el numero de parada con indexTag
        let indexTag = '';
        if (index === 0) marker = originMarker();
        else if (index === lastIndex) marker = destinationMarker();
        else {
          indexTag = ` ${index.toString()}`;
          marker = stopMarker();
        }
        return {
          coords: address,
          marker: withTooltip(`${address.tag}${indexTag}`, marker),
        };
      });

      // Si hay una posición del chofer, la añadimos a los marcadores
      const { carActualPosition, trackerPositions } = this;
      const trackerLastPosition = trackerPositions[trackerPositions.length - 1];
      if (carActualPosition) {
        return [...mapMarkers, {
          coords: carActualPosition,
          marker: carMarker(trackerLastPosition || carActualPosition, carActualPosition),
        }];
      }

      return mapMarkers;
    },
    filteredMarkers(): Array<{ coords: Coords, marker: Marker } | undefined> {
      return filterMarkers(this.mapMarkers, true);
    },
    mapRoute(): Array<Coords> {
      if (this.showRoute) return this.addresses;
      return [];
    },
    mapTrackerRouter(): Array<Coords> {
      if (this.showTrackerRoute) return this.trackerPositions;
      return [];
    },
  },
});
