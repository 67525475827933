






















import IconExclamationWhite from '@/components/icons/IconExclamationWhite.vue';
import Vue from 'vue';

export default Vue.extend({
  props: {
    error: String,
    tooltipClass: String,
    iconClass: String,
    errorClass: String,
  },
  components: {
    IconExclamationWhite,
  },
  data() {
    return {
      showError: false,
    };
  },
});
