























































import Vue from 'vue';
import { Coords, toCoords, defaultCoords } from '@/services/Coords';
import {
  Marker, defaultMarker, newAddMarker, withTooltip,
} from '@/components/map/Marker';
import Map from '@/components/map/Map.vue';
import { eventBus } from '@/main';
import IconLocation3 from '@/components/icons/IconLocation3.vue';
import IconMyLocation from '@/components/icons/IconMyLocation.vue';
import { Address, sortAddresses } from '@/models/Address';
import CreateFloatingButton from '@/modules/site/components/CreateFloatingButton.vue';
import { corporativeAddress, favouriteAddress } from '@/services/api/addressApi';
import AddressesCard from './AddressesCard.vue';

export default Vue.extend({
  components: {
    AddressesCard,
    Map,
    IconLocation3,
    IconMyLocation,
    CreateFloatingButton,
  },
  props: {
    onlyCorporative: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedAddress: null as Coords | null,
      userLocation: null as Coords | null,
      favouriteAddresses: [] as Address[],
      corporativeAddresses: [] as Address[],
      isFetching: false,
      addressOnMap: false,
      newAddress: false,
      isZoomed: false,
      cardWidth: 340,
      cardHeight: 450,
      topOffset: '35.35%',
      leftOffset: '42.65%',
    };
  },
  watch: {
    newAddress() {
      this.selectedAddress = null;
    },
    addressOnMap() {
      this.checkMapSize();
    },
    idCliente() {
      this.fetchAddresses();
    },
  },
  created() {
    eventBus.$on('selectedAddress', (address: Coords | null) => {
      this.selectedAddress = address;
    });
    eventBus.$on('addressOnMap', (addOnMap) => {
      this.addressOnMap = addOnMap;
    });
  },
  beforeMount() {
    this.fetchAddresses();
    this.checkMapSize();
  },
  computed: {
    idCliente(): number {
      return this.$store.getters.idCliente;
    },
    allAddresses(): Address[] {
      return [
        ...this.favouriteAddresses,
        ...this.corporativeAddresses,
      ];
    },
    mapMarkers(): Array<{ coords: Coords, marker: Marker }> {
      if (this.newAddress) {
        if (!this.selectedAddress || this.addressOnMap) return [];

        return [{
          coords: this.selectedAddress,
          marker: withTooltip(
            'Nueva dirección',
            newAddMarker(),
          ),
        }];
      }

      if (this.userLocation && !this.selectedAddress) {
        return [{
          coords: this.userLocation,
          marker: withTooltip(
            'Ubicación aproximada',
            newAddMarker(),
          ),
        }];
      }

      if (this.selectedAddress) {
        const addresses = this.allAddresses.filter((address) => (
          this.selectedAddress?.lat === address.lat && this.selectedAddress?.lon === address.lon
        ))[0];

        if (addresses) {
          return [{
            coords: toCoords(addresses),
            marker: withTooltip(
              addresses.alias || '-',
              this.getMarker(addresses),
            ),
          }];
        }
      }

      return [];
    },
    mapCenter(): Coords | null {
      if (this.selectedAddress) return this.selectedAddress;
      if (this.newAddress) return null;
      if (this.userLocation) return this.userLocation;

      return defaultCoords;
    },
    mapZoom(): number {
      return this.selectedAddress ? 15 : 11;
    },
  },
  methods: {
    fetchAddresses() {
      this.isFetching = true;
      if (!this.onlyCorporative) {
        favouriteAddress.getAddress()
          .then(({ data }) => {
            this.favouriteAddresses = sortAddresses(data);
          })
          .finally(() => { this.isFetching = false; });
      }
      corporativeAddress.getAddress(this.idCliente)
        .then(({ data }) => {
          this.corporativeAddresses = sortAddresses(data);
        })
        .finally(() => { this.isFetching = false; });
    },
    cardDimensionsChanged(dimensions: { height: number, width: number }) {
      this.cardWidth = dimensions.height;
      this.cardHeight = dimensions.width;
    },
    getMarker(address: Address): Marker {
      if (this.selectedAddress) {
        const { lat, lon } = this.selectedAddress;
        if (address.lat === lat && address.lon === lon) return newAddMarker();
      }
      return defaultMarker();
    },
    centerMap() {
      this.selectedAddress = null;
      eventBus.$emit('centerMap');
    },
    checkMapSize(): void {
      // El requestAnimationFrame espera un frame de animación.
      // Queremos esperar a que Vue haga los cambios en el DOM y que se rendericen
      // antes de preguntar por el tamaño del mapa.

      requestAnimationFrame(() => {
        // Nota: es importante que sea arrow function `() => {}` en vez
        // de function expression `function() {}` porque si no quedaría `this === window`
        // y se rompe todo.
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions

        const height = (this.$refs.mapContainer as any).offsetHeight;
        const width = (this.$refs.mapContainer as any).offsetWidth;
        const pinHeight = (this.$refs.pinContainer as any).offsetHeight;
        const pinWidth = (this.$refs.pinContainer as any).offsetWidth;
        this.topOffset = `${(height / 2) - pinHeight}px`;
        this.leftOffset = `${(width / 2) - (pinWidth / 2)}px`;
      });
    },
  },
});
