




























































import Vue from 'vue';
import {
  Evento, SeguimientoData, CarActualPosition, TrackerPosition, EventType,
  Direccion,
} from '@/models/requests-and-responses/seguimiento';
import { get } from '@/services/http';
import NoPhone from '@/components/no-phone/NoPhone.vue';
import { DireccionesDistanciasReq } from '@/models/requests-and-responses/direcciones';
import { getUserProperties } from '@/services/api/firebaseApi';
import IconAlert from '@/components/icons/IconAlert.vue';
import LoadingFullscreen from '../LoadingFullscreen.vue';
import MainTrackingMobile from './MainTrackingMobile.vue';
import MainTrackingDesktop from './MainTrackingDesktop.vue';
import TrackingSupportButton from './components/TrackingSupportButton.vue';

/**
 * Esta pantalla busca información cada ciertos segundos.
 * Existen 5 endpoints. Usamos tiempos diferentes para ir a buscar los datos.
 * Porque algunos datos son más "cambiantes" que otros.
 */
const MILLSECONDS_PER_FETCH_SEGUIMIENTO = 10 * 1000;
const MILLSECONDS_PER_FETCH_EVENTOS = 7.5 * 1000;
const MILLSECONDS_PER_FETCH_TRACKER_POSITIONS = 5 * 1000;
const MILLSECONDS_PER_FETCH_CAR_ACTUAL_POSITION = 5 * 1000;
const MILLSECONDS_PER_FETCH_MINUTES_LEFT = 30 * 1000;

// En vez de 'any' esto debería ser de tipo NodeJS.Timeout, pero no sé cómo importarlo.
type NodeJSTimeout = any;

export default Vue.extend({
  name: 'Tracking',
  components: {
    LoadingFullscreen,
    MainTrackingMobile,
    MainTrackingDesktop,
    NoPhone,
    IconAlert,
    TrackingSupportButton,
  },
  data() {
    return {
      seguimientoData: null as null | SeguimientoData,
      eventos: [] as Evento[],
      paradas: [] as Direccion[],
      trackerPositions: [] as TrackerPosition[],
      carActualPosition: null as null | CarActualPosition,
      minutesLeft: null as number | null,
      intervalSeguimiento: null as NodeJSTimeout,
      intervalEventos: null as NodeJSTimeout,
      intervalTrackerPositions: null as NodeJSTimeout,
      intervalCarActualPosition: null as NodeJSTimeout,
      intervalMinutesLeft: null as NodeJSTimeout,
      eventosFetched: false,
      seguimientoDataFetched: false,
      trackersFetched: false,
      /**
       * La siguiente variable sirve para evitar que haya dos fetchs a eventos al mismo tiempo.
       */
      busyEventos: false,
      error: '',
      isRecorridoReal: false,
      fetched: false,
      finalFetch: false,
    };
  },
  computed: {
    pedidoPublicId(): string {
      return this.$route.params.pedidoPublicId;
    },
    isEndedByDriver(): boolean {
      const lastEvent = this.eventos[this.eventos.length - 1];
      if (!lastEvent) return false;
      const paradaIndex = lastEvent.parada as number;
      const ultimaParada = paradaIndex === (this.seguimientoData?.paradas.length ?? 0) - 1;
      const isArribado = lastEvent.type === 'arribado';
      return isArribado && ultimaParada;
    },
    lastEvent(): Evento | undefined {
      return this.eventos[this.eventos.length - 1];
    },
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
    allDataFetched(): boolean {
      return this.eventosFetched && this.seguimientoDataFetched && this.trackersFetched;
    },
  },
  mounted() {
    // Initial fetchs...
    this.fetchSeguimiento();
    this.fetchTrackerPositions();
    // Intervals...
    this.intervalEventos = setInterval(
      this.fetchEventos,
      MILLSECONDS_PER_FETCH_EVENTOS,
    );

    const userProperties = getUserProperties();

    this.$addUserId(userProperties.username);

    this.$addUserProperties({ ...userProperties });

    this.$logEvent('seguimiento', { accion_seguimiento: 'ingresar_seguimiento' });
  },
  methods: {
    turnOnIntervalTrackingIfItIsOff(): void {
      if (this.intervalTrackerPositions) return;
      this.fetchTrackerPositions();
      this.intervalTrackerPositions = setInterval(
        this.fetchTrackerPositions,
        MILLSECONDS_PER_FETCH_TRACKER_POSITIONS,
      );
    },
    turnOffIntervalTracking(): void {
      window.clearInterval(this.intervalTrackerPositions);
      this.intervalTrackerPositions = null;
    },
    turnOnIntervalMinutesLeftIfItIsOff(): void {
      if (this.intervalMinutesLeft) return;
      this.fetchMinutesLeft();
      this.intervalMinutesLeft = setInterval(
        this.fetchMinutesLeft,
        MILLSECONDS_PER_FETCH_MINUTES_LEFT,
      );
    },
    turnOffIntervalMinutesLeft(): void {
      window.clearInterval(this.intervalMinutesLeft);
      this.intervalMinutesLeft = null;
    },
    turnOnIntervalCarActualPositionIfItIsOff(): void {
      if (this.intervalCarActualPosition) return;
      this.fetchCarActualPosition();
      this.intervalCarActualPosition = setInterval(
        this.fetchCarActualPosition,
        MILLSECONDS_PER_FETCH_CAR_ACTUAL_POSITION,
      );
    },
    turnOffIntervalCarActualPosition(): void {
      window.clearInterval(this.intervalCarActualPosition);
      this.intervalCarActualPosition = null;
    },
    turnOnIntervalSeguimientoIfItIsOff(): void {
      if (this.intervalSeguimiento) return;
      this.fetchSeguimiento();
      this.intervalSeguimiento = setInterval(
        this.fetchSeguimiento,
        MILLSECONDS_PER_FETCH_SEGUIMIENTO,
      );
    },
    turnOffIntervalSeguimiento(): void {
      window.clearInterval(this.intervalSeguimiento);
      this.intervalSeguimiento = null;
    },
    turnOnIntervalEventosIfItIsOff(): void {
      if (this.intervalEventos) return;
      this.fetchEventos();
      this.intervalEventos = setInterval(
        this.fetchEventos,
        MILLSECONDS_PER_FETCH_EVENTOS,
      );
    },
    turnOffIntervalEventos(): void {
      window.clearInterval(this.intervalEventos);
      this.intervalEventos = null;
    },
    fetchSeguimiento(): void {
      if (this.isEndedByDriver) return;
      get('/pedidos/seguimiento/$pedidoPublicId', { pedidoPublicId: this.pedidoPublicId })
        .then((e) => {
          this.seguimientoData = e.data;
        }).catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          this.error = 'Ha ocurrido un error al recuperar datos del seguimiento.';
          this.disconnect();
        }).finally(() => {
          this.fetchEventos();
          this.seguimientoDataFetched = true;
        });
    },
    fetchEventos(): void {
      if (this.busyEventos && this.seguimientoData?.status === 'finalizado') return;
      this.busyEventos = true;
      get('/pedidos/seguimiento/$pedidoPublicId/eventos', { pedidoPublicId: this.pedidoPublicId })
        .then(({ data }) => {
          if (data.length && !data.some((e) => e.type === 'confirmado')) {
            const registeredEvent = data.find((e) => e.type === 'registrado');
            if (registeredEvent) {
              data.splice(1, 0, { ...registeredEvent, type: 'confirmado' });
            }
          }
          this.eventos = data;
          if (this.eventos.length === 0) {
            this.error = 'No se encontraron eventos para este seguimiento.';
            this.disconnect();
          }
        }).catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          this.error = 'Ha ocurrido un error al recuperar los eventos.';
          this.disconnect();
        }).finally(() => {
          this.busyEventos = false;
          this.eventosFetched = true;
        });
    },
    fetchTrackerPositions(): void {
      if (this.seguimientoData?.status === 'finalizado') return;
      get('/pedidos/seguimiento/$pedidoPublicId/recorrido',
        { pedidoPublicId: this.pedidoPublicId, showReal: true.toString() })
        .then((e) => e.data)
        .then(({ isReal, paradas, recorrido }) => {
          this.isRecorridoReal = isReal;
          this.paradas = paradas;
          this.trackerPositions = isReal ? recorrido : [];
        }).catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          this.error = 'Ha ocurrido un error al recuperar las posiciones del tracker.';
          this.disconnect();
        })
        .finally(() => {
          this.trackersFetched = true;
        });
    },
    fetchCarActualPosition(): void {
      if (this.isEndedByDriver) return;
      get('/pedidos/seguimiento/$pedidoPublicId/last', { pedidoPublicId: this.pedidoPublicId })
        .then((e) => e.data)
        .then((data) => {
          this.carActualPosition = data;
        }).catch((_) => {
          // Puede que el error haya sido que el estado del pedido cambió
          // en el backend. En estos casos, el backend lanza un 400.
          this.carActualPosition = null;
        });
    },
    fetchMinutesLeft(): void {
      this.minutesLeft = null;
      const { lastEvent } = this;
      if (!lastEvent) return;
      const paradaIndex = lastEvent.parada;
      if (paradaIndex === undefined) return;
      const { carActualPosition } = this;
      if (!carActualPosition) return;
      const parada = this.seguimientoData?.paradas[paradaIndex];
      if (!parada) return;

      const req: DireccionesDistanciasReq = {
        lat1: carActualPosition.lat,
        lon1: carActualPosition.lon,
        lat2: parada.lat,
        lon2: parada.lon,
      };
      get('/direcciones/distancias/$lat1,$lon1:$lat2,$lon2/', req)
        .then((e) => e.data)
        .then((data) => {
          this.minutesLeft = Math.floor(data.durationInSeconds / 60.0);
        }).catch((_) => {
          this.minutesLeft = null;
        });
    },
    disconnect(): void {
      [this.intervalSeguimiento,
        this.intervalEventos,
        this.intervalTrackerPositions,
        this.intervalCarActualPosition,
        this.intervalMinutesLeft].forEach((interval) => {
        window.clearInterval(interval);
      });
      this.turnOffIntervalTracking();
      this.turnOffIntervalMinutesLeft();
      this.turnOffIntervalCarActualPosition();
      this.turnOffIntervalSeguimiento();
      this.turnOffIntervalEventos();
    },
  },
  watch: {
    lastEvent(val: Evento | undefined, oldVal: Evento | undefined) {
      const type = val?.type;
      const parada = val?.parada;
      if (val?.timestamp !== oldVal?.timestamp) {
        this.fetchSeguimiento();
      }

      // Prendemos o apagamos el intervalo de la posición del chofer y tracker.
      if (type === 'circulando') {
        this.turnOnIntervalCarActualPositionIfItIsOff();
        if (parada === 0) {
          this.turnOffIntervalTracking();
        } else {
          this.turnOnIntervalTrackingIfItIsOff();
        }
        this.fetched = false;
      }

      if (type === 'arribado' && this.seguimientoData?.status !== 'finalizado') {
        this.turnOffIntervalTracking();
        if (!this.fetched) {
          this.fetchTrackerPositions();
          this.fetchCarActualPosition();
          this.fetchSeguimiento();
          this.fetched = true;
        }
        if (val?.parada === this.paradas.length - 1) {
          this.turnOnIntervalSeguimientoIfItIsOff();
        }
      }

      // Si el estado es finalizado, no prendemos el intérvalo, pero si fetcheamos
      // al menos una vez para tener las posiciones del tracker.
    },
    seguimientoData(val: null | SeguimientoData, oldVal: null | SeguimientoData) {
      if (val?.status === 'finalizado' && oldVal?.status !== 'finalizado') {
        this.fetchSeguimiento();
        this.fetchTrackerPositions();
        setTimeout(() => {
          this.turnOffIntervalTracking();
          this.turnOffIntervalMinutesLeft();
          this.turnOffIntervalCarActualPosition();
          this.turnOffIntervalSeguimiento();
          this.turnOffIntervalEventos();
        }, 6000);
      }
    },
    carActualPosition(val: null | CarActualPosition) {
      if (val) {
        this.turnOnIntervalMinutesLeftIfItIsOff();
      } else {
        this.turnOffIntervalMinutesLeft();
      }
    },
    isEndedByDriver(newVal: boolean) {
      if (newVal) {
        this.disconnect();
        this.carActualPosition = null;
      }
    },
  },
  beforeDestroy() {
    this.disconnect();
  },
});
