import { api, post } from '../http';
import { InwebResponse } from '../http/types';

export const auth = {
  login: async (data) => post('/login', { data }),
  recoverPassword(data: { email: string }): Promise<InwebResponse<null> | { email: string }> {
    return api.patch('/usuarios/recuperar-contraseña', data)
      .then((response) => response.data);
  },
};

export default { auth };
