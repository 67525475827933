

























import Vue from 'vue';
import MaxWidthLayout from './MaxWidthLayout.vue';

export default Vue.extend({
  components: { MaxWidthLayout },
});
