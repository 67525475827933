import { AxiosRequestConfig } from 'axios';
import {
  ClientesResponseData,
} from '@/models/requests-and-responses';
import {
  CreateEmpleadoRequest,
  EditEmpleadoRequestBody,
  EmpleadosRequestParams,
  EmpleadosResponseData,
} from '@/models/requests-and-responses/empleados';
import { get, post, put } from '../http';

export function fetchClientes(idClientePadre: number): Promise<ClientesResponseData> {
  return get('/clientes', { idClientePadre })
    .then((response) => response.data);
}

export function fetchEmpleados(
  args: EmpleadosRequestParams,
  config: AxiosRequestConfig,
): Promise<EmpleadosResponseData> {
  return get('/usuarios/administracion', args, config)
    .then((resp) => resp.data);
}

export function editEmpleado(
  editEmpleadoRequest: EditEmpleadoRequestBody,
): Promise<null> {
  return put('/usuarios/administracion', editEmpleadoRequest)
    .then((_) => null);
}

export function createEmpleado(
  createEmpleadoRequest: CreateEmpleadoRequest,
): Promise<{ idCuentaCorporativa: number }> {
  return post('/usuarios/administracion', createEmpleadoRequest)
    .then((response) => response.data);
}
