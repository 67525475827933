
















import { propOfType } from '@/services/utils';
import Vue from 'vue';
import BaseCheckbox from './checkbox/BaseCheckbox.vue';

export default Vue.extend({
  components: { BaseCheckbox },
  props: {
    id: propOfType<string>(String(new Date().getMilliseconds() + Math.random())),
    value: propOfType<boolean>(false),
    disabled: propOfType<boolean>(false),
  },
  methods: {
    onInput(value: boolean) {
      if (!this.disabled) {
        this.$emit('input', value);
      }
    },
  },
});
