












































import Vue from 'vue';
import CreditCard from '@/components/icons/IconCreditCard.vue';
import { GeneralAccount } from '@/models/accounts/GeneralAccount';
import store from '@/services/store';
import { propOfType } from '@/services/utils';
import { CentroDeCosto, FormaDePago, formatPayMethod } from '@/models/accounts/shared';
import PayMethodItem from './PayMethodItem.vue';
import { Metadata } from '../../../CreateWeb.vue';
import SectionLayout from '../SectionLayout.vue';
import { apply } from '../../indexForm.vue';

type PayMethod = {
  idCeco: number;
  formaDePago: FormaDePago | null,
  description: null | string,
}

export default Vue.extend({
  components: {
    SectionLayout,
    CreditCard,
    PayMethodItem,
  },
  data() {
    return {
      paymentSelected: null as PayMethod | null,
    };
  },
  props: {
    isExpanded: Boolean,
    userTriedToConfirm: Boolean,
    metadata: propOfType<Metadata>(),
    idCeco: propOfType<number | null>(null),
  },
  methods: {
    mayToggle() {
      if (this.isExpandable || this.isExpanded) {
        this.$emit('toggle');
      }
    },
    selectSome() {
      if (this.idCeco && !this.paymentSelected) {
        const pmIndex = this.payMethods.findIndex((pm) => pm.idCeco === this.idCeco);
        if (pmIndex > -1) this.select(this.payMethods[pmIndex]);
      }
      if (this.idCecoPreferido) {
        for (const pm of this.payMethods) {
          if (pm.idCeco === this.idCecoPreferido) {
            if (!this.paymentSelected) {
              this.select(pm);
            }
          }
        }
      }
      if (this.payMethods.length && !this.paymentSelected) {
        this.select(this.payMethods[0]);
      }
    },
    select(mp: PayMethod) {
      this.paymentSelected = mp;
      this.$emit('close');
    },
  },
  mounted() {
    this.selectSome();
  },
  watch: {
    selectedAccount() {
      this.selectSome();
    },
    paymentSelected(val: PayMethod | null) {
      apply(this, (data) => {
        data.idCeCo = val?.idCeco || null;
      });
    },
  },
  computed: {
    isExpandable(): boolean {
      return this.payMethods.length > 1;
    },
    error() {
      if (!this.userTriedToConfirm) return '';
      if (!this.paymentSelected) return 'Seleccione la forma de pago';
      return '';
    },
    selectedFormaDePagoDescription(): string {
      return this.paymentSelected?.description || '';
    },
    selectedFormaDePagoNombre(): string {
      return formatPayMethod(this.paymentSelected?.formaDePago?.alias || '');
    },
    idCecoPreferido(): number | null {
      const { selectedAccount } = this;
      if (!selectedAccount) return null;
      if (selectedAccount.tag === 'particularAccount') return null;
      return selectedAccount.account.idCentroDeCostoPreferido;
    },
    fpDefecto(): FormaDePago | null {
      return this.selectedAccount?.account.formaDePagoPorDefecto || null;
    },
    selectedAccount(): GeneralAccount | null {
      return store.getters.selectedAccount;
    },
    isHidden(): boolean {
      return false;
    },
    canChangeDefaultCeco(): boolean {
      if (this.selectedAccount?.tag === 'corporateAccount') {
        return this.selectedAccount.account.permisos.changeDefaultCeco;
      }
      return true;
    },
    centrosDeCosto(): CentroDeCosto[] {
      const { selectedAccount } = this;
      if (selectedAccount?.tag === 'corporateAccount') {
        if (!this.canChangeDefaultCeco) {
          return selectedAccount.account.centrosDeCosto
            .filter((ceco) => (ceco.id === this.idCecoPreferido));
        }
        return selectedAccount.account.centrosDeCosto;
      }
      return [];
    },
    payMethods(): Array<PayMethod> {
      const { selectedAccount } = this;
      if (!selectedAccount) return [];
      const payments: PayMethod[] = [];
      if (selectedAccount.tag === 'particularAccount') {
        // Si es particular
        payments.push({
          idCeco: selectedAccount.account.idCentroDeCosto,
          formaDePago: this.fpDefecto,
          description: '',
        });
      } else {
        // Si la cuenta es corporativa...
        this.centrosDeCosto.forEach((ceco) => {
          payments.push({
            idCeco: ceco.id,
            formaDePago: this.fpDefecto,
            description: ceco.denominacion,
          });
        });
      }
      return payments;
    },
  },
});
