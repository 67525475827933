<template>
  <div class="flex flex-col space-y-0.5 w-full flex-grow">
    <span class="text-base text-white font-regular mr-1.5 text-center">Ya tengo una cuenta</span>
    <BaseButton
      :class="'btn-accent-secondary font-medium bg-dark-500'"
      :buttonClass="'uppercase border text-white'"
      :label="'Iniciar sesión'"
      @click.native="onNavigate('login')"
    />
  </div>
</template>

<script>
export default {
  methods: {
    onNavigate(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>
