

















import Vue from 'vue';
import { propOfType } from '@/services/utils';
import IconInpunto from '@/components/icons/IconInpunto.vue';
import IconDriverBlack from '@/components/icons/IconDriverBlack.vue';

export default Vue.extend({
  props: {
    showSwitch: propOfType<boolean>(false),
  },
  components: {
    IconDriverBlack,
    IconInpunto,
  },
  data() {
    return {
      recorridoReal: false,
      tempDisable: false,
    };
  },
  mounted() {
    this.recorridoReal = this.showSwitch;
  },
  watch: {
    recorridoReal() {
      this.$emit('switchRecorrido', this.recorridoReal);
      this.tempDisable = true;
      setTimeout(() => {
        this.tempDisable = false;
      }, 1000);
    },
  },
});
