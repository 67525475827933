import {
  getAnalytics,
  logEvent,
  setUserProperties,
  EventNameString,
  EventParams,
  AnalyticsCallOptions,
  setUserId,
} from 'firebase/analytics';

export function getUserProperties(): { [key: string]: string } {
  return {
    username: localStorage.getItem('username') || 'invitado',
    cliente: localStorage.getItem('cliente') || 'invitado',
    screen_size: `${window.screen.width}x${window.screen.height}`,
    viewport_size: `${window.innerWidth}x${window.innerHeight}`,
  };
}

export function logFirebaseEvent(
  eventName: EventNameString | string,
  eventParams?: EventParams,
  options?: AnalyticsCallOptions,
): void{
  logEvent(getAnalytics(), eventName, {
    ...eventParams,
    ...getUserProperties(),
  }, options);
}

export function addFirebaseUserId(userId: string): void {
  setUserId(getAnalytics(), userId, { global: true });
}

export function addFirebaseUserProperties(
  properties: { [key: string]: string | number | boolean },
): void {
  setUserProperties(getAnalytics(), properties, { global: true });
}
