











import Vue from 'vue';
import { propOfType } from '@/services/utils';
import msg from '@/services/userMsg';

export default Vue.extend({
  props: {
    filters: propOfType<string[]>(() => []),
    selected: propOfType<string[]>(() => []),
  },
  mounted() {
    // Inicia con todas las opciones seleccionadas
    this.selectedIndex = [...this.filters];
  },
  data() {
    return {
      selectedIndex: [] as string[],
    };
  },
  watch: {
    selected(newVal) {
      this.selectedIndex = newVal;
    },
  },
  methods: {
    getFilter(code: string): string {
      return msg.getFilter(code);
    },
    onSelectFilter(value: string) {
      const isSelected = this.selectedIndex.findIndex((elem) => elem === value);
      if (isSelected > -1 && this.selectedIndex.length > 1) {
        this.selectedIndex.splice(isSelected, 1);
      } else if (isSelected === -1) {
        this.selectedIndex.push(value);
      }
      this.$emit('onChange', this.selectedIndex);
    },
    isActive(value) {
      const isSelected = this.selectedIndex.findIndex((elem) => elem === value);
      return isSelected > -1;
    },
  },
});
