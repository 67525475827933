import TomTom from '@tomtom-international/web-sdk-maps';

export type Coords = {
  lon: number,
  lat: number,
}

export type CoordswithTag = {
  lon: number,
  lat: number,
  tag: string,
}

export type UserCoords = {
  isRealLocation: boolean,
  lon: number,
  lat: number,
}

export function coords(lon: number, lat: number): Coords {
  return { lon, lat };
}

export function toCoords<A>(latLon: A & { lat: number, lon: number }): Coords {
  const { lat, lon } = latLon;
  return { lat, lon };
}

// TomTom tiene diferente nombre para las coordenadas, son lat-lng en vez de lat-lon
export function toCoordsFromTT<A>(latLon: A & { lat: number, lng: number }): Coords {
  const { lat, lng } = latLon;
  return { lat, lon: lng };
}

export function toLngLat(c: Coords): TomTom.LngLat {
  return new TomTom.LngLat(c.lon, c.lat);
}

export function toGeoJsonCoords(c: Coords): [number, number] {
  return [c.lon, c.lat];
}

/* Coordenadas por default del centro de BsAs */
export const defaultCoords = {
  lat: -34.599722,
  lon: -58.381944,
};
