










































import Vue from 'vue';
import IconCallSupport from '@/components/icons/IconCallSupport.vue';
import IconWhatsapp from '@/components/icons/IconWhatsapp.vue';
import CallSupportModal from './CallSupportModal.vue';

export default Vue.extend({
  components: {
    IconCallSupport,
    IconWhatsapp,
    CallSupportModal,
  },
  data() {
    return {
      callSelected: false,
      user: this.$store.state.user,
      account: this.$store.getters.selectedAccount.account.nombreCliente,
    };
  },
  methods: {
    onWppSupport() {
      const phone = +5491127350000;
      const message = `Hola, soy ${this.capitalize(this.user.apellido)}, ${this.capitalize(this.user.nombre)} de la`
        + ` cuenta ${this.capitalize(this.account)} y necesito soporte`;
      window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${message}`);
    },
    capitalize(name: string): string {
      return name.split(' ')
        .map((word: string) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
        .join(' ');
    },
  },
  computed: {
    navbarHeight(): number {
      return this.$store.getters.navbarHeight;
    },
  },
});
