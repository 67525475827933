















import Vue from 'vue';
import { propOfType } from '@/services/utils';
import { Direccion, EventType } from '../../../models/requests-and-responses/seguimiento';

export type TimelineSegmentModelType = 'past' | 'present' | 'future';

export type TimelineSegmentModel = {
  timestamp?: Date,
  rowType: EventType | 'esperando' | 'espera llegar',
  direccion?: Direccion,
  paradaLabel?: string,
  futurePresentOrPast: TimelineSegmentModelType,
  timestampForWaittingClock?: Date,
};

export default Vue.extend({
  components: {
    // Your component's sub-components go here
  },
  props: {
    model: propOfType<TimelineSegmentModel>(),
    minutesLeft: propOfType<number | null>(null),
    isHovered: propOfType<boolean>(false),
  },
  computed: {
    isFuture():boolean {
      return this.model.futurePresentOrPast === 'future';
    },
    isPast():boolean {
      return this.model.futurePresentOrPast === 'past';
    },
    isPresent():boolean {
      return this.model.futurePresentOrPast === 'present';
    },
  },
});
