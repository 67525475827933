







import Vue from 'vue';
import CreateWeb from './CreateWeb.vue';
import CreateMobile from './CreateMobile.vue';

export default Vue.extend({
  name: 'indexCreate',
  components: {
    CreateWeb,
    CreateMobile,
  },
  computed: {
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
  },
});
