import Vue from 'vue';
import VueTailwind from 'vue-tailwind';
import {
  TRichSelect,
  TSelect,
} from 'vue-tailwind/dist/components';

const settings = {
  // Creo que no estamos usando esto, y se puede borrar.
  't-select': {
    component: TSelect,
    props: {
      wrapped: true,
      fixedClasses: {
        wrapper: 'relative rounded-md border overflow-hidden',
        input: [
          'block w-full pl-3 pr-10 py-2 transition duration-100 ease-in-out bg-none',
          'focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
          'disabled:opacity-50',
        ].join(' '),
        arrowWrapper: 'bg-white pointer-events-none absolute inset-y-0 right-0 flex items-center px-2',
        arrow: 'fill-current h-4 w-4',
      },
      classes: {
        wrapper: 'relative',
        input: 'text-black placeholder-gray-400 bg-white border-gray-300 focus:border-blue-500',
        arrowWrapper: 'text-gray-700',
        arrow: '',
      },
    },
  },
  't-rich-select': {
    // https://www.vue-tailwind.com/docs/rich-select/
    component: TRichSelect,
    props: {
      searchBoxPlaceholder: 'Buscar',
      noResultsText: 'No hay resultados',
      searchingText: 'Buscando...',
      loadingMoreResultsText: 'Cargando más resultados...',
      fixedClasses: {
        wrapper: 'relative',
        buttonWrapper: 'inline-block relative w-full',
        selectButton: 'w-full flex text-left justify-between items-center px-3 py-2 '
          + 'bg-gray-100 text-gray-700 border-gray-300 '
          + 'transition duration-100 ease-in-out '
          + 'border rounded shadow-sm '
          + 'focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed',
        selectButtonLabel: '',
        selectButtonPlaceholder: 'block truncate',
        selectButtonIcon: 'fill-current flex-shrink-0 ml-1 h-4 w-4',
        selectButtonClearButton: 'rounded-md flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6 transition duration-100 ease-in-out',
        selectButtonClearIcon: 'fill-current h-3 w-3',
        dropdown: 'absolute w-full z-10 -mt-1 absolute border-b border-l border-r rounded-b '
          + 'shadow-sm z-10 bg-gray-100 border-gray-300',
        dropdownFeedback: '',
        loadingMoreResults: '',
        optionsList: 'overflow-auto',
        searchWrapper: 'inline-block w-full',
        searchBox: 'inline-block w-full',
        optgroup: '',
        option: 'cursor-pointer',
        disabledOption: 'opacity-50 cursor-not-allowed',
        highlightedOption: 'cursor-pointer',
        selectedOption: 'cursor-pointer',
        selectedHighlightedOption: 'cursor-pointer',
        optionContent: '',
        optionLabel: 'truncate block',
        selectedIcon: 'fill-current h-4 w-4',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: '',
      },
      classes: {
        wrapper: '',
        buttonWrapper: '',
        selectButton: '',
        selectButtonLabel: '',
        selectButtonPlaceholder: '',
        selectButtonIcon: '',
        selectButtonClearButton: 'hover:bg-gray-200 text-gray-600',
        selectButtonClearIcon: '',
        dropdown: '',
        dropdownFeedback: 'pb-2 px-3 text-gray-400 text-sm',
        loadingMoreResults: 'pb-2 px-3 text-gray-400 text-sm',
        optionsList: '',
        searchWrapper: 'p-2 placeholder-gray-400',
        searchBox: 'px-3 py-2 bg-gray-50 text-sm rounded border focus:outline-none focus:shadow-outline border-gray-300',
        optgroup: 'text-gray-400 uppercase text-xs py-1 px-2 font-semibold',
        option: '',
        disabledOption: '',
        highlightedOption: 'bg-gray-200',
        selectedOption: 'font-semibold bg-gray-300',
        selectedHighlightedOption: 'font-semibold bg-gray-400',
        optionContent: 'flex justify-between items-center px-3 py-2',
        optionLabel: '',
        selectedIcon: '',
        enterClass: 'opacity-0',
        enterActiveClass: 'transition ease-out duration-100',
        enterToClass: 'opacity-100',
        leaveClass: 'opacity-100',
        leaveActiveClass: 'transition ease-in duration-75',
        leaveToClass: 'opacity-0',
      },
    },
  },
};

Vue.use(VueTailwind, settings);
