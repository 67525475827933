












import { propOfType } from '@/services/utils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    /** Selected tab */
    value: propOfType<0 | 1 | 2>(0),
    tabIndex: propOfType<0 | 1 | 2>(-1),
  },
  computed: {
    isSelected(): boolean {
      return this.value === this.tabIndex;
    },
  },
});
