// Create address request

import { ErrorCode } from '@/models/ErrorCode';

export type CreateAddressRequest =
  | { status: 'notAsked' }
  | { status: 'loading' }
  | { status: 'error', errorCode: ErrorCode }
  // Cuando la petición es exitosa, se redirige a la vista de AddressesList
  // y se destruye completamente el AddressCreateState
  // Por eso no necesitamos una variante tipo `{ status: 'ok' }` ni nada de eso

export function notAsked(): CreateAddressRequest {
  return { status: 'notAsked' };
}

// https://docs.google.com/spreadsheets/d/1pE1gO92bS2dm2dSzEeyN1_MjRawD_qj0Z2uHqnEIwOQ/edit#gid=554417026
export function errorCodeFromAliasAndInfo(args: {
  aliasInputValue: string,
  infoInputValue: string,
}): ErrorCode | null {
  const { aliasInputValue, infoInputValue } = args;

  if (aliasInputValue.length < 1 || aliasInputValue.length > 40) {
    return 'alias_invalid';
  }
  if (infoInputValue.length > 100) {
    return 'info_invalid';
  }
  return null;
}
