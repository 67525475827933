





































































































import Vue from 'vue';
import msg from '@/services/userMsg';
import { user } from '@/services/api/userApi';
import IconEditSolid from '@/components/icons/IconEditSolid.vue';
import IconCamera24 from '@/components/icons/IconCamera24.vue';
import IconRefresh from '@/components/icons/IconRefresh.vue';
import IconX24 from '@/components/icons/IconX24.vue';
import IconCheck24 from '@/components/icons/IconCheck24.vue';
import { AxiosError } from 'axios';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import { defaultProfilePictureUri } from '@/models/User';
import { dispatch, mapStateTyped } from '@/services/store/utils';
import BaseInput from '@/components/BaseInput.vue';
import { validators } from '@/services/utils';

export default Vue.extend({
  data() {
    return {
      fileUploadStatus: 'notAsked' as 'notAsked' | 'loading',
      editingEmail: false,
      loading: false,
      newEmail: '' as string,
    };
  },
  components: {
    IconCamera24,
    IconRefresh,
    IconEditSolid,
    IconX24,
    IconCheck24,
    BaseInput,
  },
  computed: {
    ...mapStateTyped(['user']),
    profilePictureUrl(): string {
      return this.user?.profilePictureUrl || defaultProfilePictureUri;
    },
    emailIsValid(): boolean {
      return validators.isValidEmail(this.newEmail);
    },
    emailTooltip(): string {
      return msg.getTooltip('email');
    },
    email(): string {
      return this.user ? this.user.email : '-';
    },
  },
  methods: {
    getLabel(key): string {
      return msg.getLabel(key);
    },
    clickedProfilePicture(): void {
      if (this.fileUploadStatus === 'notAsked') {
        (this.$refs.fileInput as any).click();
      }
    },
    receiveFileUpload(event: Event): void {
      if (this.fileUploadStatus === 'notAsked') {
        this.fileUploadStatus = 'loading';

        const fileInput = this.$refs.fileInput as Element & { files: FileList };

        user.uploadProfilePicture(fileInput.files[0])
          .then((apiResponse) => {
            this.$toast.success('Se actualizó la foto de perfil');
            this.$store.dispatch('updateProfilePicture', apiResponse.data);
            this.fileUploadStatus = 'notAsked';
          })
          .catch((error: AxiosError) => {
            this.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
            this.fileUploadStatus = 'notAsked';
          });
      }
    },
    changeEmail() {
      if (!this.emailIsValid) return;
      this.loading = true;
      user.changeEmail({ newEmail: this.newEmail })
        .then(() => {
          this.$toast.success(msg.getSuccess('email_changed'));
          dispatch('changedEmail', this.newEmail);
        })
        .catch((error) => {
          const code = errorCodeFromAxiosError(error);
          this.$toast.error(msg.getError(code));
        })
        .finally(() => {
          this.editingEmail = false;
          this.loading = false;
          this.newEmail = '';
        });
    },
  },
  mounted() {
    const fileInput = this.$refs.fileInput as Element;
    fileInput.addEventListener('change', this.receiveFileUpload);
  },
});
