


























import Vue from 'vue';
import { propOfType } from '@/services/utils';
import IconInformationCircle20 from '@/components/icons/IconInformationCircle20.vue';

export default Vue.extend({
  components: { IconInformationCircle20 },
  props: {
    value: propOfType<string>(''),
    disabled: propOfType<boolean>(false),
    type: propOfType<string>('text'),
    placeholder: propOfType<string>(''),
    inputId: propOfType<string>(String(new Date().getMilliseconds() + Math.random())),
    validationError: propOfType<string | null>(null),
    tooltip: propOfType<string>('Tooltip'),
    autocomplete: propOfType<boolean | 'new-password'>(true),
  },
  methods: {
    onInput(value: string) {
      this.$emit('input', value);
    },
  },
});
