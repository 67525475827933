








































import { propOfType } from '@/services/utils';
import Vue from 'vue';
import IconX24 from '@/components/icons/IconX24.vue';

export default Vue.extend({
  props: {
    title: propOfType<string>('Modal'),
    onClose: propOfType<Function>(() => {}),
    onConfirm: propOfType<Function>(() => {}),
    showSpinnerInConfirmButton: propOfType<boolean>(false),
    showConfirmButton: propOfType<boolean>(true),
    showCancelButton: propOfType<boolean>(true),
    confirmLabel: propOfType<string>('Aceptar'),
  },
  components: { IconX24 },
});
