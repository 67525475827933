






















import Vue from 'vue';
import { propOfType, OSName, isFirefox } from '@/services/utils';
import IconCalendar from './icons/IconCalendar.vue';
import IconClock from './icons/IconClock.vue';

export default Vue.extend({
  components: {
    IconCalendar,
    IconClock,
  },
  props: {
    type: propOfType<String>('date'),
    name: propOfType<String>(''),
    value: propOfType<String>(''),
    id: propOfType<String>(''),
    min: propOfType<String>(''),
    max: propOfType<String>(''),
  },
  data() {
    return {
      model: this.value,
    };
  },
  watch: {
    value() {
      this.model = this.value;
    },
    model() {
      this.$emit('input', this.model);
    },
  },
  computed: {
    isIPhone(): boolean {
      return OSName() === 'iOS';
    },
    isFirefox(): boolean {
      return isFirefox();
    },
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
    inputMaxWidth(): string {
      if (this.isMobile) {
        return this.type === 'time' ? '6.5rem' : '8rem';
      }
      return this.type === 'time' ? '4.5rem' : '7.5rem';
    },
  },
});
