














































import { propOfType } from '@/services/utils';
import Vue from 'vue';
import IconX24 from '@/components/icons/IconX24.vue';
import { post } from '@/services/http';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import { AxiosError } from 'axios';
import msg from '@/services/userMsg';

export default Vue.extend({
  props: {
    title: propOfType<string>('Modal'),
    errorMessage: propOfType<string>(''),
    isSmall: propOfType<boolean>(false),
    onClose: propOfType<Function>(() => {}),
  },
  components: { IconX24 },
  computed: {
    buttonClass() {
      if (this.isLoading) {
        return `text-xs xs:text-base font-semibold py-2 px-2 xs:px-4 rounded-lg text-center text-white
          shadow-inner bg-gray-600`;
      }
      return `text-xs xs:text-base font-semibold py-2 px-2 xs:px-4 rounded-lg text-center
        text-white bg-gray-900`;
    },
    phoneSupportRequestMsg() {
      return msg.getModalContent('phoneSupportRequest');
    },
    phoneSupportConfirmMsg() {
      return msg.getModalContent('phoneSupportConfirm');
    },
  },
  data() {
    return {
      isOpen: false,
      afterCall: false,
      isLoading: false,
      idPersona: this.$store.getters.selectedAccount.account.idPersona,
    };
  },
  methods: {
    onConfirm() {
      this.isLoading = true;
      post('$sgv/rest/alerta/sos/$idPersona', { idPersona: this.idPersona })
        .then(() => {
          this.afterCall = true;
        })
        .catch((error: AxiosError) => {
          this.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
