







import Vue from 'vue';
import TomTom from '@tomtom-international/web-sdk-maps';
import { eventBus } from '@/main';
import { gralAddress } from '@/services/api/addressApi';
import { isObject, propOfType, propOfTypeArrayOf } from '@/services/utils';
import { htmlElement } from '@/services/html';
import {
  Coords, toCoordsFromTT, toLngLat, toGeoJsonCoords, defaultCoords, UserCoords,
} from '@/services/Coords';
import { Feature, geoJSON } from '@/models/requests-and-responses/direcciones';
import { Marker, putOnTomTomMap } from './Marker';
import * as MapCache from './MapCache';

const defaultRouteColor = '#4B5563';

export function padding(n: number) {
  return {
    left: n, right: n, top: n, bottom: n,
  };
}

/** Devuelve las opciones de `TomTom.Map#addLayer` para dibujar una ruta.
 * Se usa con la función `MapCache.addLayer`.
 */
function routeLayerOptions(layerId: string, layerSource: string,
  layerColor = defaultRouteColor): TomTom.AnyLayer {
  return {
    id: layerId,
    source: layerSource,
    type: 'line',
    layout: { 'line-cap': 'round', 'line-join': 'round' },
    paint: {
      'line-color': layerColor,
      'line-width': 4,
    },
  };
}

type MarkerWCoords = {
  coords: Coords,
  marker: Marker
};

export default Vue.extend({
  props: {
    markers: propOfTypeArrayOf<MarkerWCoords>([]),
    // La diferencia entre route y trackerRoute, es que, en el caso de usar trackerRoute,
    // el mapa unirá los puntos con lineas rectas, en cambio, en el caso de usar route, se hará
    // un fetch para buscar la ruta óptima entre los puntos.
    route: propOfTypeArrayOf<Coords>([]),
    routeColor: propOfType<string>(defaultRouteColor),
    selectedStops: propOfType<number[] | null>(null),
    trackerRoute: propOfTypeArrayOf<Coords>([]),
    zoom: propOfType<number>(4),
    center: propOfType<Coords | null>(() => defaultCoords),
    padding: propOfType<{ left: number, right: number, top: number, bottom: number }>(
      () => padding(100),
    ),
    showNavigationControls: propOfType<boolean>(true),
    fitMarkersOnBounds: propOfType<boolean>(true),
    fitRouteOnBounds: propOfType<boolean>(true),
    onDistanceChangedCallback: propOfType<Function>((_: number) => {}),
    onTimeChangedCallback: propOfType<Function>((_: number) => {}),
  },
  data() {
    return {
      id: MapCache.invalidId(),
      routeCoords: [] as [number, number][],
      poolRoute: [] as Feature[],
      navigationControls: new TomTom.NavigationControl(),
      abortController: null as null | AbortController,
    };
  },
  watch: {
    markers(_) {
      this.drawMarkers();
    },
    route(_) {
      this.routeCoords = [];
      if (this.poolRoute.length) {
        this.clearPoolRoute();
      }
      this.fetchRoute();
    },
    trackerRoute() {
      this.drawTrackingRoute();
    },
    zoom(_) {
      this.setMapZoom();
    },
    center(val: Coords) {
      this.setCenter(val);
    },
    selectedStops(_) {
      this.clearPoolRoute();
      this.drawPoolRoute();
    },
    showNavigationControls() {
      const mapCache = MapCache.getFromId(this.id)?.tomTomMap;
      if (mapCache) {
        if (this.showNavigationControls && !mapCache.hasControl(this.navigationControls)) {
          mapCache.addControl(this.navigationControls, 'bottom-right');
        } else {
          mapCache.removeControl(this.navigationControls);
        }
      }
    },

  },
  computed: {
    offset(): [number, number] {
      return [
        (this.padding.left - this.padding.right) / 2,
        (this.padding.top - this.padding.bottom) / 2,
      ];
    },
    filteredRoute(): Coords[] {
      return this.route.filter((leg) => leg);
    },
    isMapEmpty(): boolean {
      return !this.route.length && !this.trackerRoute.length && !this.markers.length;
    },
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
  },
  created() {
    eventBus.$on('getCenter', () => {
      eventBus.$emit('setCenter', this.getCenter());
    });

    eventBus.$on('centerMap', () => {
      this.setCenter(this.center);
    });
  },
  mounted() {
    this.mountMap()
      .then(() => {
        this.drawMarkers();
        this.setMapZoom();
        this.getUserLocation();
        this.fetchRoute();
        this.resizeMap();
        this.fitMapBounds();
        this.drawTrackingRoute();
      })
      .catch((error: TomTom.ErrorEvent) => {
        // console.error(error);
        this.$toast.error('Ocurrió un error iniciando el mapa');
      });
  },
  beforeDestroy() {
    if (this.poolRoute.length) {
      this.clearPoolRoute();
    } else {
      MapCache.clearLayer(this.id, 'route');
    }
  },
  methods: {
    mountMap(): Promise<void> {
      this.id = MapCache.registerNewInstance();

      const cache = MapCache.getFromId(this.id);

      const element = cache !== null
        ? cache.element
        : htmlElement('div', { class: 'map h-full w-auto' }, []);

      const outerContainer = this.$refs.outerContainer as HTMLElement;

      outerContainer.appendChild(element);

      if (cache !== null) {
        return Promise.resolve();
      }

      /** Normalmente, si `getFromId` devuelve null
       * puede significar que el mapa no está inicializado, o
       * que la instancia actual no es la más reciente (el `id` es inválido).
       *
       * Pero en este caso acabamos de registrar el nuevo `id`, así que el `id` no es
       * inválido, por lo tanto el mapa no está inicializado.
      */

      const map = TomTom.map({
        key: process.env.VUE_APP_API_KEY_MAP || '',
        zoom: this.zoom,
        center: toLngLat(this.center),
        container: element,
        language: 'spanish',
        /** Elijo el `hybrid_main` porque no tiene el efecto 3D cuando se hace mucho zoom (eso es un
         * requerimiento). El `hybrid_night` es la versión para modo oscuro.
        */
        style: `${window.location.origin}/MapStyle.json`,
      });

      if (this.showNavigationControls) {
        map.addControl(this.navigationControls, 'bottom-right');
      }
      MapCache.create({ map, element, id: this.id });

      return new Promise((resolve, reject) => {
        map.once('load', (_) => {
          resolve();
        });
        map.once('error', (error) => {
          reject(error);
        });
      });
    },
    drawMarkers() {
      const cache = MapCache.getFromId(this.id);

      if (cache !== null) {
        cache.tomTomMarkers.forEach((marker) => {
          if (marker) marker.remove();
        });

        const { tomTomMap } = cache;
        const tomTomMarkers = this.markers.map((markers) => {
          if (markers) {
            const { coords: markerCoords, marker } = markers;
            return putOnTomTomMap(
              tomTomMap,
              markerCoords,
              marker,
            );
          }
          return undefined;
        });

        MapCache.updateMarkers(tomTomMarkers, this.id);

        if (this.fitMarkersOnBounds) {
          this.fitMapBounds();
          this.$emit('markersDrawn');
        }
      }
    },
    /** Intenta panear y hacer zoom al mapa para que entren todos los marcadores.
     *
     * Necesita que haya al menos dos marcadores para poder hacerlo.
     * Devuelve si pudo o no hacerlo.
    */
    fitMapBounds() {
      const cache = MapCache.getFromId(this.id);

      if (cache === null || !this.markers.length) {
        return;
      }

      try {
        const firstMarker = this.markers.find((marker) => marker);
        const secondMarker = [...this.markers].reverse()
          .find((marker) => marker && marker !== firstMarker);

        if (firstMarker && secondMarker) {
          const bounds = this.markers
            .reduce(
              (bounds_, marker):TomTom.LngLatBounds => {
                if (marker) {
                  bounds_.extend(toLngLat(marker.coords));
                }
                return bounds_;
              },
              new TomTom.LngLatBounds(
                toLngLat(firstMarker.coords),
                toLngLat(secondMarker.coords),
              ),
            );

          this.routeCoords.forEach((coord) => {
            bounds.extend(coord);
          });

          this.trackerRoute.forEach((coord) => {
            bounds.extend(toGeoJsonCoords(coord));
          });

          const camera = cache.tomTomMap.cameraForBounds(
            bounds,
            { padding: this.padding },
          );

          if (camera !== undefined) {
            cache.tomTomMap.easeTo(camera, {
              duration: 0,
            });
          }
        } else if (firstMarker || secondMarker) {
          const marker = firstMarker || secondMarker as MarkerWCoords;

          cache.tomTomMap.easeTo({
            center: toLngLat(marker.coords),
            zoom: 15,
            offset: this.offset,
          });
        } else {
          cache.tomTomMap.easeTo({
            center: toLngLat(this.center),
            zoom: this.zoom,
            offset: this.offset,
          });
        }
      } catch (error) {
        if (isObject(error) && error.message === 'Invalid LngLat object: (NaN, NaN)') {
          // Voy a ignorar este error porque es un bug de TomTom o al menos eso parece.
          // (Ya hice console.log de los `markers` y de `bounds` y no encontré ningún `NaN`).
        } else {
          throw error;
        }
      }
    },
    fetchRoute() {
      const cache = MapCache.getFromId(this.id);

      if (cache === null) {
        return;
      }

      if (this.abortController) {
        this.abortController.abort();
      }

      this.abortController = new AbortController();

      if (this.filteredRoute.length <= 1) {
        MapCache.clearLayer(this.id, 'route');
      } else {
        gralAddress
          .getRoute(this.filteredRoute, this.abortController.signal)
          .then(({ data }) => {
            if (this.onDistanceChangedCallback) {
              this.onDistanceChangedCallback(+(data.meters / 1000).toFixed(2));
            }
            if (this.onTimeChangedCallback) {
              this.onTimeChangedCallback(Math.floor(data.seconds / 60.0));
            }
            return data.geoJson;
          })
          .then((res) => {
            this.abortController = null;

            this.routeCoords = res.features.reduce((coords, feature) => {
              coords.push(...feature.geometry.coordinates);
              return coords;
            }, [] as [number, number][]);

            if (res.features.length === 1) {
              this.receivedGeoJsonRoute(res);
            } else {
              this.poolRoute = res.features;
              MapCache.clearLayer(this.id, 'route');
              this.drawPoolRoute();
            }

            if (this.fitRouteOnBounds) {
              this.fitMapBounds();
              this.$emit('routeDrawn');
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.warn('Error al pedir la ruta a TomTom');
            // eslint-disable-next-line no-console
            console.error(error);
          });
      }
    },
    drawPoolRoute() {
      this.poolRoute.forEach((leg, i) => {
        let legColor = this.routeColor;
        if (this.selectedStops && this.selectedStops.length
          && (i < this.selectedStops[0] || i >= this.selectedStops[1])) {
          legColor = '#9CA3AF';
        }

        MapCache.addLayer(
          this.id,
          `leg-${i}`,
          routeLayerOptions,
          leg,
          legColor,
        );
      });
    },
    clearPoolRoute() {
      this.poolRoute.forEach((leg, i) => {
        MapCache.clearLayer(
          this.id,
          `leg-${i}`,
        );
      });
    },
    receivedGeoJsonRoute(geoJson: geoJSON): void {
      MapCache.addLayer(
        this.id,
        'route',
        routeLayerOptions,
        geoJson,
        this.routeColor,
      );
    },
    drawTrackingRoute(): void {
      if (this.trackerRoute.length > 0) {
        const geoJson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: this.trackerRoute.map(toGeoJsonCoords),
              },
            },
          ],
        };

        MapCache.addLayer(
          this.id,
          'tracker-route',
          routeLayerOptions,
          geoJson,
          this.routeColor,
        );
        if (this.fitRouteOnBounds) {
          this.fitMapBounds();
          this.$emit('routeDrawn');
        }
      } else {
        MapCache.clearLayer(
          this.id,
          'tracker-route',
        );
      }
    },
    resizeMap(): void {
      const cache = MapCache.getFromId(this.id);

      if (cache !== null) {
        cache.tomTomMap.resize();
      }
    },
    setMapZoom(): void {
      const cache = MapCache.getFromId(this.id);

      if (cache !== null) {
        cache.tomTomMap.zoomTo(this.zoom);
      }
    },
    setCenter(center: Coords): void {
      const cache = MapCache.getFromId(this.id);

      if (cache !== null && center) {
        cache.tomTomMap.easeTo({
          center: toLngLat(center),
          zoom: this.zoom,
        });
      }
    },
    getCenter(): Coords | null {
      const cache = MapCache.getFromId(this.id);

      if (cache !== null) {
        return toCoordsFromTT(cache.tomTomMap.getCenter());
      }
      return null;
    },
    getUserLocation() {
      const userLocation = JSON.parse(sessionStorage.getItem('userLocation') || 'null') as UserCoords | null;
      if (userLocation) {
        if (this.isMapEmpty) {
          this.setCenter(userLocation);
        }
        if (userLocation.isRealLocation) {
          this.$emit('userGeolocated', userLocation);
        }
      } else if (this.isMapEmpty) {
        this.setCenter(defaultCoords);
      }
    },
  },
});
