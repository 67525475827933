




























import { propOfType, validators } from '@/services/utils';
import Vue from 'vue';
import { Group } from '@/models/requests-and-responses/grupos';
import userMsg from '@/services/userMsg';

export default Vue.extend({
  props: {
    showErrors: propOfType<boolean>(false),
    group: propOfType<Group>(),
  },
  data() {
    return {
      model: this.group,
    };
  },
  mounted() {
    this.updateErrorState();
  },
  computed: {
    errorMessages(): { invalidName: string | null, invalidDesc: string | null } {
      return {
        invalidName: validators.isValidAlphanumString(this.model.nombre, 50)
          ? null : userMsg.getError('group_name_invalid'),
        invalidDesc: !this.model.descripcion.length
          || validators.isValidAlphanumString(this.model.descripcion, 250)
          ? null : userMsg.getError('desc_invalid'),
      };
    },
    shownErrorMessages(): { invalidName: string | null, invalidDesc: string | null } {
      if (!this.showErrors) {
        return {
          invalidName: null,
          invalidDesc: null,
        };
      }

      return this.errorMessages;
    },
    errorFound(): boolean {
      return Object.values(this.errorMessages).some((error) => error !== null);
    },
  },
  methods: {
    updateErrorState() {
      this.$emit('errorState', this.errorFound);
    },
  },
  watch: {
    errorFound() {
      this.updateErrorState();
    },
  },
});

