


























import Vue from 'vue';
import msg from '@/services/userMsg';
import { propOfType, copyStringToClipboard } from '@/services/utils';
import { HeaderData } from '@/models/Pedido';
import IconExternalLink20 from '@/components/icons/IconExternalLink20.vue';

export default Vue.extend({
  name: 'Header',
  components: {
    IconExternalLink20,
  },
  props: {
    headerData: propOfType<HeaderData>(),
    haySeguimiento: propOfType<boolean>(),
    hrefSeguimiento: propOfType<string>(),
  },
  data() {
    return {
      // Your component's data goes here
    };
  },
  methods: {
    formatDate(date: Date): string {
      const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'long',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      };
      const formattedDate = new Intl.DateTimeFormat('es-ES', options).format(date);
      return formattedDate.replace(',', '').concat(' hs');
    },
    copy(str: string) {
      copyStringToClipboard(str)
        .then(() => this.$toast.success(msg.getSuccess('string_copied')));
    },
  },
  computed: {
    pedidoId(): number {
      return this.headerData.pedidoId;
    },
    servicio(): string {
      return this.headerData.servicio;
    },
    fechaHoraSalida(): string {
      return this.formatDate(this.headerData.fechaHoraSalida);
    },
    cliente(): string {
      return this.headerData.cliente;
    },
  },
  mounted() {
    // Code to run when the component is mounted goes here
  },
});
