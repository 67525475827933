import GenericPerson from './taaxiiGenericPerson';
import { SolicitudParada, SolicitudPasaje } from '../ExtrasData';
import { GenericClient } from '../employees/Cliente';

export default class SolicitudExtras {
  idSolicitud: number

  type: 's'

  inicio: Date

  solicitante: GenericPerson

  cliente: GenericClient

  cotizacion: number

  distancia: number

  duracion: number

  paradas: SolicitudParada[]

  pasajes: SolicitudPasaje[]

  constructor(data) {
    this.idSolicitud = data.idSolicitud;
    this.type = data.type;
    this.inicio = new Date(data.inicio);
    this.solicitante = data.solicitante;
    this.cliente = data.cliente;
    this.cotizacion = data.cotizacion;
    this.distancia = data.distancia;
    this.duracion = data.duracion;
    this.paradas = data.paradas.map((stop) => new SolicitudParada(stop));
    this.pasajes = data.pasajes.map((pax) => new SolicitudPasaje(pax));
  }
}
