














import Vue from 'vue';
import IconForward from '@/components/icons/IconForward.vue';
import { propOfType } from '@/services/utils';

export default Vue.extend({
  components: { IconForward },
  props: {
    tooltip: propOfType<string | null>(null),
    onClick: propOfType<() => void>(() => {}),
    offset: propOfType<string>('right-7 bottom-7'),
    buttonClass: propOfType<string>('bg-accent-500 text-white'),
  },
});
