<template>
  <div>
    <p class="pt-6 px-6">
      <span class="title pr-4">Centro de ayuda</span>
      <span class="subtitle">Encuentra respuestas a tus consultas.</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'helpCenter',
  mounted() {
    document.title = 'Centro de ayuda - InPunto';
  },
};
</script>
