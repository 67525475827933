




































import BaseDropdownMenu from '@/components/BaseDropdownMenu.vue';
import IconChevronD from '@/components/icons/IconChevronD.vue';
import IconDownloadCloud from '@/components/icons/IconDownloadCloud.vue';
import { post } from '@/services/http';
import { AxiosError } from 'axios';
import msg from '@/services/userMsg';
import Vue from 'vue';
import { propOfType, capitalizeText } from '@/services/utils';
import store from '@/services/store';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';

type Month = {
  month: number;
  year: number;
  label: string;
};

export default Vue.extend({
  name: 'MonthReportSelector',
  components: {
    BaseDropdownMenu,
    IconChevronD,
    IconDownloadCloud,
  },
  props: {
    clientID: propOfType(Number),
  },
  data() {
    const now = new Date();
    now.setMonth(now.getMonth() - 1);
    const month = now.toLocaleDateString('es-ES', { month: 'long' });
    const year = now.toLocaleDateString('es-ES', { year: 'numeric' });
    return {
      isOpen: false,
      monthSelected: {
        month: now.getMonth() + 1,
        year: now.getFullYear(),
        label: `${capitalizeText(month)} ${year}`,
      } as Month,
      reportLoading: false,
      maxMonthsShowed: 6,
    };
  },
  computed: {
    months(): Month[] {
      const months = [] as Month[];
      const now = new Date();
      now.setMonth(now.getMonth() - 1);
      for (let i = 1; i <= this.maxMonthsShowed; i += 1) {
        months.push({
          month: now.getMonth() + 1,
          year: now.getFullYear(),
          label: this.formatDate(now),
        });
        now.setMonth(now.getMonth() - 1);
      }
      return months;
    },
    monthToString(): string {
      return this.monthSelected.month.toString().padStart(2, '0');
    },
    lastDayOfMonth(): string {
      const lastDay = new Date(this.monthSelected.year, this.monthSelected.month, 0).getDate();
      return lastDay.toString().padStart(2, '0');
    },
    since(): string {
      return `${this.monthSelected.year}-${this.monthToString}-01`;
    },
    until(): string {
      return `${this.monthSelected.year}-${this.monthToString}-${this.lastDayOfMonth}`;
    },
    corporateAccount(): number | null {
      const account = store.getters.selectedAccount;
      return account.tag === 'corporateAccount' ? account.account.idCuentaSgv : null;
    },
  },
  methods: {
    selectMonth(month: Month) {
      this.monthSelected = month;
      this.isOpen = false;
      this.$emit('monthSelected', month.month);
      this.$emit('yearSelected', month.year);
    },
    formatDate(date: Date) {
      const month = date.toLocaleDateString('es-ES', { month: 'long' });
      const year = date.toLocaleDateString('es-ES', { year: 'numeric' });
      return `${capitalizeText(month)} ${year}`;
    },
    downloadReport() {
      if (!this.corporateAccount) {
        this.$toast.error(msg.getError('uknown'));
        return;
      }
      const reportTS = localStorage.getItem(`${this.corporateAccount}-pedidos-ts`) as string | null;
      if (reportTS && new Date().getTime() - new Date(reportTS).getTime() < 600000) {
        this.$toast.info(msg.getInfo('report_already_requested'));
        this.reportLoading = false;
        return;
      }
      this.reportLoading = true;
      post('/pedidos/v1/reporte/pedidos', undefined, {
        params: {
          idClient: this.clientID,
          since: this.since,
          until: this.until,
          provider: 'InPunto',
          areMyOrders: false,
        },
      })
        .then(() => {
          localStorage.setItem(`${this.corporateAccount}-pedidos-ts`, new Date().toISOString());
          this.$toast.success(msg.getSuccess('report_requested'));

          this.$logEvent(
            'descarga_reporte',
            { tipo_reporte: msg.getReports('pedidos') },
          );
        })
        .catch((error: AxiosError) => {
          const errorCode = errorCodeFromAxiosError(error);
          if (errorCode === 'internal_error') {
            this.$toast.error(msg.getError('error_on_report'));
          } else {
            this.$toast.error(msg.getError(errorCode));
          }
        })
        .finally(() => {
          this.reportLoading = false;
        });
    },
  },
  mounted() {
    // Code to run when the component is mounted goes here
  },
});
