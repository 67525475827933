/* eslint-disable no-shadow */
const state = {
  messaging: null,
  refreshNotification: false,
  refreshTooltip: 'Actualizar',
  n_notifications: 0,
  novedades: [],
};

const mutations = {
  setMessagingInstance(state, messaging) {
    state.messaging = messaging;
  },
  setRefreshNotification(state, newRefreshNotificationState) {
    state.refreshNotification = newRefreshNotificationState;
  },
  setRefreshTooltip(state, newTooltipContent) {
    state.refreshTooltip = newTooltipContent;
  },
  addNovedad(state, newNovedad) {
    const duplicado = checkDuplicados(newNovedad.idSolicitud,
      newNovedad.idViaje, newNovedad.estado, newNovedad.subestado, state.novedades);
    if (!duplicado) {
      const novedad = {
        idSolicitud: newNovedad.idSolicitud,
        idViaje: newNovedad.idViaje,
        fecha: newNovedad.fecha,
        hora: newNovedad.hora,
        estado: newNovedad.estado,
        subestado: newNovedad.subestado,
      };
      state.novedades = [novedad, ...state.novedades];
      state.n_notifications += 1;
    }
  },
  removeNovedad(state, novedad) {
    const index = state.novedades.indexOf(novedad);
    if (index > -1) {
      state.novedades.splice(index, 1);
    }
  },
  incrementNotifications(state) {
    state.n_notifications += 1;
  },
  clearNotifications(state) {
    state.n_notifications = 0;
  },
  clearNovedades(state) {
    state.novedades.splice(0, state.novedades.length);
  },
};

const actions = {
  setMessagingInstance({ commit }, messaging) {
    commit('setMessagingInstance', messaging);
  },
};

const getters = {
  getMessagingInstance: (state) => state.messaging,
  getNovedadesLength: (state) => state.novedades.length,
  getNovedades: (state) => state.novedades,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

function checkDuplicados(idSolicitud:string, idViaje:string, estado:string,
  subestado:string, lista:any[]): boolean {
  const found = lista.find((item) => item.idSolicitud === idSolicitud || item.idViaje === idViaje);
  if (found && found.estado === estado && found.subestado === subestado) return true;
  return false;
}
