<template>
<!-- Chip que se muestra seleccionado -->
    <button type="button"
      :class="active ? 'bg-gray-700' : 'bg-white'"
      class="inline-flex items-center rounded-full border border-gray-700 p-px">
      <BaseIcon
        v-if="active"
        :viewBox="'0 0 20 20'"
        class="text-white h-6 w-6 pl-2 pb-0.5">
        <IconCheck />
      </BaseIcon>
      <span :class="[active ? 'text-white' : 'text-gray-700', 'px-2', 'py-0.5', 'text-sm']">
        {{label}}
      </span>
    </button>
</template>

<script>
import IconCheck from '@/components/icons/IconCheck.vue';

export default {
  components: {
    IconCheck,
  },
  props: ['active', 'label'],
};
</script>
