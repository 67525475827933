import { GetterTree } from 'vuex';
import {
  getIdCliente,
  getIsMobile,
  getNavbarHeight,
  getUser,
  getSelectedAccount,
  State,
} from './state';

const getters: GetterTree<State, State> = {
  user: getUser,
  selectedAccount: getSelectedAccount,
  idCliente: getIdCliente,
  isMobile: getIsMobile,
  navbarHeight: getNavbarHeight,
};

export default getters;
