































































































































import Vue from 'vue';
import msg from '@/services/userMsg';
import { get } from '@/services/http';
import { Coords } from '@/services/Coords';
import {
  withTooltip, Marker, originMarker, destinationMarker, stopMarker, filterMarkers,
} from '@/components/map/Marker';
import { RecorridoInfo, CalificacionInfo, CostosInfo } from '@/models/requests-and-responses/sgv';
import {
  propOfType, capitalizeText, isFirefox, getFullName, copyStringToClipboard,
} from '@/services/utils';
import { defaultProfilePictureUri, User } from '@/models/User';
import taaxiiService from '@/services/taaxii.com/taaxiiService';
import Transportista from '@/models/taaxii.com/taaxiiTransportista';
import Pedido from '@/models/Pedido';
import IconChevronDownSection from '@/components/icons/IconChevronDownSection.vue';
import IconClose24 from '@/components/icons/IconClose24.vue';
import IconLocationHollow from '@/components/icons/IconLocationHollow.vue';
import IconExternalLink20 from '@/components/icons/IconExternalLink20.vue';
import IconDriver from '@/components/icons/IconDriver.vue';
import IconCosts from '@/components/icons/IconCosts.vue';
import IconStar from '@/components/icons/IconStar.vue';
import Map, { padding } from '@/components/map/Map.vue';
import SolicitudExtras from '@/models/taaxii.com/SolicitudExtras';
import ViajeExtras from '@/models/taaxii.com/ViajeExtras';
import { mapStateTyped } from '@/services/store/utils';
import { SolicitudPasaje } from '@/models/ExtrasData';
import { Direccion, Recorrido } from '@/models/requests-and-responses/seguimiento';
import Route from './Route.vue';
import Driver from './Driver.vue';
import Costs from './Costs.vue';
import Rating from './Rating.vue';

type Section = {
  title: string;
  component: string;
  icon: string;
}

export default Vue.extend({
  props: {
    pedido: propOfType<Pedido>(),
  },
  components: {
    IconClose24,
    IconLocationHollow,
    IconExternalLink20,
    IconDriver,
    IconCosts,
    IconStar,
    Map,
    Route,
    Driver,
    Costs,
    Rating,
    IconChevronDownSection,
  },
  data() {
    return {
      infoCostos: null as CostosInfo | null,
      infoCalificacion: [] as CalificacionInfo[],
      selectedStops: [] as number[],
      sections: [
        {
          title: 'Recorrido',
          component: 'Route',
          icon: 'IconLocationHollow',
        },
        {
          title: 'Conductor',
          component: 'Driver',
          icon: 'IconDriver',
        },
        {
          title: 'Costos',
          component: 'Costs',
          icon: 'IconCosts',
        },
        {
          title: 'Calificacion',
          component: 'Rating',
          icon: 'IconStar',
        },
      ] as Section[],
      isExpanded: false,
      bottomSheetSize: 60,
      isRecorridoReal: false,
      recorridoReal: null as Recorrido | null,
      recorridoCotizado: null as Recorrido | null,
    };
  },
  mounted() {
    this.isRecorridoReal = this.showRecorridoReal;
    this.loadCosts();
    this.loadCalificacion();
    this.fetchRecorrido(true);
    this.fetchRecorrido(false);
  },
  methods: {
    fetchRecorrido(showReal: boolean) {
      const { idPublic } = this.pedido;
      get('/pedidos/seguimiento/$pedidoPublicId/recorrido', { pedidoPublicId: idPublic, showReal: showReal.toString() })
        .then((result) => {
          if (showReal) {
            this.recorridoReal = result.data;
          } else {
            this.recorridoCotizado = result.data;
          }
        });
    },
    loadCosts() {
      taaxiiService.getCostos(this.pedido.resources)
        .then((result) => { this.infoCostos = result.costos; })
        .catch(() => { this.infoCostos = null; });
    },
    loadCalificacion() {
      if (this.pedido.extras.type === 'v') {
        const { idViaje } = this.pedido.extras;
        get('$sgv/rest/viajes/$idViaje/calificaciones', { idViaje })
          .then(((result) => {
            this.infoCalificacion = result.data;
          }));
      }
    },
    parseAddress(address: string): string {
      return address.split(',', 1)[0];
    },
    getAddress(index: number): string {
      return this.infoRecorrido?.paradas[index]
        ?.dir?.direccion.split(',', 1)[0] || '-';
    },
    scrollTo(component: string) {
      const top = this.$refs[component][0]?.$el.offsetTop - 85;
      const container = this.$refs.sections as Element;
      container.scrollTo({
        behavior: 'smooth',
        top,
      });
    },
    copy(str: string) {
      copyStringToClipboard(str)
        .then(() => this.$toast.success(msg.getSuccess('string_copied')));
    },
    changeBottomSheetSize() {
      if (this.isExpanded) {
        this.shrinkBottomSheet();
      } else {
        this.growBottomSheet();
      }
    },
    growBottomSheet() {
      this.bottomSheetSize = 100;
      this.isExpanded = true;
    },
    shrinkBottomSheet() {
      this.bottomSheetSize = 60;
      this.isExpanded = false;
    },
  },
  computed: {
    user(): User {
      return this.$store.state.user as User;
    },
    extras(): ViajeExtras | SolicitudExtras {
      return this.pedido.extras;
    },
    infoRecorrido(): RecorridoInfo | null {
      return {
        paradas: this.pedido.extras.paradas,
        pasajes: this.pedido.extras.pasajes,
      };
    },
    cliente(): string {
      return capitalizeText(this.extras.cliente?.alias || '-');
    },
    servicio(): string {
      if (this.extras.type === 'v') {
        return capitalizeText(`Servicio ${this.extras.vehiculo?.tipoVehiculo.alias || 'No Informado'}`);
      }
      return capitalizeText(`Servicio ${this.pedido.extras.pasajes[0].servicio}`);
    },
    estado(): string {
      return capitalizeText(this.pedido.status);
    },
    driver(): Transportista | null {
      if (this.extras.type === 'v') {
        const { agencia, chofer, vehiculo } = this.extras;
        return {
          agencia,
          chofer,
          vehiculo,
          choferFoto: chofer?.picUrl || defaultProfilePictureUri,
        };
      }
      return null;
    },
    costs(): CostosInfo | null {
      if (this.isAnulado) {
        return {
          idViaje: 0,
          importeTotal: 0,
          precotizacion: 0,
          detalle: [],
        } as CostosInfo;
      }
      if (this.infoCostos) {
        return {
          ...this.infoCostos,
          coefProrrateo: this.passenger?.coefProrrateo || 100,
        };
      }
      return null;
    },
    passenger(): SolicitudPasaje | null {
      if (this.pedido.type !== 'trip') {
        return null;
      }
      const passenger = this.extras.pasajes.find(({ pasajero }) => (
        getFullName(pasajero).toLowerCase()
          === `${this.user?.apellido}, ${this.user?.nombre}`.toLowerCase()
      )) || this.extras.pasajes[0];

      return passenger;
    },
    id(): number {
      if (this.passenger) {
        return this.passenger.idSolicitud;
      }
      return this.extras.pasajes[0].idSolicitud;
    },
    hrefSeguimiento(): string {
      return `/seguimiento/${this.pedido.idPublic}`;
    },
    /* Firefox y Chrome tienen diferentes anchos de scrollbar,
    asi que le corresponden diferentes paddings */
    scrollbarClass(): string {
      return isFirefox() ? 'pr-5' : 'pr-2';
    },
    filteredSections(): Section[] {
      const filtered = [] as Section[];
      if (this.infoRecorrido) filtered.push(this.sections[0]);
      if (this.driver) filtered.push(this.sections[1]);
      if (this.costs) filtered.push(this.sections[2]);
      if (this.infoCalificacion.length > 0) filtered.push(this.sections[3]);
      return filtered;
    },
    mapMarkers() {
      // Lo que vamos a devolver.
      const ret: Array<{ coords: Coords, marker: Marker }> = [];
      const markers = this.isRecorridoReal
        ? this.recorridoReal?.paradas : this.recorridoCotizado?.paradas;
      // Marcadores
      if (markers && markers.length > 0) {
        markers.forEach((parada, i, paradas) => {
          const isSelected = (this.selectedStops.length === 0
            || (i >= this.selectedStops[0] && i <= this.selectedStops[1]));
          let tooltip = `parada ${i}`;
          let marker = stopMarker(isSelected);

          if (i === 0) {
            tooltip = 'origen';
            marker = originMarker(isSelected);
          }

          if (i === (paradas.length - 1)) {
            tooltip = 'destino';
            marker = destinationMarker(isSelected);
          }

          ret.push({
            coords: parada,
            marker: withTooltip(
              tooltip,
              marker,
            ),
          });
        });
      }
      return ret;
    },
    filteredMarkers(): Array<{ coords: Coords, marker: Marker } | undefined> {
      return filterMarkers(this.mapMarkers, false);
    },
    trackerRoute(): Coords[] {
      return this.isRecorridoReal ? this.recorridoReal?.recorrido || [] : [];
    },
    route(): Coords[] {
      return this.isRecorridoReal ? [] : this.recorridoCotizado?.recorrido || [];
    },
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
    navbarHeight(): number {
      return this.$store.getters.navbarHeight;
    },
    mapPadding(): { left: number, top: number, bottom: number, right: number } {
      return this.isMobile ? {
        left: 40, right: 40, top: 70, bottom: 195,
      } : padding(100);
    },
    recorridoLabel(): string {
      return this.isRecorridoReal
        ? msg.getHelpMessage('recorridoTransportista')
        : msg.getHelpMessage('recorridoCotizado');
    },
    isFinalizado(): boolean {
      return this.pedido.status === 'finalizado';
    },
    isCanceladoFDT(): boolean {
      return this.isFinalizado && this.pedido.substatus === 'Cancelado en origen';
    },
    isCanceladoFallo(): boolean {
      return this.isFinalizado && this.pedido.substatus === 'Fallo';
    },
    isCalificado(): boolean {
      return this.pedido.status === 'calificado';
    },
    isAnulado(): boolean {
      return this.pedido.status === 'anulado';
    },
    showRecorridoReal(): boolean {
      return (this.isFinalizado
              && !this.isCanceladoFDT
              && !this.isCanceladoFallo)
              || this.isCalificado;
    },
  },
});
