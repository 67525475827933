



































































































import GenericPerson from '@/models/taaxii.com/taaxiiGenericPerson';
import { GenericCeCo } from '@/models/accounts/shared';
import { defaultProfilePictureUri } from '@/models/User';
import {
  capitalizeText, fromPhoneToWhatsappValidPhone, propOfType, copyStringToClipboard,
} from '@/services/utils';
import msg from '@/services/userMsg';
import Vue from 'vue';
import IconPackageAvatar from '@/components/icons/IconPackageAvatar.vue';
import IconChevronD from '@/components/icons/IconChevronD.vue';
import IconPaxAvatar from '@/components/icons/IconPaxAvatar.vue';
import PaxCard from './PaxCard.vue';

export default Vue.extend({
  components: {
    IconPackageAvatar,
    IconChevronD,
    IconPaxAvatar,
    PaxCard,
  },
  props: {
    person: propOfType<GenericPerson>(),
    emisor: propOfType<GenericPerson>(),
    receptor: propOfType<GenericPerson>(),
    paradas: propOfType<string[] | null>(null),
    ceco: propOfType<GenericCeCo>(),
    obs: propOfType<string>(),
    from: String,
    to: String,
  },
  data() {
    return {
      paradasExpanded: false,
      paxExpanded: false,
      emisorExpanded: false,
      receptorExpanded: false,
    };
  },
  computed: {
    destino(): string {
      return this.paradas[this.paradas.length - 1];
    },
    soloParadas(): string[] {
      return this.paradas?.slice(1, this.paradas.length - 1) || [];
    },
    profilePictureUrl(): string {
      return this.person?.picUrl || defaultProfilePictureUri;
    },
    envioEmisor(): string {
      return `${this.formatName(this.emisor)}`;
    },
    emisorPhone(): string {
      return this.emisor?.phone || '';
    },
    envioReceptor(): string {
      return `${this.formatName(this.receptor)}`;
    },
    receptorPhone(): string {
      return this.receptor?.phone || '';
    },
    name(): string {
      return this.formatName(this.person);
    },
    email(): string {
      return this.person?.email || '';
    },
    receptorEmail(): string {
      return this.receptor?.email || '';
    },
    emisorEmail(): string {
      return this.emisor?.email || '';
    },
    phone(): string {
      return this.person?.phone || this.receptor?.phone || '';
    },
    costCenter(): string {
      return `(${this.ceco?.alias})` || '';
    },
    observations(): string {
      return this.obs || 'No hay observaciones';
    },
    hrefPhone(): string {
      return `tel:${this.phone}`;
    },
    hrefWhatsApp(): string {
      if (!this.phone) return '';
      const validPhone = fromPhoneToWhatsappValidPhone(this.phone);
      return `https://wa.me/${validPhone}`;
    },
    tieneParadas(): boolean {
      return this.paradas.length > 2;
    },
    showParadas(): string {
      if ((this.paradas.length - 2) === 1) {
        return '+1 parada';
      } return `+${(this.paradas.length - 2)} paradas`;
    },
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    formatDirection(direccion: string): string {
      return direccion.split(',', 3).join(',');
    },
    formatName(person: GenericPerson): string {
      return capitalizeText(`${person?.lastName}, ${person?.firstName}`);
    },
    copy(str: string, paxType: string = 'pax'): void {
      this.$logEvent('seguimiento', { accion_seguimiento: `contactar_${paxType}_tel` });

      copyStringToClipboard(str).then(() => this.$toast.success(msg.getSuccess('phone_copied')));
    },
    getHrefWhatsApp(phone:string): string {
      if (phone === '') return '';
      const validPhone = fromPhoneToWhatsappValidPhone(phone);
      return `https://wa.me/${validPhone}`;
    },
    expandParadas(): void {
      this.paradasExpanded = true;
    },
    collapseParadas(): void {
      this.paradasExpanded = false;
    },
  },
});
