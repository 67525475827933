






































































import Vue from 'vue';
import { eventBus } from '@/main';
import IconChevronDownSection from '@/components/icons/IconChevronDownSection.vue';
import IconClose from '@/components/icons/IconClose.vue';
import { propOfType } from '@/services/utils';

export default Vue.extend({
  components: {
    IconChevronDownSection,
    IconClose,
  },
  props: {
    isExpanded: propOfType<Boolean>(false),
    loading: propOfType<Boolean>(false),
    removable: propOfType<Boolean>(false),
    hidden: propOfType<Boolean>(false),
    hiddenEndIcon: propOfType<Boolean>(false),
    error: propOfType<String>(''),
    id: propOfType<String>(''),
  },
  mounted() {
    window.addEventListener('resize', this.scrollIntoView);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.scrollIntoView);
  },
  computed: {
    shouldAnimate(): Boolean {
      return !this.removable && !this.error;
    },
  },
  methods: {
    scrollIntoView() {
      if (!this.isExpanded) {
        return;
      }
      const button = this.$refs.sectionButton as HTMLElement;
      if (button) {
        button.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      }
    },
  },
  watch: {
    isExpanded(newValue: Boolean) {
      if (newValue) {
        eventBus.$emit('grow-bottomsheet');
        this.scrollIntoView();
      }
    },
  },
});
