


















































































import { propOfType } from '@/services/utils';
import IconVisibility from '@/components/icons/IconVisibility.vue';
import IconVisibilityOff from '@/components/icons/IconVisibilityOff.vue';
import Vue from 'vue';

export default Vue.extend({
  components: { IconVisibility, IconVisibilityOff },
  props: {
    value: propOfType<string>(''),
    disabled: propOfType<boolean>(false),
    type: propOfType<string>('text'),
    max: propOfType<string>(''),
    min: propOfType<string>(''),
    placeholder: propOfType<string>(''),
    inputClass: propOfType<string>(''),
    inputId: propOfType<string>(String(new Date().getMilliseconds() + Math.random())),
    inputRef: propOfType<string>(''),
    removable: { type: Boolean, default: false },
    autocomplete: propOfType<boolean | 'new-password'>(true),
    domain: { type: String, default: '' },
    dark: { type: Boolean, default: false },
    autocapitalize: { type: String, default: '' },
  },
  data() {
    return {
      isVisible: false,
      typeInChildren: this.type,
      model: this.value,
    };
  },
  watch: {
    type() {
      this.typeInChildren = this.type;
    },
    value() {
      if (this.isTextarea) this.model = this.value;
    },
    model() {
      if (this.isTextarea) this.$emit('input', this.model);
    },
  },
  computed: {
    padding() {
      if (this.type === 'password') {
        return { paddingRight: '36px' };
      }
      return { paddingRight: '8px' };
    },
    isTextarea(): boolean {
      return this.type === 'textarea';
    },
    autocompleteText(): string {
      if (this.autocomplete === 'new-password') return this.autocomplete;
      return this.autocomplete ? 'on' : 'off';
    },
  },
  methods: {
    onBlur() {
      this.$emit('blur');
    },
    onFocus() {
      this.$emit('focus');
    },
    onInput(event: InputEvent) {
      this.$emit('input', (event?.target as any).value);
    },
    onClear() {
      this.$emit('input', '');
    },
    toggleVisibility() {
      if (this.isVisible) {
        this.typeInChildren = 'password';
      } else {
        this.typeInChildren = 'text';
      }
      this.isVisible = !this.isVisible;
    },
  },
});
