




































































































































import IconList from '@/components/icons/IconList.vue';
import IconElipsisMenu from '@/components/icons/IconElipsisMenu.vue';
import CreateFloatingButton from '@/modules/site/components/CreateFloatingButton.vue';
import { Group, Action } from '@/models/requests-and-responses/grupos';
import Vue from 'vue';
import { get } from '@/services/http';
import store from '@/services/store';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import { AxiosError } from 'axios';
import msg from '@/services/userMsg';
import { user } from '@/services/api/userApi';
import { dispatch } from '@/services/store/utils';
import EditGroupModal from './EditGroupModal.vue';
import CreateGroupModal from './CreateGroupModal.vue';
import DeleteGroupModal from './DeleteGroupModal.vue';

export default Vue.extend({
  name: 'profile',
  components: {
    IconList,
    IconElipsisMenu,
    EditGroupModal,
    CreateGroupModal,
    DeleteGroupModal,
    CreateFloatingButton,
  },
  mounted() {
    document.title = `${this.sectionData.title} - InPunto`;
    this.fetchGroups();
  },
  data() {
    return {
      sectionName: 'grupos',
      groupList: [] as Group[],
      searchQuery: '',
      isLoading: false,
      selectedGroup: {} as Group,
      modalStatus: 'none' as 'none' | 'create' | 'edit' | 'delete',
    };
  },
  watch: {
    idCliente() {
      this.fetchUserAccounts();
      this.fetchGroups();
    },
  },
  methods: {
    onSearchQueryInput(query): void {
      this.searchQuery = query;
      this.fetchGroups();
    },
    arrayFrom(length: number): Array<number> {
      return Array.from({ length }).map((_, i) => i);
    },
    onConfirm(): void {
      this.modalStatus = 'none';
      this.fetchGroups();
      this.fetchUserAccounts();
    },
    onClose(): void {
      this.modalStatus = 'none';
    },
    fetchGroups() {
      if (this.idCliente === null) {
        return;
      }
      this.isLoading = true;
      get('$sgv/rest/cuentas/grupos', undefined,
        {
          params: {
            nombre: this.searchQuery,
            offset: 0,
            limit: 9999,
            idCliente: this.idCliente,
          },
        })
        .then((res) => {
          this.groupList = res.data.content.map(({
            id,
            nombre,
            descripcion,
            idClienteFk,
          }) => (
            {
              id,
              nombre,
              descripcion,
              idCliente: idClienteFk,
            }
          ));
          this.groupList.sort((a, b) => (
            a.nombre.toLocaleLowerCase().localeCompare(b.nombre.toLocaleLowerCase())
          ));
          this.isLoading = false;
        })
        .catch((error: AxiosError) => {
          this.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
        });
    },
    fetchUserAccounts() {
      user.getAccounts()
        .then(({ data }) => {
          dispatch('updateAccounts', data);
        })
        .catch((error) => {
          this.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
          dispatch('logout', null);
          this.$router.push('/login');
        });
    },
  },
  computed: {
    sectionData(): { title: string, desc: string} {
      const title = msg.getTitle(this.sectionName);
      const desc = msg.getDescription(this.sectionName);
      return {
        title,
        desc,
      };
    },
    actions(): Array<Action> {
      return [
        {
          title: 'Editar grupo',
          action: (grupo: Group) => {
            this.selectedGroup = grupo;
            this.modalStatus = 'edit';
          },
        },
        {
          title: 'Eliminar grupo',
          action: (grupo: Group) => {
            this.selectedGroup = grupo;
            this.modalStatus = 'delete';
          },
        },
        {
          title: 'Ver miembros del grupo',
          action: ({ id }: Group) => {
            this.$router.push({ name: 'usuarios', query: { group: String(id) } });
          },
        },
        {
          title: 'Ver pedidos del grupo',
          action: ({ id }: Group) => {
            this.$router.push({ name: 'pedidos', query: { group: String(id) } });
          },
        },
      ];
    },
    idCliente(): number {
      return store.getters.idCliente as number;
    },
  },
});
