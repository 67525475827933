import Vue from 'vue';
import VueRouter from 'vue-router';
import { getAnalytics, logEvent } from 'firebase/analytics';
import SoporteMobile from '@/modules/site/pages/dashboard/ayuda/SoporteMobile.vue';
import RecoverPassword from '../modules/auth/pages/RecoverPassword.vue';
import CreateAccount from '../modules/auth/pages/indexCreate.vue';
import ConfirmAccount from '../modules/auth/pages/ConfirmAccount.vue';
import Auth from '../modules/auth/pages/indexAuth.vue';
import Profile from '../modules/site/pages/dashboard/mi-cuenta/indexProfile.vue';
import Users from '../modules/site/pages/dashboard/usuarios/indexUsers.vue';
import Pedidos from '../modules/site/pages/dashboard/mis-pedidos/indexPedidos.vue';
import Informes from '../modules/site/pages/dashboard/informes/indexReports.vue';
import Direcciones from '../modules/site/pages/dashboard/direcciones/indexDirecciones.vue';
import Create from '../modules/site/pages/dashboard/nuevo-pedido/indexCreate.vue';
import HelpCenter from '../modules/site/pages/dashboard/ayuda/indexHelpcenter.vue';
import Groups from '../modules/site/pages/dashboard/grupos/IndexGroups.vue';
import Site from '../modules/site/pages/indexSite.vue';
import Tracking from '../modules/tracking/indexTracking.vue';
import Login from '../modules/auth/pages/Login.vue';
import * as routeName from './routeName';

Vue.use(VueRouter);

// Public route names
const publicRouteNames = [
  routeName.LOGIN,
  routeName.RECOVER_PASSWORD,
  routeName.CREATE_ACCOUNT,
  routeName.CONFIRM_ACCOUNT,
  routeName.TRACKING,
];

// Router
const Router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: routeName.HOME,
      props: true,
      component: Site,
      children: [
        { path: '/crear', name: routeName.CREAR, component: Create },
        {
          path: '/pedidos',
          name: routeName.PEDIDOS,
          component: Pedidos,
          props: (route) => ({
            selected: route.query.selected,
            modal: route.query.modal,
            q: route.query.q,
            status: route.query.status,
            type: route.query.type,
            group: route.query.group,
            since: route.query.since,
            until: route.query.until,
          }),
        },
        {
          path: '/informes',
          name: routeName.INFORMES,
          component: Informes,
          props: (route) => ({
            selected: route.query.selected,
            modal: route.query.modal,
            q: route.query.q,
            status: route.query.status,
            type: route.query.type,
            group: route.query.group,
            since: route.query.since,
            until: route.query.until,
          }),
        },
        { path: '/perfil', name: routeName.PERFIL, component: Profile },
        { path: '/direcciones', name: routeName.DIRECCIONES, component: Direcciones },
        {
          path: '/usuarios',
          name: routeName.USUARIOS,
          component: Users,
          props: (route) => ({
            query: route.query.q,
            estado: route.query.estado,
            group: route.query.group,
          }),
        },
        { path: '/grupos', name: routeName.GRUPOS, component: Groups },
        { path: '/soporte', name: routeName.SOPORTE, component: HelpCenter },
        { path: '/soporteMobile', name: routeName.SOPORTEMOBILE, component: SoporteMobile },
      ],
    },
    {
      path: '/login',
      name: 'auth',
      props: true,
      component: Auth,
      children: [
        {
          path: '/login',
          name: routeName.LOGIN,
          component: Login,
        }, {
          path: '/recuperar-contraseña',
          name: routeName.RECOVER_PASSWORD,
          component: RecoverPassword,
        },
        {
          path: '/crear-cuenta',
          name: routeName.CREATE_ACCOUNT,
          component: CreateAccount,
        },
        {
          path: '/confirmar/:confirmationToken',
          name: routeName.CONFIRM_ACCOUNT,
          component: ConfirmAccount,
        },
      ],
    },
    {
      path: '/seguimiento/:pedidoPublicId',
      name: routeName.TRACKING,
      props: true,
      component: Tracking,
    },
  ],
});

Router.beforeEach((to, from, next) => {
  const authRequired = !publicRouteNames.some((name) => name === to.name);
  const authToken = localStorage.getItem('user');
  const analytics = getAnalytics();

  if (authRequired && !authToken) {
    // Si nos dirigimos a una ruta privada, pero no estamos logueados, nos vamos al login.
    logEvent(analytics, 'page_view', {
      page_title: routeName.LOGIN,
      page_location: `${window.location.origin}/login`,
      page_referrer: `${window.location.origin}${from.path}`,
    });
    next('/login');
  } else if (to.name === routeName.LOGIN && authToken) {
    // Si nos dirigimos al login, pero ya estamos logueados, nos vamos a crear un pedido.
    logEvent(analytics, 'page_view', {
      page_title: routeName.CREAR,
      page_location: `${window.location.origin}/crear`,
      page_referrer: `${window.location.origin}${from.path}`,
    });
    next('/crear');
  } else {
    logEvent(analytics, 'page_view', {
      page_title: to.name || 'noName',
      page_location: `${window.location.origin}${to.path}`,
      page_referrer: `${window.location.origin}${from.path}`,
    });
    next();
  }
});

export default Router;
