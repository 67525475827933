







import Vue from 'vue';

export default Vue.extend({
  props: {
    className: { type: String, default: 'h-6 w-6' },
  },
});

