var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{attrs:{"for":_vm.id}},[_vm._t("default"),_c('div',{staticClass:"\n      w-9 h-5 relative\n    "},[_c('div',{staticClass:"absolute left-0 top-0 w-full h-full rounded-full transition-all",class:_vm.solidColor ?
      {
        'bg-accent-500' : true
      }
      :
      {
        'bg-accent-500': _vm.value && !_vm.focused,
        'bg-gray-400': !_vm.value && !_vm.focused,
        'bg-accent-900': _vm.value && _vm.focused,
        'bg-gray-600': !_vm.value && _vm.focused,
      }}),_c('div',{staticClass:"absolute left-0 top-0 w-5 h-5 p-0.5 transition-all",class:{
        'ml-0': _vm.value,
        'ml-4': !_vm.value
      }},[_c('div',{staticClass:"w-full h-full rounded-full border border-gray-300 bg-white"})]),_c('input',{staticClass:"absolute left-0 top-0 w-full h-full opacity-0",attrs:{"type":"checkbox","id":_vm.id,"disabled":_vm.disabled},domProps:{"checked":_vm.value},on:{"change":_vm.onChange,"focus":_vm.onFocus,"blur":_vm.onBlur}})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }