import { AxiosRequestConfig } from 'axios';
import { CecoPreferidoReq, NewEmailReq, NewPhoneReq } from '@/models/requests-and-responses';
import {
  api, get, patch, post, getHeaders,
} from '../http';
import { InwebResponse } from '../http/types';

export const user = {
  getUser: async () => get('/usuarios'),
  changePassword: async (data) => patch('/usuarios', { ...data }),
  addUser: async (data) => post('/usuarios', { data }),
  getAccounts: async () => get('/cuentas'),
  async uploadProfilePicture(file: File): Promise<InwebResponse<string>> {
    const formData = new FormData();
    formData.append('data', file);
    const response = await api.post('/usuarios/foto', formData, {
      headers: {
        ...getHeaders(),
        'Content-Type': 'multipart/form-data',
      },
    });
    const responseBody = response.data as any;
    return responseBody as InwebResponse<string>;
  },
  changeDefaultCeco(
    data: CecoPreferidoReq,
    config?: AxiosRequestConfig,
  ): Promise<InwebResponse<null>> {
    return post('/cuentas/cecos/preferido', { data }, config);
  },
  changePhone(data: NewPhoneReq): Promise<InwebResponse<null>> {
    return patch('/usuarios/cambiar/telefono', { ...data });
  },
  changeEmail(data: NewEmailReq): Promise<InwebResponse<null>> {
    return patch('/usuarios/cambiar/email', { ...data });
  },
};

export default { user };
