





























































































import Vue from 'vue';
import { post } from '@/services/http';
import { validators, isFirefox, capitalizeText } from '@/services/utils';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import { AxiosError } from 'axios';
import msg from '@/services/userMsg';
import { createAccountReq } from '@/models/requests-and-responses/registro';
import ButtonRecoverPass from '../components/ButtonRecoverPass.vue';
import ButtonLogin from '../components/ButtonLogin.vue';

type ErrorMessages = {
  name: string;
  surname: string;
  dni: string;
  email: string;
  phone: string;
};

export default Vue.extend({
  components: {
    ButtonRecoverPass,
    ButtonLogin,
  },
  props: {
    value: String,
    registrationCode: String,
  },
  computed: {
    fullEmail(): string {
      return `${this.email}@${this.domain}`;
    },
    errorMessage(): ErrorMessages {
      if (!this.userTriedToRegister) return {} as ErrorMessages;
      return {
        name: this.validateName,
        surname: this.validateSurname,
        dni: this.validateDni,
        email: this.validateEmail,
        phone: this.validatePhone,
      };
    },
    noError(): boolean {
      return (
        !this.validateName
        && !this.validateSurname
        && !this.validateDni
        && !this.validateEmail
        && !this.validatePhone
      );
    },
    validateName(): string {
      if (this.name.length === 0) return 'El nombre no puede ser vacío';
      if (this.name.length > 100) return 'El nombre es demasiado largo';
      if (!validators.isValidName(this.name)) return msg.getError('name_invalid');
      return '';
    },
    validateSurname(): string {
      if (this.surname.length === 0) return 'El apellido no puede ser vacío';
      if (this.surname.length > 100) return 'El apellido es demasiado largo';
      if (!validators.isValidName(this.surname)) return msg.getError('surname_invalid');
      return '';
    },
    validateDni(): string {
      if (this.dni.length === 0) return 'El DNI no puede ser vacío';
      if (this.dni.length > 10) return 'El DNI es demasiado largo';
      if (!validators.isValidDNI(this.dni)) return msg.getError('dni_invalid');
      return '';
    },
    validateEmail(): string {
      return !validators.isValidEmail(this.fullEmail)
        ? 'El email no es válido'
        : '';
    },
    validatePhone(): string {
      return !this.phoneIsValid
        ? 'El celular debe tener el siguiente formato: +54 3511234567'
        : '';
    },
    browserPadding() {
      return isFirefox() ? 'py-2' : 'py-1.5';
    },
  },
  data() {
    return {
      name: '' as string,
      surname: '' as string,
      dni: '' as string,
      email: '' as string,
      domain: this.value,
      phone: '' as string,
      phoneIsValid: false as boolean,
      errorMsg: '' as string,
      showSpinner: false as boolean,
      userTriedToRegister: false,
    };
  },
  methods: {
    register() {
      this.userTriedToRegister = true;
      if (this.noError) {
        this.showSpinner = true;
        const req: createAccountReq = {
          firstName: capitalizeText(this.name),
          lastName: capitalizeText(this.surname),
          email: this.fullEmail,
          phone: this.phone,
          dni: this.dni,
          password: '',
          registerCode: this.registrationCode,
        };

        post('/usuarios/registro', req)
          .then(() => {
            this.$emit('changeView', 'accountCreated');
          })
          .catch((error: AxiosError) => {
            this.$toast.error(msg.getError(errorCodeFromAxiosError(error)));
          })
          .finally(() => {
            this.userTriedToRegister = false;
            this.showSpinner = false;
          });
      }
    },
  },
});
