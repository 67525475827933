


































import Vue from 'vue';
import msg from '@/services/userMsg';
import { Address } from '@/models/Address';
import { propOfType } from '@/services/utils';

export default Vue.extend({
  props: {
    selected: propOfType<Address>(),
  },
  methods: {
    getLabel(key) {
      return msg.getLabel(key);
    },
    getAddressShowData(address: Address): Object {
      return { street: address.street };
    },
  },
});
