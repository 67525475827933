
























import Vue from 'vue';
import IconChevronL from '@/components/icons/IconChevronL.vue';

export default Vue.extend({
  components: {
    IconChevronL,
  },
  props: {
    title: String,
    exit: Function,
  },
  computed: {
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
  },
});
