




































import BaseButton from '@/components/BaseButton.vue';
import BaseDropdownMenu from '@/components/BaseDropdownMenu.vue';
import IconChevronD from '@/components/icons/IconChevronD.vue';
import IconDownloadCloud from '@/components/icons/IconDownloadCloud.vue';
import { post } from '@/services/http';
import axios, { AxiosError } from 'axios';
import msg from '@/services/userMsg';
import Vue from 'vue';
import { propOfType } from '@/services/utils';
import store from '@/services/store';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';

export default Vue.extend({
  name: 'YearReportSelector',
  components: {
    BaseDropdownMenu,
    IconChevronD,
    IconDownloadCloud,
  },
  props: {
    clientID: propOfType(Number),
  },
  data() {
    return {
      isOpen: false,
      yearSelected: new Date().getFullYear(),
      reportLoading: false,
    };
  },
  computed: {
    years() {
      const start = new Date().getFullYear();
      const end = 2022;
      return Array.from({ length: start - end + 1 }, (_, i) => start - i);
    },
    since(): string {
      return `${this.yearSelected}-01-01`;
    },
    until(): string {
      return `${this.yearSelected}-12-31`;
    },
    corporateAccount(): number | null {
      const account = store.getters.selectedAccount;
      return account.tag === 'corporateAccount' ? account.account.idCuentaSgv : null;
    },
  },
  methods: {
    selectYear(year: number) {
      this.yearSelected = year;
      this.isOpen = false;
      this.$emit('yearSelected', year);
    },
    downloadReport() {
      if (!this.corporateAccount) {
        this.$toast.error(msg.getError('uknown'));
        return;
      }
      const reportTS = localStorage.getItem(`${this.corporateAccount}-pedidos-ts`) as string | null;
      if (reportTS && new Date().getTime() - new Date(reportTS).getTime() < 600000) {
        this.$toast.info(msg.getInfo('report_already_requested'));
        this.reportLoading = false;
        return;
      }
      this.reportLoading = true;
      post('/pedidos/v1/reporte/pedidos', undefined, {
        params: {
          idClient: this.clientID,
          since: this.since,
          until: this.until,
          provider: 'InPunto',
          areMyOrders: false,
        },
      })
        .then(() => {
          localStorage.setItem(`${this.corporateAccount}-pedidos-ts`, new Date().toISOString());
          this.$toast.success(msg.getSuccess('report_requested'));

          this.$logEvent(
            'descarga_reporte',
            { tipo_reporte: msg.getReports('pedidos') },
          );
        })
        .catch((error: AxiosError) => {
          const errorCode = errorCodeFromAxiosError(error);
          if (errorCode === 'internal_error') {
            this.$toast.error(msg.getError('error_on_report'));
          } else {
            this.$toast.error(msg.getError(errorCode));
          }
        })
        .finally(() => {
          this.reportLoading = false;
        });
    },
  },
  mounted() {
    // Code to run when the component is mounted goes here
  },
});
