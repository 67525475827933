<template>
  <svg xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-label="ariaLabel"
    >
    <g :fill="iconColor" :stroke="strokeColor" :stroke-width="strokeWidth">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    viewBox: { type: String, default: '0 0 24 24' },
    ariaLabel: { type: String, default: null },
    width: { type: [Number, String], default: 18 },
    height: { type: [Number, String], default: 18 },
    iconColor: { type: String, default: 'currentColor' },
    strokeColor: { type: String, default: 'none' },
    strokeWidth: { type: [Number, String], default: '2' },
  },
};
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px;
}
</style>
