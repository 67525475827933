






























import Vue from 'vue';
import IconTriangle from '@/components/icons/IconTriangle.vue';
import IconX20 from '@/components/icons/IconX20.vue';
import { propOfType, getFullName } from '@/services/utils';
import userMsg from '@/services/userMsg';
import GenericPerson from '@/models/taaxii.com/taaxiiGenericPerson';
import { SolicitudPasaje } from '@/models/ExtrasData';

export default Vue.extend({
  components: {
    IconTriangle,
    IconX20,
  },
  props: {
    accion: String,
    pasaje: propOfType<SolicitudPasaje>(),
    index: String,
    isOpen: Boolean,
    isSelected: Boolean,
  },
  computed: {
    isMail(): boolean {
      return Boolean(!this.pasaje?.pasajero && this.pasaje?.despachante && this.pasaje?.receptor);
    },
    passenger(): GenericPerson | undefined {
      if (this.isMail) {
        if (this.accion === 'ascenso') return this.pasaje.despachante;
        return this.pasaje.receptor;
      }
      return this.pasaje.pasajero;
    },
    name(): string {
      if (this.passenger) {
        return getFullName(this.passenger);
      }
      return '-';
    },
    stopText(): string {
      if (this.isMail) {
        return this.accion === 'ascenso'
          ? `Envío de ${this.name}`
          : `Envío para ${this.name}`;
      }
      return this.name;
    },
    passengerDetails(): object {
      return {
        email: this.passenger?.email,
        ceco: this.pasaje.ceco.alias,
        celular: this.passenger?.phone,
        obs: this.pasaje.obs,
      };
    },
  },
  methods: {
    getLabel(key: string): string {
      return userMsg.getLabel(key);
    },
  },
});
