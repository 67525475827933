import Vue from 'vue';
import msg from '@/services/userMsg';

Vue.filter('capitalize', (value) => {
  if (!value) return '';
  const string = value.toString();
  return string.charAt(0).toUpperCase() + string.slice(1);
});

Vue.filter('label', (value) => msg.getLabel(value));
