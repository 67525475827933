

































import Vue from 'vue';
import { AxiosError } from 'axios';
import { user } from '@/services/api/userApi';
import { User } from '@/models/User';
import msg from '@/services/userMsg';
import { dispatch, mapStateTyped } from '@/services/store/utils';
import { errorCodeFromAxiosError } from '@/models/ErrorCode';
import NoPhone from '@/components/no-phone/NoPhone.vue';
import { GeneralAccount } from '@/models/accounts/GeneralAccount';
import { defaultCoords } from '@/services/Coords';
import { capitalizeText } from '@/services/utils';
import { getUserProperties } from '@/services/api/firebaseApi';
import Navbar from './sidebar/Navbar.vue';
import Dashboard from './dashboard/indexDashboard.vue';
import Sidebar from './sidebar/indexSidebar.vue';
import LoadingFullscreen from '../../LoadingFullscreen.vue';
import SupportButton from '../components/SupportButton.vue';
import NotificationBell from '../components/NotificationBell.vue';
import LogOutModal from '../components/LogOutModal.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    Dashboard,
    Sidebar,
    LoadingFullscreen,
    NoPhone,
    SupportButton,
    NotificationBell,
    Navbar,
    LogOutModal,
  },
  data() {
    return {
      timerCount: 600,
      timerInterval: undefined as number | undefined,
      dashboardKey: 0,
      displayHeight: window.innerHeight,
      isLogOut: false,
    };
  },
  computed: {
    ...mapStateTyped(['user', 'accounts']),
    selectedAccount(): GeneralAccount | null {
      return this.$store.getters.selectedAccount;
    },
    showDashboard(): boolean {
      return this.user !== null && this.accounts !== null && this.selectedAccount !== null;
    },
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
    navbarHeight(): number {
      return this.$store.getters.navbarHeight;
    },
    maxHeight(): string {
      return `${this.displayHeight - this.navbarHeight}px`;
    },
    logoutConfirmMsg(): string {
      return msg.getModalContent('logoutConfirm');
    },
    logoutTitle(): string {
      return msg.getTitle('logout');
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.startTimer();
    window.onkeypress = this.resetTimer;
    window.onmousedown = this.resetTimer;

    Promise.allSettled([
      user.getUser(),
      user.getAccounts(),
    ])
      .then((response) => {
        const data = [] as any[];

        for (const [i, res] of response.entries()) {
          if (res.status === 'rejected') {
            throw res.reason.response.data.code;
          } else {
            data.push(res.value.data);
          }
        }

        const accounts = data[1];
        if (!accounts.particularAccount && !accounts.corporateAccounts.length) {
          sessionStorage.setItem('errorMessage', 'no_accounts');
          this.logout();
        }

        dispatch('gotLoginData', {
          user: data[0],
          accounts,
        });

        this.logLogin(data[0]);

        if (this.$router.currentRoute.path === '/') {
          this.$router.push('/crear');
        }
      })
      .catch((error: AxiosError | string) => {
        let errorMessage = errorCodeFromAxiosError(error);
        errorMessage = errorMessage === 'unknown' ? 'login_error' : errorMessage;

        this.$toast.error(msg.getError(errorMessage));
        sessionStorage.setItem('errorMessage', errorMessage);
        this.logout();
      });

    sessionStorage.setItem('userLocation', JSON.stringify({
      ...defaultCoords,
      isRealLocation: false,
    }));
    const { geolocation } = navigator;
    if (geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          sessionStorage
            .setItem('userLocation', JSON.stringify({
              lat: latitude,
              lon: longitude,
              isRealLocation: true,
            }));
        },
        () => {},
        { enableHighAccuracy: true },
      );
    }
  },
  methods: {
    updateLogOut(newValue: boolean) {
      this.isLogOut = newValue;
    },
    handleResize() {
      this.displayHeight = window.innerHeight;
    },
    startTimer(): void {
      this.timerInterval = window.setInterval(this.countdown, 10000);
    },
    resetTimer(): void {
      this.timerCount = 600;
      window.clearInterval(this.timerInterval);
    },
    stopTimer(): void {
      window.clearInterval(this.timerInterval);
      this.timerInterval = undefined;
    },
    countdown(): void {
      if (this.timerCount > 0) {
        this.timerCount -= 10;
      } else if (process.env.NODE_ENV === 'production') {
        this.logout();
      }

      if (this.$route.name === 'login') {
        this.stopTimer();
      }
    },
    onLogoutModalClose(): void {
      this.isLogOut = false;
      const navbar = this.$refs.navbar as any;
      navbar.revertOption();
    },
    logout(): void {
      dispatch('logout', null);
      this.$router.push('/login');
    },
    refreshDashboard(): void {
      // https://michaelnthiessen.com/force-re-render/
      this.dashboardKey += 1;
    },
    logLogin(loggedUser?: User): void {
      if (loggedUser) {
        this.$addUserId(loggedUser.username);

        this.$addUserProperties({
          ...getUserProperties(),
        });

        this.$logEvent('login_inweb', {
          nombre: capitalizeText(`${loggedUser.apellido}, ${loggedUser.nombre}`),
        });
      }
    },
  },
});

