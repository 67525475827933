




























import { propOfType } from '@/services/utils';
import Vue from 'vue';
import IconAlert from '@/components/icons/IconAlert.vue';

export default Vue.extend({
  props: {
    onClose: propOfType<Function>(() => {}),
  },
  components: { IconAlert },
});
