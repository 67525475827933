



































import Vue from 'vue';
import {
  utils, propOfType, OSName, propOfTypeArrayOf,
} from '@/services/utils';
import store from '@/services/store';
import Pedido from '@/models/Pedido';
import PedidosListItem from './PedidosListItem.vue';
import PedidosListItemMobile from './PedidosListItemMobile.vue';

export default Vue.extend({
  components: {
    PedidosListItem,
    PedidosListItemMobile,
  },
  props: {
    pedidos: propOfTypeArrayOf<Pedido>(),
    selectedId: propOfType<number | null>(null),
    select: Function,
    deselect: Function,
  },
  data() {
    return {
      idViaje: null as number | null,
    };
  },
  methods: {
    splitByMonth(pedidoList: Pedido[] = []): Map<string, Pedido[]> {
      const getKey = (x) => utils.capitalize(
        x.datetime.toLocaleString('es', { month: 'long', year: 'numeric' }),
      );
      const append = (ys = [], y) => [...ys, y];
      return utils.collateBy(getKey)(append)(pedidoList);
    },
    getPedidosByMonth(month: string): Pedido[] {
      return this.pedidosSplited.get(month) || [];
    },
    onShowDetail(selected): void {
      this.select(selected.id);
    },
  },
  computed: {
    pedidosSplited(): Map<string, Pedido[]> {
      return this.splitByMonth(this.pedidos);
    },
    months(): string[] {
      return Array.from(this.pedidosSplited.keys());
    },
    isMobile(): boolean {
      return store.getters.isMobile;
    },
    paddingRight(): string {
      if (OSName() === 'iOS') return '1.5rem';

      if (this.isMobile) return 'calc(0.9rem - (100vw - 100%))';

      return this.pedidos.length > 6 ? '0.2rem' : '0.9rem';
    },
  },
});
