import { AccountId } from '@/models/accounts/AccountId';

/** Ayudantes para guardar información en localStorage relativa a un usuario.
 *
 * Puede ocurrir que dos usuarios diferentes se logueen en la aplicación y necesiten
 * tener algo guardado en el localStorage sin pisarse. Por ejemplo: la cuenta seleccionada
 * de cada uno.
*/

/** El estado que se guarda *para cada usuario* que se logueó alguna vez.
 *
 * No debe incluir información sensible.
 *
 * Todos los campos son opcionales para poder agregar campos fácilmente sin hacer suposiciones
 * falsas sobre el contenido del localStorage.
 *
 * ¡Recordar que si se modifica, un usuario puede quedar con la "versión vieja" guardada!
*/
export type UserStorage = {
  selectedAccountId?: AccountId,
}

/** Se usa esta función para darle valores por defecto a los campos.
 * En caso de que se agregue un campo con un valor por defecto, se puede
 * agregar acá. Por ahora devuelve un objeto vacío porque ningún campo
 * tiene valor por defecto.
*/
function defaultUserStorage(): UserStorage {
  return {};
}

export default {
  /** Lee los datos guardados del usuario en localStorage. */
  read(userId: number): UserStorage {
    const savedUserStorage = localStorage.getItem(`user-storage-${userId}`);

    return savedUserStorage ? JSON.parse(savedUserStorage) : defaultUserStorage();
  },
  /** Escribe datos en localStorage relativos al usuario/a logueado. */
  write(userId: number, value: UserStorage): UserStorage {
    const userStorage = { ...this.read(userId), ...value };

    localStorage.setItem(
      `user-storage-${userId}`,
      JSON.stringify(userStorage),
    );

    return userStorage;
  },
};
