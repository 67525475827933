

















import Vue from 'vue';
import ButtonLogin from '../components/ButtonLogin.vue';
import ButtonRecoverPass from '../components/ButtonRecoverPass.vue';

export default Vue.extend({
  components: {
    ButtonLogin,
    ButtonRecoverPass,
  },
});
