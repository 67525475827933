





















import Vue from 'vue';
import { propOfType, capitalizeText } from '@/services/utils';
import Transportista from '@/models/taaxii.com/taaxiiTransportista';
import GenericPerson from '@/models/taaxii.com/taaxiiGenericPerson';
import ProveedorData from '@/models/taaxii.com/taaxiiProveedorData';
import VehiculoData from '@/models/taaxii.com/taaxiiVehiculoData';
import IconDriverAvatar from '@/components/icons/IconDriverAvatar.vue';

export default Vue.extend({
  components: {
    IconDriverAvatar,
  },
  props: {
    driver: propOfType<Transportista>(null),
  },
  computed: {
    hasPicture(): boolean {
      return this.driver.choferFoto !== undefined;
    },
    chofer(): GenericPerson | null {
      return this.driver.chofer || null;
    },
    agencia(): ProveedorData | null {
      return this.driver.agencia || null;
    },
    vehiculo(): VehiculoData | null {
      return this.driver.vehiculo || null;
    },
    name(): string {
      if (!this.chofer) return 'Chofer no informado';

      return capitalizeText(this.chofer?.name || `${this.chofer?.lastName}, ${this.chofer?.firstName}`);
    },
    phone(): string {
      return this.chofer?.phone || '-';
    },
    marca(): string {
      if (!this.vehiculo) return 'Vehículo no informado';

      return capitalizeText(`${this.vehiculo?.marca} ${this.vehiculo?.modelo}`).trim().toUpperCase();
    },
    patente(): string {
      return this.vehiculo?.patente || '-';
    },
    formatedPatente(): string {
      if (this.patente.length === 7) {
        return `${this.patente.slice(0, 2)} ${this.patente.slice(2, 5)} ${this.patente.slice(5, 7)}`;
      }
      return `${this.patente.slice(0, 3)} ${this.patente.slice(3, 6)}`;
    },
  },
  methods: {
    capitalize(text: string): string {
      return capitalizeText(text);
    },
  },
});
