






export default {
  name: 'Dashboard',
};
