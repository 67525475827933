











import Vue from 'vue';

export default Vue.extend({
  props: {
    buttonClass: { type: String, default: '' },
    label: { type: String, default: '' },
    showSpinner: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    centerText: { type: Boolean, default: true },
  },
});
