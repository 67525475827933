





















import { propOfType, debounce } from '@/services/utils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    label: propOfType<String>('-'),
  },
  mounted() {
    // Debounce para que el input muestre valores maximos y minimos correctamente.
    this.debounceHours = debounce(this.limitHours, 200);
    this.debounceMins = debounce(this.limitMins, 200);
  },
  data() {
    return {
      hour: '0',
      min: '0',
      debounceHours: (...args) => {},
      debounceMins: (...args) => {},
    };
  },
  methods: {
    updateDelay() {
      this.$emit('updateDelay', `PT${this.hour}H${this.min}M`);
    },
    limitHours() {
      if (Number(this.hour) > 23) this.hour = '23';
      if (Number(this.hour) < 0) this.hour = '0';
      this.updateDelay();
    },
    limitMins() {
      if (Number(this.min) > 59) this.min = '59';
      if (Number(this.min) < 0) this.min = '0';
      this.updateDelay();
    },
  },
  watch: {
    hour() {
      this.debounceHours();
    },
    min() {
      this.debounceMins();
    },
  },
});
