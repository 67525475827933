class TimelineEvent {
  constructor(data) {
    this.category = data.category;
    this.timestamp = new Date(data.timestamp);
    this.person = data.persona;
    this.body = data.event;
  }
}

export default TimelineEvent;
