// --- ACCOUNT ID

import { CorporateAccount } from './CorporateAccount';
import { ParticularAccount } from './ParticularAccount';
import { GeneralAccount } from './GeneralAccount';
import * as generalAccount from './GeneralAccount';

/** Un ID de una cuenta identifica a una cuenta "general" (o sea, a la cuenta
 * particular o bien a una corporativa).
 *
 * En combinación con un `Accounts`, se puede usar el `AccountId` para modificar o
 * obtener una cuenta específica.
*/
export type AccountId = {
  tag: 'particularAccountId' | 'corporateAccountId',
  id: number,
}

export function fromGeneralAccount(account: GeneralAccount): AccountId {
  return account.tag === 'corporateAccount'
    ? { tag: 'corporateAccountId', id: account.account.idCuentaSgv }
    : { tag: 'particularAccountId', id: account.account.id };
}

export function fromParticularAccount(particularAccount: ParticularAccount): AccountId {
  return fromGeneralAccount(generalAccount.fromParticularAccount(particularAccount));
}

export function fromCorporateAccount(corpAccount: CorporateAccount): AccountId {
  return fromGeneralAccount(generalAccount.fromCorporateAccount(corpAccount));
}
