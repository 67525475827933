

















import { FormaDePago, formatPayMethod } from '@/models/accounts/shared';
import { propOfType } from '@/services/utils';
import Vue from 'vue';

export default Vue.extend({
  components: {
  },
  props: {
    selected: Boolean,
    description: String,
    formaDePago: propOfType<FormaDePago>(),
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    formatedAlias(): string {
      return formatPayMethod(this.formaDePago.alias || '');
    },
  },
  methods: {
  },
});
