






























































































import Vue from 'vue';
import IconClose24 from '@/components/icons/IconClose24.vue';
import { propOfType } from '@/services/utils';
import DatePicker from './DatePicker.vue';
import DayPicker from './DayPicker.vue';

function dateToDateStrings(d: Date): [string, string] {
  const ds = d.toISOString().split('T')[0];
  const ts = d.toLocaleString('es-AR', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  return [ts, ds];
}

export default Vue.extend({
  props: {
    minDate: String,
    maxDate: String,
    defaultDate: propOfType<Date>(),
    defaultDateRegreso: propOfType<Date>(),
    days: Array,
    daysRegreso: Array,
  },
  components: {
    DatePicker,
    IconClose24,
    DayPicker,
  },
  data() {
    const [t, d] = dateToDateStrings(this.defaultDate);
    const [tr, dr] = dateToDateStrings(this.defaultDateRegreso);
    return {
      date: d,
      dateRegreso: dr,
      time: t,
      timeRegreso: tr,
    };
  },
  watch: {
    date() {
      this.$emit('changeDate', this.date);
    },
    dateRegreso() {
      this.$emit('changeDateRegreso', this.dateRegreso);
    },
    time() {
      this.$emit('changeTime', this.time);
    },
    timeRegreso() {
      this.$emit('changeTimeRegreso', this.timeRegreso);
    },
  },
  methods: {
    close() {
      this.$emit('changeDate', this.date);
      this.$emit('changeDateRegreso', this.dateRegreso);
      this.$emit('changeTime', this.time);
      this.$emit('changeTimeRegreso', this.timeRegreso);
      this.$emit('close');
    },
  },
  computed: {
    maxDateRegreso(): string {
      const maxDate = new Date(this.date);
      maxDate.setMonth(maxDate.getMonth() + 3);
      return dateToDateStrings(maxDate)[1];
    },
  },
});
