


























import Vue from 'vue';
import msg from '../../../services/userMsg';
import IconShare from '../../../components/icons/IconShare.vue';
import IconWhatsapp from '../../../components/icons/IconWhatsapp.vue';
import IconMail from '../../../components/icons/IconMail.vue';
import IconCopy from '../../../components/icons/IconCopy.vue';

const url = window.location.href;

export default Vue.extend({
  components: {
    IconShare,
    IconWhatsapp,
    IconMail,
    IconCopy,
  },
  props: {
    shareIconColor: {
      type: String,
      default: 'text-gray-400',
    },
    seguimientoData: {
      type: Object,
      required: true,
    },
    menuPosition: {
      type: String,
      default: 'right-0',
    },
  },
  data() {
    return {
      isShareOpen: false,
      options: [
        {
          text: 'Compartir por Whatsapp',
          icon: IconWhatsapp,
          iconClass: 'text-wpp',
        },
        {
          text: 'Compartir por Email',
          icon: IconMail,
          iconClass: '',
        },
        {
          text: 'Copiar link al portapapeles',
          icon: IconCopy,
          iconClass: '',
        },
      ],
    };
  },
  methods: {
    share(option: number): void {
      const text = encodeURI(`Seguí tu pedido del ${this.viajeDate}`
        + ` ${this.viajeTime}hs desde ${url}`);
      switch (option) {
        case 0:
          this.$logEvent('seguimiento', { accion_seguimiento: 'compartir_wpp' });
          window.open(`${this.whatsappURL}${text}`);
          break;
        case 1:
          this.$logEvent('seguimiento', { accion_seguimiento: 'compartir_email' });
          window.open(`mailto:?to=&body=${text}`);
          break;
        case 2:
          this.$logEvent('seguimiento', { accion_seguimiento: 'compartir_copiar' });
          navigator.clipboard.writeText(url)
            .then(() => { this.$toast.success(msg.getSuccess('url_copied')); });
          break;
        default:
          break;
      }
    },
  },
  computed: {
    menuClass(): string {
      return `share-dropdown transition-all-200 ${this.menuPosition} min-width`;
    },
    viajeDate(): string {
      const date = new Date(this.seguimientoData.viaje.inicio);
      return date.toLocaleString('es-AR', {
        month: 'short',
        day: '2-digit',
      });
    },
    viajeTime(): string {
      const date = new Date(this.seguimientoData.viaje.inicio);
      return date.toLocaleString('es-AR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    },
    whatsappURL(): string {
      if (this.isMobile) return 'https://wa.me/?text=';
      return 'https://web.whatsapp.com/send?text=';
    },
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
  },
});
