import { CorporateAccount } from './CorporateAccount';
import { ParticularAccount } from './ParticularAccount';
import { FormaDePago } from './shared';

// --- GENERAL ACCOUNT

/** Una cuenta "general" puede ser una cuenta particular
 * o una cuenta corporativa.
*/
export type GeneralAccount =
  | { tag: 'particularAccount', account: ParticularAccount }
  | { tag: 'corporateAccount', account: CorporateAccount }

export function fromParticularAccount(account: ParticularAccount): GeneralAccount {
  return { tag: 'particularAccount', account };
}

export function fromCorporateAccount(account: CorporateAccount): GeneralAccount {
  return { tag: 'corporateAccount', account };
}

export function getAlias(account: GeneralAccount): string {
  if (account.tag === 'particularAccount') {
    return account.account.alias;
  }
  return account.account.nombreCliente;
}

export function getEmail(generalAccount: GeneralAccount): string | null {
  return generalAccount.account.email;
}

export function getIdCliente(generalAccount: GeneralAccount): number {
  return generalAccount.account.idClientePadre;
}

export function getIdPersona(generalAccount: GeneralAccount): number {
  return generalAccount.account.idPersona;
}

/** TODO:
 * La forma de pago por defecto puede ser null por error de configuración.
 * Luego de migrar los datos actuales para que ninguno sea null se puede agregar
 * el NOT NULL en la base de datos y modificar esta función.
*/
export function getFormaDePago(generalAccount: GeneralAccount): FormaDePago | null {
  return generalAccount.account.formaDePagoPorDefecto;
}
