













































































import Vue from 'vue';
import { arrayMapNotNull, propOfType } from '@/services/utils';
import IconCheck20 from '@/components/icons/IconCheck20.vue';
import IconX20 from '@/components/icons/IconX20.vue';

export default Vue.extend({
  components: { IconCheck20, IconX20 },
  props: {
    value: propOfType<Array<number>>(() => []),
    options: propOfType<Array<{ id: number, alias: string }>>(() => []),
    selectedTitle: propOfType<String>(() => 'Seleccionados:'),
    emptyText: propOfType<String>(() => 'Ninguna opción seleccionada'),
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    filteredOptions(): Array<{ id: number, alias: string, selected: boolean }> {
      return this.options
        .filter((option) => option.alias.toLowerCase().includes(this.searchQuery.toLowerCase()))
        .map((option) => ({
          id: option.id,
          alias: option.alias,
          selected: this.value.includes(option.id),
        }));
    },
    selectedOptions(): Array<{ id: number, alias: String }> {
      return arrayMapNotNull(
        this.value,
        (id) => {
          const option = this.options.find((x) => x.id === id);

          return option || null;
        },
      );
    },
  },
  methods: {
    onOptionListClick(id: number): void {
      if (this.value.includes(id)) {
        this.$emit('input', this.value.filter((option) => option !== id));
      } else {
        this.$emit('input', [id, ...this.value]);
      }
    },
  },
});
