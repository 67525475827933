


































import Vue from 'vue';
import {
  Evento, Direccion, EventType, fromIndexParadaToLabel,
} from '../../../models/requests-and-responses/seguimiento';
import {
  propOfType, propOfTypeArrayOf, formatTime, capitalizeText,
} from '../../../services/utils';

import TimelineSegment, { TimelineSegmentModel } from './TimelineSegment.vue';

// eslint-disable-next-line max-len
function fromEventoToSegment(evento: Evento | VirtualEvent, paradas: Direccion[]): TimelineSegmentModel {
  const paradaIndex = evento.parada;
  const { type } = evento;
  return {
    rowType: type,
    paradaLabel: paradaIndex !== undefined
      ? fromIndexParadaToLabel(paradaIndex, paradas.length)
      : undefined,
    direccion: paradaIndex !== undefined
      ? paradas[paradaIndex]
      : undefined,
    futurePresentOrPast: 'timestamp' in evento
      ? 'past'
      : 'future',
    timestamp: 'timestamp' in evento
      ? new Date(evento.timestamp)
      : undefined,
  };
}

type VirtualEvent = {
  type: EventType
  parada?: number // Indice a parada
}

export default Vue.extend({
  components: {
    TimelineSegment,
  },
  props: {
    eventos: propOfTypeArrayOf<Evento>(),
    paradas: propOfTypeArrayOf<Direccion>(),
    minutesLeft: propOfType<number | null>(null),
  },
  data() {
    return {
      segmentHovered: null as number | null,
      waitingTime: '',
    };
  },
  created() {
    this.updateWaitingTime();
    setInterval(this.updateWaitingTime, 1000);
  },
  methods: {
    formatDirection(direccion: string): string {
      return capitalizeText(direccion).split(',', 3).join(',');
    },
    selectSegment(index: null | number) {
      if (
        index !== null
          && this.checkIfSegmentIsPast(index)
      ) {
        this.segmentHovered = index;

        this.$logEvent('seguimiento', { accion_seguimiento: 'hover_linea_de_tiempo' });
      } else {
        this.segmentHovered = null;
      }
    },
    updateWaitingTime() {
      const lastEvent = this.eventos[this.eventos.length - 1];
      if (lastEvent.type !== 'arribado') return;
      const waitingStart = new Date(lastEvent.timestamp);
      const waitingTime = Date.now() - waitingStart.getTime();
      const waitingTimeInSeconds = Math.floor(waitingTime / 1000.0);
      const seconds = waitingTimeInSeconds % 60;
      const minutes = Math.floor(waitingTimeInSeconds / 60) % 60;
      const hours = Math.floor(waitingTimeInSeconds / (60 * 60));
      const minAndSec = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      this.waitingTime = hours > 0 ? `${hours}:${minAndSec}` : minAndSec;
    },
    checkIfSegmentIsPast(index: number): boolean {
      return this.segments[index].futurePresentOrPast === 'past';
    },
  },
  computed: {
    // Devuelve todos los eventos que se supone que deben existir.
    virtualEvents(): VirtualEvent[] {
      const virtualEvents = [] as VirtualEvent[];
      virtualEvents.push({ type: 'registrado' });
      virtualEvents.push({ type: 'confirmado' });
      this.paradas.forEach((_, index) => {
        virtualEvents.push({ type: 'circulando', parada: index });
        virtualEvents.push({ type: 'arribado', parada: index });
      });

      // Leer comentario (1) de arriba.
      // Comentamos la linea porque no lo usamos:
      // virtualEvents.push({ type: 'finalizado' });

      return virtualEvents;
    },
    segments(): TimelineSegmentModel[] {
      const segments: TimelineSegmentModel[] = [];

      // Primero creamos las rows que ya pasaron.
      this.virtualEvents.forEach((event) => {
        const realEvent = this.eventos
          .find((e) => e.type === event.type && e.parada === event.parada);
        if (realEvent && realEvent.type !== 'finalizado') {
          segments.push(fromEventoToSegment(realEvent, this.paradas));
        }
      });

      // En caso de que no haya una cancelación o un fallo o una anulación...
      const croppingEvents: EventType[] = ['anulado', 'cancelado', 'fallo'];
      if (!this.eventos.some((e) => croppingEvents.includes(e.type))) {
        // Añadimos las rows del futuro, que son todas aquellos eventos que no han pasado.
        this.virtualEvents.forEach((e) => {
          const happened = this.eventos.some((e2) => e2.type === e.type && e2.parada === e.parada);
          if (!happened) {
            segments.push(fromEventoToSegment(e, this.paradas));
          }
        });
      }
      const lastIndex = segments.slice().reverse().findIndex(
        (segment) => segment.timestamp !== undefined,
      );
      if (lastIndex !== -1) {
        const lastNonNullTimestampIndex = segments.length - 1 - lastIndex;
        segments[lastNonNullTimestampIndex].futurePresentOrPast = 'present';
      }
      return segments;
    },
    hoveredSegmentData(): string {
      if (this.segmentHovered !== null) {
        const segment = this.segments[this.segmentHovered];
        const direccion = segment.direccion ? segment.direccion.alias || segment.direccion.direccion : '';
        const stringDireccion = direccion ? `a ${capitalizeText(this.formatDirection(direccion))}` : '';
        return segment.timestamp
          ? `${formatTime(segment?.timestamp).slice(0, -2)} · ${capitalizeText(segment.rowType)} ${stringDireccion}`
          : '';
      }
      return '';
    },
    presentSegmentTimestamp(): string {
      const lastEvent = this.eventos[this.eventos.length - 1];
      const timestamp = new Date(lastEvent.timestamp);
      return lastEvent?.timestamp
        ? formatTime(timestamp).slice(0, -2)
        : '';
    },
    presentSegmentStatus(): string {
      const lastEvent = this.eventos[this.eventos.length - 1];
      const paradaIndex = lastEvent.parada as number;
      const label = fromIndexParadaToLabel(paradaIndex, this.paradas.length);
      switch (lastEvent?.type) {
        case 'circulando':
          return `En camino a ${label}`;
        case 'arribado':
          return paradaIndex === this.paradas.length - 1
            ? 'Finalizado'
            : `Arribado en ${label}`;
        default: return capitalizeText(lastEvent.type);
      }
    },
    presentSegmentDirection(): string {
      const lastEvent = this.eventos[this.eventos.length - 1];
      const paradaIndex = lastEvent.parada as number;
      const direccion = this.paradas[paradaIndex];
      if (direccion === undefined) return '';
      return capitalizeText(this.formatDirection(direccion.alias || direccion.direccion));
    },
    isEnded(): boolean {
      return this.presentSegmentStatus === 'Finalizado';
    },
    isWaiting(): boolean {
      const lastEvent = this.eventos[this.eventos.length - 1];
      return lastEvent?.type === 'arribado' && this.presentSegmentStatus !== 'Finalizado';
    },
    isEnCamino(): boolean {
      const lastEvent = this.eventos[this.eventos.length - 1];
      return lastEvent?.type === 'circulando';
    },
    minutesToString(): string {
      if (this.minutesLeft === null) return '...';
      return this.minutesLeft === 0 ? '1' : this.minutesLeft.toString();
    },
    ETA(): string {
      const lastEvent = this.eventos[this.eventos.length - 1];
      const paradaIndex = lastEvent.parada as number;
      const label = fromIndexParadaToLabel(paradaIndex, this.paradas.length);
      return `Tiempo estimado a ${label}: ${this.minutesToString} min`;
    },
  },
});
