import axios, { AxiosRequestConfig } from 'axios';
import {
  Endpoint, InwebMethod, InwebURL, InwebRequest, GET, POST, PATCH, DELETE, PUT,
} from './types';
import { InwebEndpoint } from './endpoints';
import { dispatch } from '../store/utils';

export const SGV_BASE_URL = process.env.VUE_APP_API_URL_SGV;
export const INSERVER_BASE_URL = process.env.VUE_APP_API_URL;

// Agregar aquí un elemento en caso empecemos a utilizar otro servidor.
export const BASES = {
  inserver: INSERVER_BASE_URL,
  sgv: SGV_BASE_URL,
};

export type FilterByMethod<MTH extends InwebMethod, C extends InwebEndpoint> =
  C extends Endpoint<MTH, any, any, any> ? C : never;

export type FilterByMethodAndURL<MTH extends InwebMethod,
  URL extends InwebURL, C extends InwebEndpoint> =
  C extends Endpoint<MTH, URL, any, any> ? C : never;

export type InwebEndpointOfMethod<MTH extends InwebMethod> = FilterByMethod<MTH, InwebEndpoint>;
export type InwebEndpointOfMethodAndURL<MTH extends InwebMethod, URL extends InwebURL> =
  FilterByMethodAndURL<MTH, URL, InwebEndpoint>

export type URLOfEndpoint<C extends InwebEndpoint> =
  C extends Endpoint<any, infer U, any, any> ? U : never;

export type InwebRequestOfEndpoint<C extends InwebEndpoint> =
  C extends Endpoint<any, any, infer U, any> ? U : never;

export type InwebResponseOfEndpoint<C extends InwebEndpoint> =
  C extends Endpoint<InwebMethod, string, InwebRequest, infer U> ? U : never;

export function getHeaders(): Record<string, string> | undefined {
  const token = localStorage.getItem('user');

  if (token === null) {
    dispatch('logout', null);
    return undefined;
  }

  return {
    Authorization: token,
  };
}

export const api = axios.create({
  baseURL: INSERVER_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Application-Name': 'InWeb',
    'X-Application-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Argentina/Buenos_Aires',
  },
});

/**
 * Toma un url, por ejemplo: '$sgv/ejemplo/$id/$name/'
 * Y toma un objeto, por ejemplo: {id: 10, name: 'Jonh' }
 * Y devuelve: 'http://sgv.com.ar/ejemplo/10/Jonh'.
 * En caso de que la url no contenga un signo '$', no hace nada.
 */
function parseTemplate(template: InwebURL, provider?: any): string {
  let t = template;

  // Reemplazamos la base url (en caso de que la haya)
  if (t.startsWith('$')) {
    Object.entries(BASES).forEach((entry) => {
      const [key, value] = entry;
      if (value !== undefined) {
        t = t.replace(`$${key}/`, value);
      }
    });
  }

  // Reemplazamos variables dentro de la url (en caso de que las haya)
  if (t.indexOf('$') >= 0) {
    if (provider === undefined) {
      throw new Error("A una url con '$', se le debe pasar un request que rellene el campo.");
    }

    // Es un template. Vamos a reemplazar aquellos $...
    Object.entries(provider).forEach((entry) => {
      const [key, value] = entry;
      let v = value;
      if (typeof v === 'number') {
        v = v.toString();
      }

      if (typeof v !== 'string') {
        throw new Error(
          `El parámetro ${key} debe ser tipo string o number porque está siendo usado como valor de un template de url.`,
        );
      }

      t = t.replaceAll(`$${key}`, v);
    });

    if (t.indexOf('$') >= 0) {
      throw new Error('No se pudieron encontrar algunos valores del template.');
    }
  }
  return t;
}

export async function get<
  C extends InwebEndpointOfMethod<GET>,
  URL extends URLOfEndpoint<C>,
  EP extends InwebEndpointOfMethodAndURL<GET, URL>,
  REQ extends InwebRequestOfEndpoint<EP>,
  RES extends InwebResponseOfEndpoint<EP>
>(url: URL, params?: REQ, config?: AxiosRequestConfig<any>): Promise<RES> {
  const urlParsed = parseTemplate(url, params);
  const axiosRes = await api.get<RES>(urlParsed, {
    headers: getHeaders(),
    params,
    ...config,
  });
  return axiosRes.data;
}

export async function post<
  C extends InwebEndpointOfMethod<POST>,
  URL extends URLOfEndpoint<C>,
  EP extends InwebEndpointOfMethodAndURL<POST, URL>,
  REQ extends InwebRequestOfEndpoint<EP>,
  RES extends InwebResponseOfEndpoint<EP>
>(url: URL, body?: REQ, config?: AxiosRequestConfig<any>): Promise<RES> {
  const urlParsed = parseTemplate(url, body);
  const axiosRes = await api.post<RES>(urlParsed, body, {
    headers: getHeaders(),
    ...config,
  });
  return axiosRes.data;
}

export async function patch<
  C extends InwebEndpointOfMethod<PATCH>,
  URL extends URLOfEndpoint<C>,
  EP extends InwebEndpointOfMethodAndURL<PATCH, URL>,
  REQ extends InwebRequestOfEndpoint<EP>,
  RES = InwebResponseOfEndpoint<EP>
>(url: URL, data?: REQ): Promise<RES> {
  const urlParsed = parseTemplate(url, data);
  const axiosRes = await api.patch<RES>(urlParsed, data, {
    headers: getHeaders(),
  });
  return axiosRes.data;
}

export async function delet<
  C extends InwebEndpointOfMethod<DELETE>,
  URL extends URLOfEndpoint<C>,
  EP extends InwebEndpointOfMethodAndURL<DELETE, URL>,
  REQ extends InwebRequestOfEndpoint<EP>,
  RES = InwebResponseOfEndpoint<EP>
>(url: URL, data?: REQ): Promise<RES> {
  const urlParsed = parseTemplate(url, data);
  const axiosRes = await api.delete<RES>(urlParsed, {
    headers: getHeaders(),
    data,
  });
  return axiosRes.data;
}

export async function put<
  C extends InwebEndpointOfMethod<PUT>,
  URL extends URLOfEndpoint<C>,
  EP extends InwebEndpointOfMethodAndURL<PUT, URL>,
  REQ extends InwebRequestOfEndpoint<EP>,
  RES extends InwebResponseOfEndpoint<EP>
>(url: URL, body?: REQ, config?: AxiosRequestConfig<any>): Promise<RES> {
  const urlParsed = parseTemplate(url, body);
  const axiosRes = await api.put<RES>(urlParsed, body, {
    headers: getHeaders(),
    ...config,
  });
  return axiosRes.data;
}
