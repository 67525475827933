































import Vue from 'vue';

export default Vue.extend({
  props: {
    showText: {
      type: Boolean,
      default: true,
    },
    showDate: {
      type: Boolean,
      default: true,
    },
    showTime: {
      type: Boolean,
      default: true,
    },
    minDate: String,
    maxDate: String,
    defaultDate: String,
    defaultTime: String,
  },
  data() {
    return {
      date: this.defaultDate,
      time: this.defaultTime,
    };
  },
  watch: {
    defaultDate() {
      this.date = this.defaultDate;
    },
    defaultTime() {
      this.time = this.defaultTime;
    },
  },
  computed: {
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
  },
});
