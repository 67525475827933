







































































































import IconCalendar from '@/components/icons/IconCalendar.vue';
import IconElipsisMenu from '@/components/icons/IconElipsisMenu.vue';
import IconEditSolid from '@/components/icons/IconEditSolid.vue';
import Vue from 'vue';
import { propOfType } from '@/services/utils';
import { eventBus } from '@/main';
import SectionLayout from '../SectionLayout.vue';
import { AddressOfForm, apply } from '../../indexForm.vue';
import { Metadata } from '../../../CreateWeb.vue';
import DatePicker from './DatePicker.vue';
import DelayPicker from './DelayPicker.vue';
import PlanificationModal from './PlanificationModal.vue';

function dateToDateStrings(d: Date): [string, string] {
  const ds = d.toISOString().split('T')[0];
  const ts = d.toLocaleString('es-AR', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  return [ts, ds];
}

function initialDateStrings(offset: number): [string, string] {
  const d = new Date();
  d.setMinutes(d.getMinutes() + offset + 5);
  return dateToDateStrings(d);
}

function getDatePlanificacion(date : Date): [string, string] {
  const d = new Date(date);
  d.setDate(d.getDate() + 7);
  return dateToDateStrings(d);
}

function maxDateString(): string {
  const d = new Date();
  d.setHours(d.getHours() + 8760);
  return dateToDateStrings(d)[1];
}

export default Vue.extend({
  components: {
    SectionLayout,
    IconCalendar,
    IconElipsisMenu,
    DatePicker,
    IconEditSolid,
    PlanificationModal,
    DelayPicker,
  },
  props: {
    isExpanded: Boolean,
    dataOfForm: Object,
    userTriedToConfirm: Boolean,
    timeEstimation: Number,
    initialOption: Number,
    initialDate: propOfType<Date | null>(() => null),
    antelacionLAP: propOfType<number>(30),
    metadata: propOfType<Metadata>(),
    addresses: propOfType<AddressOfForm[]>(() => []),
    keepOpen: propOfType<boolean>(false),
    scrollableContainerId: propOfType<string | null>(null),
  },
  created() {
    eventBus.$emit('checkIfLAP', this.checkIfLAP);
  },
  mounted() {
    this.initiateDates();
    if (this.initialOption !== null && this.initialDate) {
      this.optionSelected = this.initialOption;
      [this.time, this.date] = dateToDateStrings(this.initialDate);
    }
  },
  data() {
    return {
      optionSelected: 0,
      allOptions: this.$store.state.isMobile ? ['Lo antes posible', 'Reservar'] : ['Lo antes posible', 'Reservar', 'Reservar con regreso', 'Planificar'],
      isMenuOpen: false,
      time: '',
      date: '',
      timeRegreso: '',
      dateRegreso: '',
      minDate: '',
      maxDate: '',
      minTime: '',
      days: [] as number[],
      daysRegreso: [] as number[],
      isModal: false,
      subtitleClass: 'poppins font-semibold text-gray-500 text-xs',
      errorOnLAP: false,
    };
  },
  computed: {
    headerText(): string {
      switch (this.optionSelected) {
        case 0:
          break;
        case 1: {
          const d = this.parseDate(this.date);
          const t = this.time;
          return this.isMobile
            ? `El ${d} a las ${t}`
            : `Reserva para ${d} ${t}`;
        }
        case 2:
          return 'Reserva con regreso para...';
        case 3:
          return 'Reservas planificadas para...';
        default:
          break;
      }
      return this.options[this.optionSelected];
    },
    dateParsed(): string {
      const original = this.date;
      const year = original.substr(2, 2);
      const month = original.substr(5, 2);
      const day = original.substr(8, 2);
      return `${day}/${month}/${year}`;
    },
    getDateInstance(): Date | null {
      if (this.optionSelected === 0) return null;
      const date = new Date(`${this.date}T${this.time}`);
      return date;
    },
    getDateRegreso(): Date | null {
      if (this.optionSelected < 2) {
        return null;
      }
      return new Date(`${this.dateRegreso}T${this.timeRegreso}`);
    },
    getDays(): number[] {
      if (this.optionSelected < 3) {
        return [];
      }
      return this.days;
    },
    getDaysRegreso(): number[] {
      if (this.optionSelected < 3) {
        return [];
      }
      return this.daysRegreso;
    },
    getTimeEstimation(): Date | null {
      const d = new Date(`${this.date}T${this.time}`);
      if (d) {
        d.setMinutes(d.getMinutes() + this.timeEstimation + 20);
      }
      return d;
    },
    getMinTime(): string {
      if (this.date === this.minDate) return this.minTime;
      return '';
    },
    isRegresoCorrect(): boolean {
      const dateRegreso = this.getDateRegreso;
      const timeEstimation = this.getTimeEstimation;
      if (dateRegreso && timeEstimation) {
        return dateRegreso > timeEstimation;
      }
      return true;
    },
    isPlanificadoCorrect(): boolean {
      if (this.optionSelected === 3) {
        for (let day of this.days) {
          const desde = new Date(`${this.date}T${this.time}`);
          const hasta = new Date(`${this.dateRegreso}T${this.timeRegreso}`);
          // Hago esto porque para TS la semana empieza en domingo.
          day = (day + 1) === 7 ? 0 : day + 1;
          while (desde <= hasta) {
            if (desde.getDay() === day) return true;
            desde.setDate(desde.getDate() + 1);
          }
        }
        return false;
      }
      return true;
    },
    errorMessage(): string {
      const dateInstance = this.getDateInstance;
      const dateRegreso = this.getDateRegreso;
      if (dateInstance && dateInstance < new Date()) {
        return 'Reserva en el pasado';
      }
      if (this.errorOnLAP) {
        return `La antelación para reservar debe ser mayor a ${this.antelacionLAP} `
        + 'min sobre la fecha y hora actual';
      }
      if (dateRegreso && dateRegreso < new Date()) {
        return 'Regreso en el pasado';
      }
      if (!this.isRegresoCorrect) {
        return this.optionSelected === 2 ? 'Regreso es previo al fin del viaje de ida'
          : 'El rango de fechas es incorrecto';
      }
      if (this.optionSelected === 3 && !this.days.length) {
        return 'Debe seleccionar un día de ida';
      }
      if (!this.isPlanificadoCorrect) {
        return 'No existe un viaje valido en el rango de fechas seleccionado';
      }
      return '';
    },
    error(): string {
      if (!this.userTriedToConfirm) return '';
      return this.errorMessage;
    },
    options(): string[] {
      return ['MENSAJERIA', 'PAQUETERIA', 'CONPARADAS'].includes(this.metadata.type)
        ? this.allOptions.slice(0, 2) : this.allOptions;
    },
    hasStops(): boolean {
      return this.metadata.type === 'CONPARADAS';
    },
    filteredAddresses(): AddressOfForm[] {
      return this.addresses.slice(1, -1).filter((adds) => adds);
    },
    checkExpanded(): boolean {
      return this.optionSelected === 0 && !this.hasStops ? false : this.isExpanded;
    },
    toWatch(): any[] {
      return [this.optionSelected, this.date, this.time, this.dateRegreso, this.timeRegreso,
        this.days, this.daysRegreso, this.optionSelected];
    },
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
  },
  watch: {
    toWatch() {
      this.updateDateOnForm();
    },
    optionSelected() {
      switch (this.optionSelected) {
        case 2: {
          const offsetDate = new Date(this.getTimeEstimation as Date);
          offsetDate.setMinutes(offsetDate.getMinutes() + 5);
          [this.timeRegreso, this.dateRegreso] = dateToDateStrings(offsetDate);
          break;
        }

        case 3: {
          [this.timeRegreso, this.dateRegreso] = getDatePlanificacion(this.getDateInstance as Date);
          this.showModal();
          break;
        }
        default:
          break;
      }
    },
    keepOpen() {
      this.isMenuOpen = this.keepOpen;
    },
  },
  methods: {
    initiateDates() {
      [this.time, this.date] = initialDateStrings(this.antelacionLAP);
      [this.timeRegreso, this.dateRegreso] = initialDateStrings(this.antelacionLAP * 2);
      this.maxDate = maxDateString();
      this.minDate = this.date;
      this.minTime = this.time;
    },
    updateDateOnForm() {
      this.errorOnLAP = false;
      apply(this, (data) => {
        data.date = this.getDateInstance;
        data.dateRegreso = this.getDateRegreso;
        data.days = this.getDays;
        data.daysRegreso = this.getDaysRegreso;
        data.typeOfTrip = this.optionSelected;
        data.dateError = Boolean(this.errorMessage || !this.isRegresoCorrect);
        if ('dateOption' in data) {
          data.dateOption = this.optionSelected;
        }
      });
    },
    updateDelay(address: AddressOfForm, delay: string) {
      apply(this, (data) => {
        const addressToUpdate = data.addresses.find((adds) => adds?.id === address?.id);
        if (addressToUpdate) {
          addressToUpdate.espera = delay;
        }
      });
    },
    selected(option: number): void {
      this.isMenuOpen = !this.isMenuOpen;
      if (option !== this.optionSelected) {
        this.optionSelected = option;
        this.initiateDates();
        if (option === 0 && !this.hasStops) {
          this.$emit('close');
        } else {
          this.$emit('open');
        }
      }
    },
    parseDate(date: string): string {
      const original = date;
      const year = original.substr(2, 2);
      const month = original.substr(5, 2);
      const day = original.substr(8, 2);
      return `${day}/${month}/${year}`;
    },
    parseDays(daysSelected: number[]): string {
      const dayNames = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'];
      const days = [] as String[];
      daysSelected.forEach((day) => { days.push(dayNames[day]); });
      return days.length ? days.join(', ') : 'Seleccione un día';
    },
    showModal() {
      this.isModal = true;
    },
    hideModal() {
      this.isModal = false;
    },
    checkIfLAP(): boolean {
      const d = new Date();
      d.setMinutes(d.getMinutes() + this.antelacionLAP);
      const isLAP = Boolean(this.getDateInstance && this.getDateInstance < d);
      this.errorOnLAP = isLAP;
      return isLAP;
    },
  },
});
