




















































import Vue from 'vue';
import { RecorridoInfo, Pasaje } from '@/models/requests-and-responses/sgv';
import { capitalizeText, propOfType } from '@/services/utils';
import IconLocationHollow from '@/components/icons/IconLocationHollow.vue';
import { SolicitudParada, SolicitudPasaje } from '@/models/ExtrasData';
import IconInpunto from '@/components/icons/IconInpunto.vue';
import IconDriverBlack from '@/components/icons/IconDriverBlack.vue';
import PassengerInfo from './PassengerInfo.vue';

export default Vue.extend({
  props: {
    route: propOfType<RecorridoInfo>(null),
    showSwitch: propOfType<boolean>(false),
  },
  components: {
    PassengerInfo,
    IconLocationHollow,
    IconDriverBlack,
    IconInpunto,
  },
  data() {
    return {
      selectedPassenger: null as string | null,
      recorridoReal: false,
      tempDisable: false,
    };
  },
  mounted() {
    this.recorridoReal = this.showSwitch;
  },
  watch: {
    passengerStops() {
      this.$emit('selected', this.passengerStops);
    },
    recorridoReal() {
      this.$emit('switchRecorrido', this.recorridoReal);
      this.tempDisable = true;
      setTimeout(() => {
        this.tempDisable = false;
      }, 1000);
    },
  },
  computed: {
    selectedPassengerId(): number | null{
      if (this.selectedPassenger) {
        return Number(this.selectedPassenger.split('-')[1]);
      }
      return null;
    },
    passengerStops(): number[] {
      return this.route.paradas
        .reduce((filtered: number[], { idPasajesAsc, idPasajesDes }, index) => {
          const pasajes = [...idPasajesAsc, ...idPasajesDes];
          if (pasajes.some((idPasaje) => idPasaje === this.selectedPassengerId)) {
            filtered.push(index);
          }
          return filtered;
        }, [] as number[]);
    },
  },
  methods: {
    getIdPasajes({ idPasajesAsc, idPasajesDes }: SolicitudParada)
      : { idPasaje: number, accion: string }[] {
      return [...idPasajesAsc.map((id) => ({ idPasaje: id, accion: 'ascenso' })),
        ...idPasajesDes.map((id) => ({ idPasaje: id, accion: 'descenso' }))];
    },
    formatDate(rawDate: Date | string): string {
      // Por alguna razón hay un par de casos donde queda como string
      const date = typeof rawDate === 'string' ? new Date(rawDate) : rawDate;
      const ds = date.toLocaleString('es-AR', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      });
      const ts = date.toLocaleString('es-AR', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      });
      return `${ds} a las ${ts} hs`;
    },
    formatAddress(dir: string): string {
      return capitalizeText(dir.split(',', 2).join(', '));
    },
    findPassage(id: number): SolicitudPasaje | null {
      return this.route.pasajes.find((pasaje) => pasaje.idPasaje === id) || null;
    },
    iconColor(index: number): string {
      if (index < this.route.paradas.length - 1) return 'bg-white';
      return this.timelineColor(index, true);
    },
    timelineColor(index: number, isMarker: boolean): string {
      if (!this.selectedPassenger) return 'bg-gray-600';
      const min = this.passengerStops[0];
      const max = isMarker ? this.passengerStops[1] : this.passengerStops[1] - 1;
      if (min <= index && index <= max) return 'bg-gray-600';
      return 'bg-gray-400';
    },
  },
});
