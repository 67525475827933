


























import GenericPerson from '@/models/taaxii.com/taaxiiGenericPerson';
import { GenericCeCo } from '@/models/accounts/shared';
import { propOfType, capitalizeText } from '@/services/utils';
import Vue from 'vue';
import IconX20 from '@/components/icons/IconX20.vue';

export default Vue.extend({
  components: {
    IconX20,
  },
  props: {
    person: propOfType<GenericPerson>(),
    ceco: propOfType<GenericCeCo>(null),
    obs: propOfType<string>(),
  },
  data() {
    return {
      moreData: false,
    };
  },
  computed: {
    name(): string {
      return this.formatName(this.person);
    },
    email(): string {
      return this.person?.email || '';
    },
    phone(): string {
      return this.person?.phone || '';
    },
    costCenter(): string {
      return `(${this.ceco?.alias})` || '';
    },
    observations(): string {
      return this.obs || 'No hay observaciones';
    },
  },
  methods: {
    formatName(person: GenericPerson): string {
      return capitalizeText(`${person?.lastName}, ${person?.firstName}`);
    },
    close() {
      this.$emit('close');
    },
  },
});
