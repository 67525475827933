







/**
 * Este componente sirve para wrappear a una pantalla que no debe funcionar
 * con celular:
 *
 * <NoPhone>
 *  <div>Este contenido no se muestra en celulares, en vez de eso se muestra DownloadApp.vue</div>
 * </NoPhone>
 */

import { dispatch } from '@/services/store/utils';
import Vue from 'vue';
import DownloadApp from './DownloadApp.vue';
import NoLandscape from './NoLandscape.vue';

export default Vue.extend({
  components: { DownloadApp, NoLandscape },
  props: {
    override: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    };
  },
  methods: {
    updateScreenSize(): void {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
      dispatch('updateMobileState', this.screenWidth < 640);
    },
  },
  computed: {
    isLandscapeAndMobile(): boolean {
      return this.screenWidth > this.screenHeight
        && this.screenHeight <= 450
        && this.screenWidth <= 950;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateScreenSize);
    this.updateScreenSize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenSize);
  },
});
