



























import Vue from 'vue';
import { eventBus } from '@/main';
import IconChevronDownSection from '@/components/icons/IconChevronDownSection.vue';
import { propOfType } from '@/services/utils';
import Map, { AddressForMap } from './Map.vue';

export default Vue.extend({
  name: 'BottomsheetAndMap',
  props: {
    addresses: propOfType<AddressForMap[]>(() => []),
    onDistanceChangedCallback: Function,
    onTimeChangedCallback: Function,
    isFormSelected: propOfType(Boolean),
  },
  created() {
    eventBus.$on('grow-bottomsheet', this.growBottomSheet);
    eventBus.$on('shrink-bottomsheet', this.shrinkBottomSheet);
  },
  components: {
    Map,
    IconChevronDownSection,
  },
  data() {
    return {
      bottomSheetSize: 100,
      isExpanded: true,
    };
  },
  watch: {
    isFormSelected() {
      if (this.isFormSelected) {
        this.shrinkBottomSheet();
      } else {
        this.growBottomSheet();
      }
    },
  },
  computed: {
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
    navbarHeight(): number {
      return this.$store.getters.navbarHeight;
    },
  },
  methods: {
    changeBottomSheetSize() {
      if (this.isExpanded) {
        this.shrinkBottomSheet();
      } else {
        this.growBottomSheet();
      }
    },
    growBottomSheet() {
      this.bottomSheetSize = 100;
      this.isExpanded = true;
    },
    shrinkBottomSheet() {
      this.bottomSheetSize = 60;
      this.isExpanded = false;
    },
  },
});
