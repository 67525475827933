
































import Vue from 'vue';
import { propOfType, capitalizeText } from '@/services/utils';
import { CalificacionInfo } from '@/models/requests-and-responses/sgv';
import IconStar from '@/components/icons/IconStar.vue';
import IconStarFull from '@/components/icons/IconStarFull.vue';
import IconExclamation from '@/components/icons/IconExclamation.vue';

export default Vue.extend({
  props: {
    ratings: propOfType<CalificacionInfo[]>(),
  },
  components: {
    IconStar,
    IconStarFull,
    IconExclamation,
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {
    calificacion(): number {
      if (this.ratings.length === 1) return this.ratings[0].calificacion;
      return Math.floor(this.ratings.reduce((total, rating) => (
        total + rating.calificacion), 0) / this.ratings.length);
    },
    calificacionTexto(): string {
      switch (this.calificacion) {
        case 1:
          return 'Malo';
        case 2:
          return 'Regular';
        case 3:
          return 'Esperado';
        case 4:
          return 'Muy Bueno';
        case 5:
          return 'Excelente';
        default:
          return '-';
      }
    },
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    capitalizeText(text: string): string {
      return capitalizeText(text);
    },
  },
});
