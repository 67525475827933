























import Vue from 'vue';
import IconDriver from '@/components/icons/IconDriver.vue';
import { propOfType, capitalizeText } from '@/services/utils';
import Transportista from '@/models/taaxii.com/taaxiiTransportista';
import GenericPerson from '@/models/taaxii.com/taaxiiGenericPerson';
import ProveedorData from '@/models/taaxii.com/taaxiiProveedorData';
import VehiculoData from '@/models/taaxii.com/taaxiiVehiculoData';

export default Vue.extend({
  components: {
    IconDriver,
  },
  props: {
    driver: propOfType<Transportista>(null),
  },
  computed: {
    chofer(): GenericPerson | null {
      return this.driver.chofer || null;
    },
    agencia(): ProveedorData | null {
      return this.driver.agencia || null;
    },
    vehiculo(): VehiculoData | null {
      return this.driver.vehiculo || null;
    },
    name(): string {
      if (!this.chofer) return 'Chofer no informado';

      return capitalizeText(this.chofer?.name || `${this.chofer?.lastName}, ${this.chofer?.firstName}`);
    },
    phone(): string {
      return this.chofer?.phone || '-';
    },
    marca(): string {
      if (!this.vehiculo) return 'Vehículo no informado';

      return capitalizeText(`${this.vehiculo?.marca} ${this.vehiculo?.modelo}`).trim();
    },
    patente(): string {
      return this.vehiculo?.patente || '-';
    },
    denominacion(): string {
      if (!this.agencia) return 'Agencia no informada';

      return capitalizeText(this.agencia?.denominacion || '-');
    },
  },
  methods: {
    capitalize(text: string): string {
      return capitalizeText(text);
    },
  },
});
