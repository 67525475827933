











import Vue from 'vue';

export default Vue.extend({
  props: {
    days: Array,
  },
  data() {
    return {
      allDays: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
      daysSelected: this.days,
    };
  },
  watch: {
    daysSelected() {
      this.$emit('updateDays', this.daysSelected);
    },
  },
  methods: {
    toggleDay(day: number) {
      if (this.daysSelected.includes(day)) {
        this.daysSelected.splice(this.daysSelected.indexOf(day), 1);
      } else {
        this.daysSelected.push(day);
      }
      this.daysSelected.sort();
    },
  },
});
