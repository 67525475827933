

































import Vue from 'vue';
import IconInfo from '@/components/icons/IconInfo.vue';
import { propOfType, toTwoDecimals } from '@/services/utils';
import { Importe } from '@/models/taaxii.com/taaxiiImporte';
import { CostosInfo } from '@/models/requests-and-responses/sgv';

export default Vue.extend({
  components: {
    IconInfo,
  },
  props: {
    sharedData: { type: Object, default: () => null },
    costs: propOfType<CostosInfo | null>(null),
  },
  computed: {
    detalles(): Importe[] | null {
      // Tenemos que swapear 2 elementos de esta lista para cumplir con
      // el requerimiento escrito en uno de los comentarios de este ticket:
      // https://taaxii.atlassian.net/browse/TX-1721
      if (this.costs) {
        const detalles = [...this.costs.detalle];
        const [espera, kilometros] = detalles;
        detalles[0] = kilometros;
        detalles[1] = espera;
        return detalles;
      }
      return null;
    },
  },
  methods: {
    getPesos(value): string {
      return `${toTwoDecimals(value)} $`;
    },
    getCantidad(value): string {
      return value.cantidad ? `${toTwoDecimals(value.cantidad)} ${value.unidad}` : '';
    },
  },
});
