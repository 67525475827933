






























































import Vue from 'vue';
import { Person } from '@/models/Person';
import {
  Evento, fromIndexParadaToLabel, SeguimientoData, CarActualPosition, TrackerPosition,
  Direccion, PassengerModel,
} from '@/models/requests-and-responses/seguimiento';
import {
  propOfType, propOfTypeArrayOf, getPassengersList, formatStringNumber, toTwoDecimals,
} from '@/services/utils';
import msg from '@/services/userMsg';
import IconAlert from '@/components/icons/IconAlert.vue';
import PassengerList from './components/PassengersList.vue';
import Timeline from './timeline/indexTimeline.vue';
import Map, { AddressForMap } from './Map.vue';
import shareTrackingButton from './components/shareTrackingButton.vue';
import DriverDesktop from './components/DriverDesktop.vue';
import TrackingSupportButton from './components/TrackingSupportButton.vue';

function fromStatusToLabel(status: string) {
  switch (status) {
    case 'iniciado': return 'en curso';
    default: return status;
  }
}

const url = window.location.href;

export default Vue.extend({
  components: {
    Map,
    DriverDesktop,
    Timeline,
    PassengerList,
    shareTrackingButton,
    TrackingSupportButton,
    IconAlert,
  },
  props: {
    seguimientoData: propOfType<SeguimientoData>(),
    eventos: propOfTypeArrayOf<Evento>(),
    paradas: propOfTypeArrayOf<Direccion>(),
    trackerPositions: propOfTypeArrayOf<TrackerPosition>(),
    carActualPosition: propOfType<CarActualPosition>(null),
    minutesLeft: propOfType<number | null>(null),
    isRecorridoReal: propOfType<boolean>(false),
    error: propOfType<string | null>(null),
  },
  data() {
    return {
    };
  },
  computed: {
    statusLabel(): string {
      return fromStatusToLabel(this.seguimientoData.status);
    },
    viajeDate(): string {
      const date = new Date(this.seguimientoData.viaje.inicio);
      return date.toLocaleString('es-AR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    },
    viajeTime(): string {
      const date = new Date(this.seguimientoData.viaje.inicio);
      return date.toLocaleString('es-AR', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    },
    passengers(): PassengerModel[] {
      return getPassengersList(this.seguimientoData);
    },
    addressesOfMap(): AddressForMap[] {
      return this.paradas.map((direccion, index) => ({
        lat: direccion.lat,
        lon: direccion.lon,
        tag: fromIndexParadaToLabel(index, this.seguimientoData.paradas.length),
      }));
    },
    distanciaCotizada(): string {
      return formatStringNumber(toTwoDecimals(this.seguimientoData.viaje.distanciaCotizada));
    },
    recorridoLabel(): string {
      return `Recorrido cotizado · ${this.distanciaCotizada} km`;
    },
  },
  methods: {
    share(option: number): void {
      const text = encodeURI(`Seguí tu pedido del ${this.viajeDate}`
        + ` ${this.viajeTime}hs desde ${url}`);
      switch (option) {
        case 0:
          window.open(`https://web.whatsapp.com/send?text=${text}`);
          break;
        case 1:
          window.open(`mailto:?to=&body=${text}`);
          break;
        case 2:
          navigator.clipboard.writeText(url)
            .then(() => { this.$toast.success(msg.getSuccess('url_copied')); });
          break;
        default:
          break;
      }
    },
  },
});
