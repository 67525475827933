

















import Vue from 'vue';
import IconRotate from '@/components/icons/IconRotate.vue';

export default Vue.extend({
  components: { IconRotate },
  data() {
    return { };
  },
});
