



















import Vue from 'vue';
import caja2 from '@/assets/paquetes/caja2.png';
import caja4 from '@/assets/paquetes/caja4.png';
import caja5 from '@/assets/paquetes/caja5.png';
import caja6 from '@/assets/paquetes/caja6.png';
import bolsa from '@/assets/paquetes/bolsa2.jpg';

export const packageSizes = ['Pack H1K', 'Box H2K', 'Box H5K', 'Box H10K', 'Box H15K'] as const;

export type PackageSize = typeof packageSizes[number];

const descriptionOf = { };
descriptionOf['Pack H1K'] = '28 x 41 cm';
descriptionOf['Box H2K'] = '37,5 x 30 x 7 cm';
descriptionOf['Box H5K'] = '39,5 x 30 x 16,5 cm';
descriptionOf['Box H10K'] = '40 x 30 x 30 cm';
descriptionOf['Box H15K'] = '45 x 40 x 45,5 cm';

// En milímetros
const altoOf = { };
altoOf['Pack H1K'] = 280;
altoOf['Box H2K'] = 375;
altoOf['Box H5K'] = 395;
altoOf['Box H10K'] = 400;
altoOf['Box H15K'] = 450;

// En milímetros
const largoOf = { };
largoOf['Pack H1K'] = 410;
largoOf['Box H2K'] = 300;
largoOf['Box H5K'] = 300;
largoOf['Box H10K'] = 300;
largoOf['Box H15K'] = 400;

// En milímetros
const anchoOf = { };
anchoOf['Pack H1K'] = 50; // Antes era 0, lo cambié para que tenga algo.
anchoOf['Box H2K'] = 70;
anchoOf['Box H5K'] = 165;
anchoOf['Box H10K'] = 300;
anchoOf['Box H15K'] = 455;

// En kilogramos
const pesoOf = { };
pesoOf['Pack H1K'] = 1;
pesoOf['Box H2K'] = 2;
pesoOf['Box H5K'] = 5;
pesoOf['Box H10K'] = 10;
pesoOf['Box H15K'] = 15;

export function dimensionesOf(p: PackageSize): number[] {
  return [altoOf[p], largoOf[p], anchoOf[p], pesoOf[p]];
}

const srcOf = {};
srcOf['Pack H1K'] = bolsa;
srcOf['Box H2K'] = caja2;
srcOf['Box H5K'] = caja4;
srcOf['Box H10K'] = caja5;
srcOf['Box H15K'] = caja6;

export default Vue.extend({
  components: {
  },
  props: {
    selected: Boolean,
    packageSize: String, // En realidad, de tipo PackageSize.
  },
  data() {
    return {
      hover: Boolean,
      descriptionOf,
      srcOf,
      pesoOf,
    };
  },
  computed: {
  },
  methods: {
  },
});
