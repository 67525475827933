









































import Vue from 'vue';
import Pedido from '@/models/Pedido';
import IconClose24 from '@/components/icons/IconClose24.vue';
import { propOfType } from '@/services/utils';
import { api, getHeaders } from '@/services/http';

export default Vue.extend({
  props: {
    pedido: propOfType<Pedido>(),
    href: String,
  },
  components: {
    IconClose24,
  },
  data() {
    return {
      infoViaje: null as any,
      infoMapa: null as any,
      isViajeLoaded: false,
      isMapaLoaded: false,
      isTimelineLoaded: false,
      stars: 0,
      solicitaRevisar: false,
      loading: false,
      message: '',
      starHint: 0,
      expanded: false,
    };
  },
  methods: {
    onConfirm() {
      this.loading = true;
      api.delete(this.href, {
        headers: getHeaders(),
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      }).finally(() => {
        this.loading = false;
        this.$emit('update');
        this.$emit('close');
      });
    },
  },
  computed: {
    isMobile(): boolean {
      return this.$store.getters.isMobile;
    },
  },
});
