import { render, staticRenderFns } from "./AddressesDetail.vue?vue&type=template&id=6cfc2566&scoped=true&"
import script from "./AddressesDetail.vue?vue&type=script&lang=ts&"
export * from "./AddressesDetail.vue?vue&type=script&lang=ts&"
import style0 from "./AddressesDetail.vue?vue&type=style&index=0&id=6cfc2566&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cfc2566",
  null
  
)

export default component.exports