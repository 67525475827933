import { render, staticRenderFns } from "./MainTrackingMobile.vue?vue&type=template&id=6dc2c06e&scoped=true&"
import script from "./MainTrackingMobile.vue?vue&type=script&lang=ts&"
export * from "./MainTrackingMobile.vue?vue&type=script&lang=ts&"
import style0 from "./MainTrackingMobile.vue?vue&type=style&index=0&id=6dc2c06e&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dc2c06e",
  null
  
)

export default component.exports