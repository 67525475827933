


















import { Empleado } from '@/models/employees/Empleado';
import { propOfType } from '@/services/utils';
import Vue from 'vue';
import msg from '@/services/userMsg';
import { Cliente } from '@/models/employees/Cliente';
import { editEmpleado } from '@/services/api/empleadosApi';
import { EditEmpleadoRequestBody } from '@/models/requests-and-responses/empleados';
import { ErrorCode, errorCodeFromAxiosError } from '@/models/ErrorCode';
import { Group } from '@/models/requests-and-responses/grupos';
import EditUserForm, {
  Model, ValidationError, validationErrorToString, noError,
} from '../shared/EditUserForm.vue';

// --- RequestStatus

// Estado de la llamada a la API

type RequestStatus =
  | { status: 'notAsked' }
  | { status: 'loading' }
  | { status: 'error', error: ErrorCode }

function notAsked(): RequestStatus {
  return { status: 'notAsked' };
}

// --- Model

// Funciones relacionadas al modelo de datos del formulario.

// Obtener un modelo del EditUserForm a partir de las props.
function modelFromEmpleado(
  empleado: Empleado,
): Model {
  return {
    nombre: empleado.nombre,
    apellido: empleado.apellido,
    dni: empleado.dni,
    legajo: empleado.legajo || '',
    celular: empleado.celular || '',
    email: empleado.email || '',
    activo: empleado.activo,
    permisos: empleado.permisos,
    centrosDeCostoHabilitados: empleado.idCentrosDeCostoHabilitados,
    centroDeCostoPreferido: empleado.idCentroDeCostoPreferido || -1,
    idCentrosDeCostoAuditados: empleado.idCentrosDeCostoAuditados || [],
    idGruposAuditados: empleado.idGruposAuditados || [],
    idFormaDePagoPorDefecto: empleado.formaDePagoPorDefecto?.id || 0,
    idGrupo: empleado.grupo?.id || -1,
  };
}

// Obtener el request body en el formato necesario para enviar la petición al backend.
function modelToRequestBody(idCuentaCorporativa: number, model: Model): EditEmpleadoRequestBody {
  return {
    idCuentaCorporativa,
    nombre: model.nombre.trim(),
    apellido: model.apellido.trim(),
    dni: model.dni.trim(),
    legajo: model.legajo.trim(),
    celular: model.celular.trim(),
    email: model.email.trim(),
    idCentrosDeCostoHabilitados: model.centrosDeCostoHabilitados,
    idCentrosDeCostoAuditados: model.idCentrosDeCostoAuditados || [],
    idGruposAuditados: model.idGruposAuditados || [],
    activo: model.activo,
    permisos: model.permisos,
    idCentroDeCostoPreferido: model.centroDeCostoPreferido,
    idFormaDePagoPorDefecto: model.idFormaDePagoPorDefecto,
    tipoDni: 'AR_DNI',
    soporteTelefonico: null,
    idGrupo: model.idGrupo || null,
  };
}

// --- Componente

export default Vue.extend({
  props: {
    empleado: propOfType<Empleado>(),
    cliente: propOfType<Cliente>(),
    groups: propOfType<Group[]>(),
    onClose: propOfType<Function>(() => () => {}),
    onSave: propOfType<Function>(() => () => {}),
  },
  components: { EditUserForm },
  data() {
    return {
      idEmpleado: this.empleado.id,
      model: modelFromEmpleado(this.empleado),
      save: notAsked(),
      showValidationError: false,
      showErrorToast: true,
      validationError: noError(),
    };
  },
  computed: {
    validationMessage(): string | null {
      if (!this.showValidationError) {
        return null;
      }

      return validationErrorToString(this.validationError);
    },
    errorMessage(): string | null {
      return this.save.status === 'error' ? this.errorMsg(this.save.error) : this.validationMessage;
    },
  },
  watch: {
    errorMessage(value, oldValue) {
      // Muestra un $toast cada vez que hay un error.
      if (value !== null && value !== oldValue && this.showErrorToast) {
        this.$toast.error(value);
        this.showErrorToast = false;
      }
      if (value === null) {
        this.showErrorToast = true;
      }
    },
    model() {
      if (this.validationError.tag === 'noError') {
        this.showValidationError = false;
      }
    },
  },
  methods: {
    onValidationErrorChange(validationError: ValidationError): void {
      this.validationError = validationError;
    },
    onSaveButtonClick(): void {
      if (this.validationError.tag === 'errors') {
        if (this.errorMessage) {
          this.$toast.error(this.errorMessage);
        }
        this.showValidationError = true;
        return;
      }

      if (this.save.status === 'loading') {
        return;
      }

      this.save = { status: 'loading' };

      const { permisos } = this.model;

      if (!permisos.showReports || permisos.showClientPedidos) {
        this.model.idCentrosDeCostoAuditados = [];
        this.model.idGruposAuditados = [];
      }
      if (permisos.showCeCoPedidos) {
        this.model.idGruposAuditados = [];
      }
      if (permisos.showGroupPedidos) {
        this.model.idCentrosDeCostoAuditados = [];
      }

      editEmpleado(modelToRequestBody(this.idEmpleado, this.model))
        .then(() => {
          this.onSave(this.idEmpleado);
          this.showValidationError = false;
          this.$toast.success(msg.getSuccess('user_edited'));
        })
        .catch((axiosError) => {
          const error = errorCodeFromAxiosError(axiosError);

          if (error === null) {
            this.$toast(msg.getError(error));
          } else {
            this.save = { status: 'error', error };
          }
        });
    },
    errorMsg(code: string): string {
      return msg.getError(code);
    },
  },
});

