




















// https://vue-bottom-navigation.netlify.app/v2/guide/ring
import Vue from 'vue';
import { RingBottomNavigation } from 'bottom-navigation-vue';
import IconEdit from '@/components/icons/IconEdit.vue';
import IconList from '@/components/icons/IconList.vue';
import IconSupport from '@/components/icons/IconSupport.vue';
import IconLogout from '@/components/icons/IconLogout.vue';
import { dispatch } from '@/services/store/utils';
import { propOfType } from '@/services/utils';

export default Vue.extend({
  components: {
    RingBottomNavigation,
    IconEdit,
    IconList,
    IconSupport,
    IconLogout,
  },
  props: {
    refreshDashboard: Function,
    isLogOut: propOfType<boolean>(false),
  },
  data: () => ({
    selected: 1,
    lastSelected: 1,
    wasReverted: false,
    options: [
      {
        id: 1, icon: IconEdit, title: 'Nuevo pedido', route: 'crear',
      },
      {
        id: 2, icon: IconList, title: 'Mis Pedidos', route: 'pedidos',
      },
      {
        id: 3, icon: IconSupport, title: 'Soporte', route: 'soporteMobile',
      },
      {
        id: 4, icon: IconLogout, title: 'Cerrar Sesion', route: 'login',
      },
    ],
  }),
  created() {
    this.$router.beforeEach((to, from, next) => {
      let id;
      if (to.name === 'create') {
        id = 1;
      } else {
        id = this.options.find((element) => element.route === to.name)?.id;
      }
      if (id) {
        this.lastSelected = this.selected;
        this.selected = id;
      }
      next();
    });
  },
  mounted() {
    const route = this.$router.currentRoute.name;
    const id = this.options.find((option) => option.route === route)?.id;
    if (id) {
      this.selected = id;
    }
    const navbar = this.$refs.navbar as any;
    const navbarElement = navbar.$el as HTMLElement;
    dispatch('updateNavbarHeight', navbarElement.offsetHeight);
  },
  beforeDestroy() {
    dispatch('updateNavbarHeight', 0);
  },
  watch: {
    selected(newValue) {
      if (this.wasReverted) {
        this.wasReverted = false;
        return;
      }
      const selectedOption = this.options.find((option) => option.id === newValue);
      if (selectedOption?.route === 'login') {
        this.$emit('update:isLogOut', true);
        return;
      }
      if (selectedOption?.route
        && this.$router.currentRoute.name !== selectedOption.route) {
        this.$router.push(selectedOption.route);
      } else {
        this.refreshDashboard();
      }
    },
  },
  methods: {
    navbarPressed() {
      // Al apretar en mis pedidos se vuelve a la lista de pedidos
      // limpiando los query params.
      const selectedOption = this.options.find((option) => option.id === this.selected);
      if (selectedOption?.route === 'pedidos') {
        const route = this.$route;
        const queryParams = { ...route.query };
        delete queryParams.selected;
        delete queryParams.modal;
        if (!this.areQueryParamsEqual(route.query, queryParams)) {
          this.$router.push({ path: route.path, query: queryParams });
        }
      }
    },
    // Aca se verifica si los query params son iguales para evitar una
    // redireccion innecesaria ya que si cambia el route al mismo lugar donde
    // se encuentra salta un error en consola.
    areQueryParamsEqual(queryParams1, queryParams2) {
      const keys1 = Object.keys(queryParams1);
      const keys2 = Object.keys(queryParams2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        if (queryParams1[key] !== queryParams2[key]) {
          return false;
        }
      }

      return true;
    },
    revertOption() {
      this.wasReverted = true;
      this.selected = this.lastSelected;
    },
  },
});
