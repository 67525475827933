import Vue from 'vue';
import VueResource from 'vue-resource';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VTooltip from 'v-tooltip';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import DateRangePicker from 'vue2-daterange-picker';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import '@/components/vue-tailwind-config';
import VuePhoneNumberInput from 'maz-ui/lib/maz-phone-number-input';
// eslint-disable-next-line
import VueTour from 'vue-tour';
import bottomNavigationVue from 'bottom-navigation-vue';
import Router from './services/router';
import Store from './services/store';
import App from './App.vue';
import 'maz-ui/lib/css/base.css';
import 'maz-ui/lib/css/maz-input.css';
import 'maz-ui/lib/css/maz-phone-number-input.css';
// eslint-disable-next-line
import 'vue-tour/dist/vue-tour.css';
import './assets/styles.pcss';
import './services/filters';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { logFirebaseEvent, addFirebaseUserId, addFirebaseUserProperties } from './services/api/firebaseApi';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

Vue.use(VueResource);
Vue.use(Toast, {
  hideProgressBar: true,
  timeout: 2500,
});
Vue.use(bottomNavigationVue);
Vue.use(VTooltip);
Vue.use(VueTour as any);
Vue.component('v-date-picker', DatePicker);
Vue.component('date-range-picker', DateRangePicker);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

Vue.prototype.$analytics = analytics;
Vue.prototype.$addUserId = addFirebaseUserId;
Vue.prototype.$logEvent = logFirebaseEvent;
Vue.prototype.$addUserProperties = addFirebaseUserProperties;

/* Register global components */
const requireComponent = require.context(
  './components',
  false,
  /Base[A-Z]\w+\.(vue)$/,
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = fileName
    .replace(/^\.\//, '')
    .replace(/\.\w+$/, '');

  Vue.component(
    componentName,
    componentConfig.default || componentConfig,
  );
});

// eslint-disable-next-line
export const eventBus = new Vue({});

new Vue({ // eslint-disable-line no-new
  el: '#app',
  template: '<App/>',
  router: Router,
  store: Store,
  render: (h) => h(App),
});
