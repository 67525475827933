






































import Vue from 'vue';
import { put } from '@/services/http';
import LoadingFullscreen from '../../LoadingFullscreen.vue';

export default Vue.extend({
  components: {
    LoadingFullscreen,
  },
  computed: {
    confirmationToken(): string {
      return this.$route.params.confirmationToken || 'no confirmation token';
    },
    siteText(): { [key: string]: string } {
      return !this.error ? {
        title: '¡Felicitaciones, ya sos parte de InPunto!',
        subtitle: 'La cuenta fue creada con éxito.',
      } : {
        title: 'Ha ocurrido un error',
        subtitle: 'Su cuenta no ha podido ser verificada, por favor volvé a intentarlo en unos minutos.',
      };
    },
  },
  mounted() {
    this.isLoading = true;
    this.confirmAccount();
  },
  data() {
    return {
      isLoading: true,
      showSpinner: false,
      error: false,
    };
  },
  methods: {
    confirmAccount() {
      this.showSpinner = true;
      put('/usuarios/registro', this.confirmationToken)
        .then(() => { this.error = false; })
        .catch(() => { this.error = true; })
        .finally(() => {
          this.isLoading = false;
          this.showSpinner = false;
        });
    },
    onNavigate(route) {
      this.$router.push({ name: route });
    },
  },
});
