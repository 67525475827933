class Pasaje {
  constructor(data) {
    this.servicio = data.servicio;
    this.cliente = data.cliente;
    this.origen = data.origen;
    this.destino = data.destino;
    this.ascenso = data.ascenso;
    this.descenso = data.descenso;
    // this.duracion = data.duracion;
    this.precotizacion = data.precotizacion;
  }
}

export default Pasaje;
