





















































import Vue from 'vue';
import msg from '@/services/userMsg';
import { pedidos } from '@/services/api/pedidosApi';
import { eventBus } from '@/main';
import { post } from '@/services/http';
import {
  getMessaging, onMessage, getToken, MessagePayload,
} from 'firebase/messaging';
import { Novedad } from '@/models/Notificaciones';
import IconX20 from '@/components/icons/IconX20.vue';
import IconBell from '../../../components/icons/IconBell.vue';
import IconBellSlash from '../../../components/icons/IconBellSlash.vue';
import NotificationItem from './NotificationItem.vue';

export default Vue.extend({
  components: {
    IconBell,
    IconBellSlash,
    IconX20,
    NotificationItem,
  },
  data() {
    return {
      notificationsOn: false,
      isOpen: false,
      pedidoSelected: false,
    };
  },
  mounted() {
    const messaging = getMessaging();
    // Rutina para checkear estado de las notificaciones del navegador.
    if (Notification.permission === 'granted') {
      this.backendRegister();
      this.notificationsOn = true;
    } else {
      setTimeout(() => {
        if (Notification.permission === 'denied') this.notificationsOn = false;
        else {
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              this.backendRegister();
              this.notificationsOn = true;
            } else this.notificationsOn = false;
          });
        }
      }, 5000);
    }
    onMessage(messaging, (payload) => {
      this.notificationHandler(payload);
    });
    const channel = new window.BroadcastChannel('sw-messages');
    channel.addEventListener('message', ({ data }) => {
      this.notificationHandler(data);
    });
  },
  created() {
    eventBus.$on('pedidoSelected', (isPedidoSelected) => {
      this.pedidoSelected = isPedidoSelected;
    });
  },
  methods: {
    backendRegister(): void {
      const messaging = getMessaging();
      getToken(messaging).then((token) => {
        // Se registran las notificaciones en el backend.
        const username = localStorage.getItem('username') || '';
        post('/notificaciones/devices', { app: 'in.web', username, token });
        this.$store.dispatch('notifications/setMessagingInstance', messaging);
      });
    },
    copy(str: string) {
      navigator.clipboard.writeText(str)
        .then(() => this.$toast.success(msg.getSuccess('string_copied')));
    },
    checkNotificationsOnClick(): void {
      if (this.notificationsOn) {
        this.isOpen = true;
        this.$store.commit('notifications/clearNotifications');
      } else this.$toast.info(msg.getInfo('notifications_disabled'));
    },
    notificationHandler({ data }: MessagePayload):void {
      if (data?.type?.includes('PEDIDO')) {
        if (this.$route.path === '/pedidos') {
          this.$store.commit('notifications/setRefreshNotification', true);
          this.$store.commit('notifications/setRefreshTooltip', 'Tienes novedades pendientes de recibir!');
          if (data.type === 'PEDIDO_CONFIRMADO') {
            eventBus.$emit('updateConfirmado', data.idViaje, data.idSolicitud);
          }
        }
        const { idSolicitud, idViaje } = data;
        const estado = formatearEstado(data.type);
        const subestado = data.substatus ? data.substatus : null;
        const tipoExtras = idViaje === 'null' ? 's' : 'v';
        const id = tipoExtras === 's' ? idSolicitud : idViaje;

        pedidos.getPedidoById(tipoExtras, id)
          .then((res) => {
            const [fecha, hora] = formatearDatetime(res[0].datetime);
            const nuevaNovedad = {
              idSolicitud,
              idViaje,
              fecha,
              hora,
              estado,
              subestado,
            } as Novedad;
            this.$store.commit('notifications/addNovedad', nuevaNovedad);
          });
      }
    },
    clearNotifications(): void {
      this.$store.commit('notifications/clearNovedades');
    },
    closeNotificationWindow(): void {
      this.isOpen = false;
      this.$store.commit('notifications/clearNotifications');
    },
  },
  computed: {
    novedades(): Novedad[] {
      return this.$store.state.notifications.novedades;
    },
    novedadesLength(): number {
      return this.$store.getters['notifications/getNovedadesLength'];
    },
    n_notifications(): number {
      return this.$store.state.notifications.n_notifications;
    },
  },
});

function formatearEstado(estado:string):string {
  const indiceGuionBajo = estado.indexOf('_');
  const partePosterior = estado.substring(indiceGuionBajo + 1).toLowerCase();
  const resultado = partePosterior.charAt(0).toUpperCase() + partePosterior.slice(1);
  return resultado;
}
function formatearDatetime(date: Date):[string, string] {
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const hour = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const fecha = `${day} ${month}`;
  const hora = `${hour}:${minutes}`;
  return [fecha, hora];
}
